
export let TransplantInfoLabels = {
    BoneMarrow: 'Bone Marrow/Stem Cell',
    Heart: 'Heart',
    Intestine: 'Intestine',
    Kidney: 'Kidney',
    SkinGrafts: 'Skin Grafts',
    Liver: 'Liver',
    Lung: 'Lung',
    Pancreas: 'Pancreas',
    CornealImplants: 'Corneal Implants',
    Other:'Other:'
}
