import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionBComponent } from './section-b.component';
import { IndividualRelationComponent } from './individual-relation/individual-relation.component';

const routes: Routes = [
  {
    path: '',
    component: SectionBComponent,
    children: [
      {
        path: 'individual-relation',
        component: IndividualRelationComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionBRoutingModule { }
