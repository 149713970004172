import { Component, OnInit } from '@angular/core';
import { DataService, SectionDropdownService, SectionDService, AlertsService } from '../../shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmergencyVisit, EmergencyVisitFields } from './emergency-visits';
import { DatePipe } from '@angular/common';
import { validateFutureDate } from '../../shared/validators/AlocDateValidators';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-emergency-visit',
    templateUrl: './emergency-visit.component.html',
    styleUrls: ['./emergency-visit.component.css']
})
export class EmergencyVisitComponent implements OnInit {

    constructor(private cntxService: DataService,
        private dropdownService: SectionDropdownService,
        private sectionDService: SectionDService,
        private alerts: AlertsService) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    medicalVisitsForm: FormGroup;
    screenName = "Emergency Visits";
    showERQuest = false;
    showUCQuest = false;
    showHOSQuest = false;
    showBHquest = false;
    showNFquest = false;

    ngOnInit() {
        this.documentReferance = { alocid: '', sectionid: '', name: '' };;
        this.documentReferance.alocid = this.cntxService.getSelectedDocument();
        this.documentReferance.name = this.cntxService.getSelectedDocumentName();

        this.initForm();

        this.loadData();
    }

    initForm() {

        //this.medicalVisitsForm
        let formFields = {};
        for (let fld in EmergencyVisitFields) {
            formFields[fld] = new FormControl();
        }
        this.medicalVisitsForm = new FormGroup(formFields);
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_ACAT_ID).setValidators([Validators.required]);
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_IND).setValidators([Validators.required]);
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_URGENT_IND).setValidators([Validators.required]);
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_IND).setValidators([Validators.required]);
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_IND).setValidators([Validators.required]);
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_IND).setValidators([Validators.required]);

        //config questions

        //ER
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_IND).valueChanges.subscribe(val => {
            if (val == 'Y') {
                this.showERQuest = true;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_NAME).setValidators([Validators.required]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_NUMBER).setValidators([Validators.required]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_REASON).setValidators([Validators.required]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_DATE).setValidators([Validators.required, validateFutureDate]);
            } else {
                this.showERQuest = false;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_NAME).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_NAME).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_NUMBER).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_NUMBER).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_REASON).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_REASON).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_DATE).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_DATE).reset();
            }
            this.updateValidity();
        });

        //UC
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_URGENT_IND).valueChanges.subscribe(val => {
            if (val == 'Y') {
                this.showUCQuest = true;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_URGENT_NUMBER).setValidators([Validators.required]);
            } else {
                this.showUCQuest = false;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_URGENT_NUMBER).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_URGENT_NUMBER).reset();
            }
            this.updateValidity();
        });

        //HOSP
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_IND).valueChanges.subscribe(val => {
            if (val == 'Y') {
                this.showHOSQuest = true;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_ADMIT_DATE).setValidators([Validators.required, validateFutureDate]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_DISCHARGE_DATE).setValidators([Validators.required, validateFutureDate]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_NAME).setValidators([Validators.required]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_NUMBER).setValidators([Validators.required]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_REASON).setValidators([Validators.required]);
            } else {
                this.showHOSQuest = false;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_ADMIT_DATE).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_ADMIT_DATE).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_DISCHARGE_DATE).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_DISCHARGE_DATE).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_NAME).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_NAME).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_NUMBER).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_NUMBER).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_REASON).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_REASON).reset();
            }
            this.updateValidity();
        });

        //BEH 
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_IND).valueChanges.subscribe(val => {
            if (val == 'Y') {
                this.showBHquest = true;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_ADMIT_DATE).setValidators([Validators.required, validateFutureDate]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_DISCHARGE_DATE).setValidators([Validators.required,validateFutureDate]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_NAME).setValidators([Validators.required]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_REASON).setValidators([Validators.required]);
            } else {
                this.showBHquest = false;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_ADMIT_DATE).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_ADMIT_DATE).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_DISCHARGE_DATE).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_DISCHARGE_DATE).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_NAME).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_NAME).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_REASON).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_REASON).reset();
            }
            this.updateValidity();
        });

        //NF 
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_IND).valueChanges.subscribe(val => {
            if (val == 'Y') {
                this.showNFquest = true;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_ADMIT_DATE).setValidators([Validators.required,validateFutureDate]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_DISCHARGE_DATE).setValidators([Validators.required,validateFutureDate]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_NAME).setValidators([Validators.required]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_NUMBER).setValidators([Validators.required]);
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_REASON).setValidators([Validators.required]);
            } else {
                this.showNFquest = false;
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_ADMIT_DATE).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_ADMIT_DATE).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_DISCHARGE_DATE).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_DISCHARGE_DATE).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_NAME).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_NAME).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_NUMBER).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_NUMBER).reset();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_REASON).clearValidators();
                this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_REASON).reset();
            }
            this.updateValidity();
        });

    }

    updateValidity() {
        for (let fld in EmergencyVisitFields) {
            if (!fld.endsWith('_IND'))
                this.medicalVisitsForm.controls[fld].updateValueAndValidity();
        }
    }

    loadData() {
        this.sectionDService.getEmergencyVisits(parseInt(this.documentReferance.alocid)).subscribe((resp: EmergencyVisit) => {
            this.documentReferance.sectionid = resp.tD_SECTION_ID == null ? null : resp.tD_SECTION_ID.toString();
            if (resp.tD_ACAT_ID == null || resp.tD_ACAT_ID == 0) {
                this.alerts.error('No data found for Emergency Visits');
            } else {
                this.alerts.success(`${this.screenName} Loaded`);
            }
            this.setFormData(resp);
        }, error => {
            console.error(error);
            this.alerts.error('Error loading data');
        });
    }

    setFormData(frmData: EmergencyVisit) {
        this.medicalVisitsForm.patchValue(frmData);

        this.medicalVisitsForm.get(EmergencyVisitFields.tD_ER_DATE).setValue(frmData.tD_ER_DATE == null ? null : new Date(frmData.tD_ER_DATE));
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_ADMIT_DATE).setValue(frmData.tD_HOSP_ADMIT_DATE == null ? null : new Date(frmData.tD_HOSP_ADMIT_DATE));
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_HOSP_DISCHARGE_DATE).setValue(frmData.tD_HOSP_DISCHARGE_DATE == null ? null : new Date(frmData.tD_HOSP_DISCHARGE_DATE));
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_ADMIT_DATE).setValue(frmData.tD_NF_ADMIT_DATE == null ? null : new Date(frmData.tD_NF_ADMIT_DATE));
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_NF_DISCHARGE_DATE).setValue(frmData.tD_NF_DISCHARGE_DATE == null ? null : new Date(frmData.tD_NF_DISCHARGE_DATE));
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_ADMIT_DATE).setValue(frmData.tD_BH_ADMIT_DATE == null ? null : new Date(frmData.tD_BH_ADMIT_DATE));
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_BH_DISCHARGE_DATE).setValue(frmData.tD_BH_ADMIT_DATE == null ? null : new Date(frmData.tD_BH_DISCHARGE_DATE));
    }

    onSave() {
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_ACAT_ID).setValue(this.documentReferance.alocid);
        this.medicalVisitsForm.get(EmergencyVisitFields.tD_SECTION_ID).setValue(this.documentReferance.sectionid);

        this.medicalVisitsForm.markAllAsTouched();
        this.medicalVisitsForm.updateValueAndValidity();
        //console.log(this.medicalVisitsForm.value);
        if (this.medicalVisitsForm.valid) {
            let formData = this.medicalVisitsForm.value as EmergencyVisit;
            let df = new DatePipe('en-us');
            formData.tD_BH_ADMIT_DATE = df.transform(formData.tD_BH_ADMIT_DATE, 'MM/dd/yyyy');
            formData.tD_BH_DISCHARGE_DATE = df.transform(formData.tD_BH_DISCHARGE_DATE, 'MM/dd/yyyy');
            formData.tD_ER_DATE = df.transform(formData.tD_ER_DATE, 'MM/dd/yyyy');
            formData.tD_HOSP_ADMIT_DATE = df.transform(formData.tD_HOSP_ADMIT_DATE, 'MM/dd/yyyy');
            formData.tD_HOSP_DISCHARGE_DATE = df.transform(formData.tD_HOSP_DISCHARGE_DATE, 'MM/dd/yyyy');
            formData.tD_NF_ADMIT_DATE = df.transform(formData.tD_NF_ADMIT_DATE, 'MM/dd/yyyy');
            formData.tD_NF_DISCHARGE_DATE = df.transform(formData.tD_NF_DISCHARGE_DATE, 'MM/dd/yyyy');
            //console.log(formData);

            this.sectionDService.saveEmergencyVisits(formData).subscribe(resp => {
                //console.log(resp);
                this.alerts.success(`${this.screenName} Saved`);
                this.loadData();
            }, error => {
                    console.error('error saving data', error);
                    this.alerts.error('Error saving data');
            });

        } else {
            let invCntrls = [];
            Object.keys(this.medicalVisitsForm.controls).forEach(name => {
                if (this.medicalVisitsForm.get(name).invalid) {
                    invCntrls.push(name);
                }
            });
            console.error('invalid controls ', invCntrls);
        }
    }

   

    onClear1() {
        this.medicalVisitsForm.reset();
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.medicalVisitsForm.reset();
      }
    });

  }
  preventPaste(e) {
    e.preventDefault();
    return false;
  }
}
