import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SummaryResponse, TopSummary, SavePAAReviewModel } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class SummaryService {

    constructor(private apiService: ApiService) { }

    getSummary(ACATId): Observable<SummaryResponse> {
        return this.apiService.get('summary', ACATId);//.pipe(map(data => { console.log(data);return data; }));
    }

    PostLOCDetermination(LOCformData) {

        return this.apiService.post('summary/PostLOCDetermination', LOCformData);
    }
    PostMCPReview(MCPformData) {
        return this.apiService.post('summary/PostMCPReview', MCPformData);
    }

    withDraw(acatid): Observable<any> {
        return this.apiService.delete(`summary/WithDraw/${acatid}`);
  }
  Clone(acatid): Observable<any> {
    return this.apiService.post(`summary/Clone/${acatid}`);
  }
  GetClone(acatid): Observable<any> {
    return this.apiService.get(`summary/GetClone/${acatid}`);
  }
    savePAAReview(PAAReview: SavePAAReviewModel): Observable<any> {
        return this.apiService.post(`summary/SavePAAReview`, PAAReview);
    }
}
