import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionCComponent } from './section-c.component';
import { LegalConcernComponent } from './legal-concern/legal-concern.component';
const routes: Routes = [
  {
    path: '',
    component: SectionCComponent,
    children: [
      {
        path: 'legal-concern',
        component: LegalConcernComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionCRoutingModule { }
