import { SectionDropdownService } from '../../shared/services/section-dropdown.service';
import { DropdownOptionsType } from '../../shared/common/DropDownOptionsType';
import { SKNControlType } from './SKNControlType';
import { DynFormField } from './DynFormField';


export class SKNDynFormFields extends DynFormField {
    constructor(private dropdownservice: SectionDropdownService = null) { super(); }

    options: DropdownOptionsType[];
    optionsName: string;
    
    customValidators: string[];
   
    showDepentancy: { cntrlName: string, cntrlValue: string }

    loadOptions = () => {
        if (this.type != SKNControlType.Dropdown || this.dropdownservice == null) return;
        this.dropdownservice.GetDropDownValues(this.optionsName).subscribe(values => {
            this.options = values;
        });
    }

}




