import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SectionCService {

  constructor(private apiService: ApiService) { }
  GetSectionCInfo(alocID): Observable<any> {
    return this.apiService.get(`SectionC/GetSectionCInfo/${alocID}`);
  }
  PostSectionC(formData): Observable<any> {
    return this.apiService.post('SectionC/PostSectionC', formData);
  }

}
