import { Component, OnInit } from '@angular/core';
import { DataService, SectionDropdownService, AlertsService, SectionDService } from '../../shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownOptionsType } from '../../shared/common/DropDownOptionsType';
import { DynFormField } from '../common/DynFormField';
import { SKNControlType } from '../common/SKNControlType';
import { DiagnosisInfo } from '../common/DiagnosisInfo';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { confirmDialogParam } from '../../shared/components/confirm-dialog/confirm-dialog-common';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-diagnoses-info',
    templateUrl: './diagnoses-info.component.html',
    styleUrls: ['./diagnoses-info.component.css']
})
export class DiagnosesInfoComponent implements OnInit {

    constructor(private dataService: DataService,
        private dropdownService: SectionDropdownService,
        private sectionDService: SectionDService,
        private alerts: AlertsService,
        public clearDia: MatDialog) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    DiagnosisForm: FormGroup;

    sourceOfDiagnosis: DropdownOptionsType[];
    showSrcOfDiaOther: boolean = false;

    dynamicFields: DynFormField[] = [];

    ngOnInit() {
        this.documentReferance = { alocid: '', sectionid: '', name: '' };;
        this.documentReferance.alocid = this.dataService.getSelectedDocument();
        this.documentReferance.name = this.dataService.getSelectedDocumentName();
        //initialize form
        this.initForm();

        //load section data
        this.loadData();
    }

    private initForm() {

        //form
        this.DiagnosisForm = new FormGroup({
            'tD_ACAT_ID': new FormControl('', [Validators.required]),
            'tD_SECTION_ID': new FormControl('', [Validators.required]),
            'tD_DX_IND': new FormControl('N', [Validators.required]),
            'srcOfDiagnosis': new FormControl({ value: '', disabled: true }),
            'tD_DX_FAMILY': new FormControl(''),
            'tD_DX_MEDREC': new FormControl(''),
            'tD_DX_CAREGIVER': new FormControl(''),
            'tD_DX_INDIVIDUAL': new FormControl(''),
            'tD_DX_PHYSICIAN': new FormControl(''),
            'tD_DX_OTHER': new FormControl(''),
            'tD_DX_OTHER_DESC': new FormControl(''),
            'tD_DX_COMMENTS': new FormControl({ value: '', disabled: true })
        });

        //config dynamic fields
        this.dynamicFields.push(new DynFormField().config('1.', SKNControlType.TextField, 'tD_DX_1', ''));
        this.dynamicFields.push(new DynFormField().config('2.', SKNControlType.TextField, 'tD_DX_2', ''));
        this.dynamicFields.push(new DynFormField().config('3.', SKNControlType.TextField, 'tD_DX_3', ''));
        this.dynamicFields.push(new DynFormField().config('4.', SKNControlType.TextField, 'tD_DX_4', ''));
        this.dynamicFields.push(new DynFormField().config('5.', SKNControlType.TextField, 'tD_DX_5', ''));
        this.dynamicFields.push(new DynFormField().config('6.', SKNControlType.TextField, 'tD_DX_6', ''));
        this.dynamicFields.push(new DynFormField().config('7.', SKNControlType.TextField, 'tD_DX_7', ''));
        this.dynamicFields.push(new DynFormField().config('8.', SKNControlType.TextField, 'tD_DX_8', ''));
        this.dynamicFields.push(new DynFormField().config('9.', SKNControlType.TextField, 'tD_DX_9', ''));
        this.dynamicFields.push(new DynFormField().config('10.', SKNControlType.TextField, 'tD_DX_10', ''));
        this.dynamicFields.push(new DynFormField().config('11.', SKNControlType.TextField, 'tD_DX_11', ''));
        this.dynamicFields.push(new DynFormField().config('12.', SKNControlType.TextField, 'tD_DX_12', ''));

        this.dynamicFields.forEach((fld) => {
            this.DiagnosisForm.addControl(fld.name, new FormControl({ value: fld.value, disabled: true }));
        })

        //load dia source values
        this.dropdownService.GetDropDownValues('DIAG_SOURCE').subscribe((diaSrcs: DropdownOptionsType[]) => {
            this.sourceOfDiagnosis = diaSrcs;
            //console.log(this.sourceOfDiagnosis);
            this.alerts.clearLoader();
        }, error => {
            console.error('error loading diagnosis source', error);
            this.alerts.clearLoader();
        });

        //dia inticator
        this.DiagnosisForm.get('tD_DX_IND').valueChanges.subscribe(val => {
            let disable = val == 'N' || val==null;
            if (disable) {
                this.DiagnosisForm.get('srcOfDiagnosis').reset();
               // this.DiagnosisForm.get('srcOfDiagnosis').clearValidators();
                this.DiagnosisForm.get('srcOfDiagnosis').disable();
                this.DiagnosisForm.get('srcOfDiagnosis').updateValueAndValidity();

                //set the dia src form fields
                this.DiagnosisForm.get('tD_DX_OTHER').setValue('N');
                this.DiagnosisForm.get('tD_DX_FAMILY').setValue('N');
                this.DiagnosisForm.get('tD_DX_MEDREC').setValue('N');
                this.DiagnosisForm.get('tD_DX_CAREGIVER').setValue('N');
                this.DiagnosisForm.get('tD_DX_INDIVIDUAL').setValue('N');
                this.DiagnosisForm.get('tD_DX_PHYSICIAN').setValue('N');

            } else {
                this.DiagnosisForm.get('srcOfDiagnosis').enable();
              //  this.DiagnosisForm.get('srcOfDiagnosis').setValidators([Validators.required]);
                this.DiagnosisForm.get('srcOfDiagnosis').updateValueAndValidity();
            }
            this.DiagnosisForm.get('tD_DX_OTHER_DESC').reset();
            this.DiagnosisForm.get('tD_DX_OTHER_DESC').clearValidators();

            //dyn cntrls
            this.dynamicFields.forEach((fld) => {
                this.DiagnosisForm.get(fld.name).reset();
                if (disable)
                    this.DiagnosisForm.get(fld.name).disable();
                else
                    this.DiagnosisForm.get(fld.name).enable();
            });

            //comme
            if (disable) {
                this.DiagnosisForm.get('tD_DX_COMMENTS').disable();
                this.DiagnosisForm.get('tD_DX_COMMENTS').reset();
            } else {
                this.DiagnosisForm.get('tD_DX_COMMENTS').enable();
            }

        });

        //config src dia other
        this.DiagnosisForm.get('srcOfDiagnosis').valueChanges.subscribe((diax: string[] | string) => {
            if (diax == null || diax == '') {
                this.showSrcOfDiaOther = false;
                this.DiagnosisForm.get('tD_DX_OTHER_DESC').reset();
                this.DiagnosisForm.get('tD_DX_OTHER_DESC').clearValidators();
                this.DiagnosisForm.get('tD_DX_OTHER_DESC').updateValueAndValidity();
                return;
            }
            if (diax.indexOf('OTHER') > -1) {
                this.showSrcOfDiaOther = true;
              this.DiagnosisForm.get('tD_DX_OTHER_DESC').setValidators([Validators.required]);
              this.DiagnosisForm.get('tD_DX_OTHER_DESC').markAsTouched();
            } else {
                this.showSrcOfDiaOther = false;
                this.DiagnosisForm.get('tD_DX_OTHER_DESC').reset();
                this.DiagnosisForm.get('tD_DX_OTHER_DESC').clearValidators();
                this.DiagnosisForm.get('tD_DX_OTHER_DESC').updateValueAndValidity();
            }
            //set the dia src form fields
            this.DiagnosisForm.get('tD_DX_OTHER').setValue('N');
            this.DiagnosisForm.get('tD_DX_FAMILY').setValue('N');
            this.DiagnosisForm.get('tD_DX_MEDREC').setValue('N');
            this.DiagnosisForm.get('tD_DX_CAREGIVER').setValue('N');
            this.DiagnosisForm.get('tD_DX_INDIVIDUAL').setValue('N');
            this.DiagnosisForm.get('tD_DX_PHYSICIAN').setValue('N');
            for (let da of diax) {
                switch (da) {
                    case 'OTHER':
                        this.DiagnosisForm.get('tD_DX_OTHER').setValue('Y');
                        break;
                    case 'FAMILY':
                        this.DiagnosisForm.get('tD_DX_FAMILY').setValue('Y');
                        break;
                    case 'MED_RECORD':
                        this.DiagnosisForm.get('tD_DX_MEDREC').setValue('Y');
                        break;
                    case 'CAREGIVER':
                        this.DiagnosisForm.get('tD_DX_CAREGIVER').setValue('Y');
                        break;
                    case 'INDIVIDUAL':
                        this.DiagnosisForm.get('tD_DX_INDIVIDUAL').setValue('Y');
                        break;
                    case 'PHYSICIAN':
                        this.DiagnosisForm.get('tD_DX_PHYSICIAN').setValue('Y');
                        break;
                    default:
                        break;
                }
            }
        });
    }

    private loadData() {

        this.sectionDService.getDiagnosis(parseInt(this.dataService.getSelectedDocument())).subscribe((resp: DiagnosisInfo) => {
            this.documentReferance.sectionid = resp.tD_SECTION_ID == null ? null : resp.tD_SECTION_ID.toString();
            this.setFormData(resp);
            if (resp.tD_ACAT_ID == null || resp.tD_ACAT_ID == 0) {
              this.alerts.error('No Diagnoses found.');
            } else {
              this.alerts.success('Diagnoses Loaded');
            }
        }, error => {
            console.error('Error fetching Diagnoses', error);
            this.alerts.error('Error fetching Diagnoses');
        })
    }

    getSourceOfDiaLabels(values: string[]): string {
        let return_string = [];
        if (this.sourceOfDiagnosis == null || values == null) return '';
        this.sourceOfDiagnosis.forEach(src => {
            if (values.indexOf(src.dV_VALUE) > -1) {
                return_string.push(src.dV_EXPANDED_VALUE);
            }
        }
        );
        return return_string.join(',');
    }

    setFormData(formData: DiagnosisInfo) {
        this.DiagnosisForm.patchValue(formData);
        //set diag source values
        let diaSrc: string[] = [];
        if (formData.tD_DX_CAREGIVER == 'Y') {
            diaSrc.push('CAREGIVER');
        }
        if (formData.tD_DX_INDIVIDUAL == 'Y') {
            diaSrc.push('INDIVIDUAL');
        }
        if (formData.tD_DX_FAMILY == 'Y') {
            diaSrc.push('FAMILY');
        }
        if (formData.tD_DX_MEDREC == 'Y') {
            diaSrc.push('MED_RECORD');
        }
        if (formData.tD_DX_PHYSICIAN == 'Y') {
            diaSrc.push('PHYSICIAN');
        }
        if (formData.tD_DX_OTHER == 'Y') {
            diaSrc.push('OTHER');
        }
        this.DiagnosisForm.get('srcOfDiagnosis').patchValue(diaSrc);

        if (formData.tD_DX_IND == null) {
            //this.DiagnosisForm.get('tD_DX_IND').setValue('N');
        }
        
    }

    onSave() {

        this.DiagnosisForm.get("tD_SECTION_ID").setValue(this.documentReferance.sectionid);
        this.DiagnosisForm.get("tD_ACAT_ID").setValue(this.documentReferance.alocid);

        if (this.DiagnosisForm.invalid) {
            let invalidCntrls = [];
            for (let cntrls in this.DiagnosisForm.controls) {
                if (this.DiagnosisForm.controls[cntrls].invalid) {
                    invalidCntrls.push({ 'Invalid Controls': cntrls });
                }
            }
            //console.log(invalidCntrls);
            window.status = "Invalid Form !";
            return;
        }
        this.sectionDService.saveDiagosis(this.DiagnosisForm.value).subscribe((resp) => {
            //console.log(resp);
          this.alerts.success('Diagnoses Saved');
            this.loadData();
        }, (error) => {
            console.error('error saving Diagnoses', error);
            this.alerts.error('Error saving Diagnoses');
        });
    }

    onClear1() {
        let delConfirm: confirmDialogParam = {
            message: ``,
            title: 'Are you sure ?',
            subtitle: `Entered information will be erased. Press 'Ok' to proceed.`
        };
        const clearDialog = this.clearDia.open(ConfirmDialogComponent, {
            width: '500px',
            disableClose: true,
            data: delConfirm
        });
        clearDialog.afterClosed().subscribe(result => {
            if (result == 'yes') {
                this.DiagnosisForm.reset();
            }
        });
        
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.DiagnosisForm.reset();
      }
    });

  }
}
