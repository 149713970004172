import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionGComponent } from './section-g.component';
import { DevelopmentalDisabilityComponent } from './developmental-disability/developmental-disability.component';
import { AcOkComponent } from './ac-ok/ac-ok.component';
import { NutritionalScreenComponent } from './nutritional-screen/nutritional-screen.component';
import { DentalScreenComponent } from './dental-screen/dental-screen.component';
const routes: Routes = [
  {
    path: '',
    component: SectionGComponent,
    children: [
      {
        path: 'ac-ok',
        component: AcOkComponent
      },
      {
        path: 'dental-screen',
        component: DentalScreenComponent
      },
      {
        path: 'developmental-disability',
        component: DevelopmentalDisabilityComponent
      },
      {
        path: 'nutritional-screen',
        component: NutritionalScreenComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionGRoutingModule { }
