import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-section-f',
  templateUrl: './section-f.component.html',
  styleUrls: ['./section-f.component.css']
})
export class SectionFComponent implements OnInit {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/section-f') {
          router.navigate(['/section-f/adls-iadls']);
        }
      }
    });
   
  }

  ngOnInit() {
  }

}
