import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionKComponent } from './section-k.component';
import { HomeAssessComponent } from './home-assess/home-assess.component';
import { HomeSafetyComponent } from './home-safety/home-safety.component';
import { MedSafetyComponent } from './med-safety/med-safety.component';
import { EmergencyNumberComponent } from './emergency-number/emergency-number.component';

const routes: Routes = [
  {
    path: '',
    component: SectionKComponent,
    children: [
      {
        path: 'emergency-number',
        component: EmergencyNumberComponent
      },
      {
        path: 'home-assess',
        component: HomeAssessComponent
      },
      {
        path: 'home-safety',
        component: HomeSafetyComponent
      },
      {
        path: 'med-safety',
        component: MedSafetyComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionKRoutingModule { }
