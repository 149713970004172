import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { validateFutureDate } from '../../../shared/validators/AlocDateValidators';
import { DatePipe } from '@angular/common';
import { PhysicianContact, NewPhysicianContext, NewPhysicianScreenMode } from '../../common/PhysicianContact';
import { SectionDService, AlertsService, DataService } from '../../../shared';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { debounceTime } from 'rxjs/operators';
import { zipCodeResult } from '../../common/ZipCodeResult';
import { confirmDialogParam } from '../../../shared/components/confirm-dialog/confirm-dialog-common';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-add-new-physician-contact',
    templateUrl: './add-new-physician-contact.component.html',
    styleUrls: ['./add-new-physician-contact.component.css']
})
export class AddNewPhysicianContactComponent implements OnInit {

    constructor(private sectionDService: SectionDService,
        private alertService: AlertsService,
        public dialogRef: MatDialogRef<AddNewPhysicianContactComponent>,
        @Inject(MAT_DIALOG_DATA) public cntx: NewPhysicianContext,
      public clearDia: MatDialog,
      private dataService: DataService) { }

  showSave_M: boolean;
  showSave: boolean;
  showMaxPhysician: boolean;
    addNewPhyConForm: FormGroup;
    isZipcodeLoading: boolean;
    counties: any;
    viewOnly = false;
    documentRef: {
        AcatId: string,
        SectionId: string
    }

    ngOnInit() {

        this.documentRef = {
            AcatId: this.cntx.ACATID,
            SectionId: this.cntx.SectionID
        };
        this.initForm();
      this.configFormChanges();

      this.sectionDService.getPhysicians(parseInt(this.dataService.getSelectedDocument())).subscribe((physicians: PhysicianContact[]) => {
        if (physicians.length > 9) {          
          switch (this.cntx.Mode) {
            case NewPhysicianScreenMode.Edit:
              this.showSave_M = false;
              this.showSave = true;
              break;
            case NewPhysicianScreenMode.View:
              this.showSave_M = false;
              this.showSave = false;
              break;
            case NewPhysicianScreenMode.New:
              this.showSave_M = false;
              this.showSave = false;
              this.showMaxPhysician = true;
              this.addNewPhyConForm.disable()
              break;
            default:
              break;
          }
        } else {
          switch (this.cntx.Mode) {
            case NewPhysicianScreenMode.Edit:
              this.showSave_M = true;
              this.showSave = true;
              break;
            case NewPhysicianScreenMode.View:             
              this.showSave_M = false;
              this.showSave = false;
              break;
            case NewPhysicianScreenMode.New:
              this.showSave_M = true;
              this.showSave = true;
              break;
            default:
              break;
          }
        }
        this.alertService.clearLoader();
        
      }, (error) => {
        console.error('Error getting physicians ', error);
          this.alertService.clearLoader();
        });
    }

    initForm() {

        this.addNewPhyConForm = new FormGroup({
            tdP_ACAT_ID: new FormControl(null, [Validators.required]),
            tdP_SECTION_ID: new FormControl(null),
            tdP_FIRST_NAME: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.pattern(/^[a-zA-Z- ]+$/)]),
            tdP_INIT: new FormControl(null, [Validators.pattern(/[a-zA-Z]{1}/)]),
            tdP_LAST_NAME: new FormControl('', [Validators.required, Validators.maxLength(60), Validators.pattern(/^[a-zA-Z- ]+$/)]),
            tdP_TYPE: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.pattern(/^[a-zA-Z ]+$/)]),
            tdP_ADDRESS: new FormControl('', [Validators.maxLength(60), Validators.pattern(/^[a-zA-Z0-9 .]+$/)]),
            tdP_ADDRESS2: new FormControl(null, [Validators.maxLength(60), Validators.pattern(/^[a-zA-Z0-9 .]+$/)]),
            tdP_CITY: new FormControl(null, [Validators.maxLength(60), Validators.pattern(/^[a-zA-Z ]+$/)]),
            tdP_STATE: new FormControl(null, [Validators.maxLength(3), Validators.pattern(/^[a-zA-Z ]+$/)]),
            tdP_ZIP_CODE: new FormControl(null, [Validators.maxLength(5), Validators.pattern(/^\d{5}$/)]),
            tdP_COUNTY: new FormControl(null, [Validators.maxLength(10), Validators.pattern(/^[a-zA-Z ]+$/)]),
            tdP_PHONE_PRIMARY: new FormControl(null, [Validators.required, this.validatePhone]),
            tdP_PHONE_SECONDARY: new FormControl(null, [this.validatePhone]),
            tdP_PHONE_FAX: new FormControl(null, [this.validatePhone]),
            tdP_EMAIL: new FormControl(null, [Validators.maxLength(60), Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]),
            tdP_OTHER_INFO: new FormControl(null, [Validators.maxLength(60)]),
            tdP_DATE_LAST_SEEN: new FormControl(null, [Validators.required, Validators.maxLength(10), validateFutureDate]),
            tdP_PRESCRIBING_IND: new FormControl(null, [Validators.required, Validators.maxLength(1)]),
            tdP_PRIMARY_IND: new FormControl(null, [Validators.required, Validators.maxLength(1)])
        });

        let setFormValue = () => {
            this.addNewPhyConForm.patchValue(this.cntx.SelectedRecord);
            this.addNewPhyConForm.get('tdP_DATE_LAST_SEEN').setValue(this.cntx.SelectedRecord.tdP_DATE_LAST_SEEN == null ? null : new Date(this.cntx.SelectedRecord.tdP_DATE_LAST_SEEN));
            this.addNewPhyConForm.get('tdP_PHONE_PRIMARY').markAsTouched();
            this.addNewPhyConForm.get('tdP_PHONE_PRIMARY').setValue(this.cntx.SelectedRecord.tdP_PHONE_PRIMARY);
        };

        switch (this.cntx.Mode) {
            case NewPhysicianScreenMode.Edit:
                setFormValue();
                break;
            case NewPhysicianScreenMode.View:
                setFormValue();                
                 this.addNewPhyConForm.disable()
                  
                break;
            case NewPhysicianScreenMode.New:
                this.addNewPhyConForm.get('tdP_ACAT_ID').setValue(this.cntx.ACATID);
                break;
            default:
                break;
        }



    }

    private configFormChanges() {

        //load counties
        this.sectionDService.getCounties().subscribe(cntes => {
            this.counties = cntes;
        }, error => {
            console.error('error loading counties ', error);
        });


        //load state city couny
        this.isZipcodeLoading = false;
        this.addNewPhyConForm.get('tdP_ZIP_CODE').valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
            if (this.addNewPhyConForm.get('tdP_ZIP_CODE').invalid)
                return;

            this.isZipcodeLoading = true;
            this.addNewPhyConForm.patchValue({
                tdP_CITY: '',
                tdP_COUNTY: '',
                tdP_STATE: ''
            });

            this.sectionDService.getCityState({ ZipCode: value }).subscribe((resp: zipCodeResult) => {

                if (resp.city != null || resp.countY_CODE != null) {
                    this.addNewPhyConForm.patchValue({
                        tdP_CITY: resp.city,
                        tdP_COUNTY: resp.countY_CODE,
                        tdP_STATE: resp.state == null ? 'OH' : resp.state
                    });
                } else {//invalid zipcode
                    this.addNewPhyConForm.get('tdP_ZIP_CODE').setErrors({ 'invalidZipcode': 'please provide a valid zipcode' });
                }

                //console.log('zip code resp ', resp);
                this.alertService.clearLoader();
                this.isZipcodeLoading = false;
            }, (error) => {
                console.error('error checking zipcode ', error);
                this.alertService.clearLoader();
                this.isZipcodeLoading = false;
            });
        });

    }

    onSubmit(mode) {

        this.addNewPhyConForm.get('tdP_ACAT_ID').setValue(this.cntx.ACATID);
        this.addNewPhyConForm.get('tdP_SECTION_ID').setValue(this.cntx.SectionID);

        if (!this.addNewPhyConForm.valid) {
            console.error('form invalid !')
            Object.keys(this.addNewPhyConForm.controls).forEach(cntrl => {
                if (this.addNewPhyConForm.controls[cntrl].invalid) {
                    console.log(cntrl);
                }
            })
            return;
        }

        this.addNewPhyConForm.get('tdP_PHONE_PRIMARY').setValue(this.addNewPhyConForm.get('tdP_PHONE_PRIMARY').value);
        this.addNewPhyConForm.get('tdP_PHONE_SECONDARY').setValue(this.addNewPhyConForm.get('tdP_PHONE_SECONDARY').value);
        this.addNewPhyConForm.get('tdP_PHONE_FAX').setValue(this.addNewPhyConForm.get('tdP_PHONE_FAX').value);

        let formData = this.addNewPhyConForm.value as PhysicianContact;
        //update last seen date format
        formData.tdP_DATE_LAST_SEEN = (new DatePipe('en-us')).transform(formData.tdP_DATE_LAST_SEEN, 'MM/dd/yyyy');
        //console.log('form data ', formData);
        if (this.cntx.Mode == NewPhysicianScreenMode.New || this.cntx.Mode == NewPhysicianScreenMode.Edit) {

            this.sectionDService.addNewPhysician(formData).subscribe((resp) => {
                //console.info(resp);
                this.alertService.success('Physician Information Saved');
                if (mode == 'save_close') {
                    this.dialogRef.close('success');
                } else if (mode == 'save_add_new') {
                    this.addNewPhyConForm.reset();
                  this.addNewPhyConForm.get('tdP_ACAT_ID').setValue(this.cntx.ACATID);
                  this.cntx.SectionID = null;
                  this.sectionDService.getPhysicians(parseInt(this.cntx.ACATID)).subscribe((physicians: PhysicianContact[]) => {
                    if (physicians.length > 9) {
                      this.showMaxPhysician = true;
                      this.addNewPhyConForm.disable();
                      this.showSave_M = false;
                      this.showSave = false;
                    }
                  }, (error) => {
                    console.error('Error getting physicians ', error);
                    this.alertService.clearLoader();
                  });
                }

            }, resp => {
                console.error('error saving physician ', resp);
                this.alertService.error('Error saving data');
                if (resp.error.details && resp.error.details.oracleCode) {
                    if (resp.error.details.oracleCode == '20100') {
                        let msgs: string[] = resp.error.details.oracleMsg.split('\n');
                        if (msgs.length >= 1) {
                            let f_msg: string[] = msgs[0].split(':');
                            this.alertService.error(f_msg[1]);
                        }
                    }
                }
            });

        } else {

        }




    }

    validatePhone(cntrl: AbstractControl): { [key: string]: any } | null {
        if (cntrl.value == null || cntrl.value == '') {
            return null;
        }
        if (/^\d{10}$/.test(cntrl.value.replace(/-/g, ''))) {
            return null;
        } else {
            //cntrl.markAsTouched();
            return { 'invalidPhoneNumber': 'The phone number is invalid !' };
        }
    }

    clear1() {
        let delConfirm: confirmDialogParam = {
            message: ``,
            title: 'Are you sure ?',
            subtitle: `Entered information will be erased. Press 'Ok' to proceed.`
        };
        const clearDialog = this.clearDia.open(ConfirmDialogComponent, {
            width: '500px',
            disableClose: true,
            data: delConfirm
        });
        clearDialog.afterClosed().subscribe(result => {
            if (result == 'yes') {
                this.addNewPhyConForm.reset();
            }
        });
    }
  clear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.addNewPhyConForm.reset();
      }
    });

  }
  closePhysician() {
    this.dialogRef.close('success');
  }

}
