import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionGService, Nutritional, DropdownModel, getTextForDropdownValues } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nutritional-screen',
  templateUrl: './nutritional-screen.component.html',
  styleUrls: ['./nutritional-screen.component.css']
})
export class NutritionalScreenComponent implements OnInit {
  dropDownSelectText = getTextForDropdownValues;  
  nutritionalForm: FormGroup;
  isSubmitting = false;
  nutritional: Nutritional = {} as Nutritional;
  last3Months: DropdownModel[];
  lastMonth: DropdownModel[];
  currentMonth: DropdownModel[];
    nutirtionsProblems: DropdownModel[];
    checked = true;
  constructor(private fb: FormBuilder,
    private dropdownSerice: SectionDropdownService,
    private alertService: AlertsService,
    public dataService: DataService,
    public sectionGService: SectionGService) {
    this.nutritionalForm = this.fb.group({     
      tG_ACAT_ID: new FormControl(''),
      tG_CURRENT_HEIGHT: new FormControl(''),
      tG_CURRENT_WEIGHT: new FormControl(''),
      tG_DIETICIAN_IND: new FormControl(''),
      tG_WEIGHT_LOSS_IND: new FormControl(''),
      tG_APPETITE_IND: new FormControl(''),
      tG_WEIGHT_GAIN_IND: new FormControl(''),
      tG_EXPERIENCE_NA_IND: new FormControl(''),
      tG_INTAKE_REDUCTION_IND: new FormControl(''),
      tG_ABDOM_SURGERY_IND: new FormControl(''),
      tG_INTAKE_SURGERY_NA_IND: new FormControl(''),
      tG_BITING_IND: new FormControl(''),
      tG_CHEWING_IND: new FormControl(''),
      tG_SWALLOWING_IND: new FormControl(''),
      tG_PROBLEM_NA_IND: new FormControl(''),
      tG_THICKENER_IND: new FormControl(''),
      tG_VOMITING_IND: new FormControl(''),
      tG_NAUSEA_IND: new FormControl(''),
      tG_CONSTIPATION_IND: new FormControl(''),
      tG_GERD_IND: new FormControl(''),
      tG_FOOD_AVERSION_IND: new FormControl(''),
      tG_ALLERGY_IND: new FormControl(''),      
      tG_DIARRHEA_IND: new FormControl(''),      
      tG_MALABSORBTION_IND: new FormControl(''),      
      tG_DED_IND: new FormControl(''),      
      tG_CLEFT_PALATE_IND: new FormControl(''),
      tG_NUTRITION_ISSUE_NA_IND: new FormControl(''),
      tG_DIET_IND: new FormControl(''),
      tG_DIET_COMMENT: new FormControl({ value: '', disabled: true }),
      tG_TUBE_PARENTERAL_IND: new FormControl(''),
      tG_NUTRITION_REFERRAL_IND: new FormControl(''),
      tG_NUTRITION_COMMENTS: new FormControl(''),        
        tG_LAST_3_MONTHS: new FormControl(''),
        tG_LAST_MONTH: new FormControl(''),
        tG_CURRENT_MONTH: new FormControl(''),
        tG_NUTRITIONAL_PROB: new FormControl('')

    });
  }

  ngOnInit() {

    if (this.dataService.getSelectedDocument() != null) {
      this.sectionGService.GetSectionGInfo(this.dataService.getSelectedDocument()).subscribe(response => {
        this.receiveNutritional(response);
        this.alertService.success("Nutritional Screen Loaded");
      }, err => {
        this.alertService.error(err);
      });
    }
    this.loadDropdown();
    this.onChange();
  }
  receiveNutritional(data) {
    this.nutritionalForm.patchValue(data);
    this.setData(data);
  }
  setData(data) {
    
    //if (data['tG_WEIGHT_LOSS_IND'] == 'Y') {      
    //  this.nutritionalForm.get('tG_LAST_3_MONTHS').setValue('WEIGHT_LOSS');
    //}
    //if (data['tG_APPETITE_IND'] == 'Y') {     
    //  this.nutritionalForm.get('tG_LAST_3_MONTHS').setValue('APPETITE');
    //}
    //if (data['tG_WEIGHT_GAIN_IND'] == 'Y') {     
    //  this.nutritionalForm.get('tG_LAST_3_MONTHS').setValue('WEIGHT_GAIN');
    //}
    //if (data['tG_EXPERIENCE_NA_IND'] == 'Y') {    
    //  this.nutritionalForm.get('tG_LAST_3_MONTHS').setValue('EXPERIENCE_NA');
    //}

    let Threemonths_Individual: string[] = [];
    if (data['tG_WEIGHT_LOSS_IND'] == 'Y') {
      Threemonths_Individual.push('WEIGHT_LOSS');
    }
    if (data['tG_APPETITE_IND'] == 'Y') {
      Threemonths_Individual.push('APPETITE');
    }
    if (data['tG_WEIGHT_GAIN_IND'] == 'Y') {
      Threemonths_Individual.push('WEIGHT_GAIN');
    }
    if (data['tG_EXPERIENCE_NA_IND'] == 'Y') {
      Threemonths_Individual.push('EXPERIENCE_NA');
    }
    this.nutritionalForm.get('tG_LAST_3_MONTHS').setValue(Threemonths_Individual);



    if (data['tG_INTAKE_REDUCTION_IND'] == 'T') {
      this.nutritionalForm.get('tG_INTAKE_REDUCTION_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_INTAKE_REDUCTION_IND').setValue(false);
    }

    if (data['tG_ABDOM_SURGERY_IND'] == 'T') {
      this.nutritionalForm.get('tG_ABDOM_SURGERY_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_ABDOM_SURGERY_IND').setValue(false);
    }

    if (data['tG_INTAKE_SURGERY_NA_IND'] == 'T') {
      this.nutritionalForm.get('tG_INTAKE_SURGERY_NA_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_INTAKE_SURGERY_NA_IND').setValue(false);
    }

    if (data['tG_BITING_IND'] == 'T') {
      this.nutritionalForm.get('tG_BITING_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_BITING_IND').setValue(false);
    }

    if (data['tG_CHEWING_IND'] == 'T') {
      this.nutritionalForm.get('tG_CHEWING_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_CHEWING_IND').setValue(false);
    }

    if (data['tG_SWALLOWING_IND'] == 'T') {
      this.nutritionalForm.get('tG_SWALLOWING_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_SWALLOWING_IND').setValue(false);
    }

    if (data['tG_PROBLEM_NA_IND'] == 'T') {
      this.nutritionalForm.get('tG_PROBLEM_NA_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_PROBLEM_NA_IND').setValue(false);
    }

    if (data['tG_VOMITING_IND'] == 'T') {
      this.nutritionalForm.get('tG_VOMITING_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_VOMITING_IND').setValue(false);
    }

    if (data['tG_NAUSEA_IND'] == 'T') {
      this.nutritionalForm.get('tG_NAUSEA_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_NAUSEA_IND').setValue(false);
    }

    if (data['tG_CONSTIPATION_IND'] == 'T') {
      this.nutritionalForm.get('tG_CONSTIPATION_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_CONSTIPATION_IND').setValue(false);
    }

    if (data['tG_GERD_IND'] == 'T') {
      this.nutritionalForm.get('tG_GERD_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_GERD_IND').setValue(false);
    }

    if (data['tG_FOOD_AVERSION_IND'] == 'T') {
      this.nutritionalForm.get('tG_FOOD_AVERSION_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_FOOD_AVERSION_IND').setValue(false);
    }

    if (data['tG_ALLERGY_IND'] == 'T') {
      this.nutritionalForm.get('tG_ALLERGY_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_ALLERGY_IND').setValue(false);
    }

    if (data['tG_DIARRHEA_IND'] == 'T') {
      this.nutritionalForm.get('tG_DIARRHEA_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_DIARRHEA_IND').setValue(false);
    }

    if (data['tG_MALABSORBTION_IND'] == 'T') {
      this.nutritionalForm.get('tG_MALABSORBTION_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_MALABSORBTION_IND').setValue(false);
    }

    if (data['tG_DED_IND'] == 'T') {
      this.nutritionalForm.get('tG_DED_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_DED_IND').setValue(false);
    }

    if (data['tG_CLEFT_PALATE_IND'] == 'T') {
      this.nutritionalForm.get('tG_CLEFT_PALATE_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_CLEFT_PALATE_IND').setValue(false);
    }

    if (data['tG_NUTRITION_ISSUE_NA_IND'] == 'T') {
      this.nutritionalForm.get('tG_NUTRITION_ISSUE_NA_IND').setValue(true);
    }
    else {
      this.nutritionalForm.get('tG_NUTRITION_ISSUE_NA_IND').setValue(false);
    }
  }
  loadDropdown() {
    this.dropdownSerice.GetDropDownValues('NUTRI_3_MONS').subscribe(data => {
      this.last3Months = data;
      console.log(data);
    }, error => {
      this.alertService.error(error);
    });
  }

    onChange() {
        this.nutritionalForm.get('tG_DIET_IND').valueChanges.subscribe(diet => {          
            if (diet == 'Y') {
                this.nutritionalForm.get('tG_DIET_COMMENT').enable();
                this.nutritionalForm.get('tG_DIET_COMMENT').setValidators(Validators.required);
                this.nutritionalForm.controls['tG_DIET_COMMENT'].markAsTouched();
              
            }
            else {
                this.nutritionalForm.get('tG_DIET_COMMENT').setValue('');
                this.nutritionalForm.get('tG_DIET_COMMENT').disable();
            }
        });



        this.nutritionalForm.get('tG_INTAKE_SURGERY_NA_IND').valueChanges.subscribe(na => {
            if (na) {
                this.nutritionalForm.get('tG_ABDOM_SURGERY_IND').setValue(false);
                this.nutritionalForm.get('tG_ABDOM_SURGERY_IND').disable();
                this.nutritionalForm.get('tG_INTAKE_REDUCTION_IND').setValue(false);
                this.nutritionalForm.get('tG_INTAKE_REDUCTION_IND').disable();
            } else {
                this.nutritionalForm.get('tG_ABDOM_SURGERY_IND').enable();
                this.nutritionalForm.get('tG_INTAKE_REDUCTION_IND').enable();
            }
        });
      //this.nutritionalForm.get('tG_LAST_3_MONTHS').valueChanges.subscribe(na => {
      //  let threeMonthList: [] = this.nutritionalForm.get('tG_LAST_3_MONTHS').value;
      //  if (Array.isArray(threeMonthList)) {
      //    threeMonthList.forEach(value => {
      //      switch (value) {
      //        case 'EXPERIENCE_NA':
      //          this.nutritionalForm.get('tG_EXPERIENCE_NA_IND').setValue('Y');
      //          this.nutritionalForm.get('tG_WEIGHT_LOSS_IND').setValue(null);
      //          this.nutritionalForm.get('tG_APPETITE_IND').setValue(null);
      //          this.nutritionalForm.get('tG_WEIGHT_GAIN_IND').setValue(null);
      //          break;
      //        case 'WEIGHT_LOSS':
      //          this.nutritionalForm.get('tG_WEIGHT_LOSS_IND').setValue('Y');
      //          this.nutritionalForm.get('tG_EXPERIENCE_NA_IND').setValue(null);
      //          break;
      //        case 'APPETITE':
      //          this.nutritionalForm.get('tG_APPETITE_IND').setValue('Y');
      //          this.nutritionalForm.get('tG_EXPERIENCE_NA_IND').setValue(null);
      //          break;
      //        case 'WEIGHT_GAIN':
      //          this.nutritionalForm.get('tG_WEIGHT_GAIN_IND').setValue('Y');
      //          this.nutritionalForm.get('tG_EXPERIENCE_NA_IND').setValue(null);
      //          break;
      //        default:
      //          break;
      //      }
      //    });
      //  }
      //  let Threemonths_Individual1: string[] = [];
      //  if (this.nutritionalForm.get('tG_WEIGHT_LOSS_IND').value == 'Y') {
      //    Threemonths_Individual1.push('WEIGHT_LOSS');
      //  }
      //  if (this.nutritionalForm.get('tG_APPETITE_IND').value == 'Y') {
      //    Threemonths_Individual1.push('APPETITE');
      //  }
      //  if (this.nutritionalForm.get('tG_WEIGHT_GAIN_IND').value == 'Y') {
      //    Threemonths_Individual1.push('WEIGHT_GAIN');
      //  }
      //  if (this.nutritionalForm.get('tG_EXPERIENCE_NA_IND').value == 'Y') {
      //    Threemonths_Individual1.push('EXPERIENCE_NA');
      //  }
      //  this.nutritionalForm.get('tG_LAST_3_MONTHS').setValue(Threemonths_Individual1);
        
      //});

        this.nutritionalForm.get('tG_BITING_IND').valueChanges.subscribe(v => {
            console.log(v);
        });

        this.nutritionalForm.get('tG_PROBLEM_NA_IND').valueChanges.subscribe(na => {
            if (na) {

                this.nutritionalForm.get('tG_BITING_IND').setValue(false);
                this.nutritionalForm.get('tG_BITING_IND').disable();

                this.nutritionalForm.get('tG_CHEWING_IND').setValue(false);
                this.nutritionalForm.get('tG_CHEWING_IND').disable();

                this.nutritionalForm.get('tG_SWALLOWING_IND').setValue(false);
                this.nutritionalForm.get('tG_SWALLOWING_IND').disable();


            } else {
                this.nutritionalForm.get('tG_BITING_IND').enable();
                this.nutritionalForm.get('tG_CHEWING_IND').enable();
                this.nutritionalForm.get('tG_SWALLOWING_IND').enable();
            }
        });

        this.nutritionalForm.get('tG_NUTRITION_ISSUE_NA_IND').valueChanges.subscribe(na => {
            if (na) {
                this.nutritionalForm.get('tG_VOMITING_IND').setValue(false);
                this.nutritionalForm.get('tG_VOMITING_IND').disable();

                this.nutritionalForm.get('tG_NAUSEA_IND').setValue(false);
                this.nutritionalForm.get('tG_NAUSEA_IND').disable();

                this.nutritionalForm.get('tG_CONSTIPATION_IND').setValue(false);
                this.nutritionalForm.get('tG_CONSTIPATION_IND').disable();

                this.nutritionalForm.get('tG_GERD_IND').setValue(false);
                this.nutritionalForm.get('tG_GERD_IND').disable();

                this.nutritionalForm.get('tG_FOOD_AVERSION_IND').setValue(false);
                this.nutritionalForm.get('tG_FOOD_AVERSION_IND').disable();

                this.nutritionalForm.get('tG_ALLERGY_IND').setValue(false);
                this.nutritionalForm.get('tG_ALLERGY_IND').disable();

                this.nutritionalForm.get('tG_DIARRHEA_IND').setValue(false);
                this.nutritionalForm.get('tG_DIARRHEA_IND').disable();

                this.nutritionalForm.get('tG_MALABSORBTION_IND').setValue(false);
                this.nutritionalForm.get('tG_MALABSORBTION_IND').disable();

                this.nutritionalForm.get('tG_DED_IND').setValue(false);
                this.nutritionalForm.get('tG_DED_IND').disable();

                this.nutritionalForm.get('tG_CLEFT_PALATE_IND').setValue(false);
                this.nutritionalForm.get('tG_CLEFT_PALATE_IND').disable();

            } else {
                this.nutritionalForm.get('tG_VOMITING_IND').enable();
                this.nutritionalForm.get('tG_NAUSEA_IND').enable();
                this.nutritionalForm.get('tG_CONSTIPATION_IND').enable();
                this.nutritionalForm.get('tG_GERD_IND').enable();
                this.nutritionalForm.get('tG_FOOD_AVERSION_IND').enable();
                this.nutritionalForm.get('tG_ALLERGY_IND').enable();
                this.nutritionalForm.get('tG_DIARRHEA_IND').enable();
                this.nutritionalForm.get('tG_MALABSORBTION_IND').enable();
                this.nutritionalForm.get('tG_DED_IND').enable();
                this.nutritionalForm.get('tG_CLEFT_PALATE_IND').enable();
            }
        });
    }
    updateNutritional(values: Object) {         
    Object.assign(this.nutritional, values);
    this.nutritional.tG_ACAT_ID = this.dataService.getSelectedDocument();
      let last3mons = this.nutritionalForm.get('tG_LAST_3_MONTHS').value;
      if (last3mons.length > 0) {
          this.last3Months.forEach(function (v) {
              let frmCol = 'tG_' + v.dV_COLNAME + '_IND';
              if (last3mons.includes(v.dV_COLNAME)) {
                  this.nutritional[frmCol] = 'Y';
              } else {
                  this.nutritional[frmCol] = '';
              }
          }, this);
      }

    }

  ClearForm(e) {
    this.onClear();
  }

  submitForm() {

    this.isSubmitting = true;
    this.updateNutritional(this.nutritionalForm.value);
    console.log(this.nutritional);

    this.sectionGService.postNutritional(this.nutritional).subscribe(response => {
      this.alertService.success('Nutritional Screen Saved');
    }, err => {
        console.error('Error Saving Nutritional Screen', err);
        this.alertService.error("Error Saving Nutritional Screen");
        this.alertService.error("Error Saving Nutritional Screen");
      });
    this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.nutritionalForm.reset();
      }
    });

  }
}
