import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { LoaderService } from '../shared';
@Component({
  selector: 'app-section-h',
  templateUrl: './section-h.component.html',
  styleUrls: ['./section-h.component.css']
})
export class SectionHComponent implements OnInit {

  constructor(private router: Router, private loaderService: LoaderService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/section-h') {
          this.router.navigate(['/section-h/school-work']);
        }
      }
    });
  }

  ngOnInit() {
    
    
  }
 
}
