import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService, AlertsService, SummaryService, SectionDropdownService } from '../../../shared/services';
import { LOCDetermination, SummaryResponse, SavePAAReviewModel } from '../../../shared';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-loc-determination',
    templateUrl: './loc-determination.component.html',
    styleUrls: ['./loc-determination.component.css'],
    providers: [DatePipe]
})
export class LocDeterminationComponent implements OnInit {

    PAAReviewForm: FormGroup;
    assess_outcome: any[];
    loc_determ: any[];
    adverse_deter: any[];
    enrollment_selec: any[];
    status_of_assessm: any[];
    set SummaryData(value: SummaryResponse) {
        this.PAAReviewForm.patchValue(value);
        this.PAAReviewForm.get('tO_ADVERSE_LOC_SCHED_DATE').setValue(value.tO_ADVERSE_LOC_SCHED_DATE != null ? new Date(value.tO_ADVERSE_LOC_SCHED_DATE) : null);
    }

    

    constructor(private fb: FormBuilder,
        public cntxService: DataService,
        public SummarySVC: SummaryService,
        public AlertSVC: AlertsService,
        private datePipe: DatePipe,
        private ddService: SectionDropdownService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.loadData();
    }

    initForm() {
        this.PAAReviewForm = new FormGroup({
            'tO_ACAT_ID': new FormControl('', Validators.required),
            'tO_ASSESSMENT_OUTCOME': new FormControl('', Validators.required),
            'tO_ADVERSE_LOC_REQUIRED': new FormControl('', Validators.required),
          'tO_LOC_RECOMMENDATION': new FormControl({ value: '', disabled: true }, [Validators.required]),
            'tO_ADVERSE_LOC_SCHED_DATE': new FormControl('', Validators.required),
            'tO_ADVERSE_DETERMINATION': new FormControl('', Validators.required),
            'tO_ADVERSE_ASSESSMENT_NBR': new FormControl('', Validators.required),
            'tO_ACAT_DETERMINATION': new FormControl('', Validators.required),
            'tO_DENIAL_REASON': new FormControl('', Validators.required),
            'tO_LOC_DETERMINATION_STATUS': new FormControl('', Validators.required),
            'tO_PAA_DETERM_NOTE': new FormControl('', Validators.required),
            'tO_ENROLLMENT_SELECTION': new FormControl('', Validators.required)
        });
    }
    loadData() {
        this.PAAReviewForm.get('tO_ACAT_ID').setValue(this.cntxService.getSelectedDocument());
        let adverse_det$ = this.ddService.GetDropDownValues('ADVER_DETER');
        let loc_deter$ = this.ddService.GetDropDownValues('LOC_DETER');
        let assess_outc$ = this.ddService.GetDropDownValues('ASSESS OUTCOME');
        let enr_sel$ = this.ddService.GetDropDownValues('ENROLL_SELEC');
        let status_of_assme$ = this.ddService.GetDropDownValues('STATUS_ASSESS');

        forkJoin(loc_deter$, assess_outc$, adverse_det$, enr_sel$, status_of_assme$).subscribe(([loc_deter, asses_oc, adv_deter, eroll_selec, status_of_assme]) => {
            this.assess_outcome = asses_oc;
            this.loc_determ = loc_deter;
            this.adverse_deter = adv_deter;
            this.enrollment_selec = eroll_selec;
            this.status_of_assessm = status_of_assme;
        }, error => {
            console.error(error);
        });
    }

    savePAAReview() {
        this.PAAReviewForm.updateValueAndValidity();
        this.PAAReviewForm.markAllAsTouched();
        if (this.PAAReviewForm.valid) {
            let reviewData: SavePAAReviewModel = this.PAAReviewForm.value as SavePAAReviewModel;
            reviewData.tO_ADVERSE_LOC_SCHED_DATE = this.datePipe.transform(reviewData.tO_ADVERSE_LOC_SCHED_DATE, 'MM/dd/yyyy');
            //console.log(reviewData);
            this.SummarySVC.savePAAReview(reviewData).subscribe(resp => {
                this.AlertSVC.success('Determination Saved');
                console.log(resp);
            }, error => {
                console.error(error);
                this.AlertSVC.error('Error saving LOC Determination');
            });
        }
    }

    resetForm() {
      this.onClear();
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.PAAReviewForm.reset();
        this.PAAReviewForm.get('tO_ACAT_ID').setValue(this.cntxService.getSelectedDocument());
      }
    });

  }
}
