import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionAService, AlertsService, DataService,  UserService, getTextForDropdownValues } from '../../shared/index';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-assess-participants',
  templateUrl: './assess-participants.component.html',
  styleUrls: ['./assess-participants.component.css']
})
export class AssessParticipantsComponent implements OnInit {
  additionalPtsForm: FormGroup;
  additionalPtsStatus: string;
  showAdditionalPts: boolean;
  newACATID: any;

  constructor(private fb: FormBuilder,
    private sectionAService: SectionAService,
    private alertsService: AlertsService,
    public dataService: DataService,
    private router: Router,
    private userService: UserService) {
    this.additionalPtsForm = this.fb.group({     
      tA_ACAT_ID: new FormControl(''),
      tA_PARTICIPANT: new FormControl('', Validators.required),
      tA_PART1_FIRST_NAME: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60), Validators.required]),      
      tA_PART1_LAST_NAME: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60), Validators.required]),
      tA_PART1_RELATIONSHIP: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60), Validators.required]),
      tA_PART2_FIRST_NAME: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60)]),
      tA_PART2_LAST_NAME: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60)]),
      tA_PART2_RELATIONSHIP: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60)]),
      tA_PART3_FIRST_NAME: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60)]),
      tA_PART3_LAST_NAME: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60)]),
      tA_PART3_RELATIONSHIP: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60)]),
    });
  }

  ngOnInit() {
    this.newACATID = this.dataService.getSelectedDocument();

    if (this.newACATID != null) {     
      
      this.sectionAService.getSectionA(this.newACATID).subscribe(response => {
        this.receiveAssessmentParticipants(response);
        this.alertsService.success("Additional Participants Loaded");
      }, err => {
        this.alertsService.error(err);
        });
    }
   
  }
  receiveAssessmentParticipants(data) {    

    this.additionalPtsForm.patchValue(data);
    this.setFormData(data);
 
  }
  setFormData(data) {
    if (data['tA_PARTICIPANT'] === 'Y') {
      this.showAdditionalPts = true;
      this.additionalPtsForm.get('tA_PART1_FIRST_NAME').setValidators([Validators.required]);
      this.additionalPtsForm.get('tA_PART1_LAST_NAME').setValidators([Validators.required]);
      this.additionalPtsForm.get('tA_PART1_RELATIONSHIP').setValidators([Validators.required]);
      this.additionalPtsForm.get('tA_PART1_RELATIONSHIP').setValidators([Validators.pattern("[A-Za-z\ \-]+")]);
    }
    else {
      this.showAdditionalPts = false;
      this.additionalPtsForm.get('tA_PART1_FIRST_NAME').clearValidators();
      this.additionalPtsForm.get('tA_PART1_LAST_NAME').clearValidators();
      this.additionalPtsForm.get('tA_PART1_RELATIONSHIP').clearValidators();
    }
   
  }
  changeAddtionalParticipants() {
    this.additionalPtsStatus = this.additionalPtsForm.get('tA_PARTICIPANT').value;
    if (this.additionalPtsStatus === "Y") {
      this.showAdditionalPts = true;
      this.additionalPtsForm.get('tA_PART1_FIRST_NAME').setValidators([Validators.required]);
      this.additionalPtsForm.get('tA_PART1_LAST_NAME').setValidators([Validators.required]);
      this.additionalPtsForm.get('tA_PART1_RELATIONSHIP').setValidators([Validators.required]);
      
    } else {
      this.showAdditionalPts = false;
      this.additionalPtsForm.get('tA_PART1_FIRST_NAME').setValue(null);
      this.additionalPtsForm.get('tA_PART1_LAST_NAME').setValue(null);
      this.additionalPtsForm.get('tA_PART1_RELATIONSHIP').setValue(null);
      this.additionalPtsForm.get('tA_PART2_FIRST_NAME').setValue(null);
      this.additionalPtsForm.get('tA_PART2_LAST_NAME').setValue(null);
      this.additionalPtsForm.get('tA_PART2_RELATIONSHIP').setValue(null);
      this.additionalPtsForm.get('tA_PART3_FIRST_NAME').setValue(null);
      this.additionalPtsForm.get('tA_PART3_LAST_NAME').setValue(null);
      this.additionalPtsForm.get('tA_PART3_RELATIONSHIP').setValue(null);

      this.additionalPtsForm.get('tA_PART1_FIRST_NAME').clearValidators();
      this.additionalPtsForm.get('tA_PART1_LAST_NAME').clearValidators();
      this.additionalPtsForm.get('tA_PART1_RELATIONSHIP').clearValidators();
    }

    this.additionalPtsForm.controls["tA_PART1_FIRST_NAME"].updateValueAndValidity()
    this.additionalPtsForm.controls["tA_PART1_LAST_NAME"].updateValueAndValidity()
    this.additionalPtsForm.controls["tA_PART1_RELATIONSHIP"].updateValueAndValidity()
  }
  additionalPtsSave() {
    this.additionalPtsForm.get('tA_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    if (!this.additionalPtsForm.valid) {
      for (let cnt in this.additionalPtsForm.controls) {
        if (this.additionalPtsForm.controls[cnt].invalid) {
          console.log(cnt, this.additionalPtsForm.controls[cnt]);
        }
      }
      return;
    } 

    let participantData = this.additionalPtsForm.value;

    
   
    this.sectionAService.PostAssessmentParticipants(participantData).subscribe(resp => {
      this.alertsService.success("Additional Participants Saved");
    }, error => {
        console.error('Error Saving Additional Participants', error);
        this.alertsService.error("Error Saving Additional Participants");    
    });
  }
  clearAdditionalPtsForm() {
    this.onClear();
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.showAdditionalPts = false;
        this.additionalPtsForm.reset();
      }
    });

  }
}
