import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionKService, MedicationSafety, DropdownModel } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-med-safety',
  templateUrl: './med-safety.component.html',
  styleUrls: ['./med-safety.component.css']
})
export class MedSafetyComponent implements OnInit {

    medicationSafetyForm: FormGroup;
    isSubmitting = false;
    medicationSafety: MedicationSafety = {} as MedicationSafety;

    constructor(private fb: FormBuilder,
        private dropdownSerice: SectionDropdownService,
        private alertService: AlertsService,
        public dataService: DataService,
        public sectionKService: SectionKService) {
        this.medicationSafetyForm = this.fb.group({
            tK_SECTION_ID: new FormControl(''),
            tK_ACAT_ID: new FormControl(''),
            tK_MED_STORAGE: new FormControl('',Validators.required),
            tK_MED_STORAGE_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_MED_SECURITY: new FormControl('', Validators.required),
            tK_MED_SECURITY_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_MED_ADMIN: new FormControl('', Validators.required),
            tK_MED_ADMIN_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_MED_LOCKBOX: new FormControl('', Validators.required),
            tK_MED_LOCKBOX_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_MED_O2: new FormControl('', Validators.required),
            tK_MED_O2_COMMENTS: new FormControl({ value: '', disabled: true })
        });

    }

    ngOnInit() {
        this.sectionKService.getMedicationSafety(this.dataService.getSelectedDocument()).subscribe(response => {
            this.medicationSafetyForm.patchValue(response);
          this.alertService.success('Medication Safety Loaded');
        }, err => {
            this.alertService.error(JSON.stringify(err));
        });
        this.onChange();
    }

    onChange() {
        this.medicationSafetyForm.get('tK_MED_STORAGE').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.medicationSafetyForm.get('tK_MED_STORAGE_COMMENTS').enable();
                this.medicationSafetyForm.get('tK_MED_STORAGE_COMMENTS').setValidators(Validators.required);
                this.medicationSafetyForm.controls['tK_MED_STORAGE_COMMENTS'].markAsTouched();

            } else {

                this.medicationSafetyForm.get('tK_MED_STORAGE_COMMENTS').disable();
                this.medicationSafetyForm.get('tK_MED_STORAGE_COMMENTS').setValue('');
            }
        });

        this.medicationSafetyForm.get('tK_MED_SECURITY').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.medicationSafetyForm.get('tK_MED_SECURITY_COMMENTS').enable();
                this.medicationSafetyForm.get('tK_MED_SECURITY_COMMENTS').setValidators(Validators.required);
                this.medicationSafetyForm.controls['tK_MED_SECURITY_COMMENTS'].markAsTouched();

            } else {

                this.medicationSafetyForm.get('tK_MED_SECURITY_COMMENTS').disable();
                this.medicationSafetyForm.get('tK_MED_SECURITY_COMMENTS').setValue('');
            }
        });

        this.medicationSafetyForm.get('tK_MED_ADMIN').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.medicationSafetyForm.get('tK_MED_ADMIN_COMMENTS').enable();
                this.medicationSafetyForm.get('tK_MED_ADMIN_COMMENTS').setValidators(Validators.required);
                this.medicationSafetyForm.controls['tK_MED_ADMIN_COMMENTS'].markAsTouched();

            } else {

                this.medicationSafetyForm.get('tK_MED_ADMIN_COMMENTS').disable();
                this.medicationSafetyForm.get('tK_MED_ADMIN_COMMENTS').setValue('');
            }
        });

        this.medicationSafetyForm.get('tK_MED_LOCKBOX').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.medicationSafetyForm.get('tK_MED_LOCKBOX_COMMENTS').enable();
                this.medicationSafetyForm.get('tK_MED_LOCKBOX_COMMENTS').setValidators(Validators.required);
                this.medicationSafetyForm.controls['tK_MED_LOCKBOX_COMMENTS'].markAsTouched();

            } else {

                this.medicationSafetyForm.get('tK_MED_LOCKBOX_COMMENTS').disable();
                this.medicationSafetyForm.get('tK_MED_LOCKBOX_COMMENTS').setValue('');
            }
        });

        this.medicationSafetyForm.get('tK_MED_O2').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.medicationSafetyForm.get('tK_MED_O2_COMMENTS').enable();
                this.medicationSafetyForm.get('tK_MED_O2_COMMENTS').setValidators(Validators.required);
                this.medicationSafetyForm.controls['tK_MED_O2_COMMENTS'].markAsTouched();

            } else {

                this.medicationSafetyForm.get('tK_MED_O2_COMMENTS').disable();
                this.medicationSafetyForm.get('tK_MED_O2_COMMENTS').setValue('');
            }
        });
    }

    ClearForm() {
      //  this.medicationSafetyForm.reset();
      this.onClear();
    }
    updatMedicationSafety(values: Object) {
        Object.assign(this.medicationSafety, values);
        this.medicationSafety.tK_ACAT_ID = this.dataService.getSelectedDocument();
    }

    submitForm() {

        this.isSubmitting = true;
        this.updatMedicationSafety(this.medicationSafetyForm.value);
        console.log(this.medicationSafety);
        this.sectionKService.postMedicationSafety(this.medicationSafety).subscribe(response => {
          this.alertService.success('Medication Safety Saved');
        }, err => {
            this.alertService.error(JSON.stringify(err));
        });
        this.isSubmitting = false;
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.medicationSafetyForm.reset();


      }
    });

  }
}
