import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../../services';
import { MatStepper } from '@angular/material';
import { Section } from '../../models';
@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit, AfterViewInit {
 
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  seditable: boolean = false;
  showMatStep: boolean = false;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  isLinear = true;
  constructor(private router: Router) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    //console.log(this.stepper);
    //this.stepper.steps.forEach(s => {
    //  console.log(s);
    //  //s.stepControl.disable();
    //  s.editable = false;
    //});
    
    this.stepper.selectionChange.subscribe(selection => {

      console.log(selection);
      switch (selection.selectedIndex) {
        case 0:
          this.router.navigate(['section-a']);
          break;
        case 1:
          this.router.navigate(['section-b']);
          break;
        case 2:
          this.router.navigate(['section-c']);
          break;
        case 3:
          this.router.navigate(['section-d']);
          break;
        case 4:
          this.router.navigate(['section-e']);
          break;
        case 5:
          this.router.navigate(['section-f']);
          break;
        case 6:
          this.router.navigate(['section-g']);
          break;
        case 7:
          this.router.navigate(['section-h']);
          break;
        case 8:
          this.router.navigate(['section-i']);
          break;
        case 9:
          this.router.navigate(['section-j']);
          break;
        case 10:
          this.router.navigate(['section-k']);
          break;
        case 11:
          this.router.navigate(['section-l']);
          break;
        case 12:
        this.router.navigate(['summary']);
        break;
        default:
          this.router.navigate(['search']);
          break;

      }
    });

  }

  stepNodes: Section[] = [
    { title: 'Section A', description: 'Individual Information' },
    { title: 'Section B', description: 'Contact Information' },
    { title: 'Section C', description: 'Legal Concerns' },
    { title: 'Section D', description: 'Health Information' },
    { title: 'Section E', description: 'Communication' },
    { title: 'Section F', description: 'ADLs and IADLs' },
    { title: 'Section G', description: 'Screenings' },
    { title: 'Section H', description: 'School and Work' },
    { title: 'Section I', description: 'Supports' },
    { title: 'Section J', description: 'Adaptive/Assistive Equipment' },
    { title: 'Section K', description: 'Environmental Review' },
    { title: 'Section L', description: 'Primary Caregiver Assessment' },
    { title: 'Summary', description: 'ACAT Summary' },
  ];

 
}



