import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DevelopmentalDisability, ACOK, Nutritional, Dental, ACOKDisorders  } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SectionGService {

  constructor(private apiService: ApiService) { }
  GetSectionGInfo(acatID): Observable<any> {
    return this.apiService.get(`sectionG/GetSectionGInfo/${acatID}`);
  }
  GetACOKDisordersInfo(acatID): Observable<any> {
    return this.apiService.get(`sectionG/GetACOKDisordersInfo/${acatID}`);
  }
  postDevelopmentalDisability(formData): Observable<any> {
    return this.apiService.post('sectionG/postDevelopmentalDisability', formData);
  }

  postNutritional(formData): Observable<any> {
    return this.apiService.post('sectionG/postNutritional', formData);
  }
  postDental(formData): Observable<any> {
    return this.apiService.post('sectionG/postDental', formData);
  }
  postACOK(formData): Observable<any> {
    return this.apiService.post('sectionG/postACOK', formData);
  }
  postACOKDisorders(formData): Observable<any> {
    return this.apiService.post('sectionG/postACOKDisorders', formData);
  }
  //getDDScreen(acatId): Observable<DevelopmentalDisability> {
  //  return this.apiService.get('sectionG/DevelopmentalDisability/' + acatId).pipe(map(data => {
  //    return data;
  //  }));
  //}

  //postDDScreen(ddScreen): Observable<any> {
  //  return this.apiService.post('SectionG/ddScreen', ddScreen).pipe(map(data => {
  //    return data;
  //  }));
  //}

  //getACOK(acatId): Observable<ACOK> {
  //return   this.apiService.get('sectionG/acokscreen/' + acatId).pipe(map(data => {
  //    return data;
  //  }));
  //}

  //postACOK(aCOKScreen): Observable<any> {
  //  return this.apiService.post('SectionG/acokScreen', aCOKScreen).pipe(map(data => {
  //    return data;
  //  }));
  //}

  //getACOKDisorders(acatId): Observable<ACOKDisorders> {
  //  return this.apiService.get('sectionG/acokDisorders/' + acatId).pipe(map(data => {
  //    return data;
  //  }));
  //}

  //postACOKDisorders(aCOKDisorders): Observable<any> {
  //  return this.apiService.post('SectionG/acokDisorders', aCOKDisorders).pipe(map(data => {
  //    return data;
  //  }));
  //}

  //getNutritionalScreen(acatId): Observable<Nutritional> {
  // return  this.apiService.get('sectionG/nutritionalscreen/' + acatId).pipe(map(data => {
  //    return data;
  //  }));
  //}

  //postNutritionalScreen(nutritionalScreen): Observable<any> {
  //  return this.apiService.post('SectionG/NutritionalScreen', nutritionalScreen).pipe(map(data => {
  //    return data;
  //  }));
  //}


  //getDentalScreen(acatId): Observable<Dental> {
  //  return this.apiService.get('sectionG/dentalscreen/' + acatId).pipe(map(data => {
  //    return data;
  //  }));
  //}

  //postDentalScreen(dentalScreen): Observable<any> {
  //  return this.apiService.post('SectionG/dentalscreen', dentalScreen).pipe(map(data => {
  //    return data;
  //  }));
  //}

}
