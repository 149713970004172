import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-section-j',
  templateUrl: './section-j.component.html',
  styleUrls: ['./section-j.component.css']
})
export class SectionJComponent implements OnInit {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/section-j') {
          router.navigate(['/section-j/adaptive-assistive-equip']);
        }
      }
    });
  }

  ngOnInit() {
  }

}
