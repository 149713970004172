import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SectionA } from '../models';
import { ApiService } from './api.service';
import { map, filter } from 'rxjs/operators';
import { SKNDBMergeParam } from '../../section-d/common/SKNDBMergeParam';
import { SKNFormMode } from '../../section-d/common/skilled-nursing-common';
import { PhysicianContact } from '../../section-d/common/PhysicianContact';
import { PharmacyInfo } from '../../section-d/common/PharmacyInfo';
import { DiagnosisInfo } from '../../section-d/common/DiagnosisInfo';
import { AllergyInfo } from '../../section-d/common/AllergyInfo';
import { SubstanceUse } from '../../section-d/substance-use/SubstanceUse';
import { TransplantInfo } from '../../section-d/transplant-info/TransplantInfo';
import { Incontinence } from '../../section-d/incontinence-info/incontinence-info';
import { MedStability } from '../../section-d/assess-medical-stability/assess-medical-stability';
import { EmergencyVisit } from '../../section-d/emergency-visit/emergency-visits';
import { BackgroundInfo } from '../../section-d/background-info/background-info';
import { Medication, MedicationAdmin } from '../../section-d/medication-admin/medication-admin';

@Injectable({
    providedIn: 'root'
})
export class SectionDService {

    constructor(private apiService: ApiService) { }

    getSectionD(ACATId): Observable<any> {
        return this.apiService.get(`SectionD/${ACATId}`);
    }
    saveSection(section) {
        return this.apiService.put('sectionD/', section).pipe(map(data => {
            return data;
        }));
    }

    createSection(section) {
        return this.apiService.post('sectionD/', section).pipe(map(data => {
            return data;
        }));
    }

    getSkilledNursing(ACATID, SECID, FormMode: SKNFormMode) {
        if (FormMode == SKNFormMode.SkilledNursing) {
            return this.apiService.get(`sectiond/SkilledNursing/${ACATID}/${SECID}`);
        } else {
            return this.apiService.get(`sectiond/SkilledRehab/${ACATID}/${SECID}`);
        }
    }

    saveSklledNursing(SKNData: SKNDBMergeParam) {
        return this.apiService.putNoLoader("sectiond/SkilledNursing", SKNData);
    }

    getPhysicians(ACATID: number) {
        return this.apiService.get(`sectiond/getphysicians/${ACATID}`);
    }

    addNewPhysician(physician: PhysicianContact) {
        return this.apiService.post('sectiond/addphysician', physician);
    }

    deletePhysician(sectionid): Observable<any> {
        return this.apiService.delete(`sectiond/DeletePhysician/${sectionid}`);
    }

    getMedications(ACATID: number) {
        return this.apiService.get(`sectiond/meds/${ACATID}`)
    }

    saveMedication(medication: Medication) {
        return this.apiService.put(`sectiond/meds`, medication);
    }

    deleteMedications(SECTIONID: number) {
        return this.apiService.delete(`sectiond/meds/${SECTIONID}`);
    }

    // medication admin <<
    getMedicationAdmin(ACATID: number): Observable<MedicationAdmin> {
        return this.apiService.get(`sectiond/MedAdm/${ACATID}`)
    }
    saveMedicationAdmin(medication: MedicationAdmin) {
        return this.apiService.put(`sectiond/MedAdm`, medication);
    }

    // medication admin >>

    //pharmcy <
    savePharmacy(pharmacyinfo: PharmacyInfo) {
        return this.apiService.put('sectiond/pharmacy', pharmacyinfo);
    }


    getPharmacy(ACATID: number) {
        return this.apiService.get(`sectiond/pharmacy/${ACATID}`);
    }
    //pharmcy >>

    //diagnosis <<
    getDiagnosis(ACATID: number) {
        return this.apiService.get(`sectiond/Diagnosis/${ACATID}`);
    }

    saveDiagosis(formData: DiagnosisInfo) {
        return this.apiService.put(`sectiond/Diagnosis`, formData);
    }

    //diagnosis >>

    // Allergies <<

    getAllergies(ACATID: number) {
        return this.apiService.get(`sectiond/Allergies/${ACATID}`);
    }

    saveAllergies(formData: AllergyInfo) {
        return this.apiService.put(`sectiond/Allergies`, formData);
    }

    //Allergies >>

    // substance use <<

    getSubstanceUse(ACATID: number) {
        return this.apiService.get(`sectiond/Substance/${ACATID}`);
    }

    saveSubstanceUse(formData: SubstanceUse) {
        return this.apiService.put(`sectiond/Substance`, formData);
    }

    //substance use >>

    // Transplant info <<

    getTransplantInfo(ACATID: number) {
        return this.apiService.get(`sectiond/Transplant/${ACATID}`);
    }
    saveTransplantInfo(formData: TransplantInfo) {
        return this.apiService.put(`sectiond/Transplant`, formData);
    }

    // Transplant info >>

    // incontinence info <<

    getIncontinenceInfo(ACATID: number): Observable<Incontinence> {
        return this.apiService.get(`sectiond/Incontinence/${ACATID}`);
    }
    saveIncontinenceInfo(formData: Incontinence) {
        return this.apiService.put(`sectiond/Incontinence`, formData);
    }

    // incontinence info >>

    // medical stability <<

    getMedicalStability(ALOCID: number): Observable<MedStability> {
        return this.apiService.get(`sectiond/MedStability/${ALOCID}`);
    }
    saveMedicalStability(formData: MedStability) {
        return this.apiService.put(`sectiond/MedStability`, formData);
    }

    // medical stability >>

    // medical emergency


    getEmergencyVisits(ALOCID: number) {
        return this.apiService.get(`sectiond/Emergency/${ALOCID}`);
    }
    saveEmergencyVisits(formData: EmergencyVisit) {
        return this.apiService.put(`sectiond/Emergency`, formData);
    }

    //

    // background Info


    getBackgroundInfo(ALOCID: number) {
        return this.apiService.get(`sectiond/BackgroundInfo/${ALOCID}`);
    }
    saveBackgroundInfo(formData: BackgroundInfo) {
        return this.apiService.put(`sectiond/BackgroundInfo`, formData);
    }

    //background Info

    getCityState(zipCode) {
        return this.apiService.getNoLoader('sectiona/GetCityState', zipCode);
    }

    getCounties() {
        return this.apiService.getNoLoader('sectiona/getCounties');
    }

}
