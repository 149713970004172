import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, OnDestroy, EventEmitter, HostListener } from '@angular/core';
import { SectionFService, DataService, AlertsService, SectionDropdownService, ADLOptions, DropdownModel, SectionFResponse, SectionFFormModel } from '../../shared';
import { forkJoin, Subscription, interval } from 'rxjs';
import { KeyedCollection } from '../../shared/common/Dictionary';
import { AdlRowComponent } from './adl-row/adl-row.component';

@Component({
    selector: 'app-adls-iadls',
    templateUrl: './adls-iadls.component.html',
    styleUrls: ['./adls-iadls.component.css']
})
export class AdlsIadlsComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(private sectionfService: SectionFService,
        private cntxInfoService: DataService,
        private alerts: AlertsService,
        private dropdownService: SectionDropdownService) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    ADLOptions: ADLOptions[];
    sectionFData: SectionFResponse[];
    sectionChangesStack = new KeyedCollection<SectionFFormModel>();
    autoSaveSub: Subscription;
    autoSaveInProgress = false;
    autoSaveError = false;

    @ViewChildren(AdlRowComponent)
    allSections: QueryList<AdlRowComponent>;

    get unsavedChangedPresent(): boolean {
        return this.sectionChangesStack.Count() > 0;
    }


    ngOnInit() {

        this.documentReferance = { alocid: '', sectionid: '', name: '' };
        this.documentReferance.alocid = this.cntxInfoService.getSelectedDocument();
        this.documentReferance.name = this.cntxInfoService.getSelectedDocumentName();

        this.loadData().subscribe(() => {
            this.autoSave();
        });
    }

    ngAfterViewInit(): void {
        //this.allSections.forEach(section => {
        //    section.sectionForm.disable();
        //});
    }

    ngOnDestroy(): void {
        this.autoSaveSub.unsubscribe();
    }

    loadData(): EventEmitter<string> {
        let load_complete = new EventEmitter<string>();
        let sectionF$ = this.sectionfService.getSectionFData(this.documentReferance.alocid);
        let adlText$ = this.sectionfService.getADLText();
        //let adl_text$ = this.dropdownService.GetDropDownValues('ADL_TEXT');
        //let asst_level$ = this.dropdownService.GetDropDownValues('ASSISTANCE_LEVEL');

        forkJoin(sectionF$, adlText$).subscribe(([sectionF, ADLText]) => {

            sectionF.forEach(sec => {
                sec.adl_rows.forEach(adl => {
                    let txt = ADLText.find(t => t.adl == adl.adl_val);
                    if (txt != null) {
                        adl.adl_optns = txt;
                    }
                });
            })

            this.sectionFData = sectionF;
            //this.ADLOptions = ADLText;
            //console.log(ADLText);
            //this.generateADLOptions(ADL_TXT, ASST_LEVEL);
            //console.log(JSON.stringify(this.ADLOptions));
            this.alerts.success(`ADLs and IADLs Loaded`);
            load_complete.emit('load_done');
        }, error => {
            console.error(error);
            this.alerts.error('Error loading data');
        });

        return load_complete;
    }

    onSectionUpdate(data: SectionFFormModel) {
        data.updated = true;
        this.sectionChangesStack.Add(data.adl_val, data);
        //console.log(data);
        if (this.autoSaveError && this.autoSaveInProgress) {
            this.autoSaveError = false;
            this.autoSaveInProgress = false;
        }
    }

    autoSave() {
        if (this.autoSaveSub == null) {

            this.autoSaveSub = interval(2000).subscribe(() => {
                for (let section of this.sectionChangesStack.Values()) {
                    if (section.updated) {
                        section.updated = false;
                        continue;
                    }
                    if (this.autoSaveInProgress)
                        return;
                    //rest service call
                    this.autoSaveInProgress = true;
                    this.sectionfService.saveADLIADL(section).subscribe(resp => {
                        this.alerts.success(section.adl_title.toLowerCase() + ' Saved');
                        this.sectionChangesStack.Remove(section.adl_val);
                        this.autoSaveInProgress = false;
                    }, error => {
                        this.alerts.error(`Error saving data.`);
                            console.error(error);
                            this.autoSaveError = true;
                    });
                    return;
                }

            });
        }
    }

    @HostListener('window:beforeunload')
    canNavigateAway() {
        return !this.unsavedChangedPresent;
    }


    @HostListener('document:keypress', ['$event'])
    onCntrlQ(e: KeyboardEvent) {
        //console.log(e);
        if (e.code == 'KeyQ' && e.ctrlKey) {
            this.allSections.forEach(sec => {
                sec.sectionForm.updateValueAndValidity();
                sec.sectionForm.markAllAsTouched();
            });
        }
    }

    /*
    private generateADLOptions(adlText: DropdownModel[], asst_lvl: DropdownModel[]) {
        this.ADLOptions = [];
        adlText.forEach(adl => {
            let adl_opt: ADLOptions = {
                adl: adl.dV_VALUE,
                options: []
            };
            let adl_val = adl.dV_EXPANDED_VALUE.toUpperCase().split(' - ');
            asst_lvl.forEach(ast => {
                let sel_val = '';
                switch (ast.dV_VALUE) {
                    case 'PRESENT':
                        sel_val = adl_val[1];
                        break;
                    default:
                        sel_val = adl_val[0];

                }
                adl_opt.options.push({
                    label: ast.dV_EXPANDED_VALUE.replace('<<ADL>>', sel_val),
                    value: ast.dV_VALUE
                });

            });
            this.ADLOptions.push(adl_opt);
        });
    }
    */



}
