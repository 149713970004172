import { Component, OnInit } from '@angular/core';
import { AlertsService, SectionDService, SectionDropdownService, DataService } from '../../shared';
import { FormGroup, FormControl, EmailValidator, Validators } from '@angular/forms';
import { MedStabilityFields, MedStability } from './assess-medical-stability';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-assess-medical-stability',
    templateUrl: './assess-medical-stability.component.html',
    styleUrls: ['./assess-medical-stability.component.css']
})
export class AssessMedicalStabilityComponent implements OnInit {

    constructor(private cntxService: DataService,
        private dropdownService: SectionDropdownService,
        private sectionDService: SectionDService,
        private alerts: AlertsService) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    stabilityForm: FormGroup;

    screenName = "Assessment of Medical Stability";

    ngOnInit() {
        this.documentReferance = { alocid: '', sectionid: '', name: '' };;
        this.documentReferance.alocid = this.cntxService.getSelectedDocument();
        this.documentReferance.name = this.cntxService.getSelectedDocumentName();
        this.initForm();
        this.loadData();
    }

    initForm() {
        let fieldsDef = {};
        for (let fld in MedStabilityFields) {
            fieldsDef[fld] = new FormControl('');
        }
        this.stabilityForm = new FormGroup(fieldsDef);
        this.stabilityForm.get(MedStabilityFields.tD_AMS_ASSESS_IND).setValidators([Validators.required]);
        this.stabilityForm.get(MedStabilityFields.tD_AMS_INTER_IND).setValidators([Validators.required]);
        this.stabilityForm.get(MedStabilityFields.tD_AMS_MONITOR_IND).setValidators([Validators.required]);
        this.stabilityForm.get(MedStabilityFields.tD_AMS_MONTHLY_IND).setValidators([Validators.required]);
        this.stabilityForm.get(MedStabilityFields.tD_AMS_NECESSARY_IND).setValidators([Validators.required]);
        this.stabilityForm.get(MedStabilityFields.tD_AMS_RPT_IND).setValidators([Validators.required]);
    }

    loadData() {
        this.sectionDService.getMedicalStability(parseInt(this.documentReferance.alocid)).subscribe((resp: MedStability) => {
            if (resp.tD_ACAT_ID == null || resp.tD_ACAT_ID == 0) {
                this.alerts.error('No data found for Medical Stability');
            } else {
                this.alerts.success(`${this.screenName} Loaded`);
            }
            //console.log(resp);
            this.stabilityForm.patchValue(resp);
            this.documentReferance.sectionid = resp.tD_SECTION_ID == null ? null : resp.tD_SECTION_ID.toString();
        }, error => {
            console.error('error getting data ', error);
        });
    }

    onSave() {
        this.stabilityForm.get(MedStabilityFields.tD_ACAT_ID).setValue(this.documentReferance.alocid);
        this.stabilityForm.get(MedStabilityFields.tD_SECTION_ID).setValue(this.documentReferance.sectionid);
        this.stabilityForm.markAllAsTouched();
        //console.log(this.stabilityForm.value);
        if (this.stabilityForm.valid) {
            this.sectionDService.saveMedicalStability(this.stabilityForm.value as MedStability).subscribe(resp => {
                //console.log('resp ', resp);
                this.alerts.success(`${this.screenName} Saved`);
                this.loadData();

            }, error => {
                console.error(error);
                this.alerts.error('Error saving data.');
            });
        } else {
            console.error('form incomplete !');
        }
    }

    onClear1() {
        this.stabilityForm.reset();
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.stabilityForm.reset();
      }
    });

  }
}
