
export interface Medication {
    "tdM_SECTION_ID": string;
    "tdM_ACAT_ID": string;
    "tdM_NAME": string;
    "tdM_OBTAINED": string;
    "tdM_MAIL_ORDER": string;
    "tdM_DOSE": string;
    "tdM_FREQUENCY": string;
    "tdM_ROUTE": string;
    "tdM_PACKAGING": string;
    "tdM_ASSISTANCE": string;
    "tdM_ASSISTANCE_NAME": string;
    "tdM_PRESCRIBED_BY": string;
}

export interface MedicationAdmin {
    tD_SECTION_ID: number;
    tD_ACAT_ID: number;
    tD_MED_IND: string;
    tD_MED_ASSISTANCE: string;
    tD_MED_MONITOR: string;
    tD_MED_SETUP: string;
    tD_MED_CHANGE_IND: string;
    tD_MED_CHANGE_COMMENT: string;
    tD_MED_COMMENT: string;
}

export class MedicationAdminFields {
    public static tD_SECTION_ID = 'tD_SECTION_ID';
    public static tD_ACAT_ID = 'tD_ACAT_ID';
    public static tD_MED_IND = 'tD_MED_IND';
    public static tD_MED_ASSISTANCE = 'tD_MED_ASSISTANCE';
    public static tD_MED_MONITOR = 'tD_MED_MONITOR';
    public static tD_MED_SETUP = 'tD_MED_SETUP';
    public static tD_MED_CHANGE_IND = 'tD_MED_CHANGE_IND';
    public static tD_MED_CHANGE_COMMENT = 'tD_MED_CHANGE_COMMENT';
    public static tD_MED_COMMENT = 'tD_MED_COMMENT'; 
}
