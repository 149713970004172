import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService, SectionDService, AlertsService } from '../shared';
import { SectionDResponse } from './common/SectionDResponse';

@Component({
  selector: 'app-section-d',
  templateUrl: './section-d.component.html',
  styleUrls: ['./section-d.component.css']
})

export class SectionDComponent implements OnInit {

  isActive_physician: string;
  isActive_pharmacy: string;
  isActive_diagnoses: string;
  isActive_medication: string;
  isActive_allergy: string;
  isActive_substance: string;
  isActive_transplant: string;
  isActive_incontinence: string;
  isActive_skilled: string;
  isActive_skilled_rehab: string;
  isActive_assess_medical: string;
  isActive_emergency: string;
  isActive_background: string;
  isActive_browser_class: string;

  constructor(
    private router: Router,
    private dataService: DataService,
    private secDService: SectionDService,
    private alerts: AlertsService) {
    this.router.events.subscribe(event => {

      if (router.url == '/section-d/physician-info' || router.url == '/section-d') {
        this.isActive_physician = "nav-link active vw-font";
      } else {
        this.isActive_physician = "nav-link vw-font";
      }

      if (router.url == '/section-d/pharmacy-info') {
        this.isActive_pharmacy = "nav-link active vw-font";
      } else {
        this.isActive_pharmacy = "nav-link vw-font";
      }
      if (router.url == '/section-d/diagnoses-info') {
        this.isActive_diagnoses = "nav-link active vw-font";
      } else {
        this.isActive_diagnoses = "nav-link vw-font";
      }
      if (router.url == '/section-d/medication-admin') {
        this.isActive_medication = "nav-link active vw-font";
      } else {
        this.isActive_medication = "nav-link vw-font";
      }

      if (router.url == '/section-d/allergy-info') {
        this.isActive_allergy = "nav-link active vw-font";
      } else {
        this.isActive_allergy = "nav-link vw-font";
      }
      if (router.url == '/section-d/substance-use') {
        this.isActive_substance = "nav-link active vw-font";
      } else {
        this.isActive_substance = "nav-link vw-font";
      }
      if (router.url == '/section-d/transplant-info') {
        this.isActive_transplant = "nav-link active vw-font";
      } else {
        this.isActive_transplant = "nav-link vw-font";
      }
      if (router.url == '/section-d/incontinence-info') {
        this.isActive_incontinence = "nav-link active vw-font";
      } else {
        this.isActive_incontinence = "nav-link vw-font";
      }

      if (router.url == '/section-d/skilled-nursing') {
        this.isActive_skilled = "nav-link active vw-font";
      } else {
        this.isActive_skilled = "nav-link vw-font";
      }
      if (router.url == '/section-d/skilled-rehab') {
        this.isActive_skilled_rehab = "nav-link active vw-font";
      } else {
        this.isActive_skilled_rehab = "nav-link vw-font";
      }
      if (router.url == '/section-d/assess-medical-stability') {
        this.isActive_assess_medical = "nav-link active vw-font";
      } else {
        this.isActive_assess_medical = "nav-link vw-font";
      }
      if (router.url == '/section-d/emergency-visit') {
        this.isActive_emergency = "nav-link active vw-font";
      } else {
        this.isActive_emergency = "nav-link vw-font";
      }

      if (router.url == '/section-d/background-info') {
        this.isActive_background = "nav-link active vw-font";
      } else {
        this.isActive_background = "nav-link vw-font";
      }

      this.getBrowser();

      if (event instanceof NavigationEnd) {
        if (event.url == '/section-d') {
          router.navigate(['/section-d/physician-info']);
        }
      }
    });

  }
  ngOnInit() {

    this.secDService.getSectionD(this.dataService.getSelectedDocument()).subscribe((resp: SectionDResponse) => {
      //console.log('section-d', resp);
      this.dataService.saveSectionId(resp.tD_SECTION_ID);
      this.dataService.setSectionD(resp);
      //console.log('tD_SECTION_ID ', this.dataService.getSectionId());
      this.alerts.clearLoader();
    }, error => {
      console.error(error);
    });
  }
  sectionDClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  getBrowser() {
    if (window.navigator.userAgent.indexOf('Trident') != -1) {
      this.isActive_browser_class = 'sideMenuIE';
    } else {      
      this.isActive_browser_class = 'position-sideMenu';
    }
  }
}
