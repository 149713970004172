import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { SkilledNursingComponent } from './skilled-nursing.component';

@Injectable({
  providedIn: 'root'
})
export class SecB1UnsaveGuradGuard implements CanDeactivate<SkilledNursingComponent> {
    canDeactivate(component: SkilledNursingComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {        
        if (!component.canNavigateAway()) {
            return confirm("There are unsaved changes present.\nAre you sure you want to navigate away ?");
        } else {
            return true;
        }
    }
}
