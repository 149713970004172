import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AdaptiveAssistiveEquip } from '../models';
@Injectable({
  providedIn: 'root'
})
export class SectionJService {

  constructor(private apiService: ApiService) { }

  getAAE(acatId): Observable<AdaptiveAssistiveEquip[]> {
    return this.apiService.get('sectionj/' + acatId).pipe(map(data => { return data; }));
  }

    postAAE(adaptiveAssistiveEquip: AdaptiveAssistiveEquip): Observable<any> {
        return this.apiService.putNoLoader('sectionj', adaptiveAssistiveEquip).pipe(map(data => { return data; }));
  }

}
