import { Component, OnInit, Inject  } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionBService, AlertsService, DataService, UserService, getTextForDropdownValues, SectionDropdownService } from '../../../shared/index';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-new-contact',
  templateUrl: './add-new-contact.component.html',
  styleUrls: ['./add-new-contact.component.css']
})
export class AddNewContactComponent implements OnInit {
  showSave_M: boolean;
  showSave: boolean;
  showMaxContact: boolean;
  addNewContact: FormGroup;
  relationshipContact: any[];
  legalRelationshipContact: any[];
  servicePaymentSource: any[];
  counties: any[];
  liveWithIndividualStatus: string;
  serviceInHomeStatus: string;
  show_tB_TYPE_OTHER_DESC = false;
  show_tB_REL_OTHER_DESC = false;
  show_tB_LIVES_WITH_IND = false;
  show_tB_SERVICES_IND = false;
  show_tB_PMT_OTHER_DESC = false;
  dropDownSelectText = getTextForDropdownValues;
  emailValidator = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  strCounty: string;
  strCity: string;
  strZipcode: string;

  constructor(private fb: FormBuilder, 
    private sectionBService: SectionBService,
    private alertsService: AlertsService,
    public dataService: DataService,   
    private dropdownservice: SectionDropdownService,
    private userService: UserService,
    public dialogRef: MatDialogRef<AddNewContactComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: any) {
    this.addNewContact = this.fb.group({

      tB_SECTION_ID: new FormControl(null),
      tB_ACAT_ID: new FormControl(''),
      tB_CONTACTS_IND: new FormControl(''),
      tB_PRIMARY_IND: new FormControl(''),
      tB_FIRST_NAME: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60), Validators.required]),
      tB_INIT: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(1)]),
      tB_LAST_NAME: new FormControl('', [Validators.pattern("[A-Za-z\ \-]+"), Validators.maxLength(60), Validators.required]),
      tB_EMAIL: new FormControl('', [Validators.maxLength(128), Validators.pattern(this.emailValidator)]),
      tB_ADDRESS: new FormControl('', [Validators.pattern("[A-Za-z0-9/:)(-@&*#,;.\. \'-]+"), Validators.maxLength(60)]),
      tB_ADDRESS2: new FormControl('', [Validators.pattern("[A-Za-z0-9/:)(-@&*#,;.\. \'-]+"), Validators.maxLength(60)]),
      tB_CITY: new FormControl('', [Validators.pattern(/^[A-Za-z ]+$/), Validators.maxLength(60)]),
      tB_STATE: new FormControl('', [Validators.pattern(/^[A-Za-z]+$/), Validators.maxLength(3)]),
      tB_ZIP_CODE: new FormControl('', [Validators.pattern("[0-9]{5}"), Validators.maxLength(5)]),
      tB_COUNTY: new FormControl(''),
      tB_PHONE_HOME: new FormControl('', [Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
      tB_PHONE_MOBILE: new FormControl('', [Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
      tB_PHONE_WORK: new FormControl('', [Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
      tB_Relationship: new FormControl(''),
      tB_TYPE_CHILD: new FormControl(''),
      tB_TYPE_GRANDPARENT: new FormControl(''),
      tB_TYPE_OTHER_RELATIVE: new FormControl(''),
      tB_TYPE_PARENT: new FormControl(''),
      tB_TYPE_SIBLING: new FormControl(''),
      tB_TYPE_SIGNIFICANT_OTHER: new FormControl(''),
      tB_TYPE_SPOUSE: new FormControl(''),
      tB_TYPE_FRIEND: new FormControl(''),
      tB_TYPE_NEIGHBOR: new FormControl(''),
      tB_TYPE_ROOMMATE: new FormControl(''),
      tB_TYPE_DORMITORY_RA: new FormControl(''),
      tB_TYPE_GROUP_HOME_STAFF: new FormControl(''),
      tB_TYPE_HOSPITAL_DC_PLANNER: new FormControl(''),
      tB_TYPE_PRISON_SW: new FormControl(''),
      tB_TYPE_SCHOOL_STAFF: new FormControl(''),
      tB_TYPE_CLINICAL_STAFF: new FormControl(''),
      tB_TYPE_HALFWAY_HOUSE_STAFF: new FormControl(''),
      tB_TYPE_ICF_IID_FAC_STAFF: new FormControl(''),
      tB_TYPE_NURSING_FAC_STAFF: new FormControl(''),
      tB_TYPE_CDJFS_CASE_WORKER: new FormControl(''),
      tB_TYPE_SOCIAL_PROGRAM: new FormControl(''),
      tB_TYPE_OTHER: new FormControl(''),
      tB_TYPE_OTHER_DESC: new FormControl('', [Validators.maxLength(60)]),
      tB_LegalRelationship: new FormControl(''),
      tB_REL_PRIMARY_CAREGIVER: new FormControl(''),
      tB_REL_CAREGIVER: new FormControl(''),
      tB_REL_AUTHORIZED_REP: new FormControl(''),
      tB_REL_REPRESENTATIVE_PAYEE: new FormControl(''),
      tB_REL_POA_FINANCIAL: new FormControl(''),
      tB_REL_POA_HEALTHCARE: new FormControl(''),
      tB_REL_POA_EDUCATIONAL: new FormControl(''),
      tB_REL_POA_OTHER: new FormControl(''),
      tB_REL_LG_PLACEMENT: new FormControl(''),
      tB_REL_LG_PERSON: new FormControl(''),
      tB_REL_LG_ESTATE: new FormControl(''),
      tB_REL_LG_PERSON_AND_ESTATE: new FormControl(''),
      tB_REL_SOCIAL_SUPPORT: new FormControl(''),
      tB_REL_OTHER: new FormControl(''),
      tB_REL_OTHER_DESC: new FormControl('', [Validators.maxLength(60)]),
      tB_HIPAA_AUTH: new FormControl(''),
      tB_LIVES_WITH_IND: new FormControl(''),
      tB_LIVES_WITH_IND_COMMENT: new FormControl('', [Validators.maxLength(400)]),
      tB_AGE: new FormControl(null, [Validators.maxLength(3), Validators.pattern("^(0|[1-9][0-9]*)$")]),
      tB_SERVICES_IND: new FormControl(''),
      tB_SERVICES_COMMENT: new FormControl('', [Validators.maxLength(400)]),
      tB_ServicePaymentSource: new FormControl(''),
      tB_PMT_MEDICARE: new FormControl(''),
      tB_PMT_MEDICAID: new FormControl(''),
      tB_PMT_MEDICAID_WAIVER: new FormControl(''),
      tB_PMT_COUNTY_BOARD_OF_DD: new FormControl(''),
      tB_PMT_BEHAV_HEALTH_PROG: new FormControl(''),
      tB_PMT_MEDICAID_MANAGEDCARE: new FormControl(''),
      tB_PMT_MEDICAID_MYCARE_OHIO: new FormControl(''),
      tB_PMT_DEPT_VETERAN_AFFAIR: new FormControl(''),
      tB_PMT_OTHER: new FormControl(''),
      tB_PMT_OTHER_DESC: new FormControl('', [Validators.maxLength(60)]),
      tB_BEST_CONTACT_TIME: new FormControl('', [Validators.maxLength(60)]),
      tB_COMMENTS: new FormControl('', [Validators.maxLength(400)])
    });
  }

  ngOnInit() {
   
    this.loadDropdownService();
    this.configDynValidations();
    this.onChanges();
    this.addNewContact.get('tB_FIRST_NAME').markAsTouched();
    this.addNewContact.get('tB_LAST_NAME').markAsTouched();

    this.sectionBService.LoadContacts(this.dataService.getSelectedDocument()).subscribe(response => {
      if (response.length > 19) {
        if (this.editData.mode != 'New') {
          if (this.editData.sectionID != 0 && this.editData.acatID != 0) {
            if (this.editData.mode == 'Edit') {
              this.sectionBService.GetEditContacts(this.editData.sectionID, this.editData.acatID).subscribe(response => {
                this.strCounty = response.tB_COUNTY;
                this.strCity = response.tB_CITY;
                this.strZipcode = response.tB_ZIP_CODE;
                this.receiveAddNewContact(response);
                this.showSave_M = false;
                this.showSave = true;
                this.alertsService.success("Contact Information Loaded");

              }, err => {
                this.alertsService.error(err);
              });
            } else if (this.editData.mode == 'View') {
              this.sectionBService.GetEditContacts(this.editData.sectionID, this.editData.acatID).subscribe(response => {
                this.strCounty = response.tB_COUNTY;
                this.strCity = response.tB_CITY;
                this.strZipcode = response.tB_ZIP_CODE;
                this.receiveAddNewContact(response);
                this.addNewContact.disable();
                this.showSave_M = false;
                this.showSave = false;
                this.alertsService.success("Contact Information Loaded");

              }, err => {
                this.alertsService.error(err);
              });
            }

          }
        } else {
          this.showMaxContact = true;
          this.addNewContact.disable();
          this.showSave_M = false;
          this.showSave = false;
        }
         
        
      } else {
        this.showMaxContact = false;
        this.showSave_M = true;
        this.showSave = true;
        if (this.editData.sectionID != 0 && this.editData.acatID != 0) {
          if (this.editData.mode == 'Edit') {
            this.sectionBService.GetEditContacts(this.editData.sectionID, this.editData.acatID).subscribe(response => {
              this.strCounty = response.tB_COUNTY;
              this.strCity = response.tB_CITY;
              this.strZipcode = response.tB_ZIP_CODE;
              this.receiveAddNewContact(response);
              this.alertsService.success("Contact Information Loaded");

            }, err => {
              this.alertsService.error(err);
            });
          } else if (this.editData.mode == 'View') {
            this.sectionBService.GetEditContacts(this.editData.sectionID, this.editData.acatID).subscribe(response => {
              this.strCounty = response.tB_COUNTY;
              this.strCity = response.tB_CITY;
              this.strZipcode = response.tB_ZIP_CODE;
              this.receiveAddNewContact(response);
              this.addNewContact.disable();
              this.showSave_M = false;
              this.showSave = false;
              this.alertsService.success("Contact Information Loaded");

            }, err => {
              this.alertsService.error(err);
            });
          }

        } else {
          this.strCounty = null;
          this.strCity = null;
          this.strZipcode = null;
        }
      }

    }, err => {
      this.alertsService.error(err);
      });   
   
  }
  receiveAddNewContact(data) {
    this.addNewContact.patchValue(data);
    this.setFormData(data)
  }
  setFormData(data) {

    let relationshipList: string[] = [];
    if (data['tB_TYPE_CHILD'] == 'Y') {
      relationshipList.push('CHILD');
    }
    if (data['tB_TYPE_GRANDPARENT'] == 'Y') {
      relationshipList.push('GPARENT');
    }
    if (data['tB_TYPE_OTHER_RELATIVE'] == 'Y') {
      relationshipList.push('ORELATIVE');
    }
    if (data['tB_TYPE_PARENT'] == 'Y') {
      relationshipList.push('PARENT');
    }
    if (data['tB_TYPE_SIBLING'] == 'Y') {
      relationshipList.push('SIBLING');
    }
    if (data['tB_TYPE_SIGNIFICANT_OTHER'] == 'Y') {
      relationshipList.push('SOTHER');
    }
    if (data['tB_TYPE_SPOUSE'] == 'Y') {
      relationshipList.push('SPOUSE');
    }
    if (data['tB_TYPE_FRIEND'] == 'Y') {
      relationshipList.push('FRIEND');
    }
    if (data['tB_TYPE_NEIGHBOR'] == 'Y') {
      relationshipList.push('NEIGHBOUR');
    }
    if (data['tB_TYPE_ROOMMATE'] == 'Y') {
      relationshipList.push('ROOMMATE');
    }
    if (data['tB_TYPE_DORMITORY_RA'] == 'Y') {
      relationshipList.push('DORMITORY');
    }
    if (data['tB_TYPE_GROUP_HOME_STAFF'] == 'Y') {
      relationshipList.push('GROUP');
    }
    if (data['tB_TYPE_HOSPITAL_DC_PLANNER'] == 'Y') {
      relationshipList.push('HOSPITAL');
    }
    if (data['tB_TYPE_PRISON_SW'] == 'Y') {
      relationshipList.push('PRISION');
    }
    if (data['tB_TYPE_SCHOOL_STAFF'] == 'Y') {
      relationshipList.push('SCHOOL');
    }
    if (data['tB_TYPE_CLINICAL_STAFF'] == 'Y') {
      relationshipList.push('CLINICAL');
    }
    if (data['tB_TYPE_HALFWAY_HOUSE_STAFF'] == 'Y') {
      relationshipList.push('HALFWAY');
    }
    if (data['tB_TYPE_ICF_IID_FAC_STAFF'] == 'Y') {
      relationshipList.push('LISW');
    }
    
    if (data['tB_TYPE_NURSING_FAC_STAFF'] == 'Y') {
      relationshipList.push('NURSING');
    }
    if (data['tB_TYPE_CDJFS_CASE_WORKER'] == 'Y') {
      relationshipList.push('CDJFS');
    }
    if (data['tB_TYPE_SOCIAL_PROGRAM'] == 'Y') {
      relationshipList.push('SOCIAL');
    }
    if (data['tB_TYPE_OTHER'] == 'Y') {
      relationshipList.push('OTHER');
      this.show_tB_TYPE_OTHER_DESC = true;
    } else {
      this.show_tB_TYPE_OTHER_DESC = false;     
      this.addNewContact.get('tB_TYPE_OTHER_DESC').setValue(null);
    }

    this.addNewContact.get('tB_Relationship').setValue(relationshipList);

    let legalRelationshipList: string[] = [];
    if (data['tB_REL_PRIMARY_CAREGIVER'] == 'Y') {
      legalRelationshipList.push('PCAREGIVER');
    }
    if (data['tB_REL_CAREGIVER'] == 'Y') {
      legalRelationshipList.push('CAREGIVER');
    }
    if (data['tB_REL_AUTHORIZED_REP'] == 'Y') {
      legalRelationshipList.push('AUTHORIZED');
    }
    if (data['tB_REL_REPRESENTATIVE_PAYEE'] == 'Y') {
      legalRelationshipList.push('REPPAYEE');
    }
    if (data['tB_REL_POA_FINANCIAL'] == 'Y') {
      legalRelationshipList.push('FINANCIAL');
    }
    if (data['tB_REL_POA_HEALTHCARE'] == 'Y') {
      legalRelationshipList.push('HEALTHCARE');
    }
    if (data['tB_REL_POA_EDUCATIONAL'] == 'Y') {
      legalRelationshipList.push('EDUCATION');
    }
    if (data['tB_REL_POA_OTHER'] == 'Y') {
      legalRelationshipList.push('POAOTHER');
    }
    if (data['tB_REL_LG_PLACEMENT'] == 'Y') {
      legalRelationshipList.push('PLACEMENT');
    }
    if (data['tB_REL_LG_PERSON'] == 'Y') {
      legalRelationshipList.push('PERSON');
    }
    if (data['tB_REL_LG_ESTATE'] == 'Y') {
      legalRelationshipList.push('ESTATE');
    }
    if (data['tB_REL_LG_PERSON_AND_ESTATE'] == 'Y') {
      legalRelationshipList.push('PERESTATE');
    }
    if (data['tB_REL_SOCIAL_SUPPORT'] == 'Y') {
      legalRelationshipList.push('CHILD');
    }
    if (data['tB_REL_OTHER'] == 'Y') {
      legalRelationshipList.push('OTHER');
      this.show_tB_REL_OTHER_DESC = true;
    } else {
      this.show_tB_REL_OTHER_DESC = false;      
      this.addNewContact.get('tB_REL_OTHER_DESC').setValue(null);
    }

    this.addNewContact.get('tB_LegalRelationship').setValue(legalRelationshipList);

    let paymentSourceList: string[] = [];
    if (data['tB_PMT_MEDICARE'] == 'Y') {
      paymentSourceList.push('MEDICARE');
    }
    if (data['tB_PMT_MEDICAID'] == 'Y') {
      paymentSourceList.push('MEDICAID');
    }
    if (data['tB_PMT_MEDICAID_WAIVER'] == 'Y') {
      paymentSourceList.push('MEDWAIVER');
    }
    if (data['tB_PMT_COUNTY_BOARD_OF_DD'] == 'Y') {
      paymentSourceList.push('CCOUNTY');
    }
    if (data['tB_PMT_BEHAV_HEALTH_PROG'] == 'Y') {
      paymentSourceList.push('BEHHEALTH');
    }
    if (data['tB_PMT_MEDICAID_MANAGEDCARE'] == 'Y') {
      paymentSourceList.push('MEDMNG');
    }
    if (data['tB_PMT_MEDICAID_MYCARE_OHIO'] == 'Y') {
      paymentSourceList.push('MEDMYPRG');
    }
    if (data['tB_PMT_DEPT_VETERAN_AFFAIR'] == 'Y') {
      paymentSourceList.push('DVETA');
    }
    if (data['tB_PMT_OTHER'] == 'Y') {
      paymentSourceList.push('OTHER');
      this.show_tB_PMT_OTHER_DESC = true;
    } else {
      this.show_tB_PMT_OTHER_DESC = false;      
      this.addNewContact.get('tB_PMT_OTHER_DESC').setValue(null);
    }
    this.addNewContact.get('tB_ServicePaymentSource').setValue(paymentSourceList);

    if (data['tB_LIVES_WITH_IND'] == 'Y') {
      this.show_tB_LIVES_WITH_IND = false;
    } else {
      this.show_tB_LIVES_WITH_IND = true;
    }

    if (data['tB_SERVICES_IND'] == 'Y') {
      this.show_tB_SERVICES_IND = true;
    } else {
      this.show_tB_SERVICES_IND = false;
    }

  }
  loadDropdownService() {    

    this.dropdownservice.GetDropDownValues('CONTACT_RELATION').subscribe((data: any[]) => {
      this.relationshipContact = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('CONTACT_LEGAL').subscribe((data: any[]) => {
      this.legalRelationshipContact = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('SERVICE_PAYMENT_SOURCE').subscribe((data: any[]) => {
      this.servicePaymentSource = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });
    this.sectionBService.getCounties().subscribe(data => {
      this.counties = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });
   
  }
  onChanges(): void {
   

    //this.addNewContact.get('tB_ZIP_CODE').valueChanges.subscribe(zipcode => {
    //  if (zipcode != undefined) {
    //    //if (zipcode.length == 5) {
    //    let zip = { ZipCode: this.addNewContact.get('tB_ZIP_CODE').value };
    //    if (zip.ZipCode.length == 5) {
    //      this.sectionBService.getCityState(zip).subscribe(
    //        cityState => {
    //          this.addNewContact.patchValue({ tB_CITY: cityState.city });
    //          this.addNewContact.patchValue({ tB_STATE: (zipcode.substring(0, 2) == '43' || zipcode.substring(0, 2) == '44' || zipcode.substring(0, 2) == '45') ? 'OH' : '' });
    //          this.addNewContact.patchValue({ tB_COUNTY: cityState.countY_CODE });
    //          this.alertsService.clearLoader();
    //        }, err => {
    //          this.alertsService.error(err);
    //        });
    //    }
    //    else {
    //      this.addNewContact.patchValue({ tB_CITY: '' });
    //      this.addNewContact.patchValue({ tB_STATE: '' });
    //      this.addNewContact.patchValue({ tB_COUNTY: '' });
    //    }
    //  }
    //  else {
    //    this.addNewContact.patchValue({ tB_CITY: '' });
    //    this.addNewContact.patchValue({ tB_STATE: '' });
    //    this.addNewContact.patchValue({ tB_COUNTY: '' });
    //  }
    //}, err => {
    //  this.alertsService.error(err);
    //});

    //New
    this.addNewContact.get('tB_ZIP_CODE').valueChanges.subscribe(zipcode => {
      if (zipcode != undefined) {
        let city = this.strCity;
        let county = this.strCounty
        let zipcode1 = this.strZipcode;
        let zip = { ZipCode: this.addNewContact.get('tB_ZIP_CODE').value };

        if (zip.ZipCode.length == 5) {
          this.sectionBService.getCityState(zip).subscribe(
            cityState => {
              if (zip.ZipCode == zipcode1) {
                if (cityState.city != city && city != null) {
                  this.addNewContact.patchValue({ tB_CITY: city });
                } else {
                  this.addNewContact.patchValue({ tB_CITY: cityState.city });
                }

                if (cityState.countY_CODE != county && county != null) {
                  this.addNewContact.patchValue({ tB_COUNTY: county });
                } else {
                  this.addNewContact.patchValue({ tB_COUNTY: cityState.countY_CODE });
                }
                this.addNewContact.patchValue({ tB_STATE: (zipcode.substring(0, 2) == '43' || zipcode.substring(0, 2) == '44' || zipcode.substring(0, 2) == '45') ? 'OH' : '' });

              } else {
                this.addNewContact.patchValue({ tB_CITY: cityState.city });
                this.addNewContact.patchValue({ tB_COUNTY: cityState.countY_CODE });
                this.addNewContact.patchValue({ tB_STATE: (zipcode.substring(0, 2) == '43' || zipcode.substring(0, 2) == '44' || zipcode.substring(0, 2) == '45') ? 'OH' : '' });

              }
              this.alertsService.clearLoader();
            }, err => {
              this.alertsService.error(err);
            });
        }
        else {
        this.addNewContact.patchValue({ tB_CITY: '' });
          this.addNewContact.patchValue({ tB_STATE: '' });
          this.addNewContact.patchValue({ tB_COUNTY: '' });
        }
      }
      else {
        this.addNewContact.patchValue({ tB_CITY: '' });
        this.addNewContact.patchValue({ tB_STATE: '' });
        this.addNewContact.patchValue({ tB_COUNTY: '' });
      }
    }, err => {
      this.alertsService.error(err);
    });
    this.addNewContact.get('tB_AGE').valueChanges.subscribe(age => {
      if (age > 120) {
        this.addNewContact.get('tB_AGE').setErrors({ 'incorrect': true });
      }
    });
  }
  configDynValidations() {

    this.addNewContact.get('tB_Relationship').valueChanges.subscribe(value => {
      if (value.indexOf('OTHER') > -1) {
     // if (value == 'OTHER') {
        this.show_tB_TYPE_OTHER_DESC = true;
        this.addNewContact.get('tB_TYPE_OTHER_DESC').setValidators([Validators.required]);
        this.addNewContact.get('tB_TYPE_OTHER_DESC').markAsTouched();
      } else {
        this.show_tB_TYPE_OTHER_DESC = false;
        this.addNewContact.get('tB_TYPE_OTHER_DESC').clearValidators();
        this.addNewContact.get('tB_TYPE_OTHER_DESC').setValue(null);
      }
   //   this.addNewContact.get('tB_Relationship').updateValueAndValidity();
    });

    this.addNewContact.get('tB_LegalRelationship').valueChanges.subscribe(value => {
      if (value.indexOf('OTHER') > -1) {
     // if (value == 'OTHER') {
        this.show_tB_REL_OTHER_DESC = true;
        this.addNewContact.get('tB_REL_OTHER_DESC').setValidators([Validators.required]);
        this.addNewContact.get('tB_REL_OTHER_DESC').markAsTouched();
      } else {
        this.show_tB_REL_OTHER_DESC = false;
        this.addNewContact.get('tB_REL_OTHER_DESC').clearValidators();
        this.addNewContact.get('tB_REL_OTHER_DESC').setValue(null);
      }
    //  this.addNewContact.get('tB_LegalRelationship').updateValueAndValidity();
    });

    this.addNewContact.get('tB_ServicePaymentSource').valueChanges.subscribe(value => {
      if (value.indexOf('OTHER') > -1) {
     // if (value == 'OTHER') {
        this.show_tB_PMT_OTHER_DESC = true;
        this.addNewContact.get('tB_PMT_OTHER_DESC').setValidators([Validators.required]);
        this.addNewContact.get('tB_PMT_OTHER_DESC').markAsTouched();
      } else {
        this.show_tB_PMT_OTHER_DESC = false;
        this.addNewContact.get('tB_PMT_OTHER_DESC').clearValidators();
        this.addNewContact.get('tB_PMT_OTHER_DESC').setValue(null);
      }
     // this.addNewContact.get('tB_ServicePaymentSource').updateValueAndValidity();
    });

  }
  changeLiveWithIndividual() {
    this.liveWithIndividualStatus = this.addNewContact.get('tB_LIVES_WITH_IND').value;
    if (this.liveWithIndividualStatus === "Y") {
      this.show_tB_LIVES_WITH_IND = false;

      this.addNewContact.get('tB_LIVES_WITH_IND_COMMENT').setValue(null);
      this.addNewContact.get('tB_AGE').setValue(null);
      this.addNewContact.get('tB_SERVICES_IND').setValue(null);
      this.addNewContact.get('tB_SERVICES_COMMENT').setValue(null);
     // this.addNewContact.get('tB_ServicePaymentSource').setValue(null);
      this.addNewContact.get('tB_PMT_OTHER_DESC').setValue(null);

    } else {
      this.show_tB_LIVES_WITH_IND = true;
      
    }
  }
  changeServiceInhome() {
    this.serviceInHomeStatus = this.addNewContact.get('tB_SERVICES_IND').value;
    if (this.serviceInHomeStatus === "Y") {
      this.show_tB_SERVICES_IND = true;

    } else {
      this.show_tB_SERVICES_IND = false;
      this.addNewContact.get('tB_SERVICES_COMMENT').setValue(null);
    //  this.addNewContact.get('tB_ServicePaymentSource').setValue(null);
      this.addNewContact.get('tB_PMT_OTHER_DESC').setValue(null);
    }
  }
  clearaddNewContact() {  
    this.addNewContact.get('tB_TYPE_OTHER_DESC').clearValidators();
    this.addNewContact.get('tB_REL_OTHER_DESC').clearValidators();
    this.addNewContact.get('tB_PMT_OTHER_DESC').clearValidators();
    this.show_tB_TYPE_OTHER_DESC = false;
    this.show_tB_REL_OTHER_DESC = false;
    this.show_tB_LIVES_WITH_IND = false;
    this.show_tB_SERVICES_IND = false;
    this.show_tB_PMT_OTHER_DESC = false;
    this.addNewContact.reset({ tB_Relationship: this.relationshipContact, tB_LegalRelationship: this.legalRelationshipContact, tB_ServicePaymentSource: this.servicePaymentSource});
    
  }
  saveaddNewContact() {

    this.addNewContact.get('tB_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    this.addNewContact.get('tB_CONTACTS_IND').setValue('Y');
   
    if (!this.addNewContact.valid) {
      for (let cnt in this.addNewContact.controls) {
        if (this.addNewContact.controls[cnt].invalid) {
          console.log(cnt, this.addNewContact.controls[cnt]);
        }
      }
      return;
    }

    this.addNewContact.get('tB_TYPE_CHILD').setValue(null);
    this.addNewContact.get('tB_TYPE_GRANDPARENT').setValue(null);
    this.addNewContact.get('tB_TYPE_OTHER_RELATIVE').setValue(null);
    this.addNewContact.get('tB_TYPE_PARENT').setValue(null);
    this.addNewContact.get('tB_TYPE_SIBLING').setValue(null);
    this.addNewContact.get('tB_TYPE_SIGNIFICANT_OTHER').setValue(null);
    this.addNewContact.get('tB_TYPE_SPOUSE').setValue(null);
    this.addNewContact.get('tB_TYPE_FRIEND').setValue(null);
    this.addNewContact.get('tB_TYPE_NEIGHBOR').setValue(null);
    this.addNewContact.get('tB_TYPE_ROOMMATE').setValue(null);
    this.addNewContact.get('tB_TYPE_DORMITORY_RA').setValue(null);
    this.addNewContact.get('tB_TYPE_GROUP_HOME_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_HOSPITAL_DC_PLANNER').setValue(null);
    this.addNewContact.get('tB_TYPE_PRISON_SW').setValue(null);
    this.addNewContact.get('tB_TYPE_SCHOOL_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_CLINICAL_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_HALFWAY_HOUSE_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_ICF_IID_FAC_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_NURSING_FAC_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_CDJFS_CASE_WORKER').setValue(null);
    this.addNewContact.get('tB_TYPE_SOCIAL_PROGRAM').setValue(null);
    this.addNewContact.get('tB_TYPE_OTHER').setValue(null);

    let relationshipList: [] = this.addNewContact.get('tB_Relationship').value;

    if (Array.isArray(relationshipList)) {
      relationshipList.forEach(value => {
        switch (value) {
          case 'CHILD':
            this.addNewContact.get('tB_TYPE_CHILD').setValue('Y');
            break;
          case 'GPARENT':
            this.addNewContact.get('tB_TYPE_GRANDPARENT').setValue('Y');
            break;
          case 'ORELATIVE':
            this.addNewContact.get('tB_TYPE_OTHER_RELATIVE').setValue('Y');
            break;
          case 'PARENT':
            this.addNewContact.get('tB_TYPE_PARENT').setValue('Y');
            break;
          case 'SIBLING':
            this.addNewContact.get('tB_TYPE_SIBLING').setValue('Y');
            break;
          case 'SOTHER':
            this.addNewContact.get('tB_TYPE_SIGNIFICANT_OTHER').setValue('Y');
            break;
          case 'SPOUSE':
            this.addNewContact.get('tB_TYPE_SPOUSE').setValue('Y');
            break;
          case 'FRIEND':
            this.addNewContact.get('tB_TYPE_FRIEND').setValue('Y');
            break;
          case 'NEIGHBOUR':
            this.addNewContact.get('tB_TYPE_NEIGHBOR').setValue('Y');
            break;
          case 'ROOMMATE':
            this.addNewContact.get('tB_TYPE_ROOMMATE').setValue('Y');
            break;
          case 'DORMITORY':
            this.addNewContact.get('tB_TYPE_DORMITORY_RA').setValue('Y');
            break;
          case 'GROUP':
            this.addNewContact.get('tB_TYPE_GROUP_HOME_STAFF').setValue('Y');
            break;
          case 'HOSPITAL':
            this.addNewContact.get('tB_TYPE_HOSPITAL_DC_PLANNER').setValue('Y');
            break;
          case 'PRISION':
            this.addNewContact.get('tB_TYPE_PRISON_SW').setValue('Y');
            break;
          case 'SCHOOL':
            this.addNewContact.get('tB_TYPE_SCHOOL_STAFF').setValue('Y');
            break;
          case 'CLINICAL':
            this.addNewContact.get('tB_TYPE_CLINICAL_STAFF').setValue('Y');
            break;
          case 'HALFWAY':
            this.addNewContact.get('tB_TYPE_HALFWAY_HOUSE_STAFF').setValue('Y');
            break;
          case 'ICFIID':
            this.addNewContact.get('tB_TYPE_ICF_IID_FAC_STAFF').setValue('Y');
            break;
          case 'NURSING':
            this.addNewContact.get('tB_TYPE_NURSING_FAC_STAFF').setValue('Y');
            break;
          case 'CDJFS':
            this.addNewContact.get('tB_TYPE_CDJFS_CASE_WORKER').setValue('Y');
            break;
          case 'SOCIAL':
            this.addNewContact.get('tB_TYPE_SOCIAL_PROGRAM').setValue('Y');
            break;
          case 'OTHER':
            this.addNewContact.get('tB_TYPE_OTHER').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    this.addNewContact.get('tB_REL_PRIMARY_CAREGIVER').setValue(null);
    this.addNewContact.get('tB_REL_CAREGIVER').setValue(null);
    this.addNewContact.get('tB_REL_AUTHORIZED_REP').setValue(null);
    this.addNewContact.get('tB_REL_REPRESENTATIVE_PAYEE').setValue(null);
    this.addNewContact.get('tB_REL_POA_FINANCIAL').setValue(null);
    this.addNewContact.get('tB_REL_POA_HEALTHCARE').setValue(null);
    this.addNewContact.get('tB_REL_POA_EDUCATIONAL').setValue(null);
    this.addNewContact.get('tB_REL_POA_OTHER').setValue(null);
    this.addNewContact.get('tB_REL_LG_PLACEMENT').setValue(null);
    this.addNewContact.get('tB_REL_LG_PERSON').setValue(null);
    this.addNewContact.get('tB_REL_LG_ESTATE').setValue(null);
    this.addNewContact.get('tB_REL_LG_PERSON_AND_ESTATE').setValue(null);
    this.addNewContact.get('tB_REL_SOCIAL_SUPPORT').setValue(null);
    this.addNewContact.get('tB_REL_OTHER').setValue(null);

    let legalRelationshipList: [] = this.addNewContact.get('tB_LegalRelationship').value;

    if (Array.isArray(legalRelationshipList)) {
      legalRelationshipList.forEach(value => {
        switch (value) {
          case 'PCAREGIVER':
            this.addNewContact.get('tB_REL_PRIMARY_CAREGIVER').setValue('Y');
            break;
          case 'CAREGIVER':
            this.addNewContact.get('tB_REL_CAREGIVER').setValue('Y');
            break;
          case 'AUTHORIZED':
            this.addNewContact.get('tB_REL_AUTHORIZED_REP').setValue('Y');
            break;
          case 'REPPAYEE':
            this.addNewContact.get('tB_REL_REPRESENTATIVE_PAYEE').setValue('Y');
            break;
          case 'FINANCIAL':
            this.addNewContact.get('tB_REL_POA_FINANCIAL').setValue('Y');
            break;
          case 'HEALTHCARE':
            this.addNewContact.get('tB_REL_POA_HEALTHCARE').setValue('Y');
            break;
          case 'EDUCATION':
            this.addNewContact.get('tB_REL_POA_EDUCATIONAL').setValue('Y');
            break;
          case 'POAOTHER':
            this.addNewContact.get('tB_REL_POA_OTHER').setValue('Y');
            break;
          case 'PLACEMENT':
            this.addNewContact.get('tB_REL_LG_PLACEMENT').setValue('Y');
            break;
          case 'PERSON':
            this.addNewContact.get('tB_REL_LG_PERSON').setValue('Y');
            break;
          case 'ESTATE':
            this.addNewContact.get('tB_REL_LG_ESTATE').setValue('Y');
            break;
          case 'PERESTATE':
            this.addNewContact.get('tB_REL_LG_PERSON_AND_ESTATE').setValue('Y');
            break;
          case 'SOCIAL':
            this.addNewContact.get('tB_REL_SOCIAL_SUPPORT').setValue('Y');
            break;
          case 'OTHER':
            this.addNewContact.get('tB_REL_OTHER').setValue('Y');
            break;          
          default:
            break;
        }
      });
    }

    this.addNewContact.get('tB_PMT_MEDICARE').setValue(null);
    this.addNewContact.get('tB_PMT_MEDICAID').setValue(null);
    this.addNewContact.get('tB_PMT_MEDICAID_WAIVER').setValue(null);
    this.addNewContact.get('tB_PMT_COUNTY_BOARD_OF_DD').setValue(null);
    this.addNewContact.get('tB_PMT_BEHAV_HEALTH_PROG').setValue(null);
    this.addNewContact.get('tB_PMT_MEDICAID_MANAGEDCARE').setValue(null);
    this.addNewContact.get('tB_PMT_MEDICAID_MYCARE_OHIO').setValue(null);
    this.addNewContact.get('tB_PMT_DEPT_VETERAN_AFFAIR').setValue(null);
    this.addNewContact.get('tB_PMT_OTHER').setValue(null);

    let serviceSourcePayment: [] = this.addNewContact.get('tB_ServicePaymentSource').value;

    if (Array.isArray(serviceSourcePayment)) {
      serviceSourcePayment.forEach(value => {
        switch (value) {
          case 'MEDICARE':
            this.addNewContact.get('tB_PMT_MEDICARE').setValue('Y');
            break;
          case 'MEDICAID':
            this.addNewContact.get('tB_PMT_MEDICAID').setValue('Y');
            break;
          case 'MEDWAIVER':
            this.addNewContact.get('tB_PMT_MEDICAID_WAIVER').setValue('Y');
            break;
          case 'CCOUNTY':
            this.addNewContact.get('tB_PMT_COUNTY_BOARD_OF_DD').setValue('Y');
            break;
          case 'BEHHEALTH':
            this.addNewContact.get('tB_PMT_BEHAV_HEALTH_PROG').setValue('Y');
            break;
          case 'MEDMNG':
            this.addNewContact.get('tB_PMT_MEDICAID_MANAGEDCARE').setValue('Y');
            break;
          case 'MEDMYPRG':
            this.addNewContact.get('tB_PMT_MEDICAID_MYCARE_OHIO').setValue('Y');
            break;
          case 'DVETA':
            this.addNewContact.get('tB_PMT_DEPT_VETERAN_AFFAIR').setValue('Y');
            break;
          case 'OTHER':
            this.addNewContact.get('tB_PMT_OTHER').setValue('Y');
            break;          
          default:
            break;
        }
      });
    }

    let addNewContactData = this.addNewContact.value;
    this.sectionBService.PostSectionB(addNewContactData).subscribe(resp => {      
      this.alertsService.success("Contact Information Saved");
      this.dialogRef.close("success");
    }, error => {
        console.error('Error Saving Contact Information', error);
        this.alertsService.error("Error Saving Contact Information");
      });


  }
  saveaddMultipleContacts() {

    this.addNewContact.get('tB_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    this.addNewContact.get('tB_CONTACTS_IND').setValue('Y');

    if (!this.addNewContact.valid) {
      for (let cnt in this.addNewContact.controls) {
        if (this.addNewContact.controls[cnt].invalid) {
          console.log(cnt, this.addNewContact.controls[cnt]);
        }
      }
      return;
    }

    this.addNewContact.get('tB_TYPE_CHILD').setValue(null);
    this.addNewContact.get('tB_TYPE_GRANDPARENT').setValue(null);
    this.addNewContact.get('tB_TYPE_OTHER_RELATIVE').setValue(null);
    this.addNewContact.get('tB_TYPE_PARENT').setValue(null);
    this.addNewContact.get('tB_TYPE_SIBLING').setValue(null);
    this.addNewContact.get('tB_TYPE_SIGNIFICANT_OTHER').setValue(null);
    this.addNewContact.get('tB_TYPE_SPOUSE').setValue(null);
    this.addNewContact.get('tB_TYPE_FRIEND').setValue(null);
    this.addNewContact.get('tB_TYPE_NEIGHBOR').setValue(null);
    this.addNewContact.get('tB_TYPE_ROOMMATE').setValue(null);
    this.addNewContact.get('tB_TYPE_DORMITORY_RA').setValue(null);
    this.addNewContact.get('tB_TYPE_GROUP_HOME_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_HOSPITAL_DC_PLANNER').setValue(null);
    this.addNewContact.get('tB_TYPE_PRISON_SW').setValue(null);
    this.addNewContact.get('tB_TYPE_SCHOOL_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_CLINICAL_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_HALFWAY_HOUSE_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_ICF_IID_FAC_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_NURSING_FAC_STAFF').setValue(null);
    this.addNewContact.get('tB_TYPE_CDJFS_CASE_WORKER').setValue(null);
    this.addNewContact.get('tB_TYPE_SOCIAL_PROGRAM').setValue(null);
    this.addNewContact.get('tB_TYPE_OTHER').setValue(null);

    let relationshipList: [] = this.addNewContact.get('tB_Relationship').value;

    if (Array.isArray(relationshipList)) {
      relationshipList.forEach(value => {
        switch (value) {
          case 'CHILD':
            this.addNewContact.get('tB_TYPE_CHILD').setValue('Y');
            break;
          case 'GPARENT':
            this.addNewContact.get('tB_TYPE_GRANDPARENT').setValue('Y');
            break;
          case 'ORELATIVE':
            this.addNewContact.get('tB_TYPE_OTHER_RELATIVE').setValue('Y');
            break;
          case 'PARENT':
            this.addNewContact.get('tB_TYPE_PARENT').setValue('Y');
            break;
          case 'SIBLING':
            this.addNewContact.get('tB_TYPE_SIBLING').setValue('Y');
            break;
          case 'SOTHER':
            this.addNewContact.get('tB_TYPE_SIGNIFICANT_OTHER').setValue('Y');
            break;
          case 'SPOUSE':
            this.addNewContact.get('tB_TYPE_SPOUSE').setValue('Y');
            break;
          case 'FRIEND':
            this.addNewContact.get('tB_TYPE_FRIEND').setValue('Y');
            break;
          case 'NEIGHBOUR':
            this.addNewContact.get('tB_TYPE_NEIGHBOR').setValue('Y');
            break;
          case 'ROOMMATE':
            this.addNewContact.get('tB_TYPE_ROOMMATE').setValue('Y');
            break;
          case 'DORMITORY':
            this.addNewContact.get('tB_TYPE_DORMITORY_RA').setValue('Y');
            break;
          case 'GROUP':
            this.addNewContact.get('tB_TYPE_GROUP_HOME_STAFF').setValue('Y');
            break;
          case 'HOSPITAL':
            this.addNewContact.get('tB_TYPE_HOSPITAL_DC_PLANNER').setValue('Y');
            break;
          case 'PRISION':
            this.addNewContact.get('tB_TYPE_PRISON_SW').setValue('Y');
            break;
          case 'SCHOOL':
            this.addNewContact.get('tB_TYPE_SCHOOL_STAFF').setValue('Y');
            break;
          case 'CLINICAL':
            this.addNewContact.get('tB_TYPE_CLINICAL_STAFF').setValue('Y');
            break;
          case 'HALFWAY':
            this.addNewContact.get('tB_TYPE_HALFWAY_HOUSE_STAFF').setValue('Y');
            break;
          case 'ICFIID':
            this.addNewContact.get('tB_TYPE_ICF_IID_FAC_STAFF').setValue('Y');
            break;
          case 'NURSING':
            this.addNewContact.get('tB_TYPE_NURSING_FAC_STAFF').setValue('Y');
            break;
          case 'CDJFS':
            this.addNewContact.get('tB_TYPE_CDJFS_CASE_WORKER').setValue('Y');
            break;
          case 'SOCIAL':
            this.addNewContact.get('tB_TYPE_SOCIAL_PROGRAM').setValue('Y');
            break;
          case 'OTHER':
            this.addNewContact.get('tB_TYPE_OTHER').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    this.addNewContact.get('tB_REL_PRIMARY_CAREGIVER').setValue(null);
    this.addNewContact.get('tB_REL_CAREGIVER').setValue(null);
    this.addNewContact.get('tB_REL_AUTHORIZED_REP').setValue(null);
    this.addNewContact.get('tB_REL_REPRESENTATIVE_PAYEE').setValue(null);
    this.addNewContact.get('tB_REL_POA_FINANCIAL').setValue(null);
    this.addNewContact.get('tB_REL_POA_HEALTHCARE').setValue(null);
    this.addNewContact.get('tB_REL_POA_EDUCATIONAL').setValue(null);
    this.addNewContact.get('tB_REL_POA_OTHER').setValue(null);
    this.addNewContact.get('tB_REL_LG_PLACEMENT').setValue(null);
    this.addNewContact.get('tB_REL_LG_PERSON').setValue(null);
    this.addNewContact.get('tB_REL_LG_ESTATE').setValue(null);
    this.addNewContact.get('tB_REL_LG_PERSON_AND_ESTATE').setValue(null);
    this.addNewContact.get('tB_REL_SOCIAL_SUPPORT').setValue(null);
    this.addNewContact.get('tB_REL_OTHER').setValue(null);

    let legalRelationshipList: [] = this.addNewContact.get('tB_LegalRelationship').value;

    if (Array.isArray(legalRelationshipList)) {
      legalRelationshipList.forEach(value => {
        switch (value) {
          case 'PCAREGIVER':
            this.addNewContact.get('tB_REL_PRIMARY_CAREGIVER').setValue('Y');
            break;
          case 'CAREGIVER':
            this.addNewContact.get('tB_REL_CAREGIVER').setValue('Y');
            break;
          case 'AUTHORIZED':
            this.addNewContact.get('tB_REL_AUTHORIZED_REP').setValue('Y');
            break;
          case 'REPPAYEE':
            this.addNewContact.get('tB_REL_REPRESENTATIVE_PAYEE').setValue('Y');
            break;
          case 'FINANCIAL':
            this.addNewContact.get('tB_REL_POA_FINANCIAL').setValue('Y');
            break;
          case 'HEALTHCARE':
            this.addNewContact.get('tB_REL_POA_HEALTHCARE').setValue('Y');
            break;
          case 'EDUCATION':
            this.addNewContact.get('tB_REL_POA_EDUCATIONAL').setValue('Y');
            break;
          case 'POAOTHER':
            this.addNewContact.get('tB_REL_POA_OTHER').setValue('Y');
            break;
          case 'PLACEMENT':
            this.addNewContact.get('tB_REL_LG_PLACEMENT').setValue('Y');
            break;
          case 'PERSON':
            this.addNewContact.get('tB_REL_LG_PERSON').setValue('Y');
            break;
          case 'ESTATE':
            this.addNewContact.get('tB_REL_LG_ESTATE').setValue('Y');
            break;
          case 'PERESTATE':
            this.addNewContact.get('tB_REL_LG_PERSON_AND_ESTATE').setValue('Y');
            break;
          case 'SOCIAL':
            this.addNewContact.get('tB_REL_SOCIAL_SUPPORT').setValue('Y');
            break;
          case 'OTHER':
            this.addNewContact.get('tB_REL_OTHER').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    this.addNewContact.get('tB_PMT_MEDICARE').setValue(null);
    this.addNewContact.get('tB_PMT_MEDICAID').setValue(null);
    this.addNewContact.get('tB_PMT_MEDICAID_WAIVER').setValue(null);
    this.addNewContact.get('tB_PMT_COUNTY_BOARD_OF_DD').setValue(null);
    this.addNewContact.get('tB_PMT_BEHAV_HEALTH_PROG').setValue(null);
    this.addNewContact.get('tB_PMT_MEDICAID_MANAGEDCARE').setValue(null);
    this.addNewContact.get('tB_PMT_MEDICAID_MYCARE_OHIO').setValue(null);
    this.addNewContact.get('tB_PMT_DEPT_VETERAN_AFFAIR').setValue(null);
    this.addNewContact.get('tB_PMT_OTHER').setValue(null);

    let serviceSourcePayment: [] = this.addNewContact.get('tB_ServicePaymentSource').value;

    if (Array.isArray(serviceSourcePayment)) {
      serviceSourcePayment.forEach(value => {
        switch (value) {
          case 'MEDICARE':
            this.addNewContact.get('tB_PMT_MEDICARE').setValue('Y');
            break;
          case 'MEDICAID':
            this.addNewContact.get('tB_PMT_MEDICAID').setValue('Y');
            break;
          case 'MEDWAIVER':
            this.addNewContact.get('tB_PMT_MEDICAID_WAIVER').setValue('Y');
            break;
          case 'CCOUNTY':
            this.addNewContact.get('tB_PMT_COUNTY_BOARD_OF_DD').setValue('Y');
            break;
          case 'BEHHEALTH':
            this.addNewContact.get('tB_PMT_BEHAV_HEALTH_PROG').setValue('Y');
            break;
          case 'MEDMNG':
            this.addNewContact.get('tB_PMT_MEDICAID_MANAGEDCARE').setValue('Y');
            break;
          case 'MEDMYPRG':
            this.addNewContact.get('tB_PMT_MEDICAID_MYCARE_OHIO').setValue('Y');
            break;
          case 'DVETA':
            this.addNewContact.get('tB_PMT_DEPT_VETERAN_AFFAIR').setValue('Y');
            break;
          case 'OTHER':
            this.addNewContact.get('tB_PMT_OTHER').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    let addNewContactData = this.addNewContact.value;
    this.sectionBService.PostSectionB(addNewContactData).subscribe(resp => {
      this.alertsService.success("Contact Information Saved");
     // this.dialogRef.close("success");
      this.clearaddNewContact();
      this.sectionBService.LoadContacts(this.dataService.getSelectedDocument()).subscribe(response => {
        if (response.length > 19) {
          this.showMaxContact = true;
          this.addNewContact.disable();
          this.showSave_M = false;
          this.showSave = false;
        }
      }, err => {
        this.alertsService.error(err);
        });

    }, error => {
      console.error('Error Saving Contact Information', error);
      this.alertsService.error("Error Saving Contact Information");
    });


  }
  closeaddNewContact() {
    //this.dialogRef.close("close");
    this.dialogRef.close("success");
  }

  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.addNewContact.get('tB_TYPE_OTHER_DESC').clearValidators();
        this.addNewContact.get('tB_REL_OTHER_DESC').clearValidators();
        this.addNewContact.get('tB_PMT_OTHER_DESC').clearValidators();
        this.show_tB_TYPE_OTHER_DESC = false;
        this.show_tB_REL_OTHER_DESC = false;
        this.show_tB_LIVES_WITH_IND = false;
        this.show_tB_SERVICES_IND = false;
        this.show_tB_PMT_OTHER_DESC = false;
        this.addNewContact.reset({ tB_Relationship: this.relationshipContact, tB_LegalRelationship: this.legalRelationshipContact, tB_ServicePaymentSource: this.servicePaymentSource });
      }
    });

  }
  preventPaste(e) {
    e.preventDefault();
    return false;
  }
  
}
