import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SectionDropdownService, SectionDService, AlertsService } from '../../../shared';
import { DropdownOptionsType } from '../../../shared/common/DropDownOptionsType';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Medication } from '../medication-admin';
import { dialogConfig, diaMode } from './dialogOptions';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-medication-new',
    templateUrl: './medication-new.component.html',
    styleUrls: ['./medication-new.component.css']
})
export class MedicationNewComponent implements OnInit {
  strWhoAssists: string;
    constructor(private dropdownService: SectionDropdownService,
        @Inject(MAT_DIALOG_DATA) public formOptions: dialogConfig,
        public dialogRef: MatDialogRef<MedicationNewComponent>,
        private secDService: SectionDService,
        private alertService: AlertsService
    ) { }

    medicationAdminForm: FormGroup;

    disableSubmit: boolean;

    documentContext: {
        acatId: string,
        sectionId: string
    }

  ngOnInit() {
    this.strWhoAssists = 'Who Assists';
        this.initForm();
        this.loadData();
    }

    initForm() {
        //define form
        this.medicationAdminForm = new FormGroup({
            'tdM_SECTION_ID': new FormControl(null),
            'tdM_ACAT_ID': new FormControl(null, Validators.required),
            'tdM_NAME': new FormControl(null, Validators.required),
            'tdM_OBTAINED': new FormControl(null, Validators.required),
            'tdM_MAIL_ORDER': new FormControl(null, { validators: Validators.required }),
            'tdM_DOSE': new FormControl(null, Validators.required),
            'tdM_FREQUENCY': new FormControl(null, Validators.required),
            'tdM_ROUTE': new FormControl(null, Validators.required),
            'tdM_PACKAGING': new FormControl(null, Validators.required),
            'tdM_ASSISTANCE': new FormControl(null, Validators.required),
            'tdM_ASSISTANCE_NAME': new FormControl(null),
            'tdM_PRESCRIBED_BY': new FormControl(null, Validators.required)
        });

        //tdM_ASSISTANCE
        this.medicationAdminForm.get('tdM_ASSISTANCE').valueChanges.subscribe((assoptn) => {
          if (assoptn == 'I') {
            this.strWhoAssists = 'Who Assists';
                this.medicationAdminForm.get('tdM_ASSISTANCE_NAME').clearValidators();
          } else {
            this.strWhoAssists = 'Who Assists *';
                this.medicationAdminForm.get('tdM_ASSISTANCE_NAME').setValidators(Validators.required);
            }
            this.medicationAdminForm.get('tdM_ASSISTANCE_NAME').updateValueAndValidity();
        });

       

    }

    loadData() {
        
        switch (this.formOptions.modeConfig.mode) {
            case diaMode.Edit:
                this.medicationAdminForm.patchValue(this.formOptions.modeConfig.row);
                break;
            case diaMode.View:
                this.medicationAdminForm.patchValue(this.formOptions.modeConfig.row);
                Object.keys(this.medicationAdminForm.controls).forEach(key => {
                    this.medicationAdminForm.controls[key].disable();
                });
                this.disableSubmit = true;
                break;
            default:
                break;
        }
    }

   

    

    saveMedication() {
        this.medicationAdminForm.get('tdM_ACAT_ID').setValue(this.formOptions.document.alocid);
        this.medicationAdminForm.get('tdM_SECTION_ID').setValue(this.formOptions.document.sectionid);
        //console.log(this.medicationAdminForm.value);
        let formData: Medication = this.medicationAdminForm.value as Medication;
        if (this.medicationAdminForm.valid) {
            this.secDService.saveMedication(formData).subscribe(() => {
                this.alertService.success('Medication Administration Saved');
                this.alertService.clearLoader();
                this.dialogRef.close('success');
            }, error => {
                console.error('error saving medications ', error);
                this.alertService.error('Error saving Medication.');
                this.alertService.clearLoader();
            });
        } else {
            let invCon = [];
            Object.keys(this.medicationAdminForm.controls).forEach(key => {
                if (this.medicationAdminForm.controls[key].invalid) {
                    invCon.push(key);
                }
            });
            console.error('invalid form ! ', invCon);
        }

  }
  saveMultipleMedication() {
    this.medicationAdminForm.get('tdM_ACAT_ID').setValue(this.formOptions.document.alocid);
    this.medicationAdminForm.get('tdM_SECTION_ID').setValue(this.formOptions.document.sectionid);
    //console.log(this.medicationAdminForm.value);
    let formData: Medication = this.medicationAdminForm.value as Medication;
    if (this.medicationAdminForm.valid) {
      this.secDService.saveMedication(formData).subscribe(() => {
        this.alertService.success('Medication Administration Saved'); 
        this.medicationAdminForm.reset();        
        this.medicationAdminForm.get('tdM_ASSISTANCE_NAME').clearValidators();
        this.medicationAdminForm.get('tdM_ASSISTANCE_NAME').updateValueAndValidity();
        this.strWhoAssists = 'Who Assists';
        this.alertService.clearLoader();
       // this.dialogRef.close('success');
      }, error => {
        console.error('error saving medications ', error);
        this.alertService.error('Error saving Medication.');
        this.alertService.clearLoader();
      });
    } else {
      let invCon = [];
      Object.keys(this.medicationAdminForm.controls).forEach(key => {
        if (this.medicationAdminForm.controls[key].invalid) {
          invCon.push(key);
        }
      });
      console.error('invalid form ! ', invCon);
    }

  }

    clear1() {
        this.medicationAdminForm.reset();
    }
  clear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {        
        this.medicationAdminForm.reset();
        this.medicationAdminForm.get('tdM_ASSISTANCE_NAME').clearValidators();
        this.medicationAdminForm.get('tdM_ASSISTANCE_NAME').updateValueAndValidity();
        this.strWhoAssists = 'Who Assists';
        
      }
    });

  }
  Cancel_and_Load() {
    this.dialogRef.close("close");
  }
}
