import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionGService, ACOK, DropdownModel, ACOKDisorders } from '../../shared';
import { DeprecatedPercentPipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ac-ok',
  templateUrl: './ac-ok.component.html',
  styleUrls: ['./ac-ok.component.css']
})
export class AcOkComponent implements OnInit {

  acokForm: FormGroup;
  acokDisorderForm: FormGroup;
  acOk: ACOK = {} as ACOK;
  acOKDisorders: ACOKDisorders = {} as ACOKDisorders;
  isSubmitting = false;
  acokResult: DropdownModel[];
  show_Reason: boolean = false;
  displayA: boolean = false;
  constructor(private fb: FormBuilder,
    private dropdownSerice: SectionDropdownService,
    private alertService: AlertsService,
    public dataService: DataService,
    public sectionGService: SectionGService) {
    this.acokForm = this.fb.group({     
      tG_ACAT_ID: new FormControl(''),
      tG_ACOK_IND: new FormControl('', Validators.required),
      tG_ACOK_RESULT: new FormControl(''),
      tG_ACOK_COMMENT: new FormControl('')
    });
    this.acokDisorderForm = this.fb.group({
      aK_ACOK_ID: new FormControl(''),
      aK_CONTACT_ID: new FormControl(''),
      aK_PREOCCUPIED_IND: new FormControl(''),
      aK_PROBLEM_IND: new FormControl(''),
      aK_INTENDED_IND: new FormControl(''),
      aK_NEED_MORE_IND: new FormControl(''),
      aK_ALTER_FEELING_IND: new FormControl(''),
      aK_STOP_ATTEMPTED_IND: new FormControl(''),
      aK_DEPRESSION_IND: new FormControl(''),
      aK_SELFHARM_IND: new FormControl(''),
      aK_THINKING_IND: new FormControl(''),
      aK_SUICIDE_ATTEMPT_IND: new FormControl(''),
      aK_TRUST_IND: new FormControl(''),
      aK_MEDICATION_IND: new FormControl(''),
      aK_HALLUCINATION_IND: new FormControl(''),
      aK_ABUSED_IND: new FormControl(''),
      aK_TRAUMATIC_EVENT_IND: new FormControl(''),
      aK_MH_SCORE: new FormControl(''),
      aK_TRMH_SCORE: new FormControl(''),
      aK_SA_SCORE: new FormControl(''),
      aK_ACAT_ID: new FormControl('')
    });
  }

  ngOnInit() {

    if (this.dataService.getSelectedDocument() != null) {
      this.sectionGService.GetSectionGInfo(this.dataService.getSelectedDocument()).subscribe(response => {
        this.receiveACOK(response);
        this.alertService.success("ACOK Screen Loaded");
      }, err => {
        this.alertService.error(err);
        });

      this.sectionGService.GetACOKDisordersInfo(this.dataService.getSelectedDocument()).subscribe(response => {
        if (response.aK_ACOK_ID != null) {
          this.displayA = true;
          this.receiveACOKDisorders(response);
        }
        
        this.alertService.success("ACOK Screen Loaded");
      }, err => {
        this.alertService.error(err);
      });

    }

    this.loadDropdown();    
    this.onChange();
  }
  receiveACOK(data) {
    this.acokForm.patchValue(data);
 
  }
  receiveACOKDisorders(data) {
    this.acokDisorderForm.patchValue(data);
  }
  loadDropdown() {

    this.dropdownSerice.GetDropDownValues('ACOKResult').subscribe(data => {
      this.acokResult = data;
    }, error => {
      this.alertService.error(error);
      });

  }
  changeACOK() {
    if (this.acokForm.get('tG_ACOK_IND').value == 'Y') {
      this.displayA = false;
    } else {
      this.displayA = true;
    }
  }
  onChange() {

    this.acokForm.get('tG_ACOK_RESULT').valueChanges.subscribe(value => {
      if (value == 'NOANSWER') {        
        this.acokForm.get('tG_ACOK_COMMENT').setValidators([Validators.required]);
        this.acokForm.get('tG_ACOK_COMMENT').markAsTouched();
        this.show_Reason = true;
      } else {        
        this.acokForm.get('tG_ACOK_COMMENT').clearValidators(); 
        this.acokForm.get('tG_ACOK_COMMENT').setValue(null);
        this.show_Reason = false;
      }
      this.acokForm.controls["tG_ACOK_COMMENT"].updateValueAndValidity()
    });
    this.acokDisorderForm.get('aK_DEPRESSION_IND').valueChanges.subscribe(mhscore => {
      if (mhscore == 'Y') {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value)+ 1);
    } else {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) - 1);
      }      
    });

    this.acokDisorderForm.get('aK_SELFHARM_IND').valueChanges.subscribe(mhscore => {
      if (mhscore == 'Y') {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_THINKING_IND').valueChanges.subscribe(mhscore => {
      if (mhscore == 'Y') {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_SUICIDE_ATTEMPT_IND').valueChanges.subscribe(mhscore => {
      if (mhscore == 'Y') {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_TRUST_IND').valueChanges.subscribe(mhscore => {
      if (mhscore == 'Y') {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_MEDICATION_IND').valueChanges.subscribe(mhscore => {
      if (mhscore == 'Y') {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) - 1);
      }
    });


    this.acokDisorderForm.get('aK_HALLUCINATION_IND').valueChanges.subscribe(mhscore => {
      if (mhscore == 'Y') {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_MH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_MH_SCORE').value) - 1);
      }     
    });

    this.acokDisorderForm.get('aK_ABUSED_IND').valueChanges.subscribe(trmhscore => {
      if (trmhscore == 'Y') {
        this.acokDisorderForm.get('aK_TRMH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_TRMH_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_TRMH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_TRMH_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_TRAUMATIC_EVENT_IND').valueChanges.subscribe(trmhscore => {
      if (trmhscore == 'Y') {
        this.acokDisorderForm.get('aK_TRMH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_TRMH_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_TRMH_SCORE').setValue(Number(this.acokDisorderForm.get('aK_TRMH_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_PREOCCUPIED_IND').valueChanges.subscribe(sascore => {
      if (sascore == 'Y') {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) - 1);
      }
    });


    this.acokDisorderForm.get('aK_PROBLEM_IND').valueChanges.subscribe(sascore => {
      if (sascore == 'Y') {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_INTENDED_IND').valueChanges.subscribe(sascore => {
      if (sascore == 'Y') {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_NEED_MORE_IND').valueChanges.subscribe(sascore => {
      if (sascore == 'Y') {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_ALTER_FEELING_IND').valueChanges.subscribe(sascore => {
      if (sascore == 'Y') {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) - 1);
      }
    });

    this.acokDisorderForm.get('aK_STOP_ATTEMPTED_IND').valueChanges.subscribe(sascore => {
      if (sascore == 'Y') {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) + 1);
      } else {
        this.acokDisorderForm.get('aK_SA_SCORE').setValue(Number(this.acokDisorderForm.get('aK_SA_SCORE').value) - 1);
      }
    });

  }  

  updateACOK(values: Object) {
    Object.assign(this.acOk, values);
    this.acOk.tG_ACAT_ID = this.dataService.getSelectedDocument();
    this.acOk.tG_ACOK_IND = 'Y';
  }

  updatedACOKDisorders(values: Object) {
    Object.assign(this.acOKDisorders, values);
    this.acOKDisorders.aK_ACAT_ID = this.dataService.getSelectedDocument();
  }

  ClearForm(e) {
    this.onClear();
  }
  submitForm() {
    this.isSubmitting = true;
    this.updateACOK(this.acokForm.value);
    console.log(this.acOk);
    
    this.sectionGService.postACOK(this.acOk).subscribe(response => {
      this.alertService.success('ACOK Sceen Saved');
    }, err => {
        console.error('Error Saving ACOK Screen', err);
        this.alertService.error("Error Saving ACOK Screen");
      });  

    if (this.displayA) {
      this.updatedACOKDisorders(this.acokDisorderForm.value);
      console.log(this.acOKDisorders);
      this.sectionGService.postACOKDisorders(this.acOKDisorders).subscribe(response => {
        this.alertService.success('ACOK Sceen Saved');
      }, err => {
        this.alertService.error(JSON.stringify(err));
      });
    }

    this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.displayA = false;
        this.show_Reason = false;
        this.acokForm.reset();
        this.acokDisorderForm.reset();
      }
    });

  }

}
