import { AbstractControl } from "@angular/forms";
import * as moment from 'moment';
import { DatePipe } from '@angular/common';


export function validateFutureDate(cntrl: AbstractControl): null | { [key: string]: any } {
    let dp = new DatePipe('en-us');
    let selected_date = new Date(dp.transform(cntrl.value, 'MM/dd/yyyy'));
    let current_date = new Date(dp.transform(new Date(), 'MM/dd/yyyy'));
    if (selected_date > current_date) {
        return {
            'futureDate': { value: selected_date, msg: 'future date not allowed ' }
        };
    }
    return null;
}

export function validatePastDate(cntrl: AbstractControl): null | { [key: string]: any } {
    let dp = new DatePipe('en-us');
    let selected_date = new Date(dp.transform(cntrl.value, 'MM/dd/yyyy'));
    let current_date = new Date(dp.transform(new Date(), 'MM/dd/yyyy'));
    if (selected_date < current_date) {
        return {
            'pastDate': { value: selected_date, msg: 'past date not allowed ' }
        };
    }
    return null;
}

//export function validateAge(cntrl: AbstractControl): { [key: string]: any } | null {
//    if (cntrl.value == null || cntrl.value == '') return null;
//    let selected_age = moment().diff(cntrl.value, 'years');
//    if (selected_age <= 30 || selected_age >= 120) {
//        return {
//            invalidAge: {
//                value: selected_age,
//                error: 'Age cannot be greater than 120 or less than or equal to zerror '
//            }
//        };
//    } else {
//        return null;
//    }
//}
export function validateAge(cntrl: AbstractControl): { [key: string]: any } | null {
  if (cntrl.value == null || cntrl.value == '') return null;
  //To avoid waring 
  //let selected_age = moment().diff(cntrl.value, 'years');
  //To avoid waring 
  let diff: number = Math.abs(Date.now() - cntrl.value);
  let selected_age = Number(new Date(diff).getUTCFullYear() - 1970);
  if (selected_age < 21 || selected_age > 120) {
    return {
      invalidAge: {
        value: selected_age,
        error: 'Age cannot be greater than 120 or less than or equal to zerro'
      }
    };
  } else {
    return null;
  }
}
