import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionGService, DevelopmentalDisability, DropdownModel } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-developmental-disability',
  templateUrl: './developmental-disability.component.html',
  styleUrls: ['./developmental-disability.component.css']
})
export class DevelopmentalDisabilityComponent implements OnInit {

  developmentalDisabilityForm: FormGroup;
  developmentalDisability: DevelopmentalDisability = {} as DevelopmentalDisability;
  isSubmitting = false;
  show_Questions = false;
  confirmDiagnosis: DropdownModel[];
  constructor(private fb: FormBuilder,
    private dropdownSerice: SectionDropdownService,
    private alertService: AlertsService,
    public dataService: DataService,
    public sectionGService: SectionGService) {
    this.developmentalDisabilityForm = this.fb.group({   
      tG_ACAT_ID: new FormControl(''),
      tG_DD_IND: new FormControl('', Validators.required),
      tG_ID_IND: new FormControl('', Validators.required),
      tG_CONFIRMED_IND: new FormControl(''),
      tG_CONFIRMED_YES_IND: new FormControl({ value: '', disabled: true }),
      tG_CONFIRMED_THERAPY_IND: new FormControl(''),
      tG_CONFIRMED_PSYCH_IND: new FormControl(''),
      tG_CONFIRMED_OTHER_IND: new FormControl(''),
      tG_LIFELONG_IND: new FormControl('')
    });
  }

  ngOnInit() {

    if (this.dataService.getSelectedDocument() != null) {
      this.sectionGService.GetSectionGInfo(this.dataService.getSelectedDocument()).subscribe(response => {
        if (response.tG_DD_IND == 'Y' || response.tG_ID_IND == 'Y') {
          this.show_Questions = false;
        } else {
          this.show_Questions = true;
        }
        this.receiveDevelopmentalDisability(response);
        this.alertService.success("Developmental Disability Screen Loaded");
      }, err => {
        this.alertService.error(err);
      });
    }

    this.loadDropdown();
    this.onChange();
  }
  receiveDevelopmentalDisability(data) {
    this.developmentalDisabilityForm.patchValue(data);
    this.setData(data);
  }
  setData(data) {
    let ddSupportDocuemnt: string[] = [];
    if (data['tG_CONFIRMED_THERAPY_IND'] == 'Y') {
      ddSupportDocuemnt.push('THERAPY');
    }
    if (data['tG_CONFIRMED_PSYCH_IND'] == 'Y') {
      ddSupportDocuemnt.push('PSYCH');
    }
    if (data['tG_CONFIRMED_OTHER_IND'] == 'Y') {
      ddSupportDocuemnt.push('OTHER');
    }    
    this.developmentalDisabilityForm.get('tG_CONFIRMED_YES_IND').setValue(ddSupportDocuemnt);
  }
  loadDropdown() {
    this.dropdownSerice.GetDropDownValues('DD_SUPP_DOC').subscribe(data => {
      this.confirmDiagnosis = data;
      console.log(data);
    }, error => {
      this.alertService.error(error);
    });
  }

  onChange() {
    this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').valueChanges.subscribe(ddval => {     
      if (ddval == 'Y') {
        this.developmentalDisabilityForm.get('tG_CONFIRMED_YES_IND').enable();
        this.developmentalDisabilityForm.get('tG_CONFIRMED_YES_IND').setValidators([Validators.required]);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_YES_IND').markAsTouched();
      } else {
        this.developmentalDisabilityForm.get('tG_CONFIRMED_YES_IND').clearValidators();
        this.developmentalDisabilityForm.get('tG_CONFIRMED_YES_IND').setValue('');
        this.developmentalDisabilityForm.get('tG_CONFIRMED_YES_IND').disable();      
      }
      this.developmentalDisabilityForm.get('tG_CONFIRMED_YES_IND').updateValueAndValidity();
    });

    this.developmentalDisabilityForm.get('tG_DD_IND').valueChanges.subscribe(ddval => {
      if (this.developmentalDisabilityForm.get('tG_DD_IND').value == 'Y' || this.developmentalDisabilityForm.get('tG_ID_IND').value == 'Y') {
        this.show_Questions = false;
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').setValidators([Validators.required]);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').setValidators([Validators.required]);
        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').updateValueAndValidity();
      } else {
        this.show_Questions = true;
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').clearValidators();
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_CONFIRMED_THERAPY_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_THERAPY_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_CONFIRMED_PSYCH_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_PSYCH_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_CONFIRMED_OTHER_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_OTHER_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').clearValidators();
        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').updateValueAndValidity();
      }
     
    });

    this.developmentalDisabilityForm.get('tG_ID_IND').valueChanges.subscribe(ddval => {
      if (this.developmentalDisabilityForm.get('tG_ID_IND').value == 'Y' || this.developmentalDisabilityForm.get('tG_DD_IND').value == 'Y') {
        this.show_Questions = false;
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').setValidators([Validators.required]);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').setValidators([Validators.required]);
        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').updateValueAndValidity();
      } else {
        this.show_Questions = true;
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').clearValidators();
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_CONFIRMED_THERAPY_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_THERAPY_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_CONFIRMED_PSYCH_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_PSYCH_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_CONFIRMED_OTHER_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_CONFIRMED_OTHER_IND').updateValueAndValidity();

        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').clearValidators();
        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').setValue(null);
        this.developmentalDisabilityForm.get('tG_LIFELONG_IND').updateValueAndValidity();
      }
    });
  }

  ClearForm(e) {
    this.onClear();
  }

  submitForm() {
   
    this.isSubmitting = true;    
    this.developmentalDisabilityForm.get('tG_CONFIRMED_THERAPY_IND').setValue(null);
    this.developmentalDisabilityForm.get('tG_CONFIRMED_PSYCH_IND').setValue(null);
    this.developmentalDisabilityForm.get('tG_CONFIRMED_OTHER_IND').setValue(null);

    let relationshipList: [] = this.developmentalDisabilityForm.get('tG_CONFIRMED_YES_IND').value;

    if (Array.isArray(relationshipList)) {
      relationshipList.forEach(value => {
        switch (value) {
          case 'THERAPY':
            this.developmentalDisabilityForm.get('tG_CONFIRMED_THERAPY_IND').setValue('Y');
            break;
          case 'PSYCH':
            this.developmentalDisabilityForm.get('tG_CONFIRMED_PSYCH_IND').setValue('Y');
            break;
          case 'OTHER':
            this.developmentalDisabilityForm.get('tG_CONFIRMED_OTHER_IND').setValue('Y');
            break;          
          default:
            break;
        }
      });
    }

    this.developmentalDisabilityForm.get('tG_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    console.log(this.developmentalDisabilityForm);
    this.sectionGService.postDevelopmentalDisability(this.developmentalDisabilityForm.value).subscribe(response => {
      this.alertService.success('Developmental Disability Screen Saved');
    }, err => {
        console.error('Error Saving Developmental Disability Screen', err);
        this.alertService.error("Error Saving Developmental Disability Screen");
        this.alertService.error("Error Saving Developmental Disability Screen");
      });

    this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.developmentalDisabilityForm.reset();
      }
    });

  }
}
