

export class BackgroundInfo {
    tD_SECTION_ID: number;
    tD_ACAT_ID: number;
    tD_COMMENTS: string;
}

export class BackgroundInfoFields {
    public static tD_SECTION_ID = 'tD_SECTION_ID';
    public static tD_ACAT_ID = 'tD_ACAT_ID';
    public static tD_COMMENTS = 'tD_COMMENTS'; 

}
