import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchResultModel, SearchModel, SearchParams } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor(private apiService: ApiService) { }

    getSearchLoad(pdfExport): Observable<SearchResultModel[]> {
        return this.apiService.get('search/' + pdfExport).pipe(map(data => {
            return data;
        }));
    }

    search(params: SearchParams): Observable<SearchResultModel[]> {
        return this.apiService.post('search', params);
    }

    deleteACATDoc(ACATID): Observable<any> {
        return this.apiService.delete(`search/DeleteACAT/${ACATID}`);
    }


}
