import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionJComponent } from './section-j.component';
import { AdaptiveAssistiveEquipComponent } from './adaptive-assistive-equip/adaptive-assistive-equip.component';
import { SectionJUnsavedDataGuardGuard } from './section-j-unsaved-data-guard.guard';
const routes: Routes = [
  {
    path: '',
    component: SectionJComponent,
    children: [
      {
        path: 'adaptive-assistive-equip',
            component: AdaptiveAssistiveEquipComponent,
            canDeactivate: [SectionJUnsavedDataGuardGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionJRoutingModule { }
