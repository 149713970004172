import { Injectable } from '@angular/core';
import { LoaderService } from './loader.service';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private loaderService: LoaderService) { }

  success(message) {
    Swal.fire({
      background: 'MediumSeaGreen',
      position: 'center-end',
      type: 'success',
      title: "<span style='color:white'></span>",
      showConfirmButton: false,
      toast: true,
      timer: 3500
    });
    this.loaderService.hideLoader();
  }
  error(message) {
    Swal.fire({
      //  background: 'IndianRed',
      position: 'center',
      type: 'error',
      title: 'Error Occurred',
      text: message,
      allowOutsideClick: false
    });
    this.loaderService.hideLoader();
  }

 

  clearLoader() {
    this.loaderService.hideLoader();
  }
  showLoader() {
    this.loaderService.showLoader();
  }
}
