import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, SectionDropdownService, DataService, SectionHService, SchoolWork, getTextForDropdownValues } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-school-work',
  templateUrl: './school-work.component.html',
  styleUrls: ['./school-work.component.css']
})
export class SchoolWorkComponent implements OnInit {
  dropDownSelectText = getTextForDropdownValues;
  formStatus: boolean;
  schoolworkForm: FormGroup;
  schoolWork: SchoolWork = {} as SchoolWork;
  employmentStatus: any[];
  schoolTraining: any[];
  employemntInterest: any[];
  workAssistance: any[];
  volunteerStatus: any[];
  volunteerInterest: any[];
  isSubmitting = false;

  constructor(private fb: FormBuilder,
    private alertService: AlertsService,
    private dropdownSerice: SectionDropdownService,
    public dataService: DataService,
    public sectionHService: SectionHService) {
    this.schoolworkForm = this.fb.group({
      tH_SECTION_ID: new FormControl(''),
      tH_ACAT_ID: new FormControl(''),
      tH_SCHOOL_WORK_IND: new FormControl(''),
      tH_EDUCATION_LEVEL: new FormControl(''),
      tH_TRAINING_EDUCATION: new FormControl(''),
      tH_SCHOOL_PROGRAM_NAME: new FormControl({ value: '', disabled: true }),
      tH_EMPLOYMENT_STATUS: new FormControl(''),
      tH_EMPLOYER_NAME: new FormControl({ value: '', disabled: true }),
      tH_EMPLOYMENT_INTEREST: new FormControl(''),
      tH_NEED_FOR_ASSISTANCE: new FormControl(''),
      tH_VOLUNTEER_STATUS: new FormControl(''),
      tH_VOLUNTEER_INTEREST: new FormControl(''),
      tH_OOD_IND: new FormControl(''),
      tH_COMMENTS: new FormControl('')
    });

  }

  ngOnInit() {
  //  this.alertService.showLoader();  
    this.sectionHService.getSchoolWork(this.dataService.getSelectedDocument()).subscribe(response => {
     // console.log(response);
      this.schoolworkForm.patchValue(response);
      this.alertService.success('School and Work Loaded');
    }, err => {
        this.alertService.error(err);
    });
   // this.alertService.clearLoader();
    this.loadDropdown();
    this.onChange();
  }
  loadDropdown() {
    this.dropdownSerice.GetDropDownValues('EMPLOYMENT_STATUS').subscribe(data => {
      this.employmentStatus = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('SCHOOL_TRAINING').subscribe(data => {
      this.schoolTraining = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('EMPLOYMENT_INTEREST').subscribe(data => {
      this.employemntInterest = data;
    }, error => {
      this.alertService.error(error);
    });
    this.dropdownSerice.GetDropDownValues('WORK_ASSISTANCE').subscribe(data => {
      this.workAssistance = data;
    }, error => {
      this.alertService.error(error);
    });
    this.dropdownSerice.GetDropDownValues('VOLUNTEER_STATUS').subscribe(data => {
      this.volunteerStatus = data;
    }, error => {
      this.alertService.error(error);
    });
    this.dropdownSerice.GetDropDownValues('VOLUNTEER_INTEREST').subscribe(data => {
      this.volunteerInterest = data;
    }, error => {
      this.alertService.error(error);
    });
  }

  onChange() {
    this.schoolworkForm.get('tH_EMPLOYMENT_STATUS').valueChanges.subscribe(empStatus => {     
      if (empStatus == 'PARTTIME' || empStatus == 'FULLTIME') {
        this.schoolworkForm.get('tH_EMPLOYER_NAME').enable();
        //this.schoolworkForm.get('tH_EMPLOYER_NAME').setValidators(Validators.required);
        this.schoolworkForm.get('tH_EMPLOYER_NAME').markAsTouched();
      } else {
        this.schoolworkForm.get('tH_EMPLOYER_NAME').setValue('');
        this.schoolworkForm.get('tH_EMPLOYER_NAME').disable();
        this.schoolworkForm.get('tH_EMPLOYER_NAME').clearValidators();
      }
      this.schoolworkForm.get('tH_EMPLOYER_NAME').updateValueAndValidity();
    });

    this.schoolworkForm.get('tH_TRAINING_EDUCATION').valueChanges.subscribe(trainEdu => {     
      if (trainEdu == 'CURRENTLY') {
        this.schoolworkForm.get('tH_SCHOOL_PROGRAM_NAME').enable();
        //this.schoolworkForm.get('tH_SCHOOL_PROGRAM_NAME').setValidators(Validators.required);
        this.schoolworkForm.get('tH_SCHOOL_PROGRAM_NAME').markAsTouched();
      } else {
        this.schoolworkForm.get('tH_SCHOOL_PROGRAM_NAME').setValue('');
        this.schoolworkForm.get('tH_SCHOOL_PROGRAM_NAME').disable();
        this.schoolworkForm.get('tH_SCHOOL_PROGRAM_NAME').clearValidators();
      }
      this.schoolworkForm.get('tH_SCHOOL_PROGRAM_NAME').updateValueAndValidity();
    });
  }

  submitForm() {
    this.isSubmitting = true;

    this.schoolworkForm.get('tH_ACAT_ID').setValue('');

    for (let cnt in this.schoolworkForm.controls) {
      if (this.schoolworkForm.controls[cnt].value == null || this.schoolworkForm.controls[cnt].value == '') {
        this.formStatus = false;
      }
      else {
        this.formStatus = true;
        break;
      }
    }

    if (this.formStatus == false) {
      // this.alertsService.error("Please add atleast one insurance information");
      this.warningAlert();
      this.isSubmitting = false;
      return;
    }

    this.updateSchoolWork(this.schoolworkForm.value);
    console.log(this.schoolWork);
    this.sectionHService.postSchoolWork(this.schoolWork).subscribe(response => {
      this.alertService.success('School and Work Saved');
    }, err => {
      this.alertService.error(JSON.stringify(err));
    });

    this.isSubmitting = false;
  }

  ClearForm(e) {
    this.onClear();
  }

  updateSchoolWork(values: Object) {
    Object.assign(this.schoolWork, values);
    this.schoolWork.tH_ACAT_ID = this.dataService.getSelectedDocument();
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {  
        this.schoolworkForm.reset();
      }
    });

  }
  warningAlert() {
    Swal.fire(
      '',
      'Please add atleast one school and work information',
      'warning'
    )
  }
}
