import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { BcatScreen, Communication } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SectionEService {

  constructor(private apiService: ApiService) { }

  PostCommunication(communication): Observable<any>{
    return this.apiService.post('sectionE/PostCommunication', communication);
 }
  PostBCat(bCat): Observable<any> {
    return this.apiService.post('sectionE/PostBCat', bCat);
  }
  GetSectionEInfo(alocID): Observable<any> {
    return this.apiService.get(`sectionE/GetSectionEInfo/${alocID}`);
  }

}
