import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadService } from '../../../../services/file-upload.service';
import { forkJoin } from 'rxjs';
import { MatTableDataSource } from '@angular/material';
import { AlertsService } from '../../../../services/alerts.service';
import { DataService } from '../../../../services/data.service';
import { isDefined } from '@angular/compiler/src/util';
import { FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-documents-dialog',
    templateUrl: './documents-dialog.component.html',
    styleUrls: ['./documents-dialog.component.css']
})
export class DocumentsDialogComponent implements OnInit {

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


    progress;
    @ViewChild('file', { static: false })
    file;
    public files: Set<File> = new Set();
    @Input()
    type: string;
    @Input()
    section: string;
    @Input()
    alocid: string;

    isUploadComplete = true;

    documentTypesDS: any;
    newDocType = '';
    isNewDocSelected = true;
    processing = false;

    filesDataSources: MatTableDataSource<documentUpload>;
    displayedColumns: string[] = ['fB_ID', 'fB_FILENAME', 'fB_TYPE', 'Actions'];
    sanitizer: any;
    isRefreshing = false;

    constructor(
        public dialogRef: MatDialogRef<DocumentsDialogComponent>,
        private fileUploadService: FileUploadService,
        private alertService: AlertsService,
        private dataService: DataService
    ) { }

    ngOnInit() {
        //load document types
        this.fileUploadService.getDocumentTypes().subscribe(data => {
            this.alertService.clearLoader();
            this.documentTypesDS = data;
            if (Array.isArray(this.documentTypesDS) && this.documentTypesDS.length > 0) {
                //this.newDocType = this.documentTypesDS[0].fB_TYPE;
            }
            //console.log(data);
        });

        this.loadAllUploadedDocuments();



    }

    loadAllUploadedDocuments() {
        //get all ACAT files
        //console.log('selected aloc id ', this.dataService.getSelectedDocument());
        this.isRefreshing = true;
        this.fileUploadService.getACATFiles(this.dataService.getSelectedDocument()).subscribe(data => {
            //console.log(' all docs ', data);
            this.filesDataSources = new MatTableDataSource<documentUpload>(data);
            this.filesDataSources.sort = this.sort;
            this.filesDataSources.paginator = this.paginator;
            this.alertService.clearLoader();
            //console.log('ACAT files', data);
            this.isRefreshing = false;
        }, error => {
            this.alertService.error('Error loading uploaded documents');
            console.error('Error loading uploaded documents ', error);
            this.isRefreshing = false;
        });
    }

    onNewTypeChange() {
        if (isDefined(this.newDocType) && this.newDocType != '') {
            this.isNewDocSelected = true;
        } else {
            this.isNewDocSelected = false;
        }
    }

    addFiles() {
        if (!isDefined(this.newDocType) || this.newDocType === '') {
            this.isNewDocSelected = false;
            return;
        }
        this.isNewDocSelected = true;
        this.file.nativeElement.click();
    }

    onFilesAdded() {
        this.processing = true;
        this.files.clear();
        const files: { [key: string]: File } = this.file.nativeElement.files;
        for (let key in files) {
            if (!isNaN(parseInt(key))) {
                this.files.add(files[key]);
            }
        }
        this.file.nativeElement.value = '';
        this.isUploadComplete = false;
        //this.progress = this.fileUploadService.uploadFile(this.alocid, this.section, this.type, this.files);

        this.progress = this.fileUploadService.uploadNewACATDocument({
            ACATid: this.dataService.getSelectedDocument(),
            type: this.newDocType,
            files: this.files
        });
        let allProgressObservables = [];
        for (let key in this.progress) {
            allProgressObservables.push(this.progress[key].progress);
        }
        //complete
        forkJoin(allProgressObservables).subscribe(end => {
            //console.log('fork called');
            this.alertService.clearLoader();
            this.isUploadComplete = true;
            this.files.clear();
            this.loadAllUploadedDocuments();
            this.processing = false;
        });

        //console.log(this.files);
    }

    deleteDocument(doc) {
        //console.log('doc for delete', doc);
        this.processing = true;
        this.fileUploadService.deleteDocument(doc.fB_ID).subscribe(resp => {
            this.alertService.clearLoader();
            this.loadAllUploadedDocuments();
        }, error => {
            this.alertService.error('Error deleteing file');
            console.log(error);
        }, () => {
            this.processing = false;
        });
    }

    downloadDocument(doc) {
        this.processing = true;
        let url = `/api/FileUpload/DownloadDocument/${doc.fB_ID}`;
        window.open(url, "_blank");
        this.processing = false;
        //this.fileUploadService.downloadFile(doc.fB_ID).subscribe(resp => {
        //  this.alertService.clearLoader();
        //  //console.log('download ', resp);
        //  //var newBlob = new Blob([resp.fB_FILE_DATA], { type: resp.fB_MIME_TYPE });
        //  saveAs.saveAs(resp);

        //  //var downloadURL = window.URL.createObjectURL(resp);
        //  //var link = document.createElement('a');
        //  //link.href = downloadURL;
        //  //link.download = doc.fB_FILENAME;
        //  //link.click();

        //}, error => {
        //  this.alertService.error('Error downloading file');
        //  console.log(error);
        //  }, () => {
        //    this.processing = false;
        //  });
    }

    saveDocument(doc) {
        //console.log('documentTypesDS ', this.filesDataSources);
        //console.log('selected doc ', doc);
        this.processing = true;
        let result = this.filesDataSources.data.find(value => value.fB_ID == doc.fB_ID);
        //console.log(result);
        if (result != null) {
            this.fileUploadService.updateDocumentType({ fb_id_in: result.fB_ID, fb_parent_id_in: result.fB_PARENT_ID, fb_type_in: result.fB_TYPE }).subscribe(resp => {
                //console.log('updates status ', resp);
                this.alertService.clearLoader();
                this.loadAllUploadedDocuments();
            }, error => {
                this.alertService.error('Error updating document type');
                console.error('Error updating document type ', error);
            }, () => {
                this.processing = false;
            })
        }
    }

    rowUpdated(event, row) {
        //console.log('event ', event);
        //console.log('row ', row);
        //console.log('this.filesDataSources ', this.filesDataSources);
        this.processing = true;
        for (let i = 0; i < this.filesDataSources.data.length; i++) {
            if (this.filesDataSources.data[i].fB_ID == row.fB_ID) {
                this.filesDataSources.data[i].fB_TYPE = event.value;
                break;
            }
        }
        this.processing = false;
        //console.log('this.filesDataSources after change ', this.filesDataSources);

    }

    applyFilter(filterValue: string) {
        this.filesDataSources.filter = filterValue.trim().toLowerCase();

        if (this.filesDataSources.paginator) {
            this.filesDataSources.paginator.firstPage();
        }
    }

    closeDialog() {
        //console.log('close called');
        if (this.isUploadComplete) {
            this.dialogRef.close();
        }
    }

}

export interface documentUpload {
    fB_DESCRIPTION: string;
    fB_FILENAME: string;
    fB_FILE_SIZE: number;
    fB_ID: number;
    fB_PARENT_ID: number;
    fB_STATUS: string;
    fB_TYPE: string;
    fb_parent_id_in: number;
    Actions: string;
}

