
export interface SectionFModel {
    aC_SECTION_ID: number;
    aC_ALOC_ID: number;
    rn: number;
    adL_NAME: string;
    dV_VALUE: string;
    dV_EXPANDED_VALUE: string;
    columN_NAME: string;
    columN_VALUE: string;
    adL_1: string;
    adL_VALUE: string;
    columN_NAME_SELFRPT: string;
    columN_VALUE_SELFRPT: string;
    columN_NAME_OBSRPT: string;
    columN_VALUE_OBSRPT: string;
    aA_LAST_NAME: string;
    aA_FIRST_NAME: string;
    na: boolean;
    adL_TEXT: string;
}

export interface SectionFResponse {
    adl_type: string,
    adl_title: string,
    adl_rows: {
        adl_title: string,
        adl_val: string,
        adl_optns: ADLOptions,
        col_name: string,
        col_val: string,
        rpt_col: string,
        rpt_col_val: string,
        acat_id: number,
        sec_id: number
    }[]
};

export interface ADLOptions {
    adl: string,
    options: {
        label: string,
        value: string;
    }[]
}

export class SectionFFields {
    public static adl_title = 'adl_title';
    public static adl_val = 'adl_val';
    public static na = 'na';
    public static col_name = 'col_name';
    public static col_val = 'col_val';
    public static rpt_col = 'rpt_col';
    public static rpt_col_val = 'rpt_col_val';
    public static acat_id = 'acat_id';
    public static sec_id = 'sec_id';
}

export interface SectionFFormModel {
    adl_title: string;
    adl_val: string;
    na: boolean;
    col_name: string;
    col_val: string;
    rpt_col: string;
    rpt_col_val: string;
    acat_id: string;
    sec_id: string;
    updated: boolean;
}


