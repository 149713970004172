import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { distinctUntilChanged, map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { JwtService } from './jwt.service';
import { LoaderService } from './loader.service';
import { UserService } from './user.service';
import * as moment from 'moment/moment.js';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public authState = this.authSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private jwtService: JwtService, private userService: UserService, private loaderService: LoaderService) {
  }

  getAuth(): Observable<any> {   
    return this.authSubject.asObservable();
  }


  showAuthRefresh() {
    if (sessionStorage.getItem('ACAT')) {
      //console.log(sessionStorage.getItem('ACAT'));
      //console.log("Is token expired 19 mins ? : "  + (this.jwtService.isTokenExpired(sessionStorage.getItem('ACAT'), 1140)));
    
      //2350
      if (this.jwtService.isTokenExpired(sessionStorage.getItem('ACAT'), 0)) {
        this.hideLogoutAuthRefresh();
      } else if (this.jwtService.isTokenExpired(sessionStorage.getItem('ACAT'), 1140)) {
        this.userService.checkLastTransactionTime(this.jwtService.decodeToken(sessionStorage.getItem('ACAT')).jti)
          .subscribe(tranTime => {
            const now = new Date();
            //console.log("Last Transaction Time : " + new Date(tranTime.sE_LAST_TRANSACTION_TIME));
           // console.log("-15 mins from now:" + new Date(now.setMinutes(now.getMinutes() - 15)));
            //console.log("Last Transaction time is greater then last -15 mins:" + (new Date(tranTime.sE_LAST_TRANSACTION_TIME) < new Date(now.setMinutes(now.getMinutes() - 15))));
           // console.log("CompareTime: " + new Date(now.setMinutes(now.getMinutes() - 15)));
           // console.log("Last transaction time is less than are equal to current time -15 mins? : " + (new Date(tranTime.sE_LAST_TRANSACTION_TIME) <= new Date(now.setMinutes(now.getMinutes() - 15))));
                if (new Date(tranTime.sE_LAST_TRANSACTION_TIME) <= new Date(now.setMinutes(now.getMinutes() - 15))) {
                this.loaderService.hideLoader();
                this.authSubject.next(true);
                } else {
                this.hideAuthRefresh();
                this.userService.refreshUser(
                  this.jwtService.decodeToken(
                    sessionStorage.getItem('ACAT')).jti).subscribe(res => { 
                      this.loaderService.hideLoader();
                      this.hideAuthRefresh();
                    });
                }
              });
      }
    }
  }


  hideAuthRefresh() {
    this.authSubject.next(false);
  }

  hideLogoutAuthRefresh() {
    this.authSubject.next(false);
    this.userService.purgeAuth();
  }

}

 //var actTime = moment(tranTime.sE_LAST_TRANSACTION_TIME, "MM-DD-YYYY hh:mm:ss");
                //var expTime = moment().subtract(4, 'minutes');
                //console.log(actTime);
                //console.log("TransactionTime: " + tranTime.sE_LAST_TRANSACTION_TIME);
                //console.log("SessionId: " + tranTime.Session);
                //console.log("TransactionTime is Greater than Exp Time :" + actTime + ":-" + actTime.isSameOrBefore(expTime));
                //console.log("ExpTime is Greater than Transaction Time :" + expTime + ":-" + expTime.isSameOrBefore(actTime));
                //console.log("TransactionTime: " + tranTime.sE_LAST_TRANSACTION_TIME);
                //console.log("TransactionTime: " + new Date(tranTime.sE_LAST_TRANSACTION_TIME).toLocaleString());
