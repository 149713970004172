import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertsService, DataService, SectionDService, SectionDropdownService, DateHelper } from '../../shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubstanceUseFields } from './SubstanceUseFields';
import { SubstanceUse } from './SubstanceUse';
import { DropdownOptionsType } from '../../shared/common/DropDownOptionsType';
import { validateFutureDate } from '../../shared/validators/AlocDateValidators';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-substance-use',
    templateUrl: './substance-use.component.html',
    styleUrls: ['./substance-use.component.css']
})
export class SubstanceUseComponent implements OnInit {

    constructor(private dropdownService: SectionDropdownService,
        private sectiondService: SectionDService,
        private cntxInfoService: DataService,
        private alerts: AlertsService) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    substanceUseForm: FormGroup;
    screenName = "Substance Use History";
    historyOptions: DropdownOptionsType[];

    @ViewChild('tD_ALCOHOL_AMT', { static: true })
    tD_ALCOHOL_AMT: ElementRef;
    @ViewChild('tD_CAFFEINE_AMT', { static: true })
    tD_CAFFEINE_AMT: ElementRef;
    @ViewChild('tD_NICOTINE_AMT', { static: true })
    tD_NICOTINE_AMT: ElementRef;
    @ViewChild('tD_OTHER_AMT', { static: true })
    tD_OTHER_AMT: ElementRef;

    ALCOHOL_NA_TOGGLE: toggleType;
    CAFFEINE_NA_TOGGLE: toggleType;
    NICOTINE_NA_TOGGLE: toggleType;
    OTHER_NA_TOGGLE: toggleType;

    ngOnInit() {
        //set cntx document
        this.documentReferance = {
            alocid: this.cntxInfoService.getSelectedDocument(),
            sectionid: null,
            name: this.cntxInfoService.getSelectedDocumentName()
        };

        //intit form
        this.initForm();

        //load data
        this.loadData();
    }

    private initForm() {
        //initialize form controls
        let formContrls = {};
        for (let fld in SubstanceUseFields) {
            formContrls[fld] = new FormControl();
        }
        this.substanceUseForm = new FormGroup(formContrls);
        this.substanceUseForm.get(SubstanceUseFields.tD_ACAT_ID).setValidators([Validators.required]);
        this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_USE).setValidators([Validators.required]);
        this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_USE).setValidators([Validators.required]);
        this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_USE).setValidators([Validators.required]);
        this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_USE).setValidators([Validators.required]);
        this.substanceUseForm.get(SubstanceUseFields.tD_RECOVERY_IND).setValidators([Validators.required]);

        //load dropdown options
        this.dropdownService.GetDropDownValues('SUBST_USE').subscribe((hisOptn: DropdownOptionsType[]) => {
            this.historyOptions = hisOptn;
            this.alerts.clearLoader();
        }, error => {
            console.error('error loading history options', error);
            this.alerts.clearLoader();
        });

        //dynamic
        //ALCOHOL_USE
        this.substanceUseForm.get(SubstanceUseFields.ALCOHOL_NA).valueChanges.subscribe(value => {
            if (this.ALCOHOL_NA_TOGGLE == toggleType.Subst) {
                this.ALCOHOL_NA_TOGGLE = toggleType.None;
            } else {
                if (value) {
                    this.ALCOHOL_NA_TOGGLE = toggleType.NA;
                    this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_USE).setValue('NA');
                } else {
                    this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_USE).setValue(null);
                }
            }
        });

        this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_USE).valueChanges.subscribe(val => {
            if (val == 'NA' || val == null) {

                if (val == 'NA') {
                    if (this.ALCOHOL_NA_TOGGLE == toggleType.NA) {
                        this.ALCOHOL_NA_TOGGLE = toggleType.None;
                    } else {
                        this.ALCOHOL_NA_TOGGLE = toggleType.Subst;
                        this.substanceUseForm.get(SubstanceUseFields.ALCOHOL_NA).setValue(true);
                        
                    }
                }
                

                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_AMT).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_AMT).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_AMT).disable();

                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_FREQ).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_FREQ).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_FREQ).disable();

                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_LAST_DATE).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_LAST_DATE).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_LAST_DATE).disable();
            } else {
                this.ALCOHOL_NA_TOGGLE = toggleType.Subst;
                this.substanceUseForm.get(SubstanceUseFields.ALCOHOL_NA).setValue(false);
                

                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_AMT).setValidators([Validators.required]);
                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_AMT).enable();
                this.tD_ALCOHOL_AMT.nativeElement.focus();

                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_FREQ).setValidators([Validators.required]);
                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_FREQ).enable();

                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_LAST_DATE).setValidators([Validators.required, validateFutureDate]);
                this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_LAST_DATE).enable();
            }
        });

        //CAFFEINE
        this.substanceUseForm.get(SubstanceUseFields.CAFFEINE_NA).valueChanges.subscribe(value => {
            if (this.CAFFEINE_NA_TOGGLE == toggleType.Subst) {
                this.CAFFEINE_NA_TOGGLE = toggleType.None;
            } else {
                if (value) {
                    this.CAFFEINE_NA_TOGGLE = toggleType.NA;
                    this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_USE).setValue('NA');
                } else {
                    this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_USE).setValue(null);
                }
            }
        });
        this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_USE).valueChanges.subscribe(val => {
            if (val == 'NA' || val == null) {

                if (val == 'NA') {
                    if (this.CAFFEINE_NA_TOGGLE == toggleType.NA) {
                        this.CAFFEINE_NA_TOGGLE = toggleType.None;
                    } else {
                        this.CAFFEINE_NA_TOGGLE = toggleType.Subst;
                        this.substanceUseForm.get(SubstanceUseFields.CAFFEINE_NA).setValue(true);

                    }
                }

                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_AMT).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_AMT).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_AMT).disable();

                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_FREQ).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_FREQ).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_FREQ).disable();

                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_LAST_DATE).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_LAST_DATE).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_LAST_DATE).disable();
            } else {
                this.CAFFEINE_NA_TOGGLE = toggleType.Subst;
                this.substanceUseForm.get(SubstanceUseFields.CAFFEINE_NA).setValue(false);

                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_AMT).setValidators([Validators.required]);
                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_AMT).enable();
                this.tD_CAFFEINE_AMT.nativeElement.focus();

                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_FREQ).setValidators([Validators.required]);
                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_FREQ).enable();

                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_LAST_DATE).setValidators([Validators.required, validateFutureDate]);
                this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_LAST_DATE).enable();
            }
        });

        //Nicotine
        this.substanceUseForm.get(SubstanceUseFields.NICOTINE_NA).valueChanges.subscribe(value => {
            if (this.NICOTINE_NA_TOGGLE == toggleType.Subst) {
                this.NICOTINE_NA_TOGGLE = toggleType.None;
            } else {
                if (value) {
                    this.NICOTINE_NA_TOGGLE = toggleType.NA;
                    this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_USE).setValue('NA');
                } else {
                    this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_USE).setValue(null);
                }
            }
        });
        this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_USE).valueChanges.subscribe(val => {
            if (val == 'NA' || val == null) {

                if (val == 'NA') {
                    if (this.NICOTINE_NA_TOGGLE == toggleType.NA) {
                        this.NICOTINE_NA_TOGGLE = toggleType.None;
                    } else {
                        this.NICOTINE_NA_TOGGLE = toggleType.Subst;
                        this.substanceUseForm.get(SubstanceUseFields.NICOTINE_NA).setValue(true);

                    }
                }

                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_AMT).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_AMT).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_AMT).disable();

                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_FREQ).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_FREQ).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_FREQ).disable();

                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_LAST_DATE).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_LAST_DATE).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_LAST_DATE).disable();
            } else {

                this.NICOTINE_NA_TOGGLE = toggleType.Subst;
                this.substanceUseForm.get(SubstanceUseFields.NICOTINE_NA).setValue(false);

                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_AMT).setValidators([Validators.required]);
                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_AMT).enable();
                this.tD_NICOTINE_AMT.nativeElement.focus();

                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_FREQ).setValidators([Validators.required]);
                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_FREQ).enable();

                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_LAST_DATE).setValidators([Validators.required, validateFutureDate]);
                this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_LAST_DATE).enable();
            }
        });

        //OTHER
        this.substanceUseForm.get(SubstanceUseFields.OTHER_NA).valueChanges.subscribe(value => {
            if (this.OTHER_NA_TOGGLE == toggleType.Subst) {
                this.OTHER_NA_TOGGLE = toggleType.None;
            } else {
                if (value) {
                    this.OTHER_NA_TOGGLE = toggleType.NA;
                    this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_USE).setValue('NA');
                } else {
                    this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_USE).setValue(null);
                }
            }
        });
        this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_USE).valueChanges.subscribe(val => {
            if (val == 'NA' || val == null) {
                if (val == 'NA') {
                    if (this.OTHER_NA_TOGGLE == toggleType.NA) {
                        this.OTHER_NA_TOGGLE = toggleType.None;
                    } else {
                        this.OTHER_NA_TOGGLE = toggleType.Subst;
                        this.substanceUseForm.get(SubstanceUseFields.OTHER_NA).setValue(true);
                    }
                }
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_AMT).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_AMT).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_AMT).disable();

                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_FREQ).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_FREQ).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_FREQ).disable();

                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_LAST_DATE).reset();
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_LAST_DATE).clearValidators();
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_LAST_DATE).disable();
            } else {

                this.OTHER_NA_TOGGLE = toggleType.Subst;
                this.substanceUseForm.get(SubstanceUseFields.OTHER_NA).setValue(false);

                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_AMT).setValidators([Validators.required]);
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_AMT).enable();
                this.tD_OTHER_AMT.nativeElement.focus();

                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_FREQ).setValidators([Validators.required]);
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_FREQ).enable();

                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_LAST_DATE).setValidators([Validators.required, validateFutureDate]);
                this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_LAST_DATE).enable();
            }
        });

    }

    private loadData() {

        this.sectiondService.getSubstanceUse(parseInt(this.documentReferance.alocid)).subscribe((resp: SubstanceUse) => {
            if (resp.tD_ACAT_ID == null || resp.tD_ACAT_ID == 0) {
                this.alerts.error('No data found for Substance use');
            } else {
                this.alerts.success(`${this.screenName} Loaded`);
            }            
            this.setFormData(resp);//set form data
            //console.log(this.screenName, resp);
        }, error => {
            let msg = `Error fetching ${this.screenName} `;
            console.error(msg, error);
            this.alerts.error(msg);
        })
    }

    private setFormData(frmData: SubstanceUse) {
        this.substanceUseForm.patchValue(frmData);
        this.substanceUseForm.get(SubstanceUseFields.tD_ALCOHOL_LAST_DATE).setValue(DateHelper.ConvertStrinToDate(frmData.tD_ALCOHOL_LAST_DATE));
        this.substanceUseForm.get(SubstanceUseFields.tD_CAFFEINE_LAST_DATE).setValue(DateHelper.ConvertStrinToDate(frmData.tD_CAFFEINE_LAST_DATE));
        this.substanceUseForm.get(SubstanceUseFields.tD_NICOTINE_LAST_DATE).setValue(DateHelper.ConvertStrinToDate(frmData.tD_NICOTINE_LAST_DATE));
        this.substanceUseForm.get(SubstanceUseFields.tD_OTHER_LAST_DATE).setValue(DateHelper.ConvertStrinToDate(frmData.tD_OTHER_LAST_DATE));        
       
    }

    onSave() {
        this.substanceUseForm.get(SubstanceUseFields.tD_ACAT_ID).setValue(this.documentReferance.alocid);
        this.substanceUseForm.get(SubstanceUseFields.tD_SECTION_ID).setValue(this.documentReferance.sectionid);
        //format dates to string
        let formData: SubstanceUse = this.substanceUseForm.value as SubstanceUse;
        formData.tD_ALCOHOL_LAST_DATE = DateHelper.ConvertDateToString(formData.tD_ALCOHOL_LAST_DATE);
        formData.tD_CAFFEINE_LAST_DATE = DateHelper.ConvertDateToString(formData.tD_CAFFEINE_LAST_DATE);
        formData.tD_NICOTINE_LAST_DATE = DateHelper.ConvertDateToString(formData.tD_NICOTINE_LAST_DATE);
        formData.tD_OTHER_LAST_DATE = DateHelper.ConvertDateToString(formData.tD_OTHER_LAST_DATE);
        //console.table(formData);
        if (this.substanceUseForm.valid) {
            this.sectiondService.saveSubstanceUse(formData).subscribe(resp => {
                //console.log('save response ', resp);
                this.alerts.success(`${this.screenName} Saved`);
                this.loadData();
            }, error => {
                console.error(`Error saving ${this.screenName}`, error);
                this.alerts.error(`Error saving ${this.screenName}`)
            });
        } else {
            let invCrls = [];
            Object.keys(this.substanceUseForm.controls).forEach(crl => {
                if (this.substanceUseForm.controls[crl].invalid) {
                    invCrls.push(crl);
                }
            });
            console.error('invalid fields', invCrls);
        }
    }

    onClear1() {
        this.substanceUseForm.reset();
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.substanceUseForm.reset();
      }
    });

  }
}

enum toggleType {
    NA,
    Subst,
    None
}
