import { Component, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ContactModel } from '../../../shared';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


@Component({
    selector: 'app-contact-information',
    templateUrl: './contact-information.component.html',
    styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnInit {



    //=[
    //  { agency: 'Agency 1', name: 'Name1', phoneNumber: '3454567890', email: 'agency2@company.com', fax: '2345678905' },
    //  { agency: 'Agency 2', name: 'Name2', phoneNumber: '3454567590', email: 'agency2@company.com', fax: '2345678905' },
    //  { agency: 'Agency 3', name: 'Name3', phoneNumber: '3454567590', email: 'agency3@company.com', fax: '2345678905' },
    //  { agency: 'Agency 4', name: 'Name4', phoneNumber: '3454567590', email: 'agency4@company.com', fax: '2345678905' }
    //];

    displayedColumns: string[] = ['agency', 'name', 'phoneNumber', 'email', 'fax'];
    dataSource: any;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor() { }

    ngOnInit() {


    }

    receiveContactInformation(data) {
        let Contacts: ContactModel[] = [
            {
                agency: data.submitteR_FACILITY,
                name: data.submitteR_NAME,
                phoneNumber: data.submitteR_PHONE,
                email: data.submitteR_EMAIL,
                fax: data.submitteR_FAX
            },
            {
                agency: data.paa,
                name: data.paA_NAME,
                phoneNumber: data.paA_PHONE,
                email: data.paA_EMAIL,
                fax: data.paA_FAX
            },
            // { agency: data.nfid, name: data.nfname, phoneNumber: data.nfphone, email: data.paaemail, fax: data.nffax }
        ];

        this.dataSource = new MatTableDataSource<ContactModel>(Contacts);
        this.dataSource.paginator = this.paginator;
    }

}
