import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AlertsService, DataService, SectionJService, AdaptiveAssistiveEquip, AdpAssEquipFields, SectionDropdownService, DropdownModel } from '../../shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { forkJoin, Observable, interval, timer, Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { KeyedCollection } from '../../shared/common/Dictionary';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-adaptive-assistive-equip',
    templateUrl: './adaptive-assistive-equip.component.html',
    styleUrls: ['./adaptive-assistive-equip.component.css']
})
export class AdaptiveAssistiveEquipComponent implements OnInit, OnDestroy {
    ngOnDestroy(): void {
        this.autoSaveInterval.unsubscribe();
    }

    constructor(private cntxInfoService: DataService,
        private alerts: AlertsService,
        private sectionjService: SectionJService,
        private dropdown: SectionDropdownService,
        private router: Router) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    equipments: AdaptiveAssistiveEquip[];
    equipCateg: DropdownModel[];
    equipHaveNeed: DropdownModel[];
    equipLeaseOwn: DropdownModel[];
    equipCond: DropdownModel[];
    fundSrc: DropdownModel[];

    screenName = " Adaptive / Assistive Equipment";
    equipmentForm: FormGroup;
    dataloaded = false;
    savingInProgress = false;
    newSectionChanges = new KeyedCollection<AdaptiveAssistiveEquip>();
    autoSaveEnabled = false;
    waitForUser = false;
    autoSaveInterval: Subscription;

    ngOnInit() {

        this.documentReferance = { alocid: '', sectionid: '', name: '' };
        this.documentReferance.alocid = this.cntxInfoService.getSelectedDocument();
        this.documentReferance.name = this.cntxInfoService.getSelectedDocumentName();
        this.equipmentForm = new FormGroup({});
        this.loadData();

        this.router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                //if (this.unsavedChangesPresent) {
                //    //window.alert('There are unsaved changes present !');
                //}
            }
        })
    }

    loadData() {

        //load equipments
        let secJ$ = this.sectionjService.getAAE(this.documentReferance.alocid);

        let equp$ = this.getDropDown('EQUIPMENT');
        let equpHaveNeed$ = this.getDropDown('EQUIP_HAVE_NEED');
        let equipLeaseOwn$ = this.getDropDown('EQUIP_LEASE_OWN');
        let equipCond$ = this.getDropDown('EQUIP_CONDITION');
        let fundSrc$ = this.getDropDown('EQUIP_FUNDING_SOURCE');

        forkJoin(equp$, secJ$, equpHaveNeed$, equipLeaseOwn$, equipCond$, fundSrc$).subscribe(([equpSrc, secJ, needHave, leaseOwn, equipCond, fundSrc]) => {
            this.alerts.success(`${this.screenName} Loaded`);
            this.equipments = [];
            secJ.forEach(sec => {
                if (sec.tJ_CATEGORY != null) {
                    this.equipments.push(sec);
                }
            })
            //console.log(secJ);

            this.equipCateg = equpSrc;
            this.equipHaveNeed = needHave;
            this.equipLeaseOwn = leaseOwn;
            this.equipCond = equipCond;
            this.fundSrc = fundSrc;
            //console.log('funding src ', fundSrc);
            this.initForm(secJ);
        }, error => {
            console.error(error);
            this.alerts.error('Error loading data');
        });
    }

    initForm(resp: AdaptiveAssistiveEquip[]) {

        let allSections = {};
        for (let eq of resp) {
            if (eq.tJ_CATEGORY == null)
                continue;
            eq['unsaved'] = false;
            let sectionfields = {};
            for (let fld in AdpAssEquipFields) {
                sectionfields[fld] = new FormControl();
            }
            let section = new FormGroup(sectionfields);

            allSections[eq.tJ_CATEGORY] = section;
            this.getTypes(eq, section);
            this.updateFundingSource(eq, section);

            section.get(AdpAssEquipFields.tJ_HAVE_NEED).setValidators(Validators.required);
            section.get(AdpAssEquipFields.tJ_HAVE_NEED).updateValueAndValidity();

          //  section.get(AdpAssEquipFields.tJ_TYPE).setValidators(Validators.required);
          //  section.get(AdpAssEquipFields.tJ_TYPE).updateValueAndValidity();

            section.get(AdpAssEquipFields.tJ_HAVE_NEED).valueChanges.subscribe(val => {
              if (val == 'HAVE') {
                if (eq.TYPES_FLD == 'DRP') {
                  section.get(AdpAssEquipFields.tJ_LEASE_OWN).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_COMPANY).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_AGE).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_CONDITION).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_FUNDING).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_TYPE).reset();
                  section.get(AdpAssEquipFields.tJ_TYPE).clearValidators();
                  section.get(AdpAssEquipFields.tJ_TYPE).setValidators(Validators.required);

                  section.get(AdpAssEquipFields.tJ_DESCRIPTION).reset();
                  section.get(AdpAssEquipFields.tJ_DESCRIPTION).clearValidators();
                } else {
                  section.get(AdpAssEquipFields.tJ_LEASE_OWN).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_COMPANY).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_AGE).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_CONDITION).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_FUNDING).setValidators(Validators.required);
                  section.get(AdpAssEquipFields.tJ_TYPE).reset();
                  section.get(AdpAssEquipFields.tJ_TYPE).clearValidators();
                 // section.get(AdpAssEquipFields.tJ_TYPE).setValidators(Validators.required);

                  //section.get(AdpAssEquipFields.tJ_DESCRIPTION).reset();
                  //section.get(AdpAssEquipFields.tJ_DESCRIPTION).clearValidators();
                  section.get(AdpAssEquipFields.tJ_DESCRIPTION).setValidators(Validators.required);
                }
                    
                } else {

                  if (val == 'NEED') {
                      if (eq.TYPES_FLD == 'DRP') {
                        section.get(AdpAssEquipFields.tJ_LEASE_OWN).reset();
                        section.get(AdpAssEquipFields.tJ_LEASE_OWN).clearValidators();
                        section.get(AdpAssEquipFields.tJ_COMPANY).reset();
                        section.get(AdpAssEquipFields.tJ_COMPANY).clearValidators();
                        section.get(AdpAssEquipFields.tJ_AGE).reset();
                        section.get(AdpAssEquipFields.tJ_AGE).clearValidators();
                        section.get(AdpAssEquipFields.tJ_CONDITION).reset();
                        section.get(AdpAssEquipFields.tJ_CONDITION).clearValidators();
                        section.get(AdpAssEquipFields.tJ_FUNDING).reset();
                        section.get(AdpAssEquipFields.tJ_FUNDING).clearValidators();
                        section.get(AdpAssEquipFields.tJ_COMMENT).reset();
                        section.get(AdpAssEquipFields.tJ_COMMENT).clearValidators();                        
                        section.get(AdpAssEquipFields.tJ_TYPE).reset();
                        section.get(AdpAssEquipFields.tJ_TYPE).clearValidators();
                        section.get(AdpAssEquipFields.tJ_DESCRIPTION).reset();
                        section.get(AdpAssEquipFields.tJ_DESCRIPTION).clearValidators();

                        section.get(AdpAssEquipFields.tJ_TYPE).setValidators(Validators.required);
                      } else {
                        section.get(AdpAssEquipFields.tJ_LEASE_OWN).reset();
                        section.get(AdpAssEquipFields.tJ_LEASE_OWN).clearValidators();
                        section.get(AdpAssEquipFields.tJ_COMPANY).reset();
                        section.get(AdpAssEquipFields.tJ_COMPANY).clearValidators();
                        section.get(AdpAssEquipFields.tJ_AGE).reset();
                        section.get(AdpAssEquipFields.tJ_AGE).clearValidators();
                        section.get(AdpAssEquipFields.tJ_CONDITION).reset();
                        section.get(AdpAssEquipFields.tJ_CONDITION).clearValidators();
                        section.get(AdpAssEquipFields.tJ_FUNDING).reset();
                        section.get(AdpAssEquipFields.tJ_FUNDING).clearValidators();
                        section.get(AdpAssEquipFields.tJ_COMMENT).reset();
                        section.get(AdpAssEquipFields.tJ_COMMENT).clearValidators();   
                        section.get(AdpAssEquipFields.tJ_TYPE).reset();
                        section.get(AdpAssEquipFields.tJ_TYPE).clearValidators();

                        section.get(AdpAssEquipFields.tJ_DESCRIPTION).setValidators(Validators.required);
                      }                    
                  } else {
                    section.get(AdpAssEquipFields.tJ_LEASE_OWN).reset();
                    section.get(AdpAssEquipFields.tJ_LEASE_OWN).clearValidators();
                    section.get(AdpAssEquipFields.tJ_COMPANY).reset();
                    section.get(AdpAssEquipFields.tJ_COMPANY).clearValidators();
                    section.get(AdpAssEquipFields.tJ_AGE).reset();
                    section.get(AdpAssEquipFields.tJ_AGE).clearValidators();
                    section.get(AdpAssEquipFields.tJ_CONDITION).reset();
                    section.get(AdpAssEquipFields.tJ_CONDITION).clearValidators();
                    section.get(AdpAssEquipFields.tJ_FUNDING).reset();
                    section.get(AdpAssEquipFields.tJ_FUNDING).clearValidators();
                    section.get(AdpAssEquipFields.tJ_COMMENT).reset();
                    section.get(AdpAssEquipFields.tJ_TYPE).reset();
                    section.get(AdpAssEquipFields.tJ_TYPE).clearValidators();
                    section.get(AdpAssEquipFields.tJ_DESCRIPTION).reset();
                    section.get(AdpAssEquipFields.tJ_DESCRIPTION).clearValidators();
                  }
                    
                }
                section.get(AdpAssEquipFields.tJ_LEASE_OWN).updateValueAndValidity();
                section.get(AdpAssEquipFields.tJ_COMPANY).updateValueAndValidity();
                section.get(AdpAssEquipFields.tJ_AGE).updateValueAndValidity();
                section.get(AdpAssEquipFields.tJ_CONDITION).updateValueAndValidity();
              section.get(AdpAssEquipFields.tJ_FUNDING).updateValueAndValidity();
              section.get(AdpAssEquipFields.tJ_TYPE).updateValueAndValidity();
              section.get(AdpAssEquipFields.tJ_DESCRIPTION).updateValueAndValidity();
            });

            section.get(AdpAssEquipFields.tJ_FUNDING).valueChanges.subscribe(val => {
                if (val == 'OTHER') {
                    section.get(AdpAssEquipFields.tJ_FUNDING_OTHER).setValidators(Validators.required);
                } else {
                    section.get(AdpAssEquipFields.tJ_FUNDING_OTHER).clearValidators();
                    section.get(AdpAssEquipFields.tJ_FUNDING_OTHER).reset();
                }
                section.get(AdpAssEquipFields.tJ_FUNDING_OTHER).updateValueAndValidity();
            });

            section.get(AdpAssEquipFields.tJ_TYPE).valueChanges.subscribe(val => {
                if (val === 'OTHER') {
                    section.get(AdpAssEquipFields.tJ_TYPE_OTHER).setValidators(Validators.required);
                } else {
                    section.get(AdpAssEquipFields.tJ_TYPE_OTHER).reset();
                    section.get(AdpAssEquipFields.tJ_TYPE_OTHER).clearValidators();
                }
                section.get(AdpAssEquipFields.tJ_TYPE_OTHER).updateValueAndValidity();
            });

            section.patchValue(eq);
            //section.disable();
            //update the changes dict
            //timer(2000).subscribe(() => {
                //console.log('timer on');
                //section.enable();
                section.valueChanges.subscribe((sectionData: AdaptiveAssistiveEquip) => {
                    //console.log('sectionData ', sectionData);
                    if (!section.dirty) return;
                    //if (!this.autoSaveEnabled) return;
                    this.waitForUser = true;
                    this.newSectionChanges.Add(sectionData.tJ_CATEGORY, this.updateEquipmentValue(sectionData));
                    this.savingInProgress = false;
                    eq['unsaved'] = true;
                });
           // });
            

        }

        this.equipmentForm = new FormGroup(allSections);
        //console.log('all form value', this.equipmentForm.value);
        this.configAutoSaveSettings();
    }

    getTypes(equipment: AdaptiveAssistiveEquip, section: FormGroup) {

        let reset_type = () => {
            section.get(AdpAssEquipFields.tJ_TYPE).clearValidators();
            section.get(AdpAssEquipFields.tJ_TYPE).updateValueAndValidity();
        };
        let equipValue = this.equipCateg.find(eq => eq.dV_EXPANDED_VALUE === equipment.tJ_CATEGORY);
        if (equipValue != null) {
            this.dropdown.GetDropDownValues(equipValue.dV_VALUE).subscribe((resp: DropdownModel[]) => {
                if (resp.length > 0) {
                    equipment.TYPES_SRC = resp;
                    equipment.TYPES_FLD = "DRP";

                    let sel_type = resp.find(r => r.dV_EXPANDED_VALUE == equipment.tJ_TYPE);
                    if (sel_type != null) {
                        equipment.tJ_TYPE = sel_type.dV_VALUE;
                        section.get(AdpAssEquipFields.tJ_TYPE).setValue(sel_type.dV_VALUE);
                    }


                } else {
                    equipment.TYPES_FLD = "TXT";
                    reset_type();
                }
                this.alerts.clearLoader();
            }, error => {
                equipment.TYPES_FLD = "TXT";
                reset_type();
            });
        } else {
            equipment.TYPES_FLD = "TXT";
            reset_type();
        }
    }

    updateFundingSource(equipment: AdaptiveAssistiveEquip, section: FormGroup) {
        let fund = this.fundSrc.find(fu => fu.dV_EXPANDED_VALUE == equipment.tJ_FUNDING);
        if (fund != null) {
            equipment.tJ_FUNDING = fund.dV_VALUE;
            section.get(AdpAssEquipFields.tJ_FUNDING).setValue(fund.dV_VALUE);
        }
    }

    updateEquipmentValue(equipment: AdaptiveAssistiveEquip): AdaptiveAssistiveEquip {
        let equipValue = this.equipCateg.find(eq => eq.dV_EXPANDED_VALUE === equipment.tJ_CATEGORY);
        if (equipValue != null) {
            equipment.tJ_CATEGORY = equipValue.dV_VALUE
        }
        return equipment
    }




    getDropDown(Domain: string): Observable<DropdownModel[]> {
        return this.dropdown.GetDropDownValues(Domain);
    }

    configAutoSaveSettings() {
        if (this.autoSaveInterval == null) {
            this.autoSaveInterval = interval(1000).subscribe(() => {
                //console.log('interval on');
                //if (!this.autoSaveEnabled) {
                //    this.autoSaveEnabled = true;
                //    return;
                //}
                if (this.waitForUser) {
                    this.waitForUser = false;
                    return;
                }
                if (!this.savingInProgress) {

                    for (let sec of this.newSectionChanges.Keys()) {
                        this.savingInProgress = true;
                        //console.log('data for save', this.newSectionChanges.Item(sec));
                        this.sectionjService.postAAE(this.newSectionChanges.Item(sec)).subscribe(resp => {
                            //console.log(resp);
                            this.equipments.forEach(eq => {
                                if (eq.tJ_CATEGORY == sec) {
                                    eq['unsaved'] = false;
                                    return;
                                }
                            });
                            this.newSectionChanges.Remove(sec);
                            this.alerts.success(`${sec} Saved`);
                            this.savingInProgress = false;
                        }, error => {
                            if (error.error.details && error.error.details.oracleCode == '20100') {
                                try {
                                    this.alerts.error(error.error.details.oracleMsg.split(':')[1]);
                                } catch (e) {
                                    this.alerts.error(`Error saving section ${sec}`);
                                }
                            } else {
                                this.alerts.error(`Error saving section ${sec}`);
                            }
                            //this.savingInProgress = false;
                            console.error(error);
                        });
                        break; // one section at a time;
                    }
                }
            })
        }

    }

    @HostListener('window:beforeunload')
    canNavigateAway(): boolean {
        return this.newSectionChanges.Count() <= 0 && !this.savingInProgress;
  }
  preventPaste(e) {
    e.preventDefault();
    return false;
  }

}
