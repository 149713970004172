import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { GetContacts, CityState } from '../models';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SectionBService {

  constructor(private apiService: ApiService) { }

  getContacts(alocID: string): Observable<any> {
    return this.apiService.get(`SectionB/GetContacts/${alocID}`);
  }
  GetEditContacts(sectionID: number, acatID: number): Observable<any> {
    return this.apiService.get(`SectionB/GetEditContacts/${sectionID}/${acatID}`);
  }
  LoadContacts(alocID: string): Observable<GetContacts[]> {
    return this.apiService.get(`SectionB/LoadContacts/${alocID}`).pipe(map(data => {
      return data;
    }));
  }
  DeleteContact(sectionID: number): Observable<any> {
    return this.apiService.delete(`SectionB/DeleteContact/${sectionID}`);
  }
  PostSectionB(formData): Observable<any> {
    return this.apiService.post('SectionB/PostSectionB', formData);
  }
  getCounties(): Observable<any> {
    return this.apiService.get('sectiona/getCounties');
  }
  getCityState(zipCode): Observable<CityState> {

    return this.apiService.get('sectiona/GetCityState', zipCode)
      .pipe(map(data => { return data; }));
  }

}
