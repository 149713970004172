import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionKService, EmergencyNumber, DropdownModel } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-emergency-number',
  templateUrl: './emergency-number.component.html',
  styleUrls: ['./emergency-number.component.css']
})
export class EmergencyNumberComponent implements OnInit {

    emergencyNumberForm: FormGroup;
    isSubmitting = false;
    emergencyNumber: EmergencyNumber = {} as EmergencyNumber;

    constructor(private fb: FormBuilder,
        private dropdownSerice: SectionDropdownService,
        private alertService: AlertsService,
        public dataService: DataService,
        public sectionKService: SectionKService) {
        this.emergencyNumberForm = this.fb.group({
            tK_SECTION_ID: new FormControl(''),
            tK_ACAT_ID: new FormControl(''),
            tK_EMERG_NUMBERS: new FormControl('', Validators.required),
            tK_EMERG_NUMBERS_COMMENTS: new FormControl({value:'',disabled:true}),
            tK_PHONE_ACCESS: new FormControl('', Validators.required),
            tK_PHONE_ACCESS_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_ERS: new FormControl('', Validators.required),
            tK_ERS_COMMENTS: new FormControl({ value: '', disabled: true })
        });

    }

    ngOnInit() {
        this.sectionKService.getEmergencyNumber(this.dataService.getSelectedDocument()).subscribe(response => {
            this.emergencyNumberForm.patchValue(response);
          this.alertService.success('Emergency Number and Telephone Access Loaded');
        }, err => {
            this.alertService.error(JSON.stringify(err));
        });
        this.onChange();
  }

    onChange() {
        this.emergencyNumberForm.get('tK_EMERG_NUMBERS').valueChanges.subscribe(em => {           
            if (em == 'SAFEDUISSU') {

                this.emergencyNumberForm.get('tK_EMERG_NUMBERS_COMMENTS').enable();
                this.emergencyNumberForm.get('tK_EMERG_NUMBERS_COMMENTS').setValidators(Validators.required);
                this.emergencyNumberForm.controls['tK_EMERG_NUMBERS_COMMENTS'].markAsTouched();
            
            } else {
                
                this.emergencyNumberForm.get('tK_EMERG_NUMBERS_COMMENTS').disable();
                this.emergencyNumberForm.get('tK_EMERG_NUMBERS_COMMENTS').setValue('');               
            }
        });

        this.emergencyNumberForm.get('tK_PHONE_ACCESS').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.emergencyNumberForm.get('tK_PHONE_ACCESS_COMMENTS').enable();
                this.emergencyNumberForm.get('tK_PHONE_ACCESS_COMMENTS').setValidators(Validators.required);
                this.emergencyNumberForm.controls['tK_PHONE_ACCESS_COMMENTS'].markAsTouched();

            } else {

                this.emergencyNumberForm.get('tK_PHONE_ACCESS_COMMENTS').disable();
                this.emergencyNumberForm.get('tK_PHONE_ACCESS_COMMENTS').setValue('');
            }
        });

        this.emergencyNumberForm.get('tK_ERS').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.emergencyNumberForm.get('tK_ERS_COMMENTS').enable();
                this.emergencyNumberForm.get('tK_ERS_COMMENTS').setValidators(Validators.required);
                this.emergencyNumberForm.controls['tK_ERS_COMMENTS'].markAsTouched();

            } else {

                this.emergencyNumberForm.get('tK_ERS_COMMENTS').disable();
                this.emergencyNumberForm.get('tK_ERS_COMMENTS').setValue('');
            }
        });
    }
        
    ClearForm() {
     // this.emergencyNumberForm.reset();
      this.onClear();
    }
    updatEmergencyNumber(values: Object) {
        Object.assign(this.emergencyNumber, values);
        this.emergencyNumber.tK_ACAT_ID = this.dataService.getSelectedDocument();
    }

    submitForm() { 

        this.isSubmitting = true;
        this.updatEmergencyNumber(this.emergencyNumberForm.value);
        console.log(this.emergencyNumber);
        this.sectionKService.postEmergencyNumber(this.emergencyNumber).subscribe(response => {
          this.alertService.success('Emergency Number and Telephone Access Saved');
        }, err => {
            this.alertService.error(JSON.stringify(err));
        });
        this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {        
        this.emergencyNumberForm.reset();
       

      }
    });

  }
}
