import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionGService, Dental, DropdownModel } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dental-screen',
  templateUrl: './dental-screen.component.html',
  styleUrls: ['./dental-screen.component.css']
})
export class DentalScreenComponent implements OnInit {
  formStatus: boolean;
    dentalForm: FormGroup;
    isSubmitting = false;
    dental: Dental = {} as Dental;
    tooth: any[];
    dentures: any[];    
    dentistDetails = false;
    constructor(private fb: FormBuilder,
        private dropdownSerice: SectionDropdownService,
        private alertService: AlertsService,
        public dataService: DataService,
        public sectionGService: SectionGService) {
        this.dentalForm = this.fb.group({            
            tG_ACAT_ID: new FormControl(''),
            tG_DENTAL_PROVIDER_IND: new FormControl(''),
            tG_DENTIST_NAME: new FormControl(''),
            tG_DENTIST_PHONE: new FormControl(''),
            tG_DENTIST_VISIT_IND: new FormControl(''),
            tG_BROKEN_TEETH_IND: new FormControl(''),
            tG_TEETH_IND: new FormControl(''),
            tG_DENTURE_TYPE: new FormControl({value:'',disabled:true}),            
            tG_TEETH_CLEANING_IND: new FormControl(''),
            tG_SUGAR_IND: new FormControl(''),
            tG_CIGARETTE_IND: new FormControl(''),
            tG_CIGAR_PIPE_IND: new FormControl(''),
            tG_SMOKELESS_IND: new FormControl(''),
            tG_TOBACCO_USE_NA_IND: new FormControl(''),
            tG_GINGIVITIS_IND: new FormControl(''),
            tG_PLAQUE_IND: new FormControl(''),
            tG_MOUTH_PAIN_IND: new FormControl(''),
            tG_TOOTH_DECAY_IND: new FormControl(''),
            tG_BULIMIA_IND: new FormControl(''),
            tG_ABSCESS_IND: new FormControl(''),
            tG_DENTAL_ISSUES_NA_IND: new FormControl(''),
            tG_DENTAL_REFERRAL_IND: new FormControl(''),
            tG_DENTAL_COMMENTS: new FormControl('')           
        });

    }

  ngOnInit() {

    if (this.dataService.getSelectedDocument() != null) {
      this.sectionGService.GetSectionGInfo(this.dataService.getSelectedDocument()).subscribe(response => {
        this.receiveDental(response);
        this.alertService.success("Dental Screen Loaded");
      }, err => {
        this.alertService.error(err);
      });
    }   

        this.loadDropdown();
        this.onChange();
  }
  receiveDental(data) {
    this.dentalForm.patchValue(data);
    this.setData(data);
  }
  setData(data) {
    if (data['tG_CIGARETTE_IND'] == 'T') {
      this.dentalForm.get('tG_CIGARETTE_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_CIGARETTE_IND').setValue(false);
    }

    if (data['tG_CIGAR_PIPE_IND'] == 'T') {
      this.dentalForm.get('tG_CIGAR_PIPE_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_CIGAR_PIPE_IND').setValue(false);
    }

    if (data['tG_SMOKELESS_IND'] == 'T') {
      this.dentalForm.get('tG_SMOKELESS_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_SMOKELESS_IND').setValue(false);
    }

    if (data['tG_TOBACCO_USE_NA_IND'] == 'T') {
      this.dentalForm.get('tG_TOBACCO_USE_NA_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_TOBACCO_USE_NA_IND').setValue(false);
    }

    if (data['tG_GINGIVITIS_IND'] == 'T') {
      this.dentalForm.get('tG_GINGIVITIS_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_GINGIVITIS_IND').setValue(false);
    }

    if (data['tG_PLAQUE_IND'] == 'T') {
      this.dentalForm.get('tG_PLAQUE_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_PLAQUE_IND').setValue(false);
    }

    if (data['tG_MOUTH_PAIN_IND'] == 'T') {
      this.dentalForm.get('tG_MOUTH_PAIN_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_MOUTH_PAIN_IND').setValue(false);
    }

    if (data['tG_TOOTH_DECAY_IND'] == 'T') {
      this.dentalForm.get('tG_TOOTH_DECAY_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_TOOTH_DECAY_IND').setValue(false);
    }

    if (data['tG_BULIMIA_IND'] == 'T') {
      this.dentalForm.get('tG_BULIMIA_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_BULIMIA_IND').setValue(false);
    }

    if (data['tG_ABSCESS_IND'] == 'T') {
      this.dentalForm.get('tG_ABSCESS_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_ABSCESS_IND').setValue(false);
    }

    if (data['tG_DENTAL_ISSUES_NA_IND'] == 'T') {
      this.dentalForm.get('tG_DENTAL_ISSUES_NA_IND').setValue(true);
    }
    else {
      this.dentalForm.get('tG_DENTAL_ISSUES_NA_IND').setValue(false);
    }
  }
  loadDropdown() {
    this.dropdownSerice.GetDropDownValues('TEETH').subscribe(data => {
      this.tooth = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('DENTURE').subscribe(data => {
      this.dentures = data;
    }, error => {
      this.alertService.error(error);
    });
  }
    onChange() {
        this.dentalForm.get('tG_TOBACCO_USE_NA_IND').valueChanges.subscribe(na => {
            if (na) {
                this.dentalForm.get('tG_CIGARETTE_IND').setValue(false);
                this.dentalForm.get('tG_CIGARETTE_IND').disable();

                this.dentalForm.get('tG_CIGAR_PIPE_IND').setValue(false);
                this.dentalForm.get('tG_CIGAR_PIPE_IND').disable();

                this.dentalForm.get('tG_SMOKELESS_IND').setValue(false);
                this.dentalForm.get('tG_SMOKELESS_IND').disable();
            }
            else {
                this.dentalForm.get('tG_CIGARETTE_IND').enable();
                this.dentalForm.get('tG_CIGAR_PIPE_IND').enable();
                this.dentalForm.get('tG_SMOKELESS_IND').enable();
            }
        });

        this.dentalForm.get('tG_DENTAL_ISSUES_NA_IND').valueChanges.subscribe(na => {
            if (na) {
                this.dentalForm.get('tG_GINGIVITIS_IND').setValue(false);
                this.dentalForm.get('tG_GINGIVITIS_IND').disable();

                this.dentalForm.get('tG_PLAQUE_IND').setValue(false);
                this.dentalForm.get('tG_PLAQUE_IND').disable();

                this.dentalForm.get('tG_MOUTH_PAIN_IND').setValue(false);
                this.dentalForm.get('tG_MOUTH_PAIN_IND').disable();

                this.dentalForm.get('tG_TOOTH_DECAY_IND').setValue(false);
                this.dentalForm.get('tG_TOOTH_DECAY_IND').disable();

                this.dentalForm.get('tG_BULIMIA_IND').setValue(false);
                this.dentalForm.get('tG_BULIMIA_IND').disable();

                this.dentalForm.get('tG_ABSCESS_IND').setValue(false);
                this.dentalForm.get('tG_ABSCESS_IND').disable();
            }
            else {
                this.dentalForm.get('tG_GINGIVITIS_IND').enable();
                this.dentalForm.get('tG_PLAQUE_IND').enable();
                this.dentalForm.get('tG_MOUTH_PAIN_IND').enable();
                this.dentalForm.get('tG_TOOTH_DECAY_IND').enable();
                this.dentalForm.get('tG_BULIMIA_IND').enable();
                this.dentalForm.get('tG_ABSCESS_IND').enable();
            }
        });

        this.dentalForm.get('tG_TEETH_IND').valueChanges.subscribe(teeth => {
            if (teeth == 'ARTIFICIAL' || teeth == 'BOTH') {
              this.dentalForm.get('tG_DENTURE_TYPE').enable();
              this.dentalForm.get('tG_DENTURE_TYPE').setValidators([Validators.required]);
              this.dentalForm.get('tG_DENTURE_TYPE').markAsTouched();
            } else {
              this.dentalForm.get('tG_DENTURE_TYPE').clearValidators();
              this.dentalForm.get('tG_DENTURE_TYPE').disable();
              this.dentalForm.get('tG_DENTURE_TYPE').setValue('');
              
            }
          this.dentalForm.get('tG_DENTURE_TYPE').updateValueAndValidity();
        });
        this.dentalForm.get('tG_DENTAL_PROVIDER_IND').valueChanges.subscribe(dentist => {
            if (dentist == 'Y') {
              this.dentistDetails = true;
              this.dentalForm.get('tG_DENTIST_NAME').setValidators([Validators.required]);
              this.dentalForm.get('tG_DENTIST_PHONE').setValidators([Validators.required]);
              this.dentalForm.get('tG_DENTIST_NAME').markAsTouched();
              this.dentalForm.get('tG_DENTIST_PHONE').markAsTouched();
            } else {
              this.dentistDetails = false;
              this.dentalForm.get('tG_DENTIST_NAME').clearValidators();
              this.dentalForm.get('tG_DENTIST_PHONE').clearValidators();
              this.dentalForm.get('tG_DENTIST_NAME').setValue('');
              this.dentalForm.get('tG_DENTIST_PHONE').setValue('');

            }
        });
    }
   
    ClearForm(e) {
      this.onClear();
    }

    submitForm() {

        this.isSubmitting = true;

      this.dentalForm.get('tG_ACAT_ID').setValue('');

      for (let cnt in this.dentalForm.controls) {
        if (this.dentalForm.controls[cnt].value == null || this.dentalForm.controls[cnt].value == '') {
          this.formStatus = false;
        }
        else {
          this.formStatus = true;
          break;
        }
      }

      if (this.formStatus == false) {        
        this.warningAlert();
        this.isSubmitting = false;
        return;
      }

      console.log(this.dentalForm.value);        
      this.dentalForm.get('tG_ACAT_ID').setValue(this.dataService.getSelectedDocument());
      this.sectionGService.postDental(this.dentalForm.value).subscribe(response => {
        this.alertService.success('Dental Screen Saved');
      }, err => {
        console.error('Error Saving Dental Screen', err);
        this.alertService.error("Error Saving Dental Screen");        
        });

        this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {        
        this.dentalForm.reset();
      }
    });

  }
  warningAlert() {
    Swal.fire(
      '',
      'Please add atleast one dental screen  information',
      'warning'
    )
  }

}
