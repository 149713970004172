import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionKService, HomeSafety, DropdownModel } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-safety',
  templateUrl: './home-safety.component.html',
  styleUrls: ['./home-safety.component.css']
})
export class HomeSafetyComponent implements OnInit {

    homeSafetyForm: FormGroup;
    isSubmitting = false;
    homeSafety: HomeSafety = {} as HomeSafety;
    constructor(private fb: FormBuilder,
        private dropdownSerice: SectionDropdownService,
        private alertService: AlertsService,
        public dataService: DataService,
        public sectionKService: SectionKService) {
        this.homeSafetyForm = this.fb.group({
            tK_SECTION_ID: new FormControl(''),
            tK_ACAT_ID: new FormControl(''),
            tK_CORDS: new FormControl(''),
            tK_CORDS_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_BK_CAREGIVER: new FormControl(''),
            tK_BK_CAREGIVER_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_POWER_LOSS: new FormControl(''),
            tK_POWER_LOSS_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_LIGHTING: new FormControl(''),
            tK_LIGHTING_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_FIRE_PLAN: new FormControl(''),
            tK_FIRE_PLAN_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_CO_DETECTOR: new FormControl(''),
            tK_CO_DETECTOR_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_SMOKE_ALARM: new FormControl(''),
            tK_SMOKE_ALARM_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_EMERG_EXITS: new FormControl(''),
            tK_EMERG_EXITS_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_INFECTION: new FormControl(''),
            tK_INFECTION_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_PATHWAYS: new FormControl(''),
            tK_PATHWAYS_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_SMOKERS: new FormControl(''),
            tK_SMOKERS_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_FIRST_AID: new FormControl(''),
            tK_FIRST_AID_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_WEAPONS: new FormControl(''),
            tK_WEAPONS_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_O2_STORAGE: new FormControl(''),
            tK_O2_STORAGE_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_BED_BUGS: new FormControl(''),
            tK_BED_BUGS_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_PESTS: new FormControl(''),
            tK_PESTS_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_HOARDING: new FormControl(''),
            tK_HOARDING_COMMENTS: new FormControl({ value: '', disabled: true }),
            tK_SPECIAL_NEEDS: new FormControl(''),
            tK_SPECIAL_NEEDS_COMMENTS: new FormControl({ value: '', disabled: true })
        });
    }

    ngOnInit() {
        this.sectionKService.getHomeSafety(this.dataService.getSelectedDocument()).subscribe(response => {
            this.homeSafetyForm.patchValue(response);
          this.alertService.success('Home Safety Assessment Loaded');
            //console.log(response);
        }, error => {
            console.error(error)
            this.alertService.error("Error loading data");
        });

        this.onChange();
    }

    onChange() {
        this.homeSafetyForm.get('tK_CORDS').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_CORDS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_CORDS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_CORDS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_CORDS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_CORDS_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_BK_CAREGIVER').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_BK_CAREGIVER_COMMENTS').enable();
                this.homeSafetyForm.get('tK_BK_CAREGIVER_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_BK_CAREGIVER_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_BK_CAREGIVER_COMMENTS').disable();
                this.homeSafetyForm.get('tK_BK_CAREGIVER_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_POWER_LOSS').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_POWER_LOSS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_POWER_LOSS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_POWER_LOSS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_POWER_LOSS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_POWER_LOSS_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_LIGHTING').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_LIGHTING_COMMENTS').enable();
                this.homeSafetyForm.get('tK_LIGHTING_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_LIGHTING_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_LIGHTING_COMMENTS').disable();
                this.homeSafetyForm.get('tK_LIGHTING_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_FIRE_PLAN').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_FIRE_PLAN_COMMENTS').enable();
                this.homeSafetyForm.get('tK_FIRE_PLAN_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_FIRE_PLAN_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_FIRE_PLAN_COMMENTS').disable();
                this.homeSafetyForm.get('tK_FIRE_PLAN_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_CO_DETECTOR').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_CO_DETECTOR_COMMENTS').enable();
                this.homeSafetyForm.get('tK_CO_DETECTOR_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_CO_DETECTOR_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_CO_DETECTOR_COMMENTS').disable();
                this.homeSafetyForm.get('tK_CO_DETECTOR_COMMENTS').setValue('');
            }
        });


        this.homeSafetyForm.get('tK_SMOKE_ALARM').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_SMOKE_ALARM_COMMENTS').enable();
                this.homeSafetyForm.get('tK_SMOKE_ALARM_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_SMOKE_ALARM_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_SMOKE_ALARM_COMMENTS').disable();
                this.homeSafetyForm.get('tK_SMOKE_ALARM_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_EMERG_EXITS').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_EMERG_EXITS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_EMERG_EXITS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_EMERG_EXITS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_EMERG_EXITS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_EMERG_EXITS_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_INFECTION').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_INFECTION_COMMENTS').enable();
                this.homeSafetyForm.get('tK_INFECTION_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_INFECTION_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_INFECTION_COMMENTS').disable();
                this.homeSafetyForm.get('tK_INFECTION_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_PATHWAYS').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_PATHWAYS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_PATHWAYS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_PATHWAYS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_PATHWAYS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_PATHWAYS_COMMENTS').setValue('');
            }
        });


        this.homeSafetyForm.get('tK_SMOKERS').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_SMOKERS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_SMOKERS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_SMOKERS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_SMOKERS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_SMOKERS_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_FIRST_AID').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_FIRST_AID_COMMENTS').enable();
                this.homeSafetyForm.get('tK_FIRST_AID_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_FIRST_AID_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_FIRST_AID_COMMENTS').disable();
                this.homeSafetyForm.get('tK_FIRST_AID_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_WEAPONS').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_WEAPONS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_WEAPONS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_WEAPONS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_WEAPONS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_WEAPONS_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_O2_STORAGE').valueChanges.subscribe(em => {
            if (em == 'SAFEDUISSU') {

                this.homeSafetyForm.get('tK_O2_STORAGE_COMMENTS').enable();
                this.homeSafetyForm.get('tK_O2_STORAGE_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_O2_STORAGE_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_O2_STORAGE_COMMENTS').disable();
                this.homeSafetyForm.get('tK_O2_STORAGE_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_BED_BUGS').valueChanges.subscribe(em => {
            if (em == 'Y') {

                this.homeSafetyForm.get('tK_BED_BUGS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_BED_BUGS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_BED_BUGS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_BED_BUGS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_BED_BUGS_COMMENTS').setValue('');
            }
        });


        this.homeSafetyForm.get('tK_BED_BUGS').valueChanges.subscribe(em => {
            if (em == 'Y') {

                this.homeSafetyForm.get('tK_BED_BUGS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_BED_BUGS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_BED_BUGS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_BED_BUGS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_BED_BUGS_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_PESTS').valueChanges.subscribe(em => {
            if (em == 'Y') {

                this.homeSafetyForm.get('tK_PESTS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_PESTS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_PESTS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_PESTS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_PESTS_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_HOARDING').valueChanges.subscribe(em => {
            if (em == 'Y') {

                this.homeSafetyForm.get('tK_HOARDING_COMMENTS').enable();
                this.homeSafetyForm.get('tK_HOARDING_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_HOARDING_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_HOARDING_COMMENTS').disable();
                this.homeSafetyForm.get('tK_HOARDING_COMMENTS').setValue('');
            }
        });

        this.homeSafetyForm.get('tK_SPECIAL_NEEDS').valueChanges.subscribe(em => {
            if (em == 'Y') {

                this.homeSafetyForm.get('tK_SPECIAL_NEEDS_COMMENTS').enable();
                this.homeSafetyForm.get('tK_SPECIAL_NEEDS_COMMENTS').setValidators(Validators.required);
                this.homeSafetyForm.controls['tK_SPECIAL_NEEDS_COMMENTS'].markAsTouched();

            } else {

                this.homeSafetyForm.get('tK_SPECIAL_NEEDS_COMMENTS').disable();
                this.homeSafetyForm.get('tK_SPECIAL_NEEDS_COMMENTS').setValue('');
            }
        });

    }

    ClearForm() {
       // this.homeSafetyForm.reset();
      this.onClear();
    }
    updatHomeSafety(values: Object) {
        Object.assign(this.homeSafety, values);
        this.homeSafety.tK_ACAT_ID = parseInt(this.dataService.getSelectedDocument());
    }
    submitForm() {

        this.isSubmitting = true;
        this.updatHomeSafety(this.homeSafetyForm.value);
        //console.log(this.homeSafety);
        this.sectionKService.postHomeSafety(this.homeSafety).subscribe(response => {
          this.alertService.success('Home Safety Assessment Saved');
        }, err => {
                console.error(err);
                this.alertService.error("Error saving data");
        });
        this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.homeSafetyForm.reset();


      }
    });

  }
}
