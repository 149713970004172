import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { SectionDService, DataService, AlertsService } from '../../shared';
import { TransplantInfo } from './TransplantInfo';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TransplantInfoFields } from './TransplantInfoFields';
import { HadTransplant } from './had-transplant-cntrl/HadTransplant';
import { HadTransplantCntrlComponent } from './had-transplant-cntrl/had-transplant-cntrl.component';
import { TransplantInfoFormHelper } from './TransplantInfoFormHelper';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-transplant-info',
    templateUrl: './transplant-info.component.html',
    styleUrls: ['./transplant-info.component.css']
})
export class TransplantInfoComponent implements OnInit {

    constructor(private sectiondService: SectionDService,
        private cntxInfoService: DataService,
        private alerts: AlertsService) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    screenName = "Transplant Information";
    transplantInfoForm: FormGroup;
    txOrganData: Array<HadTransplant>;
    get hadTransplant(): boolean {
        return this.transplantInfoForm.get(TransplantInfoFields.tD_TX_IND).value == 'Y';
    }

    @ViewChildren(HadTransplantCntrlComponent)
    txOrganRows: QueryList<HadTransplantCntrlComponent>;

    ngOnInit() {
        //set cntx document
        this.documentReferance = {
            alocid: this.cntxInfoService.getSelectedDocument(),
            sectionid: '',
            name: this.cntxInfoService.getSelectedDocumentName()
        };
        //init form
        this.initForm();

        //load data
        this.loadData();
    }

    private initForm() {
        let formContrls = {};
        for (let fld in TransplantInfoFields) {
            formContrls[fld] = new FormControl('');
        }

        this.transplantInfoForm = new FormGroup(formContrls);
        this.transplantInfoForm.get(TransplantInfoFields.tD_ACAT_ID).setValidators([Validators.required]);
        this.transplantInfoForm.get(TransplantInfoFields.tD_TX_IND).setValidators([Validators.required]);

        //init tx organ data
        this.txOrganData = new Array<HadTransplant>();

        //conf tx ind
        this.transplantInfoForm.get(TransplantInfoFields.tD_TX_IND).valueChanges.subscribe(val => {
            if (val == 'Y') {
                for (let crl of this.txOrganData) {
                    crl.DISABLED = false;
                }
            } else {
                for (let crl of this.txOrganData) {
                    crl.DISABLED = true;
                }
            }
        });


    }

    private loadData() {

        this.sectiondService.getTransplantInfo(parseInt(this.documentReferance.alocid)).subscribe((resp: TransplantInfo) => {
            if (resp.tD_ACAT_ID == null || resp.tD_ACAT_ID == 0) {
                this.alerts.error('No data found for Transplant Information');
            } else {
                this.alerts.success(`${this.screenName} Loaded`);
            }        
            this.setFormData(resp);//set form data
            this.documentReferance.sectionid = resp.tD_SECTION_ID == null ? null : resp.tD_SECTION_ID.toString();
        }, error => {
            let msg = `Error fetching ${this.screenName} `;
            console.error(msg, error);
            this.alerts.error(msg);
        });
    }

    private setFormData(frmData: TransplantInfo) {
        this.transplantInfoForm.patchValue(frmData);
        this.txOrganData.splice(0, this.txOrganData.length);
        this.txOrganData = TransplantInfoFormHelper.mapParentToChild(frmData);
    }

    onSave() {

        this.transplantInfoForm.get(TransplantInfoFields.tD_ACAT_ID).setValue(this.documentReferance.alocid);
        this.transplantInfoForm.get(TransplantInfoFields.tD_SECTION_ID).setValue(this.documentReferance.sectionid);

        if (this.transplantInfoForm.invalid) {
            console.error('parent form invalid');
            return;
        }

        // check transpalnt rows are valid
        let isChildFormsValid = true;
        let childFormData = new Array<HadTransplant>();
        this.txOrganRows.forEach(r => {
            r.txCntrlForm.markAllAsTouched();
            r.txCntrlForm.updateValueAndValidity();
            if (isChildFormsValid && r.txCntrlForm.invalid) {
                isChildFormsValid = false;
            }
            childFormData.push(r.getFormData);
        });

        if (!isChildFormsValid) {
            console.error('child form(s) invalid');
            return;
        }
        //console.log('child form data ', childFormData);

        let parentFormData = this.transplantInfoForm.value as TransplantInfo;
        //console.log('parent form data ', this.transplantInfoForm.value);

        let updatedParentData = TransplantInfoFormHelper.mapDataChildToParent(parentFormData, childFormData);

        this.sectiondService.saveTransplantInfo(updatedParentData).subscribe(resp => {
            this.alerts.success(`${this.screenName} Saved`);
            this.loadData();
        }, error => {
            console.error('Error saving', error);
            this.alerts.error('Error saving the form');
        });
    }

    onClear1() {
        this.transplantInfoForm.reset();
        this.txOrganRows.forEach(r => {
            r.clearForm();
        });
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.transplantInfoForm.reset();
        this.txOrganRows.forEach(r => {
          r.clearForm();
        });
      }
    });

  }



}
