import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionDRoutingModule } from './section-d-routing.module';
import { SectionDComponent } from './section-d.component';
import { PhysicianInfoComponent } from './physician-info/physician-info.component';
import { PharmacyInfoComponent } from './pharmacy-info/pharmacy-info.component';
import { DiagnosesInfoComponent } from './diagnoses-info/diagnoses-info.component';
import { MedicationAdminComponent } from './medication-admin/medication-admin.component';
import { AllergyInfoComponent } from './allergy-info/allergy-info.component';
import { SubstanceUseComponent } from './substance-use/substance-use.component';
import { TransplantInfoComponent } from './transplant-info/transplant-info.component';
import { IncontinenceInfoComponent } from './incontinence-info/incontinence-info.component';
import { SkilledNursingComponent } from './skilled-nursing/skilled-nursing.component';
import { SkilledRehabComponent } from './skilled-rehab/skilled-rehab.component';
import { AssessMedicalStabilityComponent } from './assess-medical-stability/assess-medical-stability.component';
import { EmergencyVisitComponent } from './emergency-visit/emergency-visit.component';
import { BackgroundInfoComponent } from './background-info/background-info.component';
import { MainSectionComponent } from './skilled-nursing/main-section/main-section.component';
import { AddNewPhysicianContactComponent } from './physician-info/add-new-physician-contact/add-new-physician-contact.component';
import { MedicationNewComponent } from './medication-admin/medication-new/medication-new.component';
import { ConfirmMedDeleteComponent } from './medication-admin/confirm-med-delete/confirm-med-delete.component';
import { HadTransplantCntrlComponent } from './transplant-info/had-transplant-cntrl/had-transplant-cntrl.component';
import { MedRefTblComponent } from './medication-admin/med-ref-tbl/med-ref-tbl.component';
import { MedTblComponent } from './medication-admin/med-tbl/med-tbl.component';
import { ConfirmDialogComponent } from '../shared/components/confirm-dialog/confirm-dialog.component';

@NgModule({
    declarations: [
        SectionDComponent,
        PhysicianInfoComponent,
        PharmacyInfoComponent,
        DiagnosesInfoComponent,
        MedicationAdminComponent,
        AllergyInfoComponent,
        SubstanceUseComponent,
        TransplantInfoComponent,
        IncontinenceInfoComponent,
        SkilledNursingComponent,
        SkilledRehabComponent,
        AssessMedicalStabilityComponent,
        EmergencyVisitComponent,
        BackgroundInfoComponent,
        MainSectionComponent,
        AddNewPhysicianContactComponent,
        MedicationNewComponent,
        ConfirmMedDeleteComponent,
        HadTransplantCntrlComponent,
        MedRefTblComponent,
        MedTblComponent],
    imports: [
        CommonModule,
        SectionDRoutingModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    entryComponents: [AddNewPhysicianContactComponent, MedicationNewComponent, ConfirmMedDeleteComponent, ConfirmDialogComponent]
})
export class SectionDModule { }
