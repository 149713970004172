import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionKRoutingModule } from './section-K-routing.module';
import { SectionKComponent } from './section-k.component';
import { HomeAssessComponent } from './home-assess/home-assess.component';
import { HomeSafetyComponent } from './home-safety/home-safety.component';
import { MedSafetyComponent } from './med-safety/med-safety.component';
import { EmergencyNumberComponent } from './emergency-number/emergency-number.component';

@NgModule({
  declarations: [SectionKComponent, HomeAssessComponent, HomeSafetyComponent, MedSafetyComponent, EmergencyNumberComponent],
  imports: [
    CommonModule,
    SectionKRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionKModule { }
