import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionAComponent } from './section-a.component';
import { AssessInfoComponent } from './assess-info/assess-info.component';
import { AssessParticipantsComponent } from './assess-participants/assess-participants.component';
import { InsuranceInfoComponent } from './insurance-info/insurance-info.component';
import { LanguageSpokenComponent } from './language-spoken/language-spoken.component';
import { LivingSituationComponent } from './living-situation/living-situation.component';
import { VeteranStatusComponent } from './veteran-status/veteran-status.component';

const routes: Routes = [
  {
    path: '',
    component: SectionAComponent,
    children: [
      {
        path: 'assess-info',
        component: AssessInfoComponent
      },
      {
        path: 'assess-participants',
        component: AssessParticipantsComponent
      },
      {
        path: 'insurance-info',
        component: InsuranceInfoComponent
      },
      {
        path: 'language-spoken',
        component: LanguageSpokenComponent
      },
      {
        path: 'living-situation',
        component : LivingSituationComponent
      },
      {
        path: 'veteran-status',
        component: VeteranStatusComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionARoutingModule { }
