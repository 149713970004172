import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionBService, AlertsService, DataService, UserService} from '../../shared/index';
import { GetContacts } from '../../shared';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MatDialogConfig ,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddNewContactComponent } from './add-new-contact/add-new-contact.component'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-individual-relation',
  templateUrl: './individual-relation.component.html',
  styleUrls: ['./individual-relation.component.css']
})
export class IndividualRelationComponent implements OnInit {
  individualRelationFrm: FormGroup;
  individualContacts: any;
  newACATID: any;
  changeContactInfo: string;
  showContactInfo: boolean;
  showContactInfoGrid: boolean;  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  displayedColumns: string[] = ['VIEW', 'EDITE', 'DELETE', 'TB_PRIMARY_IND', 'TB_FIRST_NAME', 'TB_INIT', 'TB_LAST_NAME', 'TB_EMAIL', 'TB_ADDRESS', 'TB_ADDRESS2', 'TB_CITY', 'TB_STATE', 'TB_ZIP_CODE', 'TB_COUNTY', 'TB_PHONE_HOME', 'TB_PHONE_MOBILE','TB_PHONE_WORK'];  

  constructor(private fb: FormBuilder,    
    public alertsService: AlertsService,
    public dataService: DataService,
    public router: Router,  
    public userService: UserService,
    public sectionBService: SectionBService,
    public confirmDialog: MatDialog
  ) {
    this.individualRelationFrm = this.fb.group({

      tB_SECTION_ID: new FormControl(null),   
      tB_ACAT_ID: new FormControl(''),   
      tB_CONTACTS_IND: new FormControl(''),   
      tB_PRIMARY_IND: new FormControl(''),   
      tB_FIRST_NAME: new FormControl(''),   
      tB_INIT: new FormControl(''),   
      tB_LAST_NAME: new FormControl(''),   
      tB_EMAIL: new FormControl(''),   
      tB_ADDRESS: new FormControl(''),   
      tB_ADDRESS2: new FormControl(''),   
      tB_CITY: new FormControl(''),   
      tB_STATE: new FormControl(''),   
      tB_ZIP_CODE: new FormControl(''),   
      tB_COUNTY: new FormControl(''),   
      tB_PHONE_HOME: new FormControl(''),   
      tB_PHONE_MOBILE: new FormControl(''),   
      tB_PHONE_WORK: new FormControl(''),   
      tB_TYPE_CHILD: new FormControl(''),   
      tB_TYPE_GRANDPARENT: new FormControl(''),   
      tB_TYPE_OTHER_RELATIVE: new FormControl(''),   
      tB_TYPE_PARENT: new FormControl(''),   
      tB_TYPE_SIBLING: new FormControl(''),   
      tB_TYPE_SIGNIFICANT_OTHER: new FormControl(''),   
      tB_TYPE_SPOUSE: new FormControl(''),   
      tB_TYPE_FRIEND: new FormControl(''),   
      tB_TYPE_NEIGHBOR: new FormControl(''),   
      tB_TYPE_ROOMMATE: new FormControl(''),   
      tB_TYPE_DORMITORY_RA: new FormControl(''),   
      tB_TYPE_GROUP_HOME_STAFF: new FormControl(''),   
      tB_TYPE_HOSPITAL_DC_PLANNER: new FormControl(''),   
      tB_TYPE_PRISON_SW: new FormControl(''),   
      tB_TYPE_SCHOOL_STAFF: new FormControl(''),   
      tB_TYPE_CLINICAL_STAFF: new FormControl(''),   
      tB_TYPE_HALFWAY_HOUSE_STAFF: new FormControl(''),   
      tB_TYPE_ICF_IID_FAC_STAFF: new FormControl(''),   
      tB_TYPE_NURSING_FAC_STAFF: new FormControl(''),   
      tB_TYPE_CDJFS_CASE_WORKER: new FormControl(''),   
      tB_TYPE_SOCIAL_PROGRAM: new FormControl(''),   
      tB_TYPE_OTHER: new FormControl(''),   
      tB_TYPE_OTHER_DESC: new FormControl(''),   
      tB_REL_PRIMARY_CAREGIVER: new FormControl(''),   
      tB_REL_CAREGIVER: new FormControl(''),   
      tB_REL_AUTHORIZED_REP: new FormControl(''),   
      tB_REL_REPRESENTATIVE_PAYEE: new FormControl(''),   
      tB_REL_POA_FINANCIAL: new FormControl(''),   
      tB_REL_POA_HEALTHCARE: new FormControl(''),   
      tB_REL_POA_EDUCATIONAL: new FormControl(''),   
      tB_REL_POA_OTHER: new FormControl(''),   
      tB_REL_LG_PLACEMENT: new FormControl(''),   
      tB_REL_LG_PERSON: new FormControl(''),   
      tB_REL_LG_ESTATE: new FormControl(''),   
      tB_REL_LG_PERSON_AND_ESTATE: new FormControl(''),   
      tB_REL_SOCIAL_SUPPORT: new FormControl(''),   
      tB_REL_OTHER: new FormControl(''),   
      tB_REL_OTHER_DESC: new FormControl(''),   
      tB_HIPAA_AUTH: new FormControl(''),   
      tB_LIVES_WITH_IND: new FormControl(''),   
      tB_LIVES_WITH_IND_COMMENT: new FormControl(''),   
      tB_AGE: new FormControl(null),   
      tB_SERVICES_IND: new FormControl(''),   
      tB_SERVICES_COMMENT: new FormControl(''),   
      tB_PMT_MEDICARE: new FormControl(''), 
      tB_PMT_MEDICAID: new FormControl(''),  
      tB_PMT_MEDICAID_WAIVER: new FormControl(''),  
      tB_PMT_COUNTY_BOARD_OF_DD: new FormControl(''),  
      tB_PMT_BEHAV_HEALTH_PROG: new FormControl(''),  
      tB_PMT_MEDICAID_MANAGEDCARE: new FormControl(''),  
      tB_PMT_MEDICAID_MYCARE_OHIO: new FormControl(''),  
      tB_PMT_DEPT_VETERAN_AFFAIR: new FormControl(''),  
      tB_PMT_OTHER: new FormControl(''),  
      tB_PMT_OTHER_DESC: new FormControl(''),  
      tB_BEST_CONTACT_TIME: new FormControl(''),  
      tB_COMMENTS: new FormControl('') 

     
    });
  }

  ngOnInit() {

    this.newACATID = this.dataService.getSelectedDocument();


    this.sectionBService.LoadContacts(this.newACATID).subscribe(response => {
      
      this.individualContacts = new MatTableDataSource<GetContacts>(response);
     
      this.individualContacts.paginator = this.paginator;     
      if (response.length != 0) {
        this.individualRelationFrm.get('tB_CONTACTS_IND').setValue(response[0].tB_CONTACTS_IND);
        if (response[0].tB_CONTACTS_IND === "Y") {
          this.showContactInfo = false;
          this.showContactInfoGrid = false;          
        } else {
          this.showContactInfo = true;
          this.showContactInfoGrid = true;          
        }
      } else {
        this.showContactInfo = true;
        this.showContactInfoGrid = true;      
      }    
     
      this.alertsService.success("Individual's Relations Loaded");
      
    }, err => {
      this.alertsService.error(err);
      });

    this.alertsService.clearLoader();

  }
  individualRelationSave() {

    if (this.individualRelationFrm.get('tB_CONTACTS_IND').value == null || this.individualRelationFrm.get('tB_CONTACTS_IND').value == '') {
      this.individualRelationFrm.get('tB_CONTACTS_IND').setValidators([Validators.required]);
      this.individualRelationFrm.get('tB_CONTACTS_IND').setErrors({ 'required': true });
    }

    this.newACATID = this.dataService.getSelectedDocument();
    if (this.newACATID != null) {
      this.individualRelationFrm.get('tB_ACAT_ID').setValue(this.newACATID);

      if (!this.individualRelationFrm.valid) {
        for (let cnt in this.individualRelationFrm.controls) {
          if (this.individualRelationFrm.controls[cnt].invalid) {
            console.log(cnt, this.individualRelationFrm.controls[cnt]);
          }
        }
        return;
      }
      let individualRelationData = this.individualRelationFrm.value;

      this.sectionBService.LoadContacts(this.newACATID).subscribe(response => {

        this.individualContacts = new MatTableDataSource<GetContacts>(response);
     

        if (individualRelationData['tB_CONTACTS_IND'] == 'N' && response.length != 0 && response[0].tB_LAST_NAME != null) {
         // this.alertsService.error('Having contact(s), please select correct option');
          this.warningAlert('Having contact(s), please select correct option');
          this.alertsService.clearLoader();
          return;
        } else {
          if (individualRelationData['tB_CONTACTS_IND'] == 'Y' && response.length == 0 ) {
           // this.alertsService.error('No contact(s) added, please select correct option');
            this.warningAlert('No contact(s) added, please select correct option');
            this.alertsService.clearLoader();
            return;
          }
          else {

            if (individualRelationData['tB_CONTACTS_IND'] == 'Y' && response.length != 0 && response[0].tB_LAST_NAME == null) {
             // this.alertsService.error('No contact(s) added, please select correct option');
              this.warningAlert('No contact(s) added, please select correct option');
              this.alertsService.clearLoader();
              return;

            } else {
              // Save Section B.
              this.sectionBService.PostSectionB(individualRelationData).subscribe(resp => {
                this.gridRefresh();
                this.alertsService.success("Individual's Relations Saved");
              }, error => {
                  console.error('Error Saving Individuals Relations', error);
                  this.alertsService.error("Error Saving Individual's Relations");
              });
            }

          }
        }       

      }, err => {
        this.alertsService.error(err);
        });


    }
  

  }
  changeContactInformation() {

    this.changeContactInfo = this.individualRelationFrm.get('tB_CONTACTS_IND').value;
    this.newACATID = this.dataService.getSelectedDocument();

    if (this.changeContactInfo === "Y") {

      this.sectionBService.getContacts(this.newACATID).subscribe(response => {
        if (response.tB_FIRST_NAME != null) {
          this.showContactInfoGrid = false;
        } else {

          this.showContactInfoGrid = true;
        }
        this.alertsService.clearLoader();
      }, err => {
        this.alertsService.error(err);
        });

      this.showContactInfo = false;

    } else {
      this.showContactInfo = true;
      this.showContactInfoGrid = true;
    }
    this.alertsService.clearLoader();
  }
  onView(viewContact) {

    const dialogConfigView = new MatDialogConfig();
    dialogConfigView.data = { sectionID: viewContact.tB_SECTION_ID, acatID: viewContact.tB_ACAT_ID, mode: 'View' };
    let editDialog = this.confirmDialog.open(AddNewContactComponent, dialogConfigView);   
    editDialog.afterClosed().subscribe(result => {
      if (result == "success")
        this.gridRefresh();
    });

  }
  onEdit(editContact) {

    const dialogConfigEdit = new MatDialogConfig();
    dialogConfigEdit.data = { sectionID: editContact.tB_SECTION_ID, acatID: editContact.tB_ACAT_ID, mode: 'Edit'};
    let editDialog = this.confirmDialog.open(AddNewContactComponent, dialogConfigEdit);

    editDialog.afterClosed().subscribe(result => {
      if (result == "success")
        this.gridRefresh();
    });

  }
  
  onDelete(delContact) {

    Swal.fire({
      title: 'Confirm Delete',
      text: "Are you sure want to delete?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {

        this.sectionBService.DeleteContact(delContact.tB_SECTION_ID).subscribe(resp => {
          this.gridRefresh();

          this.alertsService.success("Contact Deleted");
        }, error => {
          console.error('Error Deleting Contact', error);
          this.alertsService.error("Error Deleting Contact");
        });
      }
    });

    this.alertsService.clearLoader();

  }
  gridRefresh() {

    this.newACATID = this.dataService.getSelectedDocument();
    this.sectionBService.LoadContacts(this.newACATID).subscribe(response => {
      this.individualContacts = new MatTableDataSource<GetContacts>(response);
      this.individualContacts.paginator = this.paginator;  
     // this.individualContacts = response;     
      if (response.length != 0) {
        this.individualRelationFrm.get('tB_CONTACTS_IND').setValue(response[0].tB_CONTACTS_IND);
        if (response[0].tB_CONTACTS_IND === "Y") {
          this.showContactInfo = false;
          this.showContactInfoGrid = false;
        } else {
          this.individualRelationFrm.get('tB_CONTACTS_IND').setValue(response[0].tB_CONTACTS_IND);
          this.showContactInfo = true;
          this.showContactInfoGrid = true;
        }
      } else {
        this.individualRelationFrm.get('tB_CONTACTS_IND').setValue(null);
        this.showContactInfo = true;
        this.showContactInfoGrid = true;
      }
      
      this.alertsService.clearLoader();
    }, err => {
      this.alertsService.error(err);
    });
   
  }
  clearindividualRelationFrm() {
    this.onClear();   
  }
 
  newContact() {

    const dialogConfigNew = new MatDialogConfig();
    dialogConfigNew.data = { mode: 'New' };
    let editDialog = this.confirmDialog.open(AddNewContactComponent, dialogConfigNew);

    editDialog.afterClosed().subscribe(result => {
      if (result == "success")
        this.gridRefresh();
    });

  }
  warningAlert(msg) {
    Swal.fire(
      '',
      msg,
      'warning'
    )
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.individualRelationFrm.reset();
      }
    });

  }
  applyFilter = (filterTxt: string) => this.individualContacts.filter = filterTxt;
}
