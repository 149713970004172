import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionAService, AlertsService, DataService, UserService, getTextForDropdownValues, SectionA, SectionDropdownService } from '../../shared/index';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-veteran-status',
  templateUrl: './veteran-status.component.html',
  styleUrls: ['./veteran-status.component.css']
})
export class VeteranStatusComponent implements OnInit {
  veteranStatusForm: FormGroup;
  veteranStatus: any[];
  veteranDuty: any[];
  veteranActiveDuty: any[];
  veteranStatusWar: any[];
  veteranDiagonesed: any[];
  show_TA_VET_OTHER_DESC = false;
  show_TA_VET_ACTIVATED_WAR = false;
  hide_VET_Served = false;
  newACATID: any;
  dropDownSelectText = getTextForDropdownValues;
  constructor(private fb: FormBuilder,
    private sectionAService: SectionAService,
    private alertsService: AlertsService,
    public dataService: DataService,
    private router: Router,
    private dropdownservice: SectionDropdownService,
    private userService: UserService) {
    this.veteranStatusForm = this.fb.group({
      tA_ACAT_ID: new FormControl(''),
      tA_VET_SERVICE: new FormControl('', [Validators.required]),
      tA_VET_ACTIVE_Option: new FormControl(''),
      tA_VET_ACTIVE: new FormControl(''),
      tA_VET_NAT_GUARD: new FormControl(''),
      tA_VET_RESERVES: new FormControl(''),
      tA_VET_RETIRED: new FormControl(''),
      tA_VET_OTHER: new FormControl(''),
      tA_VET_OTHER_DESC: new FormControl(''),
      tA_VET_ACTIVATED: new FormControl(''),
      // tA_VET_ACTIVATED_WAR, not in table. using for show dropdown
      tA_VET_ACTIVATED_WAR: new FormControl(''),      
      tA_VET_ACTIVATED_WWI: new FormControl(''),
      tA_VET_ACTIVATED_WWII: new FormControl(''),
      tA_VET_ACTIVATED_KOREA: new FormControl(''),
      tA_VET_ACTIVATED_VIETNAM: new FormControl(''),
      tA_VET_ACTIVATED_GULF: new FormControl(''),     
      tA_VET_ACTIVATED_OTHER: new FormControl(''),
      tA_VET_ACTIVATED_UNSURE: new FormControl(''),
      tA_VA_HEALTHCARE_REGISTERED: new FormControl(''),
      tA_VA_HEALTHCARE_RECEIVED: new FormControl(''),
      tA_VA_HEALTHCARE_CLAIM: new FormControl(''),
      tA_DIAG_AL_AMYLOIDOSIS: new FormControl(''),
      tA_DIAG_B_CELL_LEUKEMIA: new FormControl(''),
      tA_DIAG_CHLORACNE: new FormControl(''),
      tA_DIAG_DIABETES_TYPE2: new FormControl(''),
      tA_DIAG_HODGKINS: new FormControl(''),
      tA_DIAG_HEART_DISEASE: new FormControl(''),
      tA_DIAG_MULTIPLE_MYELOMA: new FormControl(''),
      tA_DIAG_NONHODGKINS_LYMPH: new FormControl(''),
      tA_DIAG_PARKINSONS: new FormControl(''),
      tA_DIAG_RESPIRATORY_CANCERS: new FormControl(''),
      tA_DIAG_PORPHYRIA: new FormControl(''),
      tA_DIAG_PROSTATE_CANCER: new FormControl(''),
      tA_DIAG_SOFT_TISSUE_SARCOMA: new FormControl(''),
      tA_DIAG_PERIPH_NEUROPATHY: new FormControl(''),
      tA_DIAG_LOU_GEHRIGS: new FormControl(''),
      tA_DIAG_NONE: new FormControl(''),
      tA_DIAG: new FormControl('')
    });
  }

  ngOnInit() {

    this.newACATID = this.dataService.getSelectedDocument();
    if (this.newACATID != null) {
      this.sectionAService.getSectionA(this.newACATID).subscribe(response => {
        this.receiveVeteranStatus(response);
        this.alertsService.success("Veteran Status Loaded");

      }, err => {
        this.alertsService.error(err);
      });
    }
    this.loadDropdownService();
    this.configDynValidations();
  }
  receiveVeteranStatus(data) {
    this.veteranStatusForm.patchValue(data);
    this.setFormData(data);
  }
  loadDropdownService() {

    this.dropdownservice.GetDropDownValues('VETERAN_STATUS').subscribe((data: any[]) => {
      this.veteranStatus = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    this.dropdownservice.GetDropDownValues('VETERAN_DUTY').subscribe((data: any[]) => {
      this.veteranDuty = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    this.dropdownservice.GetDropDownValues('ACTIVE_DUTY').subscribe((data: any[]) => {
      this.veteranActiveDuty = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    this.dropdownservice.GetDropDownValues('VETERAN_STATUS_WAR').subscribe((data: any[]) => {
      this.veteranStatusWar = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    this.dropdownservice.GetDropDownValues('VETERAN_DIAG').subscribe((data: any[]) => {
      this.veteranDiagonesed = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });
  }
  veteranStatusSave() {

    this.veteranStatusForm.get('tA_ACAT_ID').setValue(this.dataService.getSelectedDocument());

    if (!this.veteranStatusForm.valid) {
      for (let cnt in this.veteranStatusForm.controls) {
        if (this.veteranStatusForm.controls[cnt].invalid) {
          console.log(cnt, this.veteranStatusForm.controls[cnt]);
        }
      }
      return;
    }

    this.veteranStatusForm.get('tA_VET_ACTIVE').setValue(null);
    this.veteranStatusForm.get('tA_VET_NAT_GUARD').setValue(null);
    this.veteranStatusForm.get('tA_VET_RESERVES').setValue(null);
    this.veteranStatusForm.get('tA_VET_RETIRED').setValue(null);
    this.veteranStatusForm.get('tA_VET_OTHER').setValue(null);

    let veteranActiveStatus: [] = this.veteranStatusForm.get('tA_VET_ACTIVE_Option').value;

    if (Array.isArray(veteranActiveStatus)) {
      veteranActiveStatus.forEach(value => {
        switch (value) {
          case 'ACTIVE':
            this.veteranStatusForm.get('tA_VET_ACTIVE').setValue('Y');
            break;
          case 'GUARD':
            this.veteranStatusForm.get('tA_VET_NAT_GUARD').setValue('Y');
            break;
          case 'RESERVES':
            this.veteranStatusForm.get('tA_VET_RESERVES').setValue('Y');
            break;
          case 'RETIRED':
            this.veteranStatusForm.get('tA_VET_RETIRED').setValue('Y');
            break;
          case 'OTHER':
            this.veteranStatusForm.get('tA_VET_OTHER').setValue('Y');
            break;
          default:
            break;
        }
      });
    }
    if (this.veteranStatusForm.get('tA_VET_OTHER').value != 'Y') {
      this.veteranStatusForm.get('tA_VET_OTHER_DESC').setValue(null);
    }

    //if (this.veteranStatusForm.get('tA_VET_ACTIVATED').value != 'OTHER') {
    //  this.veteranStatusForm.get('tA_LANG_PREFERRED_DESC').setValue(null);
    //}

    this.veteranStatusForm.get('tA_VET_ACTIVATED_WWI').setValue(null);
    this.veteranStatusForm.get('tA_VET_ACTIVATED_WWII').setValue(null);
    this.veteranStatusForm.get('tA_VET_ACTIVATED_KOREA').setValue(null);
    this.veteranStatusForm.get('tA_VET_ACTIVATED_VIETNAM').setValue(null);
    this.veteranStatusForm.get('tA_VET_ACTIVATED_GULF').setValue(null);
    this.veteranStatusForm.get('tA_VET_ACTIVATED_OTHER').setValue(null);
    this.veteranStatusForm.get('tA_VET_ACTIVATED_UNSURE').setValue(null);

    let veteranWarStatus: [] = this.veteranStatusForm.get('tA_VET_ACTIVATED_WAR').value;

    if (Array.isArray(veteranWarStatus)) {
      veteranWarStatus.forEach(value => {
        switch (value) {
          case 'WWI':
            this.veteranStatusForm.get('tA_VET_ACTIVATED_WWI').setValue('Y');
            break;
          case 'WWII':
            this.veteranStatusForm.get('tA_VET_ACTIVATED_WWII').setValue('Y');
            break;
          case 'KOREAN':
            this.veteranStatusForm.get('tA_VET_ACTIVATED_KOREA').setValue('Y');
            break;
          case 'VIETNAM':
            this.veteranStatusForm.get('tA_VET_ACTIVATED_VIETNAM').setValue('Y');
            break;
          case 'GULF':
            this.veteranStatusForm.get('tA_VET_ACTIVATED_GULF').setValue('Y');
            break;
          case 'PERIOD':
            this.veteranStatusForm.get('tA_VET_ACTIVATED_OTHER').setValue('Y');
            break;
          case 'UNSURE':
            this.veteranStatusForm.get('tA_VET_ACTIVATED_UNSURE').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    
    this.veteranStatusForm.get('tA_DIAG_AL_AMYLOIDOSIS').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_B_CELL_LEUKEMIA').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_CHLORACNE').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_DIABETES_TYPE2').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_HODGKINS').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_HEART_DISEASE').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_MULTIPLE_MYELOMA').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_NONHODGKINS_LYMPH').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_PARKINSONS').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_RESPIRATORY_CANCERS').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_PORPHYRIA').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_PROSTATE_CANCER').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_SOFT_TISSUE_SARCOMA').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_PERIPH_NEUROPATHY').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_LOU_GEHRIGS').setValue(null);
    this.veteranStatusForm.get('tA_DIAG_NONE').setValue(null);

    let veteranDiagnosedStatus: [] = this.veteranStatusForm.get('tA_DIAG').value;

    if (Array.isArray(veteranDiagnosedStatus)) {
      veteranDiagnosedStatus.forEach(value => {
        switch (value) {
          case 'AMYLOIDOSI':
            this.veteranStatusForm.get('tA_DIAG_AL_AMYLOIDOSIS').setValue('Y');
            break;
          case 'LEUKEMIA':
            this.veteranStatusForm.get('tA_DIAG_B_CELL_LEUKEMIA').setValue('Y');
            break;
          case 'CHLORACNE':
            this.veteranStatusForm.get('tA_DIAG_CHLORACNE').setValue('Y');
            break;
          case 'DIABETES':
            this.veteranStatusForm.get('tA_DIAG_DIABETES_TYPE2').setValue('Y');
            break;
          case 'HODGKIN':
            this.veteranStatusForm.get('tA_DIAG_HODGKINS').setValue('Y');
            break;
          case 'DISEASE':
            this.veteranStatusForm.get('tA_DIAG_HEART_DISEASE').setValue('Y');
            break;
          case 'MYELOMA':
            this.veteranStatusForm.get('tA_DIAG_MULTIPLE_MYELOMA').setValue('Y');
            break;
          case 'LYMPHOMA':
            this.veteranStatusForm.get('tA_DIAG_NONHODGKINS_LYMPH').setValue('Y');
            break;
          case 'PARKINSONS':
            this.veteranStatusForm.get('tA_DIAG_PARKINSONS').setValue('Y');
            break;
          case 'RESPIRATOR':
            this.veteranStatusForm.get('tA_DIAG_RESPIRATORY_CANCERS').setValue('Y');
            break;
          case 'PORPHYRIA':
            this.veteranStatusForm.get('tA_DIAG_PORPHYRIA').setValue('Y');
            break;
          case 'PROSTATE':
            this.veteranStatusForm.get('tA_DIAG_PROSTATE_CANCER').setValue('Y');
            break;
          case 'SARCOMA':
            this.veteranStatusForm.get('tA_DIAG_SOFT_TISSUE_SARCOMA').setValue('Y');
            break;
          case 'NEUROPATHY':
            this.veteranStatusForm.get('tA_DIAG_PERIPH_NEUROPATHY').setValue('Y');
            break;
          case 'LOUG':
            this.veteranStatusForm.get('tA_DIAG_LOU_GEHRIGS').setValue('Y');
            break;
          case 'NONE':
            this.veteranStatusForm.get('tA_DIAG_NONE').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    let veteranStatusData = this.veteranStatusForm.value;

    this.sectionAService.PostVeteranStatus(veteranStatusData).subscribe(resp => {
      this.alertsService.success("Veteran Status Saved");
    }, error => {
        console.error('Error Saving Veteran Status', error);
        this.alertsService.error("Error Saving Veteran Status");
      });

  }
  clearVeteranStatus() {
    this.onClear();    
  }
  configDynValidations() {

    this.veteranStatusForm.get('tA_VET_ACTIVE_Option').valueChanges.subscribe(value => {
      if (value.indexOf('OTHER') > -1) {
     // if (value == 'OTHER') {
        this.show_TA_VET_OTHER_DESC = true;
        this.veteranStatusForm.get('tA_VET_OTHER_DESC').setValidators([Validators.required]);
        this.veteranStatusForm.get('tA_VET_OTHER_DESC').markAsTouched();
      } else {
        this.show_TA_VET_OTHER_DESC = false;
        this.veteranStatusForm.get('tA_VET_OTHER_DESC').clearValidators();
        this.veteranStatusForm.get('tA_VET_OTHER_DESC').setValue(null);
      }
      this.veteranStatusForm.get('tA_VET_OTHER_DESC').updateValueAndValidity();
    });

    this.veteranStatusForm.get('tA_VET_ACTIVATED').valueChanges.subscribe(value => {
      if (value != 'NO') {
        this.show_TA_VET_ACTIVATED_WAR = true;
       // this.veteranStatusForm.get('tA_VET_ACTIVATED_WAR').setValidators([Validators.required]);
      } else {
        this.show_TA_VET_ACTIVATED_WAR = false;
      //  this.veteranStatusForm.get('tA_VET_ACTIVATED_WAR').clearValidators();
        this.veteranStatusForm.get('tA_VET_ACTIVATED_WAR').setValue(null);
      }
    });

    this.veteranStatusForm.get('tA_VET_SERVICE').valueChanges.subscribe(value => {
      if (value != 'NO') {
        this.hide_VET_Served = true;
        this.show_TA_VET_OTHER_DESC = false;
      } else {
        this.hide_VET_Served = false;

        this.veteranStatusForm.get('tA_VET_ACTIVE').setValue(null);
        this.veteranStatusForm.get('tA_VET_NAT_GUARD').setValue(null);
        this.veteranStatusForm.get('tA_VET_RESERVES').setValue(null);
        this.veteranStatusForm.get('tA_VET_RETIRED').setValue(null);
        this.veteranStatusForm.get('tA_VET_OTHER').setValue(null);
       // this.veteranStatusForm.get('tA_VET_ACTIVE_Option').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVE_Option').patchValue([]);
        this.veteranStatusForm.get('tA_VET_OTHER_DESC').setValue(null);        
        this.veteranStatusForm.get('tA_VET_ACTIVATED').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVATED_WWI').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVATED_WWII').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVATED_KOREA').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVATED_VIETNAM').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVATED_GULF').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVATED_OTHER').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVATED_UNSURE').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVATED_WAR').setValue(null);
        this.veteranStatusForm.get('tA_VA_HEALTHCARE_REGISTERED').setValue(null);
        this.veteranStatusForm.get('tA_VA_HEALTHCARE_RECEIVED').setValue(null);
        this.veteranStatusForm.get('tA_VA_HEALTHCARE_CLAIM').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_AL_AMYLOIDOSIS').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_B_CELL_LEUKEMIA').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_CHLORACNE').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_DIABETES_TYPE2').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_HODGKINS').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_HEART_DISEASE').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_MULTIPLE_MYELOMA').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_NONHODGKINS_LYMPH').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_PARKINSONS').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_RESPIRATORY_CANCERS').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_PORPHYRIA').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_PROSTATE_CANCER').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_SOFT_TISSUE_SARCOMA').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_PERIPH_NEUROPATHY').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_LOU_GEHRIGS').setValue(null);
        this.veteranStatusForm.get('tA_DIAG_NONE').setValue(null);
        this.veteranStatusForm.get('tA_DIAG').setValue(null);
        this.veteranStatusForm.get('tA_VET_ACTIVATED_WAR').clearValidators();
        this.veteranStatusForm.get('tA_VET_OTHER_DESC').clearValidators();
        this.veteranStatusForm.get('tA_VET_OTHER_DESC').updateValueAndValidity();
        
      }
    });
  }
  setFormData(data) {

    let VeteranStatus: string[] = [];
    if (data['tA_VET_ACTIVE'] == 'Y') {
      VeteranStatus.push('ACTIVE');
    }
    if (data['tA_VET_NAT_GUARD'] == 'Y') {
      VeteranStatus.push('GUARD');
    }
    if (data['tA_VET_RESERVES'] == 'Y') {
      VeteranStatus.push('RESERVES');
    }
    if (data['tA_VET_RETIRED'] == 'Y') {
      VeteranStatus.push('RETIRED');
    }    
    if (data['tA_VET_OTHER'] == 'Y') {
      VeteranStatus.push('OTHER');
      this.show_TA_VET_OTHER_DESC = true;
    }
    else {
      this.show_TA_VET_OTHER_DESC = false;
    }
    this.veteranStatusForm.get('tA_VET_ACTIVE_Option').setValue(VeteranStatus);

    let status_In_War: string[] = [];
    if (data['tA_VET_ACTIVATED_WWI'] == 'Y') {
      status_In_War.push('WWI');
    }
    if (data['tA_VET_ACTIVATED_WWII'] == 'Y') {
      status_In_War.push('WWII');
    }
    if (data['tA_VET_ACTIVATED_KOREA'] == 'Y') {
      status_In_War.push('KOREAN');
    }
    if (data['tA_VET_ACTIVATED_VIETNAM'] == 'Y') {
      status_In_War.push('VIETNAM');
    }
    if (data['tA_VET_ACTIVATED_GULF'] == 'Y') {
      status_In_War.push('GULF');
     
    }
    if (data['tA_VET_ACTIVATED_OTHER'] == 'Y') {
      status_In_War.push('PERIOD');

    }
    if (data['tA_VET_ACTIVATED_UNSURE'] == 'Y') {
      status_In_War.push('UNSURE');

    }
    this.veteranStatusForm.get('tA_VET_ACTIVATED_WAR').setValue(status_In_War);

    let status_Diagnosed: string[] = [];
    if (data['tA_DIAG_AL_AMYLOIDOSIS'] == 'Y') {
      status_Diagnosed.push('AMYLOIDOSI');
    }
    if (data['tA_DIAG_B_CELL_LEUKEMIA'] == 'Y') {
      status_Diagnosed.push('LEUKEMIA');
    }
    if (data['tA_DIAG_CHLORACNE'] == 'Y') {
      status_Diagnosed.push('CHLORACNE');
    }
    if (data['tA_DIAG_DIABETES_TYPE2'] == 'Y') {
      status_Diagnosed.push('DIABETES');
    }
    if (data['tA_DIAG_HODGKINS'] == 'Y') {
      status_Diagnosed.push('HODGKIN');
    }
    if (data['tA_DIAG_HEART_DISEASE'] == 'Y') {
      status_Diagnosed.push('DISEASE');
    }
    if (data['tA_DIAG_MULTIPLE_MYELOMA'] == 'Y') {
      status_Diagnosed.push('MYELOMA');
    }
    if (data['tA_DIAG_NONHODGKINS_LYMPH'] == 'Y') {
      status_Diagnosed.push('LYMPHOMA');
    }
    if (data['tA_DIAG_PARKINSONS'] == 'Y') {
      status_Diagnosed.push('PARKINSONS');
    }
    if (data['tA_DIAG_RESPIRATORY_CANCERS'] == 'Y') {
      status_Diagnosed.push('RESPIRATOR');
    }
    if (data['tA_DIAG_PORPHYRIA'] == 'Y') {
      status_Diagnosed.push('PORPHYRIA');
    }
    if (data['tA_DIAG_PROSTATE_CANCER'] == 'Y') {
      status_Diagnosed.push('PROSTATE');
    }
    if (data['tA_DIAG_SOFT_TISSUE_SARCOMA'] == 'Y') {
      status_Diagnosed.push('SARCOMA');
    }
    if (data['tA_DIAG_PERIPH_NEUROPATHY'] == 'Y') {
      status_Diagnosed.push('NEUROPATHY');
    }
    if (data['tA_DIAG_LOU_GEHRIGS'] == 'Y') {
      status_Diagnosed.push('LOUG');
    }
    if (data['tA_DIAG_NONE'] == 'Y') {
      status_Diagnosed.push('NONE');
    }

    this.veteranStatusForm.get('tA_DIAG').setValue(status_Diagnosed);

  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.show_TA_VET_OTHER_DESC = false;
        this.veteranStatusForm.get('tA_VET_OTHER_DESC').clearValidators();
        this.veteranStatusForm.reset({ tA_VET_ACTIVE_Option: this.veteranDuty });
      }
    });

  }
}
