import { Injectable } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    constructor(
        private apiService: ApiService) { }

    uploadFile(alocid, section, type, files: Set<File>): { [key: string]: { progress: Observable<number> } } {
        const status: { [key: string]: { progress: Observable<number> } } = {};

        files.forEach(file => {
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);
            formData.append('alocid', alocid);
            formData.append('section', section);
            formData.append('type', type);

            const progress = new Subject<number>();

            this.apiService.postFile('FileUpload/uploadFile', formData).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    progress.next(percentDone);
                } else if (event instanceof HttpResponse) {
                    progress.complete();
                }
            });//api call

            status[file.name] = {
                progress: progress.asObservable()
            };

        });//file foreach

        return status;
    }


    getDocumentTypes(): Observable<any> {
        return this.apiService.get('FileUpload/GetDocumentTypes')
    }

    getACATFiles(ACATid): Observable<any> {
        return this.apiService.getNoLoader(`FileUpload/GetALOCFiles/${ACATid}`);
    }

    uploadNewACATDocument(newdocs: {
        ACATid: string,
        type: string,
        files: Set<File>
    }): { [key: string]: { progress: Observable<number> } } {

        const status: { [key: string]: { progress: Observable<number> } } = {};
        newdocs.files.forEach(file => {

            const formData: FormData = new FormData();
            formData.append('file', file, file.name);
            formData.append('acatid', newdocs.ACATid);
            formData.append('type', newdocs.type);

            const progress = new Subject<number>();

            this.apiService.postFile('FileUpload/UploadNewDocument', formData).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    progress.next(percentDone);
                } else if (event instanceof HttpResponse) {
                    progress.complete();
                }
            });//api call

            status[file.name] = {
                progress: progress.asObservable()
            };

        });


        return status;

    }

    deleteDocument(fb_id): Observable<any> {
        return this.apiService.delete(`FileUpload/DeleteDocument/${fb_id}`);
    }

    downloadFile(fb_id): Observable<any> {
        return this.apiService.getFile(`FileUpload/DownloadDocument/${fb_id}`);
    }

    updateDocumentType(dbparams: { fb_id_in: number, fb_parent_id_in: number, fb_type_in: string }): Observable<any> {
        //console.log('dbparams ', dbparams);
        return this.apiService.post('FileUpload/UpdateDocumentType', dbparams);
    }

}
