import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Medication } from '../medication-admin';

@Component({
  selector: 'app-confirm-med-delete',
  templateUrl: './confirm-med-delete.component.html',
  styleUrls: ['./confirm-med-delete.component.css']
})
export class ConfirmMedDeleteComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public formOptions: {
        row: Medication
    },
        public dialogRef: MatDialogRef<ConfirmMedDeleteComponent>
    ) { }

  ngOnInit() {
  }
    onDelete() {
        this.dialogRef.close('delete');
    }

}
