import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material';
import { validateFreqOptions, transformToSKNMergeParams } from '../../common/skilled-nursing-common';
import { isNullOrUndefined, error } from 'util';
import { SKNRowType } from '../../common/SKNRowType';
import { SKNDynFormBuilder } from '../../common/SKNDynFormBuilder';
import { SKNDynFormFields } from '../../common/SNDynForm';
import { SectionDropdownService } from '../../../shared/services/section-dropdown.service';
import { AlertsService } from '../../../shared';
import { validateFutureDate } from '../../../shared/validators/AlocDateValidators';
import { SKNSectionFormType } from '../../common/SKNSectionFormType';
import { SKNDBMergeParam } from '../../common/SKNDBMergeParam';
import { debounceTime, delay } from 'rxjs/operators';
import { Subscription, timer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import * as ALOCValidators from '../../../shared/validators/AlocDateValidators';

@Component({
    selector: 'app-main-section',
    templateUrl: './main-section.component.html',
    styleUrls: ['./main-section.component.css']
})
export class MainSectionComponent implements OnInit, OnDestroy {
  tomorrow = new Date();
  myDate: Date;
    constructor(private dropdownservice: SectionDropdownService,
      private alerts: AlertsService,
      private route: ActivatedRoute) {
      this.tomorrow.setDate(this.tomorrow.getDate());
    }
 
    private _sectionData: SKNRowType;
    @Input()
    public set sectionData(value: SKNRowType) {
        this._sectionData = value;
    }

    public get sectionData(): SKNRowType {
        return this._sectionData;
    }

    dynamicFormValues: SKNDynFormFields[];

    sectionForm = new FormGroup({
        ACAT_ID: new FormControl(''),
        CATEGORY: new FormControl(''),
        SKILL_ID: new FormControl(''),
        NotApplicable: new FormControl(false),
        SkillLevel: new FormControl('', [Validators.required]),
      //FrequencyOptions: new FormControl('', [Validators.required]),
      FrequencyOptions: new FormControl(''),
        WeeklyOptions: new FormGroup({
            SUN: new FormControl(''),
            MON: new FormControl(''),
            TUE: new FormControl(''),
            WED: new FormControl(''),
            THU: new FormControl(''),
            FRI: new FormControl(''),
            SAT: new FormControl('')
        }),
        FrequencyOthers: new FormControl(''),
        Comments: new FormControl('')
    });

    @ViewChild(MatExpansionPanel, { static: true })
    sectionPanel: MatExpansionPanel;

    @ViewChild('sectionFormEle', { static: false })
    formElement: ElementRef;

    @ViewChild('FrequencyOtherstxt', { static: false })
    FrequencyOtherstxt: ElementRef;

    isRowEnabled: boolean;
    isFrequencyDisable: boolean;
    isFrqWeekly: boolean;
    isFrqOthers: boolean;
    isFormMode: boolean;
  isShowComment: boolean;
  strCommentTxt: string;
  
    sectionUpdateEventTimer: Subscription;
    _isSectionValid: boolean = true;
    get isSectionValid(): boolean {
    //  return this.sectionForm.valid && this.sectionForm.touched;
      return this.sectionForm.valid;
    }
    //set isSectionValid(value: boolean) {
    //    this._isSectionValid = value;
    //}

    @Output() sectionUpdated = new EventEmitter<SKNDBMergeParam>();

    sectionSelectedForNA: SKNSectionFormType;

    ngOnInit() {
        this.initForm();
      this.loadData();
      //Below code for Skilled Rehab page- hide "Independent" option
      if (this.route.snapshot.data.title == 'skilled-nursing') {
          //Below code for skilled-nursing page- hide "Independent" option for SEIZURE MANAGEMENT.
          if (this.sectionData.skilL_ID == 'SEIZURE MANAGEMENT') {
               this.isFormMode = false;
          } else {
              this.isFormMode = true;
        }
        
      } else {
        this.isFormMode = false;
      }

      //for hiding comment field for below categories.
      //if (this.sectionData.tdS_SKILL_ID == 'OTHERSR' || this.sectionData.tdS_SKILL_ID == 'OTHERSN') {
      //  this.isShowComment = false;
      //} else {
      //  this.isShowComment = true;
      //}

      //As per the PDF, SKIN category is having different lable text for Comments field. The below code is using for it.
      if (this.sectionData.tdS_SKILL_ID == 'SKIN') {
        this.strCommentTxt = 'Comments: (Adaptive/assistive equipment should be listed in the Equipment Section including lambskin, specialized mattress, etc.)';
      } else {
        this.strCommentTxt = 'Comments';
      }

        
    }

    ngOnDestroy(): void {
        this.sectionUpdateEventTimer.unsubscribe();
    }

    private initForm() {

        this.sectionForm.get('NotApplicable').valueChanges.subscribe(value => {

            this.sectionSelectedForNA = this.sectionForm.value as SKNSectionFormType;
            //console.log(this.sectionForm.value);
            if (value) {
                //this.resetForm();
                this.isRowEnabled = true;
                for (let cntrl in this.sectionForm.controls) {
                    if (cntrl == 'NotApplicable')
                        continue;
                    this.sectionForm.controls[cntrl].disable();
                }
                this.isFrequencyDisable = true;
                this.sectionPanel.close();
                this.sectionForm.get('SkillLevel').reset();
                this.sectionForm.get('FrequencyOptions').reset();
                this.sectionForm.get('WeeklyOptions').reset();
                this.sectionForm.get('FrequencyOthers').reset();
                this.sectionForm.get('Comments').reset();
                //console.log(this.sectionForm.value);
            }
            //applicable
            if (!value) {
                for (let cntrl in this.sectionForm.controls) {
                    if (cntrl == 'NotApplicable')
                        continue;
                    this.sectionForm.controls[cntrl].enable();
                }
                this.sectionForm.get('SkillLevel').setValidators([Validators.required]);
                this.sectionForm.get('SkillLevel').updateValueAndValidity();
               // this.sectionForm.get('FrequencyOptions').setValidators([Validators.required]);
                this.sectionPanel.open();
            }
            //keep it collpased  if the data is not complete
            //if (!this.isInitialLoadComplete) {
            //  if (this.sectionPanel!=null)
            //  this.sectionPanel.close();
            //}

        });

        this.sectionForm.get('SkillLevel').valueChanges.subscribe(value => {

            if (this.sectionPanel.closed && value != null) {
                this.sectionPanel.open();
            }
            this.sectionForm.get('FrequencyOptions').reset();
            this.sectionForm.get('FrequencyOptions').clearValidators();
            this.sectionForm.get('FrequencyOptions').updateValueAndValidity();

            //this.sectionForm.get('WeeklyOptions').reset();
            this.sectionForm.get('WeeklyOptions').clearValidators();
            this.sectionForm.get('WeeklyOptions').updateValueAndValidity();

            ///independent
            if (value === 'I') {
                this.isFrqOthers = false;
                this.isFrqWeekly = false;
                this.isFrequencyDisable = true;
              this.sectionForm.get('FrequencyOptions').clearValidators();
              this.sectionForm.get('FrequencyOptions').updateValueAndValidity();
            } else if (value === 'F') //frequency
            {
                this.isFrequencyDisable = false;
                this.sectionForm.get('FrequencyOptions').setValidators([Validators.required]);
                this.sectionForm.get('FrequencyOptions').updateValueAndValidity();
                //console.log('FrequencyOptions' , this.sectionForm.get('FrequencyOptions'))      
            }
            //this.isInitialLoadComplete = true;
            //this.sectionForm.get('Comments').setValidators([Validators.required]);
        });

        this.sectionForm.get('FrequencyOptions').valueChanges.subscribe(value => {

            this.isFrqOthers = false;
            this.isFrqWeekly = false;

            switch (value) {
                case 'WEEKLY':
                    //console.log('WEEKLY CASE ');
                    this.isFrqWeekly = true;
                    this.sectionForm.get('WeeklyOptions').setValidators(validateFreqOptions);

                    //this.sectionForm.get('FrequencyOptions').markAsTouched();
                    this.sectionForm.get('WeeklyOptions').updateValueAndValidity();
                    //this.sectionForm.get('FrequencyOptions').setValue('WEEKLY');
                    break;

                case 'OTHER':
                    //console.log('FREQ OTHER CASE');
                    this.isFrqOthers = true;
                    this.sectionForm.get('FrequencyOptions').setValidators(Validators.required);
                    this.sectionForm.get('FrequencyOthers').setValidators([Validators.required]);
                    this.sectionForm.get('FrequencyOthers').markAsTouched();
                    this.sectionForm.get('FrequencyOthers').updateValueAndValidity();
                    break;

                default:
                //this.sectionForm.get('FrequencyOptions').clearValidators();
                //this.sectionForm.get('FrequencyOptions').updateValueAndValidity();
                    // if below line is not commanded, "Individual" option is not working properly. it shows red broder even if you entered all data
                //this.sectionForm.get('FrequencyOptions').setValidators(Validators.required);                      

                    this.sectionForm.get('FrequencyOthers').clearValidators();
                    this.sectionForm.get('FrequencyOthers').updateValueAndValidity();
                    this.sectionForm.get('FrequencyOthers').reset();

                    this.sectionForm.get('WeeklyOptions').clearValidators();
                    this.sectionForm.get('WeeklyOptions').get('SUN').clearValidators();
                    this.sectionForm.get('WeeklyOptions').get('MON').clearValidators();
                    this.sectionForm.get('WeeklyOptions').get('TUE').clearValidators();
                    this.sectionForm.get('WeeklyOptions').get('WED').clearValidators();
                    this.sectionForm.get('WeeklyOptions').get('FRI').clearValidators();
                    this.sectionForm.get('WeeklyOptions').get('SAT').clearValidators();
                    this.sectionForm.get('WeeklyOptions').reset();
                    this.sectionForm.get('WeeklyOptions').updateValueAndValidity();

            }
        });

        //this.sectionForm.valueChanges.subscribe(() => {
        //    this.isSectionValid = this.sectionForm.valid && this.sectionForm.touched;
        //});

        if (this.sectionUpdateEventTimer == null) {
            this.sectionUpdateEventTimer = timer(3000).subscribe(() => {
                this.sectionForm.valueChanges.pipe(debounceTime(500)).subscribe((value: SKNSectionFormType) => {
                    if (value.NotApplicable && this.sectionSelectedForNA != null) {
                        this.sectionSelectedForNA.SkillLevel = 'N';
                        this.sectionUpdated.emit(transformToSKNMergeParams(this.sectionSelectedForNA));                        
                    } else if (!value.NotApplicable) {
                      
                        this.sectionUpdated.emit(transformToSKNMergeParams(value));
                    } else {
                        throw new error('Something went wrong on selecting NA !!');
                    }

                });

            });

        }

    }



    loadData() {
        //console.log('this._sectionData ', this._sectionData);
        if (this._sectionData == null) return;


        //ACAT ID
        this.sectionForm.get('ACAT_ID').setValue(this._sectionData.tdS_ACAT_ID);
        //CATEGORY
        this.sectionForm.get('CATEGORY').setValue(this._sectionData.tdS_CATEGORY);
        //SKILL_ID
        this.sectionForm.get('SKILL_ID').setValue(this._sectionData.tdS_SKILL_ID);


        //SKILL_LEVEL <<
        switch (this._sectionData.tdS_SKILL_LEVEL) {
            case 'N':
                this.sectionForm.get('NotApplicable').setValue(true);
                this.isFrequencyDisable = true;
                break;
            case 'I':
                this.sectionForm.get('SkillLevel').setValue('I');
                this.isFrequencyDisable = true;
                break;
            case 'F':
                this.sectionForm.get('SkillLevel').setValue('F');
                this.isFrequencyDisable = false;
                break;
            case null:
                //this.sectionForm.get('NotApplicable').setValue(true);
                this.isFrequencyDisable = true;
                break;
                //return;
            default:
                break;
        }
        // >>
        //FREQ <<
        this.sectionForm.get('FrequencyOptions').setValue(this._sectionData.tdS_FREQ);
        this.sectionForm.get('FrequencyOthers').setValue(this.sectionData.tdS_FREQ_COMMENT);
        this.isFrqOthers = false;
        switch (this._sectionData.tdS_FREQ) {
            case 'WEEKLY':
                this.isFrqWeekly = true;
                this.sectionForm.get('WeeklyOptions').setValue(this.setFreqDays(this.sectionData.tdS_FREQ_DAYS));
                break;
            case 'DAILY':
                break;
            case 'MONTHLY':
                break;
            case 'OTHER':
                this.isFrqOthers = true;
                break;
            default: 
                break;
        }
        // >>
        //commentts
        //tdS_SKILL_COMMENT
        this.sectionForm.get('Comments').setValue(this.sectionData.tdS_SKILL_COMMENT);



        // CREATE Dynamic Form <<

        this.dynamicFormValues = (new SKNDynFormBuilder(this.dropdownservice)).GetDynSection(this._sectionData);
        //console.log('dynamic form ', this.dynamicFormValues);
        if (this.dynamicFormValues != null) {

            this.dynamicFormValues.forEach((fld: SKNDynFormFields) => {

                let cntrl = new FormControl(Date.parse(fld.value) > 0 ? new Date(fld.value) : fld.value);
                let custom_validators = [];

                if (fld.customValidators != null)
                    fld.customValidators.forEach((cusVal) => {
                        switch (cusVal) {
                            case 'futureDate':
                            custom_validators.push(validateFutureDate);                            
                                break;
                            default:
                                break;
                        }
                    });
                if (fld.required) {
                    custom_validators.push(Validators.required);
                }
              cntrl.setValidators(custom_validators);            
              this.sectionForm.addControl(fld.name, cntrl);
              
              fld.loadOptions();              

                if (fld.showDepentancy != null) {
                    this.sectionForm.get(fld.showDepentancy.cntrlName).valueChanges.subscribe(value => {
                        this.sectionForm.get(fld.name).clearValidators();
                        this.sectionForm.get(fld.name).updateValueAndValidity();
                        if (value == fld.showDepentancy.cntrlValue) {
                          fld.show = true;
                          if (fld.name == 'Option_2' && fld.type == 'DateField') {
                            this.sectionForm.get(fld.name).setValidators([Validators.required, ALOCValidators.validateFutureDate]);
                            this.sectionForm.get(fld.name).updateValueAndValidity();
                          } else {
                            this.sectionForm.get(fld.name).setValidators(Validators.required);
                            this.sectionForm.get(fld.name).updateValueAndValidity();
                          }
                        } else {
                            fld.show = false;
                            this.sectionForm.get(fld.name).reset();
                        }
                    });
                    if (this.sectionForm.get(fld.showDepentancy.cntrlName).value == fld.showDepentancy.cntrlValue) {
                        fld.show = true;
                    }
                }


            });


        }
        // >>


        this.alerts.clearLoader();

    }//loadData

    private setFreqDays = (days: string) => {
        if (!isNullOrUndefined(days)) {
            let theDays: string[] = days.split(',');
            return {
                'SUN': theDays.indexOf('SUN') > -1,
                'MON': theDays.indexOf('MON') > -1,
                'TUE': theDays.indexOf('TUE') > -1,
                'WED': theDays.indexOf('WED') > -1,
                'THU': theDays.indexOf('THU') > -1,
                'FRI': theDays.indexOf('FRI') > -1,
                'SAT': theDays.indexOf('SAT') > -1
            }
        }
        return {
            'SUN': false,
            'MON': false,
            'TUE': false,
            'WED': false,
            'THU': false,
            'FRI': false,
            'SAT': false
        }
    }

   



}
