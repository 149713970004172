import { Component, OnInit, Input } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { DocumentsDialogComponent } from './documents-dialog/documents-dialog.component';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

    constructor(
        public dialog: MatDialog
    ) { }

    @Input()
    showDefaultButton = true;

    ngOnInit() {
        
    }


    openDialog(): void {
        const dialogRef = this.dialog.open(DocumentsDialogComponent, {
            width: '60%',
            minHeight: '30%',
            disableClose: true
        });
    }

}




