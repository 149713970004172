import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-section-g',
  templateUrl: './section-g.component.html',
  styleUrls: ['./section-g.component.css']
})
export class SectionGComponent implements OnInit {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/section-g') {
          router.navigate(['/section-g/developmental-disability']);
        }
      }
    });
  }

  ngOnInit() {
  }

}
