import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionLService, AssessorObservations, DropdownModel } from '../../shared';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-assessor-observation',
  templateUrl: './assessor-observation.component.html',
  styleUrls: ['./assessor-observation.component.css']
})
export class AssessorObservationComponent implements OnInit {
    formStatus: boolean;
    assessorObservationForm: FormGroup;
    isSubmitting = false;
    assessorObservation: AssessorObservations = {} as AssessorObservations;

    constructor(private fb: FormBuilder,
        private dropdownSerice: SectionDropdownService,
        private alertService: AlertsService,
        public dataService: DataService,
        public sectionLService: SectionLService) {
        this.assessorObservationForm = this.fb.group({            
            tL_ACAT_ID: new FormControl(''),
            tL_CG_CONCERNS_IND: new FormControl(''),
            tL_CG_SA_IND: new FormControl(''),
            tL_CG_MHI_IND: new FormControl(''),
            tL_ASSESSOR_CONCERNS: new FormControl(''),
            tL_COMMENTS: new FormControl('')
        });
    }

  ngOnInit() {

    if (this.dataService.getSelectedDocument() != null) {
      this.sectionLService.GetSectionLInfo(this.dataService.getSelectedDocument()).subscribe(response => {
        this.receiveAssessorObservation(response);
        this.alertService.success("Assessor Observations Loaded");
      }, err => {
        this.alertService.error(err);
      });
    }

    }

    ClearForm(e) {
      this.onClear();
  }
  receiveAssessorObservation(data) {
    this.assessorObservationForm.patchValue(data);
    this.setData(data);
  }
  setData(data) {

    if (data['tL_CG_SA_IND'] == 'TRUE') {
      this.assessorObservationForm.get('tL_CG_SA_IND').setValue(true);
    }
    else {
      this.assessorObservationForm.get('tL_CG_SA_IND').setValue(false);
    }
    if (data['tL_CG_MHI_IND'] == 'TRUE') {
      this.assessorObservationForm.get('tL_CG_MHI_IND').setValue(true);
    }
    else {
      this.assessorObservationForm.get('tL_CG_MHI_IND').setValue(false);
    }

  }
    submitForm() {
      this.isSubmitting = true;

      this.assessorObservationForm.get('tL_ACAT_ID').setValue('');
      for (const field in this.assessorObservationForm.controls) {

        if (this.assessorObservationForm.controls[field].value == null || this.assessorObservationForm.controls[field].value == '') {
          this.formStatus = false;
        } else {
          this.formStatus = true;
          break;
        }
      }

      if (this.formStatus == false) {
        this.warningAlert();
        this.isSubmitting = false;
        return;
      }
      this.assessorObservationForm.get('tL_ACAT_ID').setValue(this.dataService.getSelectedDocument());
      console.log(this.assessorObservationForm);
      this.sectionLService.postAssessorObservation(this.assessorObservationForm.value).subscribe(response => {
        this.alertService.success('Assessor Observations Saved');
      }, err => {
          console.error('Error Saving Assessor Observations', err);
          this.alertService.error("Error Saving Assessor Observations");
        });

      this.isSubmitting = false;

    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.assessorObservationForm.reset();
      }
    });

  }
  warningAlert() {
    Swal.fire(
      '',
      'Please add atleast one assessor observations information',
      'warning'
    )
  }
}
