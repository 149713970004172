import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { SummaryService, DataService, AlertsService, SummaryResponse, SummaryRequest } from '../../shared/index';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { TopsummaryComponent } from './topsummary/topsummary.component';
import { LocDeterminationComponent } from './loc-determination/loc-determination.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
    @ViewChild(TopsummaryComponent, { static: false }) topSummary: TopsummaryComponent;
    @ViewChild(LocDeterminationComponent, { static: false }) locDetermination: LocDeterminationComponent;
    @ViewChild(ContactInformationComponent, { static: false }) contactInformation: ContactInformationComponent;
    summaryResponseModel: SummaryResponse = {} as SummaryResponse;
    summaryRequestModel: SummaryRequest = {} as SummaryRequest;
    constructor(private summaryService: SummaryService, private alertsService: AlertsService, public dataService: DataService) { }

    ngOnInit() {
        //console.log('context doc ', this.dataService.getSelectedDocument());
        this.summaryRequestModel.aS_ID = this.dataService.getSelectedDocument();
        this.summaryService.getSummary(this.summaryRequestModel).subscribe((response: SummaryResponse) => {
            this.locDetermination.SummaryData = response;
            this.summaryResponseModel = response;
            console.log(response);
          this.alertsService.success("Summary Loaded");

            this.topSummary.summaryData = response;
            
        }, err => {
            console.error(err);
            this.alertsService.error(err);
        });

    }

}
