import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AdlsIadlsComponent } from './adls-iadls/adls-iadls.component';

@Injectable({
  providedIn: 'root'
})
export class AdlsUsaveGurardGuard implements CanDeactivate<AdlsIadlsComponent> {
    canDeactivate(component: AdlsIadlsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!component.canNavigateAway()) {
            return window.confirm(`There are unsaved changes. \nDo you wish to navigate away ?`);
        } else {
            return component.canNavigateAway();
        }
        
    }
  
}
