import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService, AlertsService, SummaryService, SectionDropdownService } from '../../../shared/services';
import { DatePipe } from '@angular/common';
import { MCPReview } from '../../../shared';

@Component({
    selector: 'app-mcp-review',
    templateUrl: './mcp-review.component.html',
    styleUrls: ['./mcp-review.component.css'],
    providers: [DatePipe]
})
export class McpReviewComponent implements OnInit {
    mcpReviewForm: FormGroup;

    MCPReview: MCPReview = {} as MCPReview;
    mcpEnrollWaiver: boolean;
    enrollWaiverStatus: string;
    mcpDenialReason: any[];
    minDate = new Date();
    minmumDate120 = new Date((new Date()).getFullYear() - 120, (new Date()).getMonth(), (new Date()).getDate());
    maximumDate120 = new Date((new Date()).getFullYear() + 120, (new Date()).getMonth(), (new Date()).getDate());
    constructor(private fb: FormBuilder,
        public dataService: DataService,
        public AlertSVC: AlertsService,
        public SummarySVC: SummaryService,
        private datePipe: DatePipe,
        private dropdownservice: SectionDropdownService) {
        this.mcpReviewForm = this.fb.group({
            aO_ESTIMATED_COST: new FormControl(''),
            aO_DENIAL_REASON: new FormControl(''),
            aO_ENROLLMENT_DATE: new FormControl('')
        });
    }

    ngOnInit() {

        this.mcpEnrollWaiver = true;
        this.enrollWaiverStatus = "Yes";
        this.loadDropdownService();
    }

    mcpReviewSave() {

        if (this.mcpReviewForm.get('aO_ENROLL_IN_WAIVERT').value == '') {
            this.mcpReviewForm.get('aO_ENROLL_IN_WAIVERT').setValidators([Validators.required]);
            this.mcpReviewForm.get('aO_ENROLL_IN_WAIVERT').setErrors({ 'required': true });
        }

        this.mcpReviewForm.get('aO_ALOC_ID').setValue(this.dataService.getSelectedDocument());

        if (this.enrollWaiverStatus === "Yes") {
            if (this.mcpReviewForm.get('aO_ENROLLMENT_DATE').value != null && this.mcpReviewForm.get('aO_ESTIMATED_COST').value != null && this.mcpReviewForm.get('aO_ENROLLMENT_REQUEST_DATE').value != null) {
                this.mcpReviewForm.get('aO_DENIAL_REASON').setValue(null);
            } else {
                //console.log('invalid field ');
                return;
            }

        } else {

            if (this.mcpReviewForm.get('aO_DENIAL_REASON').value != null) {
                this.mcpReviewForm.get('aO_ENROLLMENT_DATE').setValue(null);
                this.mcpReviewForm.get('aO_ENROLLMENT_REQUEST_DATE').setValue(null);
                this.mcpReviewForm.get('aO_ESTIMATED_COST').setValue(null);
            } else {
                //console.log('invalid field ');
                return;
            }



        }

        //console.log(this.mcpReviewForm.value);
        this.updateMCPReview(this.mcpReviewForm.value);
        let formData = this.MCPReview;
        if (formData['aO_ENROLLMENT_DATE'] != null)
            formData['aO_ENROLLMENT_DATE'] = this.datePipe.transform(formData['aO_ENROLLMENT_DATE'], 'MM/dd/yyyy');

        if (formData['aO_ENROLLMENT_REQUEST_DATE'] != null)
            formData['aO_ENROLLMENT_REQUEST_DATE'] = this.datePipe.transform(formData['aO_ENROLLMENT_REQUEST_DATE'], 'MM/dd/yyyy');

        this.SummarySVC.PostMCPReview(this.MCPReview).subscribe(resp => {
            // console.log('summary MCP create ', resp);
            this.AlertSVC.success("Summary MCP Review Saved");

        }, error => {
            console.error('Error Creating Summary MCP Review', error);
            this.AlertSVC.error("Error Saving Summary MCP Review");
        }
        );

    }

    setData(data) {
        if (data['tO_ENROLLMENT_DATE'] != null) {
            this.mcpReviewForm.get('tO_ENROLL_IN_WAIVERT').setValue('Yes');
            this.mcpEnrollWaiver = true;
        } else {
            if (data['tO_DENIAL_REASON'] != null) {
                this.mcpReviewForm.get('tO_ENROLL_IN_WAIVERT').setValue('No');
                this.mcpEnrollWaiver = false;
            }
        }
    }

    enrollWaiverStatusChange() {

        this.enrollWaiverStatus = this.mcpReviewForm.get('aO_ENROLL_IN_WAIVERT').value;

        if (this.enrollWaiverStatus === "Yes") {
            this.mcpEnrollWaiver = true;
        } else {
            this.mcpEnrollWaiver = false;
        }
    }

    updateMCPReview(values: Object) {
        Object.assign(this.MCPReview, values);
    }

    loadDropdownService() {
        //aO_LOC_DETERMINATION_STATUS_IN
        this.dropdownservice.GetDropDownValues('DEN_REAS').subscribe((data: any[]) => {
            this.mcpDenialReason = data;
            this.AlertSVC.clearLoader();
        }, error => {
            this.AlertSVC.error(error);
        });
    }

}
