import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, EmailValidator, Validators } from '@angular/forms';
import { HadTransplantFields, HadTransplant, ValidateHadTranspOptns } from './HadTransplant';
import { validateFutureDate, validatePastDate } from '../../../shared/validators/AlocDateValidators';

@Component({
    selector: 'app-had-transplant-cntrl',
    templateUrl: './had-transplant-cntrl.component.html',
    styleUrls: ['./had-transplant-cntrl.component.css']
})
export class HadTransplantCntrlComponent implements OnInit, OnChanges {

    constructor() {
        //init form
        this.initForm();
    }

    @ViewChild('tx_yes_date_fld', { static: true })
    tx_yes_date_fld: ElementRef;

    @ViewChild('tx_pending_data_fld', { static: true })
    tx_pending_data_fld: ElementRef;

    txCntrlForm: FormGroup;

    private _RowData: HadTransplant;

    public get getFormData(): HadTransplant {
        return this.txCntrlForm.value as HadTransplant;
    }

    @Input()
    set RowData(value: HadTransplant) {
        this._RowData = value;
        this.loadData();
        //console.log('data updated ');
    }
    get RowData() {
        return this._RowData;
    }

    @Input()
    set disableRow(value: boolean) {
        if (value) {
            this.txCntrlForm.clearValidators();
            this.txCntrlForm.updateValueAndValidity();
            this.txCntrlForm.get(HadTransplantFields.TX_DATE).reset();
            this.txCntrlForm.get(HadTransplantFields.TX_NO).reset();
            this.txCntrlForm.get(HadTransplantFields.TX_OTHER).reset();
            this.txCntrlForm.get(HadTransplantFields.TX_PENDING).reset();
            this.txCntrlForm.get(HadTransplantFields.TX_PENDING_DATE).reset();
            this.txCntrlForm.get(HadTransplantFields.TX_YES).reset();
            this.txCntrlForm.disable();

        } else {
            this.txCntrlForm.enable();
            //dsiable dates by default
            if (this.Cntrl(HadTransplantFields.TX_DATE).value == null) {
                this.Cntrl(HadTransplantFields.TX_DATE).disable();
            }
            if (this.Cntrl(HadTransplantFields.TX_PENDING_DATE).value == null) {
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).disable();
            }            
            //set yesnopending validation
            this.txCntrlForm.setValidators([ValidateHadTranspOptns]);
        }

    }

    @Input()
    Title: string;


    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        //console.log(changes);
    }

    Cntrl(cntrlName) {
        return this.txCntrlForm.get(cntrlName);
    }

    initForm() {

        let formCntrls = {};
        for (let cntrl in HadTransplantFields) {
            formCntrls[cntrl] = new FormControl('');
        }
        this.txCntrlForm = new FormGroup(formCntrls);

        //dsiable dates by default
        //this.Cntrl(HadTransplantFields.TX_DATE).disable();
        //this.Cntrl(HadTransplantFields.TX_PENDING_DATE).disable();

        //config other:
        this.Cntrl(HadTransplantFields.TX_OTHER).valueChanges.subscribe(txt => {
            if (this.Cntrl(HadTransplantFields.TX_LABEL).value != 'Other:') {
                return;
            }
            if (txt == null || txt == "") {
                this.Cntrl(HadTransplantFields.TX_NO).reset();
                this.Cntrl(HadTransplantFields.TX_NO).disable();
                this.Cntrl(HadTransplantFields.TX_YES).reset();
                this.Cntrl(HadTransplantFields.TX_YES).disable();
                this.Cntrl(HadTransplantFields.TX_PENDING).reset();
                this.Cntrl(HadTransplantFields.TX_PENDING).disable();
            } else {
                this.Cntrl(HadTransplantFields.TX_NO).enable();
                this.Cntrl(HadTransplantFields.TX_YES).enable();
                this.Cntrl(HadTransplantFields.TX_PENDING).enable();
            }
        });

        //config for NO
        this.Cntrl(HadTransplantFields.TX_NO).valueChanges.subscribe(val => {
            if (val) { //if no selected                
                this.Cntrl(HadTransplantFields.TX_YES).setValue(false);
                this.Cntrl(HadTransplantFields.TX_PENDING).setValue(false);

                //disable and clear dates
                this.Cntrl(HadTransplantFields.TX_DATE).reset();
                this.Cntrl(HadTransplantFields.TX_DATE).disable();
                this.Cntrl(HadTransplantFields.TX_DATE).clearValidators();
                this.Cntrl(HadTransplantFields.TX_DATE).updateValueAndValidity();

                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).reset();
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).disable();
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).clearValidators();
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).updateValueAndValidity();
            }
        });

        //config for yes
        this.Cntrl(HadTransplantFields.TX_YES).valueChanges.subscribe(val => {
            if (val) {
                this.Cntrl(HadTransplantFields.TX_NO).setValue(false);
                //yes date
                this.Cntrl(HadTransplantFields.TX_DATE).enable();
                this.Cntrl(HadTransplantFields.TX_DATE).setValidators([Validators.required, validateFutureDate]);
                this.Cntrl(HadTransplantFields.TX_DATE).updateValueAndValidity();
            } else {
                this.Cntrl(HadTransplantFields.TX_DATE).disable();
                this.Cntrl(HadTransplantFields.TX_DATE).reset();
                this.Cntrl(HadTransplantFields.TX_DATE).clearValidators();
                this.Cntrl(HadTransplantFields.TX_DATE).updateValueAndValidity();
            }
        });

        //config for PENDING
        this.Cntrl(HadTransplantFields.TX_PENDING).valueChanges.subscribe(val => {
            if (val) {
                this.Cntrl(HadTransplantFields.TX_NO).setValue(false);
                //pending date
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).enable();
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).setValidators([Validators.required, validatePastDate]);
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).updateValueAndValidity();
            } else {
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).disable();
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).reset();
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).clearValidators();
                this.Cntrl(HadTransplantFields.TX_PENDING_DATE).updateValueAndValidity();
            }
        });

        
    }

    private loadData() {
        this.txCntrlForm.patchValue(this._RowData);
    }

    clearForm() {
        let val = this.txCntrlForm.get(HadTransplantFields.TX_LABEL).value;
        this.txCntrlForm.reset();
        this.txCntrlForm.get(HadTransplantFields.TX_LABEL).setValue(val);
    }

}
