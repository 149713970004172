import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { SchoolWork } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SectionHService {

  constructor(private apiService: ApiService) { }

  getSchoolWork(acatId): Observable<SchoolWork> {
    return this.apiService.get('sectionh/' + acatId).pipe(map(data => { return data; }));
  }

  postSchoolWork(schoolWork): Observable<any> {
    return this.apiService.post('sectionh', schoolWork).pipe(map(data => { return data; }));
  }

}
