import { DropdownOptionsType } from '../../../shared/common/DropDownOptionsType';
import { Medication } from '../medication-admin';

export interface dialogModeConfig {
    mode: diaMode ;
    row: Medication;
}

export interface dialogConfig {
    'obtainedSrc': DropdownOptionsType;
    'assistSrc': DropdownOptionsType;
    'mediRoute': DropdownOptionsType;
    'mediPackg': DropdownOptionsType;
    'document': { alocid: string, sectionid: string, name: string } ;
    'modeConfig': dialogModeConfig
}

export enum diaMode {
    View, Edit,Delete,New
}
