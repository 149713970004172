import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionIService, AlertsService, DataService, UserService } from '../../shared/index';
import { Supports } from '../../shared';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddNewSupportComponent } from './add-new-support/add-new-support.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-supports',
  templateUrl: './supports.component.html',
  styleUrls: ['./supports.component.css']
})
export class SupportsComponent implements OnInit {
  supportFrm: FormGroup;
  newACATID: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['VIEW', 'EDITE', 'DELETE', 'tI_SUPPORT_PROVIDER', 'tI_SUPPORT_PROVIDER_TYPE', 'tI_SUPPORT_SUPPORT_TYPE', 'tI_SUPPORT_FUNDING'];
  supportDataSource: any;
  supportData: any;
  showSupportInfo: boolean;
  showSupportInfoGrid: boolean;
  changeSupportInfo: string;
  constructor(private fb: FormBuilder,
    public alertsService: AlertsService,
    public dataService: DataService,
    public router: Router,
    public userService: UserService,
    public sectionIService: SectionIService,
    public confirmDialog: MatDialog) {
    this.supportFrm = this.fb.group({
      
      tI_SECTION_ID: new FormControl(),
      tI_ACAT_ID: new FormControl(''),
      tI_CURRENT_SUPPORT_IND: new FormControl(''),
      tI_SUPPORT_PROVIDER: new FormControl(''),
      tI_SUPPORT_PROVIDER_TYPE: new FormControl(''),
      tI_SUPPORT_SUPPORT_TYPE: new FormControl(''),
      tI_SUPPORT_NAT_PTOTST_IND: new FormControl(''),
      tI_SUPPORT_NAT_SN_IND: new FormControl(''),
      tI_SUPPORT_NAT_PC_IND: new FormControl(''),
      tI_SUPPORT_NAT_HOME_IND: new FormControl(''),
      tI_SUPPORT_NAT_MEAL_IND: new FormControl(''),
      tI_SUPPORT_NAT_LAUNDRY_IND: new FormControl(''),
      tI_SUPPORT_NAT_SHOPPING_IND: new FormControl(''),
      tI_SUPPORT_NAT_TRANS_IND: new FormControl(''),
      tI_SUPPORT_NAT_PSYCH_IND: new FormControl(''),
      tI_SUPPORT_NAT_FINANCE_IND: new FormControl(''),
      tI_SUPPORT_NAT_RESPITE_IND: new FormControl(''),
      tI_SUPPORT_NAT_HC_IND: new FormControl(''),
      tI_SUPPORT_NAT_BEHAV_IND: new FormControl(''),
      tI_SUPPORT_NAT_MED_IND: new FormControl(''),
      tI_SUPPORT_NAT_OTHER_IND: new FormControl(''),
      tI_SUPPORT_NAT_OTHER_DESC: new FormControl(''),
      tI_SUPPORT_NAT_FREQ_DETAIL: new FormControl(''),
      tI_SUPPORT_COM_PTOTST_IND: new FormControl(''),
      tI_SUPPORT_COM_SN_IND: new FormControl(''),
      tI_SUPPORT_COM_PC_IND: new FormControl(''),
      tI_SUPPORT_COM_HOME_IND: new FormControl(''),
      tI_SUPPORT_COM_MEAL_IND: new FormControl(''),
      tI_SUPPORT_COM_LAUNDRY_IND: new FormControl(''),
      tI_SUPPORT_COM_SHOPPING_IND: new FormControl(''),
      tI_SUPPORT_COM_TRANS_IND: new FormControl(''),
      tI_SUPPORT_COM_PSYCH_IND: new FormControl(''),
      tI_SUPPORT_COM_FINANCE_IND: new FormControl(''),
      tI_SUPPORT_COM_RESPITE_IND: new FormControl(''),
      tI_SUPPORT_COM_HC_IND: new FormControl(''),
      tI_SUPPORT_COM_BEHAV_IND: new FormControl(''),
      tI_SUPPORT_COM_MED_IND: new FormControl(''),
      tI_SUPPORT_COM_OTHER_IND: new FormControl(''),
      tI_SUPPORT_COM_OTHER_DESC: new FormControl(''),
      tI_SUPPORT_COM_FREQ_DETAIL: new FormControl(''),
      tI_SUPPORT_FUNDING: new FormControl(''),
      tI_SUPPORT_FUNDING_OTHER: new FormControl('')

    });
  }
 
  ngOnInit() {
    this.newACATID = this.dataService.getSelectedDocument();

    this.sectionIService.LoadSupport(this.newACATID).subscribe(response => {

      this.supportDataSource = new MatTableDataSource<Supports>(response);
      this.supportDataSource.paginator = this.paginator;
      if (response.length != 0) {
        this.supportFrm.get('tI_CURRENT_SUPPORT_IND').setValue(response[0].tI_CURRENT_SUPPORT_IND);
        if (response[0].tI_CURRENT_SUPPORT_IND === "Y") {
          this.showSupportInfo = false;
          this.showSupportInfoGrid = false;
        } else {
          this.showSupportInfo = true;
          this.showSupportInfoGrid = true;
        }
      } else {
        this.showSupportInfo = true;
        this.showSupportInfoGrid = true;
      }

      this.alertsService.success("Supports Loaded");

    }, err => {
      this.alertsService.error(err);
    });

    this.alertsService.clearLoader();
  }
  onDelete(delSupport) {

    Swal.fire({
      title: 'Confirm Delete',
      text: "Are you sure want to delete?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {

        this.sectionIService.DeleteSupport(delSupport.tI_SECTION_ID).subscribe(resp => {
          this.gridRefresh();
          this.alertsService.success("Support Deleted");
        }, error => {
            console.error('Error Deleting Support', error);
            this.alertsService.error("Error Deleting Support");
        });
      }
    });

    this.alertsService.clearLoader();
  }
  onView(viewSupport) {
    const dialogConfig_View = new MatDialogConfig();
    dialogConfig_View.data = { sectionID: viewSupport.tI_SECTION_ID, acatID: viewSupport.tI_ACAT_ID, Mode:'View' };
    let editDialog = this.confirmDialog.open(AddNewSupportComponent, dialogConfig_View);

    editDialog.afterClosed().subscribe(result => {
      if (result == "success")
        this.gridRefresh();
    });
  }
  onEdit(editSupport) {
    const dialogConfig_Edit = new MatDialogConfig();
    dialogConfig_Edit.data = { sectionID: editSupport.tI_SECTION_ID, acatID: editSupport.tI_ACAT_ID, Mode: 'Edit' };
    let editDialog = this.confirmDialog.open(AddNewSupportComponent, dialogConfig_Edit);

    editDialog.afterClosed().subscribe(result => {
      if (result == "success")
        this.gridRefresh();
    });
  }
  supportFrmSave() {

    if (this.supportFrm.get('tI_CURRENT_SUPPORT_IND').value == null || this.supportFrm.get('tI_CURRENT_SUPPORT_IND').value == '') {
      this.supportFrm.get('tI_CURRENT_SUPPORT_IND').setValidators([Validators.required]);
      this.supportFrm.get('tI_CURRENT_SUPPORT_IND').setErrors({ 'required': true });
    }

    this.newACATID = this.dataService.getSelectedDocument();
    // if (this.newACATID != null) {
    this.supportFrm.get('tI_ACAT_ID').setValue(this.newACATID);

    if (!this.supportFrm.valid) {
      for (let cnt in this.supportFrm.controls) {
        if (this.supportFrm.controls[cnt].invalid) {
          console.log(cnt, this.supportFrm.controls[cnt]);
        }
      }
      return;
    }
    let supportFormData = this.supportFrm.value;

    this.sectionIService.LoadSupport(this.newACATID).subscribe(response => {
      this.supportData = new MatTableDataSource<Supports>(response);
      if (supportFormData['tI_CURRENT_SUPPORT_IND'] == 'N' && response.length != 0 && response[0].tI_SUPPORT_SUPPORT_TYPE != null) {
       // this.alertsService.error('Having support(s), please select correct option');
        this.warningAlert('Having support(s), please select correct option');
        this.alertsService.clearLoader();
        return;
      } else {
        if (supportFormData['tI_CURRENT_SUPPORT_IND'] == 'Y' && response.length == 0) {
         // this.alertsService.error('No support(s) added, please select correct option');
          this.warningAlert('No support(s) added, please select correct option');
          this.alertsService.clearLoader();
          return;
        }
        else {

          if (supportFormData['tI_CURRENT_SUPPORT_IND'] == 'Y' && response.length != 0 && response[0].tI_SUPPORT_SUPPORT_TYPE == null) {
           // this.alertsService.error('No support(s) added, please select correct option');
            this.warningAlert('No support(s) added, please select correct option');
            this.alertsService.clearLoader();
            return;
          } else {
            // Save Section I.
            this.sectionIService.PostSectionI(supportFormData).subscribe(resp => {
              this.gridRefresh();
              this.alertsService.success("Supports Saved");
            }, error => {
                console.error('Error Saving Supports', error);
                this.alertsService.error("Error Saving Supports");
            });

          }

        }
      }

    }, err => {
      this.alertsService.error(err);
      });

  }
  supportFrmClear() {
    this.onClear();
  }
 
  newSupport() {
    const dialogConfig_New = new MatDialogConfig();
    dialogConfig_New.data = { Mode: 'New' };
    let editDialog = this.confirmDialog.open(AddNewSupportComponent, dialogConfig_New);

    editDialog.afterClosed().subscribe(result => {
      if (result == "success")
        this.gridRefresh();
    });
  }

  changeSupportInformation() {

    this.changeSupportInfo = this.supportFrm.get('tI_CURRENT_SUPPORT_IND').value;
    this.newACATID = this.dataService.getSelectedDocument();
    if (this.changeSupportInfo == 'Y') {

      this.sectionIService.LoadSupport(this.newACATID).subscribe(response => {
        if (response[0].tI_SUPPORT_SUPPORT_TYPE != null) {
          this.showSupportInfoGrid = false;
        } else {

          this.showSupportInfoGrid = true;
        }
        this.alertsService.clearLoader();
      }, err => {
        this.alertsService.error(err);
      });

      this.showSupportInfo = false;
    } else {
      this.showSupportInfo = true;
      this.showSupportInfoGrid = true;
    }
    this.alertsService.clearLoader();
  }
  gridRefresh() {
    this.newACATID = this.dataService.getSelectedDocument();
    this.sectionIService.LoadSupport(this.newACATID).subscribe(response => {
      this.supportDataSource = new MatTableDataSource<Supports>(response);
      this.supportDataSource.paginator = this.paginator;
      if (response.length != 0) {
        this.supportFrm.get('tI_CURRENT_SUPPORT_IND').setValue(response[0].tI_CURRENT_SUPPORT_IND);
        if (response[0].tI_CURRENT_SUPPORT_IND === "Y") {
          this.showSupportInfo = false;
          this.showSupportInfoGrid = false;
        } else {
          this.showSupportInfo = true;
          this.showSupportInfoGrid = true;
          this.supportFrm.get('tI_CURRENT_SUPPORT_IND').setValue(response[0].tI_CURRENT_SUPPORT_IND);
        }
      } else {
        this.showSupportInfo = true;
        this.showSupportInfoGrid = true;
        this.supportFrm.get('tI_CURRENT_SUPPORT_IND').setValue(null);
      }

      this.alertsService.success("Supports Loaded");

    }, err => {
      this.alertsService.error(err);
      });

  }
  warningAlert(msg) {
    Swal.fire(
      '',
      msg,
      'warning'
    )
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.supportFrm.reset();
      }
    });

  }
  applyFilter = (filterTxt: string) => this.supportDataSource.filter = filterTxt;
}
