import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, SectionDropdownService, DataService, SectionEService, Communication, getTextForDropdownValues } from '../../shared';
import { DropdownModel } from '../../shared/models/dropdown.model';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {
  communicationForm: FormGroup;
  newACATID: any;
  communication: Communication = {} as Communication;
  isSubmitting = false;
  hearing: DropdownModel[];
  speech: DropdownModel[];
  understand: DropdownModel[];
  expression: DropdownModel[];
  understood: DropdownModel[];
  devices: DropdownModel[];
  vision: DropdownModel[];
  formStatus: boolean;
  dropDownSelectText = getTextForDropdownValues;
  constructor(private fb: FormBuilder,
    private alertService: AlertsService,
    private dropdownSerice: SectionDropdownService,
    public dataService: DataService,
    public sectionEService: SectionEService) {
    this.communicationForm = this.fb.group({
      tE_ACAT_ID: new FormControl(''),
      tE_HEARING: new FormControl(''),
      tE_SPEECH_CLARITY: new FormControl(''),
      tE_ABILITY_UNDERSTAND: new FormControl(''),
      tE_EXPRESION: new FormControl(''),
      tE_ME_SPEECH: new FormControl(''),
      tE_ME_SIGNS: new FormControl(''),
      tE_ME_WRITING: new FormControl(''),
      tE_ME_BOARD: new FormControl(''),
      tE_ME_ASL: new FormControl(''),
      tE_ME_INTERPRETR: new FormControl(''),
      tE_ME_DEVICE: new FormControl(''),
      tE_MAKING_UNDERSTOOD: new FormControl(''),
      tE_COMM_DEVICES: new FormControl(''),
      tE_VISION: new FormControl(''),
      tE_COMMENTS: new FormControl(''),
      tE_OTHER_COMM_DEVICES: new FormControl({ value: '', disabled: true })
    });
  }

  ngOnInit() {

    this.newACATID = this.dataService.getSelectedDocument();
    if (this.newACATID != null) {
      this.sectionEService.GetSectionEInfo(this.newACATID).subscribe(data => {
        this.receiveCommunication(data);
        this.alertService.success("Communication Loaded");

      }, err => {
          this.alertService.error(err);
      });
    }

    this.loadDropdowns();
    this.onChange();
  }
  receiveCommunication(data) {
    this.communicationForm.patchValue(data);
    this.setData(data);

  }

  onChange() {
    this.communicationForm.get('tE_COMM_DEVICES').valueChanges.subscribe(val => {     
      if (val == 'OTHER') {
        this.communicationForm.get('tE_OTHER_COMM_DEVICES').enable();
        this.communicationForm.get('tE_OTHER_COMM_DEVICES').setValidators([Validators.required]);  
        this.communicationForm.get('tE_OTHER_COMM_DEVICES').markAsTouched();
        
      } else {
        this.communicationForm.get('tE_OTHER_COMM_DEVICES').clearValidators();
        this.communicationForm.get('tE_OTHER_COMM_DEVICES').setValue('');
        this.communicationForm.get('tE_OTHER_COMM_DEVICES').disable();
      }
      this.communicationForm.get('tE_OTHER_COMM_DEVICES').updateValueAndValidity();
    });
  }
  loadDropdowns() {
    this.dropdownSerice.GetDropDownValues('ACAT_HEARING').subscribe(data => {
      this.hearing = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('ACAT_SPEECH').subscribe(data => {
      this.speech = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('ACAT_UNDERSTAND').subscribe(data => {
      this.understand = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('MODE_EXPRESSION').subscribe(data => {
      this.expression = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('SELF_UNDERSTOOD').subscribe(data => {
      this.understood = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('COMM_DEVICES').subscribe(data => {
      this.devices = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('ACAT_VISION').subscribe(data => {
      this.vision = data;
    }, error => {
      this.alertService.error(error);
    });
  } 

  ClearForm(e) { 
    this.onClear();
  }
  setData(data) {
    let expressionList: string[] = [];
    if (data['tE_ME_SPEECH'] == 'Y') {
      expressionList.push('SPEECH');
    }
    if (data['tE_ME_SIGNS'] == 'Y') {
      expressionList.push('SIGNS');
    }
    if (data['tE_ME_WRITING'] == 'Y') {
      expressionList.push('WRITING');
    }
    if (data['tE_ME_BOARD'] == 'Y') {
      expressionList.push('BOARD');
    }
    if (data['tE_ME_ASL'] == 'Y') {
      expressionList.push('ASL');
    }
    if (data['tE_ME_INTERPRETR'] == 'Y') {
      expressionList.push('INTERPRETR');
    }
    if (data['tE_ME_DEVICE'] == 'Y') {
      expressionList.push('DEVICE');
    }   

    this.communicationForm.get('tE_EXPRESION').setValue(expressionList);
  }

  submitForm() {
    this.isSubmitting = true;
    this.communicationForm.get('tE_ACAT_ID').setValue('');
    for (const field in this.communicationForm.controls) {

      if (this.communicationForm.controls[field].value == null || this.communicationForm.controls[field].value == '') {
        this.formStatus = false;        
      } else {
        this.formStatus = true;
        break;
      }
    }

    if (this.formStatus == false) {
      this.warningAlert();
      this.isSubmitting = false;      
      return;
    }
    this.communicationForm.get('tE_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    if (!this.communicationForm.valid) {
      for (let cnt in this.communicationForm.controls) {
        if (this.communicationForm.controls[cnt].invalid) {
          console.log(cnt, this.communicationForm.controls[cnt]);
        }
      }
      return;
    }

    this.communicationForm.get('tE_ME_SPEECH').setValue(null);
    this.communicationForm.get('tE_ME_SIGNS').setValue(null);
    this.communicationForm.get('tE_ME_WRITING').setValue(null);
    this.communicationForm.get('tE_ME_BOARD').setValue(null);
    this.communicationForm.get('tE_ME_ASL').setValue(null);
    this.communicationForm.get('tE_ME_INTERPRETR').setValue(null);
    this.communicationForm.get('tE_ME_DEVICE').setValue(null);
    let expressionList: [] = this.communicationForm.get('tE_EXPRESION').value;

    if (Array.isArray(expressionList)) {
      expressionList.forEach(value => {
        switch (value) {
          case 'SPEECH':
            this.communicationForm.get('tE_ME_SPEECH').setValue('Y');
            break;
          case 'SIGNS':
            this.communicationForm.get('tE_ME_SIGNS').setValue('Y');
            break;
          case 'WRITING':
            this.communicationForm.get('tE_ME_WRITING').setValue('Y');
            break;
          case 'BOARD':
            this.communicationForm.get('tE_ME_BOARD').setValue('Y');
            break;
          case 'ASL':
            this.communicationForm.get('tE_ME_ASL').setValue('Y');
            break;
          case 'INTERPRETR':
            this.communicationForm.get('tE_ME_INTERPRETR').setValue('Y');
            break;
          case 'DEVICE':
            this.communicationForm.get('tE_ME_DEVICE').setValue('Y');
            break;         
          default:
            break;
        }
      });
    }

    let communicationFormData = this.communicationForm.value;     
    this.sectionEService.PostCommunication(communicationFormData).subscribe(response => {
      this.alertService.success('Communication Saved');
    }, err => {
        console.error('Error Saving Communication', err);
        this.alertService.error("Error Saving Communication");
    });

    this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.communicationForm.reset();
      }
    });

  }
  warningAlert() {
    Swal.fire(
      '',
      'Please add atleast one communication information',
      'warning'
    )
  }

}
