import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionARoutingModule } from './section-a-routing.module';
import { SectionAComponent } from './section-a.component';
import { AssessInfoComponent } from './assess-info/assess-info.component';
import { AssessParticipantsComponent } from './assess-participants/assess-participants.component';
import { LanguageSpokenComponent } from './language-spoken/language-spoken.component';
import { InsuranceInfoComponent } from './insurance-info/insurance-info.component';
import { LivingSituationComponent } from './living-situation/living-situation.component';
import { VeteranStatusComponent } from './veteran-status/veteran-status.component';

@NgModule({
  declarations: [
    SectionAComponent,
    AssessInfoComponent,
    AssessParticipantsComponent,
    LanguageSpokenComponent,
    InsuranceInfoComponent,
    LivingSituationComponent,
    VeteranStatusComponent],
  imports: [
    CommonModule,
    SectionARoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionAModule { }
