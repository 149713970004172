import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AdaptiveAssistiveEquipComponent } from './adaptive-assistive-equip/adaptive-assistive-equip.component';

@Injectable({
    providedIn: 'root'
})
export class SectionJUnsavedDataGuardGuard implements CanDeactivate<AdaptiveAssistiveEquipComponent> {


    canDeactivate(component: AdaptiveAssistiveEquipComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        console.log('unsaved ?', component.canNavigateAway());
        if (!component.canNavigateAway()) {
            return confirm('There is unsaved changes present \n Do you want to navigate away ?');
        } else {
            return true;
        }
    }

}
