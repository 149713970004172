import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Supports } from '../models';
@Injectable({
  providedIn: 'root'
})
export class SectionIService {

  constructor(private apiService: ApiService) { }

  getSupports(acatId): Observable<Supports> {
    return this.apiService.get('sectioni/' + acatId).pipe(map(data => { return data; }));
  }

  //postSupports(supports): Observable<any> {
  //  return this.apiService.post('sectioni', supports).pipe(map(data => { return data; }));
  //}
  PostSectionI(formData): Observable<any> {
    return this.apiService.post('sectioni/PostSectionI', formData);
  }
  LoadSupport(alocID: string): Observable<Supports[]> {
    return this.apiService.get(`sectioni/LoadSupport/${alocID}`).pipe(map(data => {
      return data;
    }));
  }
  DeleteSupport(sectionID: number): Observable<any> {
    return this.apiService.delete(`sectioni/DeleteSupport/${sectionID}`);
  }
  GetEditSupport(sectionID: number, acatID: number): Observable<any> {
    return this.apiService.get(`sectioni/GetEditSupport/${sectionID}/${acatID}`);
  }
}
