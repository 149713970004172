export interface MCPReview {
    aO_ALOC_ID: number;
    aO_DENIAL_REASON: string;
    aO_ENROLLMENT_REQUEST_DATE: string;
    aO_ENROLLMENT_DATE: string;
    aO_ESTIMATED_COST: number;

}


export interface TopSummary {
    //documentid: string;
    //type: string;
    //status: string;
    //name: string;
    //ssn: string;
    //dateofbirth: string;
    //submitterfacility: string;
    //submittername: string;
    //submitdate: string;
    //paa: string;
    //clientnumber: string;
    //aS_ADLS_INDEPENDENT: string;
    //aS_ADLS_SUPERVISED: string;
    //aS_ADLS_ASSISTED: string;
    //aS_IADLS_INDEPENDENT: string;
    //aS_IADLS_SUPERVISED: string;
    //aS_IADLS_ASSISTED: string;
    //aS_MED_ADMIN: string;
    //aS_MED_ADMIN_INDEPENDENT: string;
    //aS_MED_ADMIN_SUPERVISED: string;
    //aS_MED_ADMIN_ASSISTED: string;
    //aS_BCAT_SCORE: string;
    //aS_MEDICAL_CONDITION: string;
    //aS_SKILLED_NURSING_DAYS: string;
    //aS_SKILLED_NURSING_SERVICE: string;
    //aS_SKILLED_REHAB_DAYS: string;
    //aS_SKILLED_REHAB_SERVICE: string;
}

export interface SummaryRequest {
    aS_SESSION_USER: string;
    aS_SESSION_ID: string;
    aS_BEGIN_DATE: string;
    aS_END_DATE: string;
    aS_ID: string;
    aS_TYPE: string;
    aS_SSN: string;
    aS_MEDICAID_BILLING_NUMBER: string;
    aS_FIRST_NAME: string;
    aS_LAST_NAME: string;
    aS_CREATE_FACILITY_ID: string;
    aS_CREATE_WORKER: string;
    aS_RECEIVE_FACILITY_ID: number;
    aS_CLIENT_NUMBER: string;
    aS_PIMS_EVENT_ID: number;
    aS_CATEGORICAL_IND: string;
    aS_LEVEL_II: string;
    aS_DATE_OF_BIRTH: string;
    aS_NF_STATUS: string;
    aS_DODD_STATUS: string;
    aS_DOCUMENT_STATUS: string;
}

export interface SummaryResponse {
    categorical: string;
    documenT_ID: string;
    lasT_NAME: string;
    firsT_NAME: string;
    name: string;
    ssn: string;
    datE_OF_BIRTH: string;
    medicaiD_NUMBER: string;
    documenT_TYPE: string;
    submitteR_FACILITY: string;
    submiT_DATE: string;
    creatE_DATE: string;
    status: string;
    odmH_STATUS: string;
    dodD_STATUS: string;
    locaL_STATUS: string;
    odmH_REFERRAL_REASON: string;
    odmH_REVIEW_RESULTS: string;
    odmH_REVIEW_DATE: string;
    dodD_REFERRAL_REASON: string;
    dodD_REVIEW_RESULTS: string;
    dodD_REVIEW_DATE: string;
    cbdD_REVIEW_DATE: string;
    pimS_CLIENT_NUMBER: string;
    pimS_EVENT_ID: string;
    type: string;
  submitteR_NAME: string;
  assessoR_NAME: string;
    nF_NAME: string;
    hospitaL_NAME: string;
    countY_CODE: string;
    nF_ID: string;
    hospitaL_ID: string;
    psycH_DISCHARGE: string;
    dB_ID: string;
    statuS_DESC: string;
    odmH_REVIEW_WORKER: string;
    dodD_REVIEW_WORKER: string;
    cbdD_REVIEW_WORKER: string;
    submitteR_PHONE: string;
    submitteR_EMAIL: string;
    submitteR_FAX: string;
    nF_PHONE: string;
    nF_FAX: string;
    dodD_PHONE: string;
    dodD_FAX: string;
    dodD_EMAIL: string;
    odmH_PHONE: string;
    odmH_FAX: string;
    odmH_EMAIL: string;
    cbdD_NAME: string;
    cbdD_PHONE: string;
    cbdD_FAX: string;
    cbdD_EMAIL: string;
    paA_NAME: string;
    paA_PHONE: string;
    paA_FAX: string;
    paA_EMAIL: string;
    creatE_WORKER: string;
    ABIGAIL: string;
    createD_AT: string;
    paa: string;
    consumeR_NAME: string;
    clienT_NUMBER: string;
    useR_AGENCY: string;
    creatE_FACILITY_ID: string;
    sex: string;
    useR_NAME: string;
    institutioN_ID: string;
    useR_TYPE: string;
    attachmenT_ID: string;
    attachmenT_NAME: string;
    prinT_IND: string;
    fulL_ACCESS: string;
    loaD_IND: string;
    ediT_IND: string;
    withdraW_IND: string;
    deletE_IND: string;
    fullvieW_IND: string;
    dS_CREATOR_ACCESS: string;
    dB_CREATE_FACILITY_ID: string;
    dS_HOSPITAL_ACCESS: string;
    dS_NF_ACCESS: string;
    dS_PAA_ACCESS: string;
    dS_ODMH_ACCESS: string;
    dS_DODD_ACCESS: string;
    dS_LOCAL_ACCESS: string;
    dB_TYPE: string;
    reviewer: string;
    tO_PAS_ID: string;
    tO_ASSESSMENT_OUTCOME: string;
    tO_ADVERSE_LOC_REQUIRED: string;
    tO_ADVERSE_DETERMINATION: string;
    tO_ADVERSE_ASSESSMENT_NBR: string;
    tO_ACAT_DETERMINATION: string;
    tO_PAS_VALIDATION: string;
    tO_REQUEST_COMPLETE_DATE: string;
    tO_DOC_REQUEST_COMMENTS: string;
    tO_DOC_REQUEST_DATE: string;
    tO_NEEDS_FACE_TO_FACE: string;
    tO_ADVERSE_LOC_SCHED_DATE: string;
    tO_DELAYED_LOC_NEEDED: string;
    tO_DELAYED_EXEMPTION_REASON: string;
    tO_LOC_RECOMMENDATION: string;
    tO_LOC_DETERMINATION: string;
    tO_LOC_DETERMINATION_DATE: string;
    tO_LOC_DETERMINATION_STATUS: string;
    tO_LOC_EFFECTIVE_DATE: string;
    tO_PAS_EFFECTIVE_DATE: string;
    tO_ENROLLMENT_SELECTION: string;
    scorE_x0020_ID: string;
    acaT_x0020_ID: string;
    tS_ADLS_INDEPENDENT: string;
    tS_ADLS_SUPERVISED: string;
    tS_ADLS_ASSISTED: string;
    tS_IADLS_INDEPENDENT: string;
    tS_IADLS_SUPERVISED: string;
    tS_IADLS_ASSISTED: string;
    tS_MED_ADMIN: string;
    tS_MED_ADMIN_INDEPENDENT: string;
    tS_MED_ADMIN_SUPERVISED: string;
    tS_MED_ADMIN_ASSISTED: string;
    tS_BCAT_SCORE: string;
    tS_MEDICAL_CONDITION: string;
    tS_SKILLED_NURSING_DAYS: string;
    tS_SKILLED_NURSING_SERVICE: string;
    tS_SKILLED_REHAB_DAYS: string;
    tS_SKILLED_REHAB_SERVICE: string;
    tO_CREATE_WORKER: string;
    tO_TYPE: string;
    tO_DENIAL_REASON: string;
    tO_PAA_DETERM_NOTE: string;
    tO_PAA_DETERM_NOTE_DATE: string;
    odmH_REASON_TEXT: string;

}

export type LOCDetermination = {
    tO_ACAT_ID: string;
    tO_REQUEST_COMPLETE_DATE: string;
    tO_DOC_REQUEST_DATE: string;
    tO_DOC_REQUEST_COMMENTS: string;
    tO_PAS_VALIDATION: string;
    tO_PAS_ID: string;
    tO_PAS_EFFECTIVE_DATE: string;
    tO_NEEDS_FACE_TO_FACE: string;
    tO_ADVERSE_LOC_SCHED_DATE: string;
    tO_DELAYED_LOC_NEEDED: string;
    tO_DELAYED_EXEMPTION_REASON: string;
    tO_LOC_DETERMINATION_STATUS: string;
    tO_LOC_EFFECTIVE_DATE: string;
    tO_LOC_DETERMINATION_DATE: string;
    tO_LOC_RECOMMENDATION: string;
    tO_LOC_DETERMINATION: string;
    tO_PAA_DETERM_NOTE: string;
    tO_PAA_DETERM_NOTE_DATE: string;
}

export let SummaryFields = {

    categorical: 'categorical',
    documenT_ID: 'documenT_ID',
    lasT_NAME: 'lasT_NAME',
    firsT_NAME: 'firsT_NAME',
    nname: 'name',
    ssn: 'ssn',
    datE_OF_BIRTH: 'datE_OF_BIRTH',
    medicaiD_NUMBER: 'medicaiD_NUMBER',
    documenT_TYPE: 'documenT_TYPE',
    submitteR_FACILITY: 'submitteR_FACILITY',
    submiT_DATE: 'submiT_DATE',
    creatE_DATE: 'creatE_DATE',
    status: 'status',
    odmH_STATUS: 'odmH_STATUS',
    dodD_STATUS: 'dodD_STATUS',
    locaL_STATUS: 'locaL_STATUS',
    odmH_REFERRAL_REASON: 'odmH_REFERRAL_REASON',
    odmH_REVIEW_RESULTS: 'odmH_REVIEW_RESULTS',
    odmH_REVIEW_DATE: 'odmH_REVIEW_DATE',
    dodD_REFERRAL_REASON: 'dodD_REFERRAL_REASON',
    dodD_REVIEW_RESULTS: 'dodD_REVIEW_RESULTS',
    dodD_REVIEW_DATE: 'dodD_REVIEW_DATE',
    cbdD_REVIEW_DATE: 'cbdD_REVIEW_DATE',
    pimS_CLIENT_NUMBER: 'pimS_CLIENT_NUMBER',
    pimS_EVENT_ID: 'pimS_EVENT_ID',
    type: 'type',
    submitteR_NAME: 'submitteR_NAME',
    nF_NAME: 'nF_NAME',
    hospitaL_NAME: 'hospitaL_NAME',
    countY_CODE: 'countY_CODE',
    nF_ID: 'nF_ID',
    hospitaL_ID: 'hospitaL_ID',
    psycH_DISCHARGE: 'psycH_DISCHARGE',
    dB_ID: 'dB_ID',
    statuS_DESC: 'statuS_DESC',
    odmH_REVIEW_WORKER: 'odmH_REVIEW_WORKER',
    dodD_REVIEW_WORKER: 'dodD_REVIEW_WORKER',
    cbdD_REVIEW_WORKER: 'cbdD_REVIEW_WORKER',
    submitteR_PHONE: 'submitteR_PHONE',
    submitteR_EMAIL: 'submitteR_EMAIL',
    submitteR_FAX: 'submitteR_FAX',
    nF_PHONE: 'nF_PHONE',
    nF_FAX: 'nF_FAX',
    dodD_PHONE: 'dodD_PHONE',
    dodD_FAX: 'dodD_FAX',
    dodD_EMAIL: 'dodD_EMAIL',
    odmH_PHONE: 'odmH_PHONE',
    odmH_FAX: 'odmH_FAX',
    odmH_EMAIL: 'odmH_EMAIL',
    cbdD_NAME: 'cbdD_NAME',
    cbdD_PHONE: 'cbdD_PHONE',
    cbdD_FAX: 'cbdD_FAX',
    cbdD_EMAIL: 'cbdD_EMAIL',
    paA_NAME: 'paA_NAME',
    paA_PHONE: 'paA_PHONE',
    paA_FAX: 'paA_FAX',
    paA_EMAIL: 'paA_EMAIL',
    creatE_WORKER: 'creatE_WORKER',
    ABIGAIL: 'ABIGAIL',
    createD_AT: 'createD_AT',
    paa: 'paa',
    consumeR_NAME: 'consumeR_NAME',
    clienT_NUMBER: 'clienT_NUMBER',
    useR_AGENCY: 'useR_AGENCY',
    creatE_FACILITY_ID: 'creatE_FACILITY_ID',
    sex: 'sex',
    useR_NAME: 'useR_NAME',
    institutioN_ID: 'institutioN_ID',
    useR_TYPE: 'useR_TYPE',
    attachmenT_ID: 'attachmenT_ID',
    attachmenT_NAME: 'attachmenT_NAME',
    prinT_IND: 'prinT_IND',
    fulL_ACCESS: 'fulL_ACCESS',
    loaD_IND: 'loaD_IND',
    ediT_IND: 'ediT_IND',
    withdraW_IND: 'withdraW_IND',
    deletE_IND: 'deletE_IND',
    fullvieW_IND: 'fullvieW_IND',
    dS_CREATOR_ACCESS: 'dS_CREATOR_ACCESS',
    dB_CREATE_FACILITY_ID: 'dB_CREATE_FACILITY_ID',
    dS_HOSPITAL_ACCESS: 'dS_HOSPITAL_ACCESS',
    dS_NF_ACCESS: 'dS_NF_ACCESS',
    dS_PAA_ACCESS: 'dS_PAA_ACCESS',
    dS_ODMH_ACCESS: 'dS_ODMH_ACCESS',
    dS_DODD_ACCESS: 'dS_DODD_ACCESS',
    dS_LOCAL_ACCESS: 'dS_LOCAL_ACCESS',
    dB_TYPE: 'dB_TYPE',
    reviewer: 'reviewer',
    tO_PAS_ID: 'tO_PAS_ID',
    tO_PAS_VALIDATION: 'tO_PAS_VALIDATION',
    tO_REQUEST_COMPLETE_DATE: 'tO_REQUEST_COMPLETE_DATE',
    tO_DOC_REQUEST_COMMENTS: 'tO_DOC_REQUEST_COMMENTS',
    tO_DOC_REQUEST_DATE: 'tO_DOC_REQUEST_DATE',
    tO_NEEDS_FACE_TO_FACE: 'tO_NEEDS_FACE_TO_FACE',
    tO_ADVERSE_LOC_SCHED_DATE: 'tO_ADVERSE_LOC_SCHED_DATE',
    tO_DELAYED_LOC_NEEDED: 'tO_DELAYED_LOC_NEEDED',
    tO_DELAYED_EXEMPTION_REASON: 'tO_DELAYED_EXEMPTION_REASON',
    tO_LOC_RECOMMENDATION: 'tO_LOC_RECOMMENDATION',
    tO_LOC_DETERMINATION: 'tO_LOC_DETERMINATION',
    tO_LOC_DETERMINATION_DATE: 'tO_LOC_DETERMINATION_DATE',
    tO_LOC_DETERMINATION_STATUS: 'tO_LOC_DETERMINATION_STATUS',
    tO_LOC_EFFECTIVE_DATE: 'tO_LOC_EFFECTIVE_DATE',
    tO_PAS_EFFECTIVE_DATE: 'tO_PAS_EFFECTIVE_DATE',
    tO_ENROLLMENT_SELECTION: 'tO_ENROLLMENT_SELECTION',
    scorE_x0020_ID: 'scorE_x0020_ID',
    acaT_x0020_ID: 'acaT_x0020_ID',
    tS_ADLS_INDEPENDENT: 'tS_ADLS_INDEPENDENT',
    tS_ADLS_SUPERVISED: 'tS_ADLS_SUPERVISED',
    tS_ADLS_ASSISTED: 'tS_ADLS_ASSISTED',
    tS_IADLS_INDEPENDENT: 'tS_IADLS_INDEPENDENT',
    tS_IADLS_SUPERVISED: 'tS_IADLS_SUPERVISED',
    tS_IADLS_ASSISTED: 'tS_IADLS_ASSISTED',
    tS_MED_ADMIN: 'tS_MED_ADMIN',
    tS_MED_ADMIN_INDEPENDENT: 'tS_MED_ADMIN_INDEPENDENT',
    tS_MED_ADMIN_SUPERVISED: 'tS_MED_ADMIN_SUPERVISED',
    tS_MED_ADMIN_ASSISTED: 'tS_MED_ADMIN_ASSISTED',
    tS_BCAT_SCORE: 'tS_BCAT_SCORE',
    tS_MEDICAL_CONDITION: 'tS_MEDICAL_CONDITION',
    tS_SKILLED_NURSING_DAYS: 'tS_SKILLED_NURSING_DAYS',
    tS_SKILLED_NURSING_SERVICE: 'tS_SKILLED_NURSING_SERVICE',
    tS_SKILLED_REHAB_DAYS: 'tS_SKILLED_REHAB_DAYS',
    tS_SKILLED_REHAB_SERVICE: 'tS_SKILLED_REHAB_SERVICE',
    tO_CREATE_WORKER: 'tO_CREATE_WORKER',
    tO_TYPE: 'tO_TYPE',
    tO_DENIAL_REASON: 'tO_DENIAL_REASON',
    tO_PAA_DETERM_NOTE: 'tO_PAA_DETERM_NOTE',
    tO_PAA_DETERM_NOTE_DATE: 'tO_PAA_DETERM_NOTE_DATE',
    odmH_REASON_TEXT: 'odmH_REASON_TEXT'
}

export let TopSummaryFields = {
    documenT_ID: 'documenT_ID',
    type: 'type',
    status: 'status',
    HOSPITAL_NAME: 'HOSPITAL_NAME',
    submiT_DATE: 'submiT_DATE',
    submitteR_NAME: 'submitteR_NAME',
    consumeR_NAME: 'consumeR_NAME',
    ssn: 'ssn',
    datE_OF_BIRTH: 'datE_OF_BIRTH',
    clienT_NUMBER: 'clienT_NUMBER',
    medicaiD_NUMBER: 'medicaiD_NUMBER',
    useR_NAME: 'useR_NAME',
    useR_AGENCY: 'useR_AGENCY',
    countY_CODE: 'countY_CODE',
    paA_NAME: 'paA_NAME',
    tS_ADLS_INDEPENDENT: 'tS_ADLS_INDEPENDENT',
    tS_ADLS_SUPERVISED: 'tS_ADLS_SUPERVISED',
    tS_ADLS_ASSISTED: 'tS_ADLS_ASSISTED',
    tS_IADLS_INDEPENDENT: 'tS_IADLS_INDEPENDENT',
    tS_IADLS_SUPERVISED: 'tS_IADLS_SUPERVISED',
    tS_IADLS_ASSISTED: 'tS_IADLS_ASSISTED',
    tS_MED_ADMIN_INDEPENDENT: 'tS_MED_ADMIN_INDEPENDENT',
    tS_MED_ADMIN_SUPERVISED: 'tS_MED_ADMIN_SUPERVISED',
    tS_MED_ADMIN_ASSISTED: 'tS_MED_ADMIN_ASSISTED',
    tS_SKILLED_NURSING_SERVICE: 'tS_SKILLED_NURSING_SERVICE',
    tS_SKILLED_NURSING_DAYS: 'tS_SKILLED_NURSING_DAYS',
    tS_BCAT_SCORE: 'tS_BCAT_SCORE',
    tS_SKILLED_REHAB_SERVICE: 'tS_SKILLED_REHAB_SERVICE',
    tS_SKILLED_REHAB_DAYS: 'tS_SKILLED_REHAB_DAYS',
  tS_MEDICAL_CONDITION: 'tS_MEDICAL_CONDITION',
  assessoR_NAME: 'assessoR_NAME',
  submitteR_FACILITY: 'submitteR_FACILITY',

}

export interface SavePAAReviewModel {
    tO_ACAT_ID: number;
    tO_ASSESSMENT_OUTCOME: string;
    tO_ADVERSE_LOC_REQUIRED: string;
    tO_ADVERSE_LOC_SCHED_DATE: string;
    tO_ADVERSE_DETERMINATION: string;
    tO_ADVERSE_ASSESSMENT_NBR: string;
    tO_ACAT_DETERMINATION: string;
    tO_DENIAL_REASON: string;
    tO_LOC_DETERMINATION_STATUS: string;
    tO_PAA_DETERM_NOTE: string;
    tO_ENROLLMENT_SELECTION: string;

}
