import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { SectionDService, DataService, AlertsService, SectionDropdownService } from '../../shared';
import { Medication, MedicationAdmin, MedicationAdminFields } from './medication-admin';
import { DropdownOptionsType } from '../../shared/common/DropDownOptionsType';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { AdaptiveAssistiveEquipComponent } from '../../section-j/adaptive-assistive-equip/adaptive-assistive-equip.component';
import { MedicationNewComponent } from './medication-new/medication-new.component';
import { diaMode, dialogModeConfig } from './medication-new/dialogOptions';
import { ConfirmMedDeleteComponent } from './confirm-med-delete/confirm-med-delete.component';
import { forkJoin } from 'rxjs';
import { confirmDialogParam } from '../../shared/components/confirm-dialog/confirm-dialog-common';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-medication-admin',
    templateUrl: './medication-admin.component.html',
    styleUrls: ['./medication-admin.component.css']
})
export class MedicationAdminComponent implements OnInit {

    constructor(
        private cntxService: DataService,
        private alerts: AlertsService,
        private sectiondSerice: SectionDService,
        private dropdownService: SectionDropdownService,
        public addNewDialog: MatDialog,
        public clearDia: MatDialog) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    //drop down variables
    assistanceOptions: DropdownOptionsType[];
    screenName = "Medication Administration";
    medicalAdminForm: FormGroup;

    showMedication = false;
    showWhoMoni = false;
    showSetUp = false;
    showMedCommnt = false;

    ngOnInit() {

        this.documentReferance = { alocid: '', sectionid: '', name: '' };;
        this.documentReferance.alocid = this.cntxService.getSelectedDocument();
        this.documentReferance.name = this.cntxService.getSelectedDocumentName();

        this.initForm();
        this.loadData();

    }

    initForm() {

        let formFields = {};
        for (let fld in MedicationAdminFields) {
            formFields[fld] = new FormControl();
        }
        this.medicalAdminForm = new FormGroup(formFields);
        this.medicalAdminForm.get(MedicationAdminFields.tD_MED_IND).setValidators(Validators.required);
        this.medicalAdminForm.get(MedicationAdminFields.tD_MED_ASSISTANCE).setValidators(Validators.required);
        this.medicalAdminForm.get(MedicationAdminFields.tD_MED_CHANGE_IND).setValidators(Validators.required);
        this.medicalAdminForm.get(MedicationAdminFields.tD_ACAT_ID).setValidators(Validators.required);

        //med int
        this.medicalAdminForm.get(MedicationAdminFields.tD_MED_IND).valueChanges.subscribe(val => {
            if (val == null || val == 'N') {
                this.showMedication = false;
            } else if (val == 'Y') {
                this.showMedication = true;
            }
        });

        //assist
        this.medicalAdminForm.get(MedicationAdminFields.tD_MED_ASSISTANCE).valueChanges.subscribe(val => {
            this.showSetUp = false;
            this.showWhoMoni = false;
            this.medicalAdminForm.get(MedicationAdminFields.tD_MED_SETUP).clearValidators();
            this.medicalAdminForm.get(MedicationAdminFields.tD_MED_MONITOR).clearValidators();
            switch (val) {
                case 'S':
                    this.showWhoMoni = true;
                    this.medicalAdminForm.get(MedicationAdminFields.tD_MED_MONITOR).setValidators(Validators.required);
                    break;
                case 'A':
                    this.showSetUp = true;
                    this.medicalAdminForm.get(MedicationAdminFields.tD_MED_SETUP).setValidators(Validators.required);
                    break;
                case 'I':
                    break;
                default:
                    break;
            }
            this.medicalAdminForm.get(MedicationAdminFields.tD_MED_MONITOR).updateValueAndValidity();
            this.medicalAdminForm.get(MedicationAdminFields.tD_MED_SETUP).updateValueAndValidity();
        });

        //med change
        this.medicalAdminForm.get(MedicationAdminFields.tD_MED_CHANGE_IND).valueChanges.subscribe(val => {
            this.medicalAdminForm.get(MedicationAdminFields.tD_MED_CHANGE_COMMENT).reset();
            if (val == null || val == 'N') {
                this.showMedCommnt = false;
                this.medicalAdminForm.get(MedicationAdminFields.tD_MED_CHANGE_COMMENT).clearValidators();                
            } else if (val == 'Y') {
                this.showMedCommnt = true;
                this.medicalAdminForm.get(MedicationAdminFields.tD_MED_CHANGE_COMMENT).setValidators(Validators.required);
            }
            this.medicalAdminForm.get(MedicationAdminFields.tD_MED_CHANGE_COMMENT).updateValueAndValidity();
        });


    }

    loadData() {
        //assistance options
        let med_asst = this.dropdownService.GetDropDownValues('MED_ADM_ASSIST');
        //load medication admin data
        let med_adm = this.sectiondSerice.getMedicationAdmin(parseInt(this.documentReferance.alocid));
        forkJoin(med_asst, med_adm).subscribe(([medAsst, medAdm]) => {
            this.assistanceOptions = medAsst;
            this.medicalAdminForm.patchValue(medAdm);
            this.documentReferance.sectionid = medAdm.tD_SECTION_ID == null ? null : medAdm.tD_SECTION_ID.toString();
            if (medAdm.tD_ACAT_ID == null || medAdm.tD_ACAT_ID == 0) {
                this.alerts.error('No data found for Medical Admin');
            } else {
                this.alerts.success(`${this.screenName} Loaded`);
            }
        }, error => {
            console.error(error);
            this.alerts.error('Error loading data');
        });
    }

    onSave() {

        this.medicalAdminForm.get(MedicationAdminFields.tD_ACAT_ID).setValue(this.documentReferance.alocid);
        this.medicalAdminForm.get(MedicationAdminFields.tD_SECTION_ID).setValue(this.documentReferance.sectionid);

        this.medicalAdminForm.markAllAsTouched();
        this.medicalAdminForm.updateValueAndValidity();
        //console.log(this.medicalAdminForm.value);
        
        if (this.medicalAdminForm.invalid) {
            let invcntrls = [];
            Object.keys(this.medicalAdminForm.controls).forEach(key => {
                if (this.medicalAdminForm.controls[key].invalid) {
                    invcntrls.push(key);
                }
            });
            console.error('invalid form ', invcntrls);
            return;
        }

        let frmData = this.medicalAdminForm.value as MedicationAdmin;
        this.sectiondSerice.saveMedicationAdmin(frmData).subscribe(resp => {
            //console.log(resp);
            this.alerts.success(`${this.screenName} saved successfully`);
            this.loadData();
        }, error => {
            console.error(error);
            this.alerts.error('Error saving data');
        });
        

    }

    onClear1() {

        let delConfirm: confirmDialogParam = {
            message: ``,
            title: 'Are you sure ?',
            subtitle: `Entered information will be erased. Press 'Ok' to proceed.`
        };
        const clearDialog = this.clearDia.open(ConfirmDialogComponent, {
            width: '500px',
            disableClose: true,
            data: delConfirm
        });
        clearDialog.afterClosed().subscribe(result => {
            if (result == 'yes') {
                this.medicalAdminForm.reset();
            }
        });
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.medicalAdminForm.reset();
      }
    });

  }
}
