import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionCService, AlertsService, DataService, UserService, getTextForDropdownValues } from '../../shared/index';
import * as moment from 'moment';
import * as ALOCValidators from '../../shared/validators/AlocDateValidators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-legal-concern',
  templateUrl: './legal-concern.component.html',
  styleUrls: ['./legal-concern.component.css']
})
export class LegalConcernComponent implements OnInit {
  legalConcernForm: FormGroup;
  OtherAdvanceHealthDirectives: string;
  RecevingInformation: string;
  DNR: string
  newACATID: any;
  show_tC_DIRECTIVE_PROVIDED_IND_IN=false;
  show_tC_INFO_REQUESTED_IND_IN=false;
  show_tC_INFO_PROVIDED_IND_IN=false;
  show_tC_DNR_PROVIDED_IND_IN=false;

  constructor(private fb: FormBuilder,
    private sectionCService: SectionCService,
    private alertsService: AlertsService,
    public dataService: DataService,
    private router: Router,
    private userService: UserService) {
    this.legalConcernForm = this.fb.group({
      tC_ACAT_ID: new FormControl(''),
      tC_DIRECTIVE_IND: new FormControl('', Validators.required),
      tC_DIRECTIVE_PROVIDED_IND : new FormControl(''),
      tC_INFO_REQUESTED_IND : new FormControl(''),
      tC_INFO_PROVIDED_IND : new FormControl(''),
      tC_DNR_IND : new FormControl(''),
      tC_DNR_PROVIDED_IND : new FormControl(''),
      tC_DONOR_IND : new FormControl(''),
      tC_COMMENTS : new FormControl('')
     
    });
  }

  ngOnInit() {
    this.newACATID = this.dataService.getSelectedDocument();
    if (this.newACATID != null) {
      this.sectionCService.GetSectionCInfo(this.newACATID).subscribe(response => {
        this.receiveLivingSituation(response);
        this.alertsService.success("Legal Concerns Loaded");

      }, err => {
        this.alertsService.error(err);
      });
    }
  }
  receiveLivingSituation(data) {
    this.legalConcernForm.patchValue(data);
    this.setData();
  
  }
  setData() {

    this.OtherAdvanceHealthDirectives = this.legalConcernForm.get('tC_DIRECTIVE_IND').value;
    if (this.OtherAdvanceHealthDirectives === "Y") {
      this.show_tC_DIRECTIVE_PROVIDED_IND_IN = true;
      this.show_tC_INFO_REQUESTED_IND_IN = false;
      this.show_tC_INFO_PROVIDED_IND_IN = false;
      //this.legalConcernForm.get('tC_DIRECTIVE_PROVIDED_IND').setValidators([Validators.required]);
      //this.legalConcernForm.get('tC_INFO_REQUESTED_IND').clearValidators();
      //this.legalConcernForm.get('tC_INFO_PROVIDED_IND').clearValidators();
      
    } else {
      if (this.OtherAdvanceHealthDirectives === "N") {
        this.show_tC_DIRECTIVE_PROVIDED_IND_IN = false;
        this.show_tC_INFO_REQUESTED_IND_IN = true;
        //this.legalConcernForm.get('tC_INFO_REQUESTED_IND').setValidators([Validators.required]);
        //this.legalConcernForm.get('tC_INFO_PROVIDED_IND').setValidators([Validators.required]);
        //this.legalConcernForm.get('tC_DIRECTIVE_PROVIDED_IND').clearValidators();
      }
      

    }

    this.RecevingInformation = this.legalConcernForm.get('tC_INFO_REQUESTED_IND').value;
    if (this.RecevingInformation === "Y") {
      this.show_tC_INFO_PROVIDED_IND_IN = true;
      //this.legalConcernForm.get('tC_INFO_PROVIDED_IND').setValidators([Validators.required]);
    } else {
      this.show_tC_INFO_PROVIDED_IND_IN = false;      
     // this.legalConcernForm.get('tC_INFO_PROVIDED_IND').clearValidators();

    }

    this.DNR = this.legalConcernForm.get('tC_DNR_IND').value;
    if (this.DNR === "Y") {
      this.show_tC_DNR_PROVIDED_IND_IN = true;
     // this.legalConcernForm.get('tC_DNR_PROVIDED_IND').setValidators([Validators.required]);
    } else {
      this.show_tC_DNR_PROVIDED_IND_IN = false;
     // this.legalConcernForm.get('tC_DNR_PROVIDED_IND').clearValidators();
    }
  }
  changeOtherAdvanceHealthDirectives() {
    this.OtherAdvanceHealthDirectives = this.legalConcernForm.get('tC_DIRECTIVE_IND').value;
    if (this.OtherAdvanceHealthDirectives === "Y") {
      this.show_tC_DIRECTIVE_PROVIDED_IND_IN = true;
      this.show_tC_INFO_REQUESTED_IND_IN = false;
      this.show_tC_INFO_PROVIDED_IND_IN = false;

      this.legalConcernForm.get('tC_DIRECTIVE_PROVIDED_IND').setValidators([Validators.required]);
    
      this.legalConcernForm.get('tC_INFO_REQUESTED_IND').clearValidators();
      this.legalConcernForm.get('tC_INFO_PROVIDED_IND').clearValidators();
    } else {
      this.show_tC_DIRECTIVE_PROVIDED_IND_IN = false;
      this.show_tC_INFO_REQUESTED_IND_IN = true;
      this.legalConcernForm.get('tC_INFO_REQUESTED_IND').setValidators([Validators.required]);
      this.legalConcernForm.get('tC_INFO_PROVIDED_IND').setValidators([Validators.required]);

     
      this.legalConcernForm.get('tC_DIRECTIVE_PROVIDED_IND').clearValidators();
     
    }
    this.legalConcernForm.get('tC_DIRECTIVE_PROVIDED_IND').updateValueAndValidity();
    this.legalConcernForm.get('tC_INFO_REQUESTED_IND').updateValueAndValidity();
    this.legalConcernForm.get('tC_INFO_PROVIDED_IND').updateValueAndValidity();
  }
  changeRecevingInformation() {
    this.RecevingInformation = this.legalConcernForm.get('tC_INFO_REQUESTED_IND').value;
    if (this.RecevingInformation === "Y") {
      this.show_tC_INFO_PROVIDED_IND_IN = true;
      this.legalConcernForm.get('tC_INFO_PROVIDED_IND').setValidators([Validators.required]);
    } else {
      this.show_tC_INFO_PROVIDED_IND_IN = false;    
      this.legalConcernForm.get('tC_INFO_PROVIDED_IND').clearValidators();

    }
    this.legalConcernForm.get('tC_INFO_PROVIDED_IND').updateValueAndValidity();
    
  }
  changeDNR() {
    this.DNR = this.legalConcernForm.get('tC_DNR_IND').value;
    if (this.DNR === "Y") {
      this.show_tC_DNR_PROVIDED_IND_IN = true;
      this.legalConcernForm.get('tC_DNR_PROVIDED_IND').setValidators([Validators.required]);
    } else {
      this.show_tC_DNR_PROVIDED_IND_IN = false;    
      this.legalConcernForm.get('tC_DNR_PROVIDED_IND').clearValidators();
    }
    this.legalConcernForm.get('tC_DNR_PROVIDED_IND').updateValueAndValidity();
  }
  legalConcernFormSave() {


    this.legalConcernForm.get('tC_ACAT_ID').setValue(this.dataService.getSelectedDocument());
   

    if (!this.legalConcernForm.valid) {
      for (let cnt in this.legalConcernForm.controls) {
        if (this.legalConcernForm.controls[cnt].invalid) {
          console.log(cnt, this.legalConcernForm.controls[cnt]);
        }
      }
      return;
    }

    let legalConcernFormData = this.legalConcernForm.value;  

    this.sectionCService.PostSectionC(legalConcernFormData).subscribe(resp => {      
      this.alertsService.success("Legal Concerns Saved");
    }, error => {
        console.error('Error Saving Legal Concerns', error);
        this.alertsService.error("Error Saving Legal Concerns");
    }); 

  }
  clearlegalConcernForm() {
    this.onClear();
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.show_tC_DIRECTIVE_PROVIDED_IND_IN = false;
        this.show_tC_INFO_REQUESTED_IND_IN = false;
        this.show_tC_INFO_PROVIDED_IND_IN = false;
        this.show_tC_DNR_PROVIDED_IND_IN = false;

        this.legalConcernForm.get('tC_INFO_PROVIDED_IND').clearValidators();
        this.legalConcernForm.get('tC_DNR_PROVIDED_IND').clearValidators();
        this.legalConcernForm.get('tC_DIRECTIVE_PROVIDED_IND').clearValidators();
        this.legalConcernForm.get('tC_INFO_REQUESTED_IND').clearValidators();

        this.legalConcernForm.reset();
      }
    });

  }
}
