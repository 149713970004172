import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionLService, EmploymentSchool, DropdownModel } from '../../shared';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-employment-school',
  templateUrl: './employment-school.component.html',
  styleUrls: ['./employment-school.component.css']
})
export class EmploymentSchoolComponent implements OnInit {
    formStatus: boolean;
    employmentSchoolForm: FormGroup;
    isSubmitting = false;
    employmentSchool: EmploymentSchool = {} as EmploymentSchool;
    employschool: DropdownModel[];
  
    constructor(private fb: FormBuilder,
        private dropdownSerice: SectionDropdownService,
        private alertService: AlertsService,
        public dataService: DataService,
        public sectionLService: SectionLService
    ) {
        this.employmentSchoolForm = this.fb.group({            
            tL_ACAT_ID: new FormControl(''),
            tL_CG_EMPLOYMENT: new FormControl(''),
            tL_CG_ENROLLED: new FormControl(''),
        });
    }

  ngOnInit() {

    if (this.dataService.getSelectedDocument() != null) {
      this.sectionLService.GetSectionLInfo(this.dataService.getSelectedDocument()).subscribe(response => {
        this.employmentSchoolForm.patchValue(response);
        this.alertService.success("Caregiver Employment and School Loaded");
      }, err => {
        this.alertService.error(err);
      });
    }

    this.loadDropdown();        

  }
  loadDropdown() {
    this.dropdownSerice.GetDropDownValues('CAREGIVER_EMPLOYMENT_STATUS').subscribe(data => {
      this.employschool = data;
    }, error => {
      this.alertService.error(error);
    });
  }
    ClearForm(e) {
      this.onClear();
  }
  
    submitForm() {
      this.isSubmitting = true;
      this.employmentSchoolForm.get('tL_ACAT_ID').setValue('');
      for (const field in this.employmentSchoolForm.controls) {

        if (this.employmentSchoolForm.controls[field].value == null || this.employmentSchoolForm.controls[field].value == '') {
          this.formStatus = false;
        } else {
          this.formStatus = true;
          break;
        }
      }

      if (this.formStatus == false) {
        this.warningAlert();
        this.isSubmitting = false;
        return;
      }
      this.employmentSchoolForm.get('tL_ACAT_ID').setValue(this.dataService.getSelectedDocument());
      console.log(this.employmentSchool);
      this.sectionLService.postCaregiverEmploymentandSchool(this.employmentSchoolForm.value).subscribe(response => {
        this.alertService.success('Caregiver Employment and School Saved');
      }, err => {
          console.error('Error Saving Caregiver Employment and School', err);
          this.alertService.error("Error Saving Caregiver Employment and School");
      });
        this.isSubmitting = false;
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.employmentSchoolForm.reset();
      }
    });

  }
  warningAlert() {
    Swal.fire(
      '',
      'Please add atleast one caregiver employment and school information',
      'warning'
    )
  }
}
