import { Directive, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[appFilterChars]'
})
export class FilterCharsDirective {

    constructor() { }


    @Input('allowedChars')
    charsToAllow: string;

    @Input('allowedKeys')
    allowedKeys = ['Delete', 'Backspace'];

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        //console.log(e);
        if (e.ctrlKey && e.key == 'v') {
            return true;
        }
        if (this.allowedKeys.indexOf(e.key) > -1) {
            return;
        }
        let allowed = new RegExp(this.charsToAllow);

        if (!allowed.test(e.key)) {
            e.preventDefault();
            return;
        }
    }

    //@HostListener('paste', ['$event'])
    //onPaste(e: ClipboardEvent) {
    //    let paste = (e.clipboardData).getData('text');//|| window.clipboardData
    //    let allowed = new RegExp(this.charsToAllow);
    //    if (!allowed.test(paste)) {
    //        e.stopPropagation();
    //        e.preventDefault();
    //        return;
    //    }
    //}

}
