import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-med-ref-tbl',
  templateUrl: './med-ref-tbl.component.html',
  styleUrls: ['./med-ref-tbl.component.css']
})
export class MedRefTblComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
