import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionIService, AlertsService, DataService, UserService, getTextForDropdownValues, SectionDropdownService } from '../../../shared/index';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-new-support',
  templateUrl: './add-new-support.component.html',
  styleUrls: ['./add-new-support.component.css']
})
export class AddNewSupportComponent implements OnInit {
  showBtn: boolean;
  addNewSupportFrm: FormGroup;
  supportProviderTypeList: any[];
  supportTypeList: any[];
  supportFundingSourceList: any[];
  supportNaturalList: any[];
  supportCommunity: any[];
  show_tI_SUPPORT_FUNDING_OTHER = false;
  show_tI_SUPPORT_COM_OTHER_DESC = false;
  show_tI_SUPPORT_NAT_OTHER_DESC = false;
  show_tI_SUPPORT_NAT_LIST = false;
  show_tI_SUPPORT_COM_LIST = false;
  formStatus: boolean;
  dropDownSelectText = getTextForDropdownValues;
  constructor(private fb: FormBuilder,
    private sectionIService: SectionIService,
    private alertsService: AlertsService,
    public dataService: DataService,
    private dropdownservice: SectionDropdownService,
    private userService: UserService,
    public dialogRef: MatDialogRef<AddNewSupportComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: any) {
    this.addNewSupportFrm = this.fb.group({

      tI_SECTION_ID: new FormControl(null),
      tI_ACAT_ID: new FormControl(''),
      tI_CURRENT_SUPPORT_IND: new FormControl(''),
      tI_SUPPORT_PROVIDER: new FormControl(null, [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60)]),
      tI_SUPPORT_PROVIDER_TYPE: new FormControl(null),
      tI_SUPPORT_SUPPORT_TYPE: new FormControl(null),
      tI_SUPPORT_NAT_LIST: new FormControl(''),
      tI_SUPPORT_NAT_PTOTST_IND: new FormControl(''),
      tI_SUPPORT_NAT_SN_IND: new FormControl(''),
      tI_SUPPORT_NAT_PC_IND: new FormControl(''),
      tI_SUPPORT_NAT_HOME_IND: new FormControl(''),
      tI_SUPPORT_NAT_MEAL_IND: new FormControl(''),
      tI_SUPPORT_NAT_LAUNDRY_IND: new FormControl(''),
      tI_SUPPORT_NAT_SHOPPING_IND: new FormControl(''),
      tI_SUPPORT_NAT_TRANS_IND: new FormControl(''),
      tI_SUPPORT_NAT_PSYCH_IND: new FormControl(''),
      tI_SUPPORT_NAT_FINANCE_IND: new FormControl(''),
      tI_SUPPORT_NAT_RESPITE_IND: new FormControl(''),
      tI_SUPPORT_NAT_HC_IND: new FormControl(''),
      tI_SUPPORT_NAT_BEHAV_IND: new FormControl(''),
      tI_SUPPORT_NAT_MED_IND: new FormControl(''),
      tI_SUPPORT_NAT_OTHER_IND: new FormControl(''),
      tI_SUPPORT_NAT_OTHER_DESC: new FormControl(''),
      tI_SUPPORT_NAT_FREQ_DETAIL: new FormControl(''),
      tI_SUPPORT_COM_LIST: new FormControl(''),
      tI_SUPPORT_COM_PTOTST_IND: new FormControl(''),
      tI_SUPPORT_COM_SN_IND: new FormControl(''),
      tI_SUPPORT_COM_PC_IND: new FormControl(''),
      tI_SUPPORT_COM_HOME_IND: new FormControl(''),
      tI_SUPPORT_COM_MEAL_IND: new FormControl(''),
      tI_SUPPORT_COM_LAUNDRY_IND: new FormControl(''),
      tI_SUPPORT_COM_SHOPPING_IND: new FormControl(''),
      tI_SUPPORT_COM_TRANS_IND: new FormControl(''),
      tI_SUPPORT_COM_PSYCH_IND: new FormControl(''),
      tI_SUPPORT_COM_FINANCE_IND: new FormControl(''),
      tI_SUPPORT_COM_RESPITE_IND: new FormControl(''),
      tI_SUPPORT_COM_HC_IND: new FormControl(''),
      tI_SUPPORT_COM_BEHAV_IND: new FormControl(''),
      tI_SUPPORT_COM_MED_IND: new FormControl(''),
      tI_SUPPORT_COM_OTHER_IND: new FormControl(''),
      tI_SUPPORT_COM_OTHER_DESC: new FormControl(''),
      tI_SUPPORT_COM_FREQ_DETAIL: new FormControl(''),
      tI_SUPPORT_FUNDING: new FormControl(null),
      tI_SUPPORT_FUNDING_OTHER: new FormControl('')

    });}

  ngOnInit() {
    this.loadDropdownService();
    this.configDynValidations();
    if (this.editData.Mode != 'New') {
      if (this.editData.sectionID != 0 && this.editData.acatID != 0) {
        if (this.editData.Mode == 'Edit') {
          this.sectionIService.GetEditSupport(this.editData.sectionID, this.editData.acatID).subscribe(response => {

            this.receiveAddNewSupport(response);
            this.showBtn = true;
            this.alertsService.success("Support Information Loaded");

          }, err => {
            this.alertsService.error(err);
          });
        } else if (this.editData.Mode == 'View') {
          this.sectionIService.GetEditSupport(this.editData.sectionID, this.editData.acatID).subscribe(response => {
            this.addNewSupportFrm.disable();
            this.receiveAddNewSupport(response);
            this.showBtn = false;
            this.alertsService.success("Support Information Loaded");

          }, err => {
            this.alertsService.error(err);
          });
        }

      }
    } else {
      this.showBtn = true;
    }

  }

  receiveAddNewSupport(data) {
    this.addNewSupportFrm.patchValue(data);
    this.setFormData(data);
  }
  setFormData(data) {

    //Natural Support Service dropdown
    let sNaturalList: string[] = [];
    if (data['tI_SUPPORT_NAT_PTOTST_IND'] == 'Y') {
      sNaturalList.push('PTOTST');
    }
    if (data['tI_SUPPORT_NAT_SN_IND'] == 'Y') {
      sNaturalList.push('SKILLEDNUR');
    }
    if (data['tI_SUPPORT_NAT_PC_IND'] == 'Y') {
      sNaturalList.push('PERSONALCA');
    }
    if (data['tI_SUPPORT_NAT_HOME_IND'] == 'Y') {
      sNaturalList.push('HOMEMAKSER');
    }
    if (data['tI_SUPPORT_NAT_MEAL_IND'] == 'Y') {
      sNaturalList.push('MELPREPSER');
    }
    if (data['tI_SUPPORT_NAT_LAUNDRY_IND'] == 'Y') {
      sNaturalList.push('LAUNDRYSER');
    }
    if (data['tI_SUPPORT_NAT_SHOPPING_IND'] == 'Y') {
      sNaturalList.push('SHOPSER');
    }
    if (data['tI_SUPPORT_NAT_TRANS_IND'] == 'Y') {
      sNaturalList.push('TRANSSER');
    }
    if (data['tI_SUPPORT_NAT_PSYCH_IND'] == 'Y') {
      sNaturalList.push('PSYCHOLSER');
    }
    if (data['tI_SUPPORT_NAT_FINANCE_IND'] == 'Y') {
      sNaturalList.push('MANAGFINAC');
    }
    if (data['tI_SUPPORT_NAT_RESPITE_IND'] == 'Y') {
      sNaturalList.push('RESPITE');
    }
    if (data['tI_SUPPORT_NAT_HC_IND'] == 'Y') {
      sNaturalList.push('HEALTHCARE');
    }
    if (data['tI_SUPPORT_NAT_BEHAV_IND'] == 'Y') {
      sNaturalList.push('BEHAVMANAG');
    }
    if (data['tI_SUPPORT_NAT_MED_IND'] == 'Y') {
      sNaturalList.push('MEDADMIN');
    }
    if (data['tI_SUPPORT_NAT_OTHER_IND'] == 'Y') {
      sNaturalList.push('OTHER');
      this.show_tI_SUPPORT_NAT_OTHER_DESC = true;
    } else {
      this.show_tI_SUPPORT_NAT_OTHER_DESC = false;
      this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').setValue(null);
    }

    this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').setValue(sNaturalList);

    //Community Support Service dropdown
    let sCommunityList: string[] = [];
    if (data['tI_SUPPORT_COM_PTOTST_IND'] == 'Y') {
      sCommunityList.push('PTOTST');
    }
    if (data['tI_SUPPORT_COM_SN_IND'] == 'Y') {
      sCommunityList.push('SKILLEDNUR');
    }
    if (data['tI_SUPPORT_COM_PC_IND'] == 'Y') {
      sCommunityList.push('PERSONALCA');
    }
    if (data['tI_SUPPORT_COM_HOME_IND'] == 'Y') {
      sCommunityList.push('HOMEMAKSER');
    }
    if (data['tI_SUPPORT_COM_MEAL_IND'] == 'Y') {
      sCommunityList.push('MELPREPSER');
    }
    if (data['tI_SUPPORT_COM_LAUNDRY_IND'] == 'Y') {
      sCommunityList.push('LAUNDRYSER');
    }
    if (data['tI_SUPPORT_COM_SHOPPING_IND'] == 'Y') {
      sCommunityList.push('SHOPSER');
    }
    if (data['tI_SUPPORT_COM_TRANS_IND'] == 'Y') {
      sCommunityList.push('TRANSSER');
    }
    if (data['tI_SUPPORT_COM_PSYCH_IND'] == 'Y') {
      sCommunityList.push('PSYCHOLSER');
    }
    if (data['tI_SUPPORT_COM_FINANCE_IND'] == 'Y') {
      sCommunityList.push('MANAGFINAC');
    }
    if (data['tI_SUPPORT_COM_RESPITE_IND'] == 'Y') {
      sCommunityList.push('RESPITE');
    }
    if (data['tI_SUPPORT_COM_HC_IND'] == 'Y') {
      sCommunityList.push('HEALTHCARE');
    }
    if (data['tI_SUPPORT_COM_BEHAV_IND'] == 'Y') {
      sCommunityList.push('BEHAVMANAG');
    }
    if (data['tI_SUPPORT_COM_MED_IND'] == 'Y') {
      sCommunityList.push('MEDADMIN');
    }
    if (data['tI_SUPPORT_COM_OTHER_IND'] == 'Y') {
      sCommunityList.push('OTHER');
      this.show_tI_SUPPORT_COM_OTHER_DESC = true;
    } else {
      this.show_tI_SUPPORT_COM_OTHER_DESC = false;
      this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').setValue(null);
    }
    this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').setValue(sCommunityList);

    // Type of support dropdown
    if (data['tI_SUPPORT_SUPPORT_TYPE'] == 'BOTH') {
      this.show_tI_SUPPORT_NAT_LIST = true;
      this.show_tI_SUPPORT_COM_LIST = true;     
    } else {
      if (data['tI_SUPPORT_SUPPORT_TYPE'] == 'COMMUNITYP') {      
        this.show_tI_SUPPORT_COM_LIST = true;
        this.show_tI_SUPPORT_NAT_LIST = false;
        this.show_tI_SUPPORT_NAT_OTHER_DESC = false;
      } else {
        if (data['tI_SUPPORT_SUPPORT_TYPE'] == 'NATURALUNP') {        
          this.show_tI_SUPPORT_NAT_LIST = true;
          this.show_tI_SUPPORT_COM_LIST = false;
          this.show_tI_SUPPORT_COM_OTHER_DESC = false;
        } else {
          this.show_tI_SUPPORT_NAT_LIST = false;
          this.show_tI_SUPPORT_COM_LIST = false;
        }
        
      }
    }
    this.addNewSupportFrm.get('tI_SUPPORT_FUNDING_OTHER').setValue(data['tI_SUPPORT_FUNDING_OTHER']);
    
  }
  loadDropdownService() {

    this.dropdownservice.GetDropDownValues('SUPPORT_PROVIDER_TYPE').subscribe((data: any[]) => {
      this.supportProviderTypeList = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('SUPPORT_TYPE').subscribe((data: any[]) => {
      this.supportTypeList = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('SUPPORT_FUNDING_SOURCE').subscribe((data: any[]) => {
      this.supportFundingSourceList = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('SUPPORT_NATURAL').subscribe((data: any[]) => {
      this.supportNaturalList = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('SUPPORT_COMMUNITY').subscribe((data: any[]) => {
      this.supportCommunity = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });

  }
  configDynValidations() {

    this.addNewSupportFrm.get('tI_SUPPORT_PROVIDER_TYPE').valueChanges.subscribe(value => {             
      
      this.show_tI_SUPPORT_COM_OTHER_DESC = false;
      this.show_tI_SUPPORT_NAT_OTHER_DESC = false;
      this.show_tI_SUPPORT_NAT_LIST = false;
      this.show_tI_SUPPORT_COM_LIST = false;
      this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').clearValidators();      
      this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').clearValidators()
      this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').clearValidators();
      this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').clearValidators()

      this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').setValue(null);
      this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').setValue(null);
      this.addNewSupportFrm.get('tI_SUPPORT_NAT_FREQ_DETAIL').setValue(null);
      this.addNewSupportFrm.get('tI_SUPPORT_COM_FREQ_DETAIL').setValue(null);

      this.addNewSupportFrm.get('tI_SUPPORT_SUPPORT_TYPE').patchValue([]);
      this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').patchValue([]);
      this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').patchValue([]);    
     
    });

    this.addNewSupportFrm.get('tI_SUPPORT_SUPPORT_TYPE').valueChanges.subscribe(value => {
      if (value == 'BOTH') {
        this.show_tI_SUPPORT_NAT_LIST = true;
        this.show_tI_SUPPORT_COM_LIST = true;
        this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').setValidators([Validators.required]);
        this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').setValidators([Validators.required]);
        this.addNewSupportFrm.controls['tI_SUPPORT_NAT_LIST'].markAsTouched();
        this.addNewSupportFrm.controls['tI_SUPPORT_COM_LIST'].markAsTouched();

        let supportNATList: [] = this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').value;
        if (Array.isArray(supportNATList)) {
          supportNATList.forEach(value => {
            switch (value) {
              case 'OTHER':
                this.show_tI_SUPPORT_NAT_OTHER_DESC = true;
                this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').setValidators([Validators.required]);
                this.addNewSupportFrm.controls['tI_SUPPORT_NAT_OTHER_DESC'].markAsTouched();
                break;             
              default:
                break;
            }
          });
        }

        let supportCOMList: [] = this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').value;
        if (Array.isArray(supportCOMList)) {
          supportCOMList.forEach(value => {
            switch (value) {
              case 'OTHER':
                this.show_tI_SUPPORT_COM_OTHER_DESC = true;
                this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').setValidators([Validators.required]);
                this.addNewSupportFrm.controls['tI_SUPPORT_COM_OTHER_DESC'].markAsTouched();
                break;
              default:
                break;
            }
          });
        }

      } else {
        if (value == 'COMMUNITYP') {
          this.show_tI_SUPPORT_COM_LIST = true;
          this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').setValidators([Validators.required]);
          this.addNewSupportFrm.controls['tI_SUPPORT_COM_LIST'].markAsTouched();
          
          // Natural Support Service dropdown and other
          this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').patchValue([]);          
          this.show_tI_SUPPORT_NAT_LIST = false;    
          this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').clearValidators();
          this.show_tI_SUPPORT_NAT_OTHER_DESC = false;
          this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').clearValidators();
          this.addNewSupportFrm.get('tI_SUPPORT_NAT_FREQ_DETAIL').setValue(null);
          

        } else {
          if (value == 'NATURALUNP') {
            this.show_tI_SUPPORT_NAT_LIST = true;
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').setValidators([Validators.required]);            
            this.addNewSupportFrm.controls['tI_SUPPORT_NAT_LIST'].markAsTouched();

            //Community Services dropdown and other
            this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').patchValue([]);
            this.show_tI_SUPPORT_COM_LIST = false;            
            this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').clearValidators();
            this.show_tI_SUPPORT_COM_OTHER_DESC = false;
            this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').clearValidators();
            this.addNewSupportFrm.get('tI_SUPPORT_COM_FREQ_DETAIL').setValue(null);

          } else {
            this.show_tI_SUPPORT_NAT_LIST = false;
            this.show_tI_SUPPORT_COM_LIST = false;
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').clearValidators();
           // this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').setValue(null);
            this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').clearValidators();
           // this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').setValue(null);
          }
        }
        
      }

      this.addNewSupportFrm.controls["tI_SUPPORT_NAT_LIST"].updateValueAndValidity()
      this.addNewSupportFrm.controls["tI_SUPPORT_COM_LIST"].updateValueAndValidity()
      this.addNewSupportFrm.controls["tI_SUPPORT_COM_OTHER_DESC"].updateValueAndValidity()
      this.addNewSupportFrm.controls["tI_SUPPORT_NAT_OTHER_DESC"].updateValueAndValidity()

    });

    this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').valueChanges.subscribe(value => {
      if (value.indexOf('OTHER') > -1) {
        this.show_tI_SUPPORT_NAT_OTHER_DESC = true;
        this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').setValidators([Validators.required]);
        this.addNewSupportFrm.controls['tI_SUPPORT_NAT_OTHER_DESC'].markAsTouched();
        
      } else {
        this.show_tI_SUPPORT_NAT_OTHER_DESC = false;
        this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').clearValidators();
        this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').setValue(null);
      }

  
    });

    this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').valueChanges.subscribe(value => {
      if (value.indexOf('OTHER') > -1) {        
        this.show_tI_SUPPORT_COM_OTHER_DESC = true;
        this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').setValidators([Validators.required]);
        this.addNewSupportFrm.controls['tI_SUPPORT_COM_OTHER_DESC'].markAsTouched();
      } else {
        this.show_tI_SUPPORT_COM_OTHER_DESC = false;
        this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').clearValidators();
        this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').setValue(null);
      }
    
    });

    this.addNewSupportFrm.get('tI_SUPPORT_FUNDING').valueChanges.subscribe(value => {     
      if (value == 'OTHER') {        
        this.addNewSupportFrm.get('tI_SUPPORT_FUNDING_OTHER').setValidators([Validators.required]);
        this.addNewSupportFrm.controls['tI_SUPPORT_FUNDING_OTHER'].markAsTouched();
        this.show_tI_SUPPORT_FUNDING_OTHER = true;
      } else {        
        this.addNewSupportFrm.get('tI_SUPPORT_FUNDING_OTHER').clearValidators();
        this.addNewSupportFrm.get('tI_SUPPORT_FUNDING_OTHER').setValue(null);
        this.show_tI_SUPPORT_FUNDING_OTHER = false;
      }
      this.addNewSupportFrm.controls["tI_SUPPORT_FUNDING_OTHER"].updateValueAndValidity()
    });
  }
  saveMultipleSupport() {
  
    if (this.addNewSupportFrm.get('tI_SUPPORT_PROVIDER').value == null &&
      this.addNewSupportFrm.get('tI_SUPPORT_PROVIDER_TYPE').value == null &&
      this.addNewSupportFrm.get('tI_SUPPORT_SUPPORT_TYPE').value == null &&
      this.addNewSupportFrm.get('tI_SUPPORT_FUNDING').value == null) {
      this.formStatus = false;
    } else {
      this.formStatus = true;
    }

    if (this.formStatus == false) {
     // this.alertsService.error("Please add atleast one support information");
      this.warningAlert("Please add atleast one support information");
      return;
    }

    this.addNewSupportFrm.get('tI_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    this.addNewSupportFrm.get('tI_CURRENT_SUPPORT_IND').setValue('Y');

    if (!this.addNewSupportFrm.valid) {
      for (let cnt in this.addNewSupportFrm.controls) {
        if (this.addNewSupportFrm.controls[cnt].invalid) {
          console.log(cnt, this.addNewSupportFrm.controls[cnt]);
        }
      }
      return;
    }

    this.addNewSupportFrm.get('tI_SUPPORT_NAT_PTOTST_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_SN_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_PC_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_HOME_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_MEAL_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_LAUNDRY_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_SHOPPING_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_TRANS_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_PSYCH_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_FINANCE_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_RESPITE_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_HC_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_BEHAV_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_MED_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_IND').setValue(null);

    let supportNATList: [] = this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').value;

    if (Array.isArray(supportNATList)) {
      supportNATList.forEach(value => {
        switch (value) {
          case 'PTOTST':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_PTOTST_IND').setValue('Y');
            break;
          case 'SKILLEDNUR':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_SN_IND').setValue('Y');
            break;
          case 'PERSONALCA':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_PC_IND').setValue('Y');
            break;
          case 'HOMEMAKSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_HOME_IND').setValue('Y');
            break;
          case 'MELPREPSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_MEAL_IND').setValue('Y');
            break;
          case 'LAUNDRYSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_LAUNDRY_IND').setValue('Y');
            break;
          case 'SHOPSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_SHOPPING_IND').setValue('Y');
            break;
          case 'TRANSSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_TRANS_IND').setValue('Y');
            break;
          case 'PSYCHOLSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_PSYCH_IND').setValue('Y');
            break;
          case 'MANAGFINAC':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_FINANCE_IND').setValue('Y');
            break;
          case 'RESPITE':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_RESPITE_IND').setValue('Y');
            break;
          case 'HEALTHCARE':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_HC_IND').setValue('Y');
            break;
          case 'BEHAVMANAG':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_BEHAV_IND').setValue('Y');
            break;
          case 'MEDADMIN':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_MED_IND').setValue('Y');
            break;
          case 'OTHER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_IND').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    this.addNewSupportFrm.get('tI_SUPPORT_COM_PTOTST_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_SN_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_PC_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_HOME_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_MEAL_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_LAUNDRY_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_SHOPPING_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_TRANS_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_PSYCH_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_FINANCE_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_RESPITE_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_HC_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_BEHAV_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_MED_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_IND').setValue(null);


    let supportCOMList: [] = this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').value;

    if (Array.isArray(supportCOMList)) {
      supportCOMList.forEach(value => {
        switch (value) {
          case 'PTOTST':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_PTOTST_IND').setValue('Y');
            break;
          case 'SKILLEDNUR':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_SN_IND').setValue('Y');
            break;
          case 'PERSONALCA':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_PC_IND').setValue('Y');
            break;
          case 'HOMEMAKSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_HOME_IND').setValue('Y');
            break;
          case 'MELPREPSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_MEAL_IND').setValue('Y');
            break;
          case 'LAUNDRYSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_LAUNDRY_IND').setValue('Y');
            break;
          case 'SHOPSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_SHOPPING_IND').setValue('Y');
            break;
          case 'TRANSSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_TRANS_IND').setValue('Y');
            break;
          case 'PSYCHOLSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_PSYCH_IND').setValue('Y');
            break;
          case 'MANAGFINAC':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_FINANCE_IND').setValue('Y');
            break;
          case 'RESPITE':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_RESPITE_IND').setValue('Y');
            break;
          case 'HEALTHCARE':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_HC_IND').setValue('Y');
            break;
          case 'BEHAVMANAG':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_BEHAV_IND').setValue('Y');
            break;
          case 'MEDADMIN':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_MED_IND').setValue('Y');
            break;
          case 'OTHER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_IND').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    let addNewSupportData = this.addNewSupportFrm.value;

    this.sectionIService.PostSectionI(addNewSupportData).subscribe(resp => {
      this.formStatus = false;
      this.alertsService.success("Support Information Saved");
      this.clearaddNewSupportFrm();
      //this.dialogRef.close("success");
    }, error => {
        this.formStatus = false;
      console.error('Error Saving Support Information', error);
      this.alertsService.error("Error Saving Support Information");
    });

  }
  saveSupport() {
  

    if (this.addNewSupportFrm.get('tI_SUPPORT_PROVIDER').value == null &&
      this.addNewSupportFrm.get('tI_SUPPORT_PROVIDER_TYPE').value == null &&
      this.addNewSupportFrm.get('tI_SUPPORT_SUPPORT_TYPE').value == null &&
      this.addNewSupportFrm.get('tI_SUPPORT_FUNDING').value == null) {
      this.formStatus = false;
    } else{
      this.formStatus = true;
    }   

    if (this.formStatus == false) {
      //this.alertsService.error("Please add atleast one support information");
      this.warningAlert("Please add atleast one support information");
      return;
    }

    this.addNewSupportFrm.get('tI_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    this.addNewSupportFrm.get('tI_CURRENT_SUPPORT_IND').setValue('Y');

    if (!this.addNewSupportFrm.valid) {
      for (let cnt in this.addNewSupportFrm.controls) {
        if (this.addNewSupportFrm.controls[cnt].invalid) {
          console.log(cnt, this.addNewSupportFrm.controls[cnt]);
        }
      }
      return;
    }    

    this.addNewSupportFrm.get('tI_SUPPORT_NAT_PTOTST_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_SN_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_PC_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_HOME_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_MEAL_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_LAUNDRY_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_SHOPPING_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_TRANS_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_PSYCH_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_FINANCE_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_RESPITE_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_HC_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_BEHAV_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_MED_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_IND').setValue(null);

    let supportNATList: [] = this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').value;

    if (Array.isArray(supportNATList)) {
      supportNATList.forEach(value => {
        switch (value) {
          case 'PTOTST':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_PTOTST_IND').setValue('Y');
            break;
          case 'SKILLEDNUR':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_SN_IND').setValue('Y');
            break;
          case 'PERSONALCA':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_PC_IND').setValue('Y');
            break;
          case 'HOMEMAKSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_HOME_IND').setValue('Y');
            break;
          case 'MELPREPSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_MEAL_IND').setValue('Y');
            break;
          case 'LAUNDRYSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_LAUNDRY_IND').setValue('Y');
            break;
          case 'SHOPSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_SHOPPING_IND').setValue('Y');
            break;
          case 'TRANSSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_TRANS_IND').setValue('Y');
            break;
          case 'PSYCHOLSER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_PSYCH_IND').setValue('Y');
            break;
          case 'MANAGFINAC':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_FINANCE_IND').setValue('Y');
            break;
          case 'RESPITE':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_RESPITE_IND').setValue('Y');
            break;
          case 'HEALTHCARE':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_HC_IND').setValue('Y');
            break;
          case 'BEHAVMANAG':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_BEHAV_IND').setValue('Y');
            break;
          case 'MEDADMIN':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_MED_IND').setValue('Y');
            break;
          case 'OTHER':
            this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_IND').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    this.addNewSupportFrm.get('tI_SUPPORT_COM_PTOTST_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_SN_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_PC_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_HOME_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_MEAL_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_LAUNDRY_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_SHOPPING_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_TRANS_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_PSYCH_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_FINANCE_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_RESPITE_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_HC_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_BEHAV_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_MED_IND').setValue(null);
    this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_IND').setValue(null);

    let supportCOMList: [] = this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').value;

    if (Array.isArray(supportCOMList)) {
      supportCOMList.forEach(value => {
        switch (value) {
          case 'PTOTST':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_PTOTST_IND').setValue('Y');
            break;
          case 'SKILLEDNUR':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_SN_IND').setValue('Y');
            break;
          case 'PERSONALCA':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_PC_IND').setValue('Y');
            break;
          case 'HOMEMAKSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_HOME_IND').setValue('Y');
            break;
          case 'MELPREPSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_MEAL_IND').setValue('Y');
            break;
          case 'LAUNDRYSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_LAUNDRY_IND').setValue('Y');
            break;
          case 'SHOPSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_SHOPPING_IND').setValue('Y');
            break;
          case 'TRANSSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_TRANS_IND').setValue('Y');
            break;
          case 'PSYCHOLSER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_PSYCH_IND').setValue('Y');
            break;
          case 'MANAGFINAC':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_FINANCE_IND').setValue('Y');
            break;
          case 'RESPITE':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_RESPITE_IND').setValue('Y');
            break;
          case 'HEALTHCARE':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_HC_IND').setValue('Y');
            break;
          case 'BEHAVMANAG':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_BEHAV_IND').setValue('Y');
            break;
          case 'MEDADMIN':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_MED_IND').setValue('Y');
            break;
          case 'OTHER':
            this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_IND').setValue('Y');
            break;
          default:
            break;
        }
      });
    }
   

    let addNewSupportData = this.addNewSupportFrm.value;

    this.sectionIService.PostSectionI(addNewSupportData).subscribe(resp => {
      this.alertsService.success("Support Information Saved");
      this.formStatus = false;
      this.dialogRef.close("success");
    }, error => {
        this.formStatus = false;
        console.error('Error Saving Support Information', error);
        this.alertsService.error("Error Saving Support Information");
      });

  }
  clearaddNewSupportFrm() {
    
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').clearValidators();
    this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').clearValidators();
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').clearValidators();
    this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').clearValidators();
    this.addNewSupportFrm.get('tI_SUPPORT_NAT_FREQ_DETAIL').clearValidators();
    this.addNewSupportFrm.get('tI_SUPPORT_COM_FREQ_DETAIL').clearValidators();
    this.addNewSupportFrm.get('tI_SUPPORT_FUNDING_OTHER').clearValidators();
    this.show_tI_SUPPORT_FUNDING_OTHER = false;
    this.show_tI_SUPPORT_COM_OTHER_DESC = false;
    this.show_tI_SUPPORT_NAT_OTHER_DESC = false;
    this.show_tI_SUPPORT_NAT_LIST = false;
    this.show_tI_SUPPORT_COM_LIST = false;
    this.addNewSupportFrm.reset({ tI_SUPPORT_NAT_LIST: this.supportNaturalList, tI_SUPPORT_COM_LIST: this.supportCommunity });
    //this.addNewSupportFrm.reset();
    //this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').patchValue([]);
    //this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').patchValue([]);
  }
  closeaddNewSupportFrm() {
    this.dialogRef.close("close");
   // this.dialogRef.close();
  }
  warningAlert(msg) {
    Swal.fire(
      '',
      msg,
      'warning'
    )
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.addNewSupportFrm.get('tI_SUPPORT_NAT_LIST').clearValidators();
        this.addNewSupportFrm.get('tI_SUPPORT_COM_LIST').clearValidators();
        this.addNewSupportFrm.get('tI_SUPPORT_NAT_OTHER_DESC').clearValidators();
        this.addNewSupportFrm.get('tI_SUPPORT_COM_OTHER_DESC').clearValidators();
        this.addNewSupportFrm.get('tI_SUPPORT_NAT_FREQ_DETAIL').clearValidators();
        this.addNewSupportFrm.get('tI_SUPPORT_COM_FREQ_DETAIL').clearValidators();
        this.addNewSupportFrm.get('tI_SUPPORT_FUNDING_OTHER').clearValidators();
        this.show_tI_SUPPORT_FUNDING_OTHER = false;
        this.show_tI_SUPPORT_COM_OTHER_DESC = false;
        this.show_tI_SUPPORT_NAT_OTHER_DESC = false;
        this.show_tI_SUPPORT_NAT_LIST = false;
        this.show_tI_SUPPORT_COM_LIST = false;
        this.addNewSupportFrm.reset({ tI_SUPPORT_NAT_LIST: this.supportNaturalList, tI_SUPPORT_COM_LIST: this.supportCommunity });
      }
    });

  }
}
