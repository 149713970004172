import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { DropdownModel } from '../models/dropdown.model';

@Injectable({
    providedIn: 'root'
})
export class SectionDropdownService {

    constructor(private apiService: ApiService) { }

    GetDropDownValues(dropDownName): Observable<DropdownModel[]> {
        return this.apiService.get(`Dropdown/${dropDownName}`);
    }

}
