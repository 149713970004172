import { Component, OnInit } from '@angular/core';
import { DataService, SectionDService, AlertsService } from '../../shared';
import { SectionDResponse } from '../common/SectionDResponse';
import { FormGroup, FormControlName, Validators, FormControl } from '@angular/forms';
import { PharmacyInfo } from '../common/PharmacyInfo';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { confirmDialogParam } from '../../shared/components/confirm-dialog/confirm-dialog-common';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-pharmacy-info',
    templateUrl: './pharmacy-info.component.html',
    styleUrls: ['./pharmacy-info.component.css']
})
export class PharmacyInfoComponent implements OnInit {

    constructor(private dataService: DataService,
        private sectionDService: SectionDService,
        private alerts: AlertsService,
        public clearDia: MatDialog) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    PharmacyInfoForm: FormGroup;

    ngOnInit() {
        this.documentReferance = { alocid: '', sectionid: '', name: '' };;
        this.documentReferance.alocid = this.dataService.getSelectedDocument();
        this.documentReferance.name = this.dataService.getSelectedDocumentName();

        // initialize the form
        this.initForm();
        this.loadData();

    }

    private initForm() {

        this.PharmacyInfoForm = new FormGroup({
            "tD_ACAT_ID": new FormControl('', [Validators.required]),
            "tD_SECTION_ID": new FormControl('',),


            'tD_PHARM_NAME_1': new FormControl('', [Validators.required]),
            'tD_PHARM_PHONE_1': new FormControl(''),
            'tD_PHARM_FAX_1': new FormControl(''),
            'tD_PHARM_ADDR_1': new FormControl(''),

            'tD_PHARM_NAME_2': new FormControl(''),
            'tD_PHARM_PHONE_2': new FormControl(''),
            'tD_PHARM_FAX_2': new FormControl(''),
            'tD_PHARM_ADDR_2': new FormControl(''),

            'tD_PHARM_NAME_3': new FormControl(''),
            'tD_PHARM_PHONE_3': new FormControl(''),
            'tD_PHARM_FAX_3': new FormControl(''),
            'tD_PHARM_ADDR_3': new FormControl('')
        });

    }

    private loadData() {
        this.sectionDService.getPharmacy(parseInt(this.dataService.getSelectedDocument())).subscribe((resp: PharmacyInfo) => {
            this.PharmacyInfoForm.patchValue(resp);
            this.documentReferance.sectionid = resp.tD_SECTION_ID != null ? resp.tD_SECTION_ID.toString() : null;

            if (resp.tD_ACAT_ID == null || resp.tD_ACAT_ID == 0 || resp.tD_SECTION_ID == null || resp.tD_SECTION_ID == 0) {
                this.alerts.error('No pharmacy records found !');
            } else {
                this.alerts.success('Pharmacy Information Loaded');
            }

        }, error => {
            console.error('error retriving pharmcy ', error);
            this.alerts.clearLoader();
        });
    }

    onSave() {

        this.PharmacyInfoForm.get('tD_ACAT_ID').setValue(this.documentReferance.alocid);
        this.PharmacyInfoForm.get('tD_SECTION_ID').setValue(this.documentReferance.sectionid);

        if (this.PharmacyInfoForm.valid) {
            let formData: PharmacyInfo = this.PharmacyInfoForm.value as PharmacyInfo;
            this.sectionDService.savePharmacy(formData).subscribe((resp) => {
                this.alerts.success('Pharmacy Information Saved');
                this.loadData();
            }, error => {
                console.error('error saving pharmacy info ', error);
                this.alerts.error('Error saving Pharmacy Information');
            });
        } else {
            let invalid_cntrls = [];
            Object.keys(this.PharmacyInfoForm.controls).forEach(key => {
                if (this.PharmacyInfoForm.controls[key].invalid) {
                    invalid_cntrls.push(key);
                }
            });
            console.error('invlid fields ', invalid_cntrls);
        }
    }

    onClear1() {
        let delConfirm: confirmDialogParam = {
            message: ``,
            title: 'Are you sure ?',
            subtitle: `Entered information will be erased. Press 'Ok' to proceed.`
        };
        const clearDialog = this.clearDia.open(ConfirmDialogComponent, {
            width: '500px',
            disableClose: true,
            data: delConfirm
        });
        clearDialog.afterClosed().subscribe(result => {
            if (result == 'yes') {
                this.PharmacyInfoForm.reset();
            }
        });

        
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.PharmacyInfoForm.reset();
      }
    });

  }
}
