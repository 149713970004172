import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';

export interface Attachments {
  FileName: string,
  FileType: string,
  FileId: number
}

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.css']
})
export class AttachmentsComponent implements OnInit {
  AttachmentFiles: Attachments[];
  SortedAttachmentFiles: Attachments[];

  constructor() { }

  ngOnInit() {
    this.AttachmentFiles = [
      { FileId: 1, FileName: "attachment.pdf", FileType: 'pdf' },
      { FileId: 2, FileName: "attachment.xlsx", FileType: 'Excel' },
      { FileId: 3, FileName: "attachment.docx", FileType: 'Word' }
    ];
    this.SortedAttachmentFiles = this.AttachmentFiles.slice();
   
  }

  sortData(sort: Sort) {
    const data = this.AttachmentFiles.slice();
    if (!sort.active || sort.direction === '') {
      this.SortedAttachmentFiles = data;
      return;
    }
    this.SortedAttachmentFiles = data.sort((a, b) => {
      const isAsync = sort.direction === 'asc';
      switch (sort.active) {
        case 'FileName': return this.compare(a.FileName, b.FileName, isAsync);
        case 'FileType': return this.compare(a.FileType, b.FileType, isAsync);
        default: return 0;
      }
    });
  }

  compare(a: string | number, b: string | number, isAsc: boolean) {
    let ret = (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    console.log(`${ret}`);
    return ret;
  }

}
