


export interface MedStability {
    tD_SECTION_ID: number;
    tD_ACAT_ID: number;
    tD_AMS_MONITOR_IND: string;
    tD_AMS_MONITOR_COMMENT: string;
    tD_AMS_RPT_IND: string;
    tD_AMS_RPT_COMMENT: string;
    tD_AMS_INTER_IND: string;
    tD_AMS_INTER_COMMENT: string;
    tD_AMS_ASSESS_IND: string;
    tD_AMS_ASSESS_COMMENT: string;
    tD_AMS_MONTHLY_IND: string;
    tD_AMS_MONTHLY_COMMENT: string;
    tD_AMS_NECESSARY_IND: string;
    tD_AMS_NECESSARY_COMMENT: string;
}

export class MedStabilityFields {
    public static tD_SECTION_ID = 'tD_SECTION_ID';
    public static tD_ACAT_ID = 'tD_ACAT_ID';
    public static tD_AMS_MONITOR_IND = 'tD_AMS_MONITOR_IND';
    public static tD_AMS_MONITOR_COMMENT = 'tD_AMS_MONITOR_COMMENT';
    public static tD_AMS_RPT_IND = 'tD_AMS_RPT_IND';
    public static tD_AMS_RPT_COMMENT = 'tD_AMS_RPT_COMMENT';
    public static tD_AMS_INTER_IND = 'tD_AMS_INTER_IND';
    public static tD_AMS_INTER_COMMENT = 'tD_AMS_INTER_COMMENT';
    public static tD_AMS_ASSESS_IND = 'tD_AMS_ASSESS_IND';
    public static tD_AMS_ASSESS_COMMENT = 'tD_AMS_ASSESS_COMMENT';
    public static tD_AMS_MONTHLY_IND = 'tD_AMS_MONTHLY_IND';
    public static tD_AMS_MONTHLY_COMMENT = 'tD_AMS_MONTHLY_COMMENT';
    public static tD_AMS_NECESSARY_IND = 'tD_AMS_NECESSARY_IND';
    public static tD_AMS_NECESSARY_COMMENT = 'tD_AMS_NECESSARY_COMMENT';

}

