import { Injectable } from '@angular/core';
import { SectionDResponse } from '../../section-d/common/SectionDResponse';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor() { }

    getSelectedDocument() {
        return sessionStorage.getItem('documentId');
    }

    getSelectedDocumentName() {
        return sessionStorage.getItem('documentName');
    }

    saveSelectedDocument(doc: { dB_ID: string, name: string }) {
        sessionStorage.setItem('documentId', doc.dB_ID);
        sessionStorage.setItem('documentName', doc.name);
    }

    saveSectionId(sectionID) {
        sessionStorage.setItem('sectionID', sectionID);
    }

    getSectionId() {
        return sessionStorage.getItem('sectionID');
    }

    setSectionD(data: SectionDResponse) {
        sessionStorage.setItem('section-d', JSON.stringify(data));
    }
    getSectionD(): SectionDResponse | null {
        return JSON.parse(sessionStorage.getItem('section-d')) as SectionDResponse;
    }

    clearSelectedDocument() {
        if (sessionStorage.getItem('documentId')) {
            sessionStorage.removeItem('documentId');
            sessionStorage.removeItem('documentName');
        }
    }
    clearToken() {
        sessionStorage.removeItem('ACAT');
    }

    saveSearchFilter(filter) {
        sessionStorage.setItem('search-filter', JSON.stringify(filter));
    }

    getSearchFilter() {
        return JSON.parse(sessionStorage.getItem('search-filter'));
    }

    clearSearchFilter() {
        sessionStorage.removeItem('search-filter');
    }
}
