import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { HomeAssessment, HomeSafety, MedicationSafety, EmergencyNumber } from '../models';
@Injectable({
    providedIn: 'root'
})
export class SectionKService {

    constructor(private apiService: ApiService) { }

    getHomeAssessment(acatId): Observable<HomeAssessment> {
        return this.apiService.get('sectionK/HomeAssessment/' + acatId).pipe(map(data => { return data; }));
    }

    postHomeAssessment(homeAssessment): Observable<any> {
        return this.apiService.put('sectionK/HomeAssessment', homeAssessment).pipe(map(data => { return data; }));
    }


    getHomeSafety(acatId): Observable<HomeSafety> {
        return this.apiService.get('sectionK/HomeSafety/' + acatId).pipe(map(data => { return data; }));
    }

    postHomeSafety(homeSafety): Observable<any> {
        return this.apiService.put('sectionK/HomeSafety', homeSafety).pipe(map(data => { return data; }));
    }

    getMedicationSafety(acatId): Observable<MedicationSafety> {
        return this.apiService.get('sectionK/MedicalSafety/' + acatId).pipe(map(data => { return data; }));
    }

    postMedicationSafety(medicationSafety): Observable<any> {
        return this.apiService.put('sectionK/MedicalSafety', medicationSafety).pipe(map(data => { return data; }));
    }

    getEmergencyNumber(acatId): Observable<EmergencyNumber> {
        return this.apiService.get('sectionK/EmergencyNumber/' + acatId).pipe(map(data => { return data; }));
    }

    postEmergencyNumber(emergencyNumber): Observable<any> {
        return this.apiService.put('sectionK/EmergencyNumber', emergencyNumber).pipe(map(data => { return data; }));
    }
}
