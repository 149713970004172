import { Component, OnInit } from '@angular/core';
import { SectionDService, DataService, AlertsService, SectionDropdownService } from '../../shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownOptionsType } from '../../shared/common/DropDownOptionsType';
import { forkJoin } from 'rxjs';
import { Incontinence } from './incontinence-info';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-incontinence-info',
    templateUrl: './incontinence-info.component.html',
    styleUrls: ['./incontinence-info.component.css']
})
export class IncontinenceInfoComponent implements OnInit {

    constructor(private sectiondService: SectionDService,
        private cntxInfoService: DataService,
        private alerts: AlertsService,
        private dropdownService: SectionDropdownService) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    incontinenceForm: FormGroup;
    incontinenceBladerOptions: DropdownOptionsType[];
    incontinenceBowelOptions: DropdownOptionsType[];
  screenName = "Incontinence";


    ngOnInit() {
        this.documentReferance = { alocid: '', sectionid: '', name: '' };
        this.documentReferance.alocid = this.cntxInfoService.getSelectedDocument();
        this.documentReferance.name = this.cntxInfoService.getSelectedDocumentName();
        this.initForm();
        this.loadData();
    }

    private initForm() {

        this.incontinenceForm = new FormGroup({
            tD_ACAT_ID: new FormControl('', [Validators.required]),
            tD_SECTION_ID: new FormControl(''),
            tD_INCONTINENCE_BLADDER: new FormControl(''),
            tD_INCONTINENCE_BOWEL: new FormControl(''),
        });


    }

    private loadData() {
        //load dropdowns
        let bladder_src = this.dropdownService.GetDropDownValues('INCONTINENCE_BLADER');
        let bowel_src = this.dropdownService.GetDropDownValues('INCONTINENCE_BOWEL');
        let form_data = this.sectiondService.getIncontinenceInfo(parseInt(this.documentReferance.alocid));

        forkJoin(bladder_src, bowel_src, form_data).subscribe(([bladder, bowel, formData]) => {
            if (formData.tD_ACAT_ID == null || formData.tD_ACAT_ID === 0) {
                this.alerts.error('No data found for Incontinence');
            } else {
                this.alerts.success(`${this.screenName} Loaded`);
            }
            this.incontinenceBladerOptions = bladder;
            this.incontinenceBowelOptions = bowel;
            //console.log('form data', formData);            
            this.incontinenceForm.patchValue(formData);
            this.documentReferance.sectionid = formData.tD_SECTION_ID == null ? null : formData.tD_SECTION_ID.toString();
        }, error => {
            console.error("Error loading data ", error);
            this.alerts.error("Error loading data");
        });
    }


    onSave() {
        this.incontinenceForm.get('tD_ACAT_ID').setValue(this.documentReferance.alocid);
        this.incontinenceForm.get('tD_SECTION_ID').setValue(this.documentReferance.sectionid);

        let formData = this.incontinenceForm.value as Incontinence;
        if (this.incontinenceForm.valid) {
            this.sectiondService.saveIncontinenceInfo(formData).subscribe(resp => {
                console.log('resp ', resp);
                this.alerts.success(`${this.screenName} Saved`);
                this.loadData();
            }, error => {
                console.error('error saving ', error);
                this.alerts.error('Error saving data !');
            });
        } else {
            console.error("Invalid form");
        }
    }

    onClear1() {
        this.incontinenceForm.reset();
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.incontinenceForm.reset();
      }
    });

  }
}
