import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SectionAService, AlertsService, DataService, UserService, getTextForDropdownValues, SectionA } from '../shared/index';
import { AssessInfoComponent } from './assess-info/assess-info.component';
@Component({
  selector: 'app-section-a',
  templateUrl: './section-a.component.html',
  styleUrls: ['./section-a.component.css']
})
export class SectionAComponent implements OnInit {
  newACATID: any;
  show_SubMenu: boolean;
  sectionAModel: SectionA = {} as SectionA;
  @ViewChild(AssessInfoComponent, { static: false }) assessInformation: AssessInfoComponent;
  
  constructor(private router: Router, private sectionAService: SectionAService,
    private alertsService: AlertsService,
    public dataService: DataService,) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/section-a') {
          this.newACATID = this.dataService.getSelectedDocument();
          if (this.newACATID != null) {
            this.show_SubMenu = true;
          } else {
            this.show_SubMenu = false;
            
          }
          router.navigate(['/section-a/assess-info']);
        }
      }
    });
   
  }

  ngOnInit() {
    this.newACATID = this.dataService.getSelectedDocument();
    if (this.newACATID != null) {
      this.show_SubMenu = true;
    } else {
      this.show_SubMenu = false;
      this.router.navigate(['/section-a/assess-info']);
    }

  }

}
