import { Component, OnInit } from '@angular/core';
import { SectionDService, DataService, AlertsService } from '../../shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AllergyInfoFields } from './AllergyInfoFormFields';
import { AllergyInfo } from '../common/AllergyInfo';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-allergy-info',
    templateUrl: './allergy-info.component.html',
    styleUrls: ['./allergy-info.component.css']
})
export class AllergyInfoComponent implements OnInit {

    constructor(private sectiondService: SectionDService,
        private cntxInfoService: DataService,
        private alerts: AlertsService) { }

    AllergyInfoForm: FormGroup;
    documentReferance: { alocid: string, sectionid: string, name: string };
    disablePanels: boolean;

    ngOnInit() {

        this.documentReferance = { alocid: '', sectionid: '', name: '' };
        this.documentReferance.alocid = this.cntxInfoService.getSelectedDocument();
        this.documentReferance.name = this.cntxInfoService.getSelectedDocumentName();

        this.initForm();

        this.loadData();
    }

    initForm() {

        //initialize form controls
        let formContrls = {};
        for (let fld in AllergyInfoFields) {
            formContrls[fld] = new FormControl();
        }
        this.AllergyInfoForm = new FormGroup(formContrls);
        this.AllergyInfoForm.get(AllergyInfoFields.tD_ACAT_ID).setValidators([Validators.required]);
        this.AllergyInfoForm.get(AllergyInfoFields.tD_ALLERGY_IND).setValidators([Validators.required]);

        //allergy indicator
        this.AllergyInfoForm.get(AllergyInfoFields.tD_ALLERGY_IND).valueChanges.subscribe(value => {
            if (value == null || value == 'N') {
                for (let fld in AllergyInfoFields) {
                    if (fld == AllergyInfoFields.tD_ACAT_ID || fld == AllergyInfoFields.tD_ALLERGY_IND || fld == AllergyInfoFields.tD_SECTION_ID) continue;
                    this.AllergyInfoForm.get(fld).reset();
                    this.AllergyInfoForm.get(fld).disable();
                }
                this.setStep(-1);
                this.disablePanels = true;
            } else {
                for (let fld in AllergyInfoFields) {
                    if (fld == AllergyInfoFields.tD_ACAT_ID || fld == AllergyInfoFields.tD_ALLERGY_IND || fld == AllergyInfoFields.tD_SECTION_ID) continue;
                    this.AllergyInfoForm.get(fld).enable();
                }
                this.setStep(0);
                this.disablePanels = false;
            }
        });

    }

    loadData() {
        this.sectiondService.getAllergies(parseInt(this.cntxInfoService.getSelectedDocument())).subscribe((resp: AllergyInfo) => {
            if (resp.tD_ACAT_ID == null || resp.tD_ACAT_ID == 0) {
              this.alerts.error('No data found for Allergies');
            } else {
              this.alerts.success('Allergies Loaded');
            }
            this.AllergyInfoForm.patchValue(resp);
            if (resp.tD_ALLERGY_IND == null) {
                //this.AllergyInfoForm.get(AllergyInfoFields.tD_ALLERGY_IND).setValue('N');
            }
            this.documentReferance.sectionid = resp.tD_SECTION_ID == null ? null : resp.tD_SECTION_ID.toString();
        }, error => {
            console.error('error fetching Allergies', error);
            this.alerts.error('Error loading Allergies');
        })
    }

    onSave() {
        this.AllergyInfoForm.get(AllergyInfoFields.tD_ACAT_ID).setValue(this.documentReferance.alocid);
        this.AllergyInfoForm.get(AllergyInfoFields.tD_SECTION_ID).setValue(this.documentReferance.sectionid);
        let formData: AllergyInfo = this.AllergyInfoForm.value as AllergyInfo;
        //console.log('form data ', formData);
        if (this.AllergyInfoForm.valid) {
            this.sectiondService.saveAllergies(formData).subscribe((resp) => {
                console.log('resp ', resp);
              this.alerts.success('Allergies Saved');
                this.loadData();
            }, error => {
                console.log('error saving allergies ', error);
                this.alerts.error('Error saving Allergies');
            });
        } else {
            let invalidCntrols = [];
            Object.keys(this.AllergyInfoForm.controls).forEach(name => {
                if (this.AllergyInfoForm.get(name).invalid) {
                    invalidCntrols.push(name);
                }
            });
            console.error('invalid form controls ', invalidCntrols);
        }
    }

    onClear1() {
        this.AllergyInfoForm.reset();
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.AllergyInfoForm.reset();
      }
    });

  }
    step = 0;

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

}
