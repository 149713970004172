import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionLService, PrimaryCaregiver, DropdownModel } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-primary-caregiver',
  templateUrl: './primary-caregiver.component.html',
  styleUrls: ['./primary-caregiver.component.css']
})
export class PrimaryCaregiverComponent implements OnInit {

    primaryCaregiverForm: FormGroup;
    isSubmitting = false;
    primaryCaregiver: PrimaryCaregiver = {} as PrimaryCaregiver;
    caregiverYes = false;
    caregiverFreq: DropdownModel[];
    caregiverDistance: DropdownModel[];
    constructor(private fb: FormBuilder,
        private dropdownSerice: SectionDropdownService,
        private alertService: AlertsService,
        public dataService: DataService,
        public sectionLService: SectionLService) {
        this.primaryCaregiverForm = this.fb.group({            
            tL_ACAT_ID: new FormControl(''),
            tL_PRIMARY_CG: new FormControl('', Validators.required),
            tL_TRAINED_CG: new FormControl(''),
            tL_PAID_CG: new FormControl(''),
            tL_OTHER_CG: new FormControl(''),
            tL_OTHER_CG_COMMENTS: new FormControl({value:'',disabled:true}),
            tL_CG_YEARS: new FormControl(''),
            tL_CG_MONTHS: new FormControl(''),
            tL_CG_FREQUENCY: new FormControl(''),
            tL_CG_MINOR_CHILDREN: new FormControl(''),
            tL_CG_OTHERS: new FormControl(''),
            tL_CG_DISTANCE: new FormControl('')
        });
    }

  ngOnInit() {

    if (this.dataService.getSelectedDocument() != null) {
      this.sectionLService.GetSectionLInfo(this.dataService.getSelectedDocument()).subscribe(response => {
        this.primaryCaregiverForm.patchValue(response);
        this.alertService.success("Primary Caregiver Assessment Loaded");
      }, err => {
          this.alertService.error(err);
      });
    }
        
    this.loadDropdown();
    this.onChange();
  }
  loadDropdown() {
    this.dropdownSerice.GetDropDownValues('CAREGIVER_FREQUENCY').subscribe(data => {
      this.caregiverFreq = data;
    }, error => {
      this.alertService.error(error);
    });

    this.dropdownSerice.GetDropDownValues('CAREGIVER_DISTANCE').subscribe(data => {
      this.caregiverDistance = data;
    }, error => {
      this.alertService.error(error);
    });
  }
    onChange() {
        this.primaryCaregiverForm.get('tL_PRIMARY_CG').valueChanges.subscribe(pg => {
            if (pg == 'Y') {
                this.caregiverYes = true;
            } else {
                this.caregiverYes = false;
                this.primaryCaregiverForm.get('tL_TRAINED_CG').reset();
                this.primaryCaregiverForm.get('tL_PAID_CG').reset();
                this.primaryCaregiverForm.get('tL_PAID_CG').reset();
                this.primaryCaregiverForm.get('tL_OTHER_CG').reset();
                this.primaryCaregiverForm.get('tL_OTHER_CG_COMMENTS').reset();
                this.primaryCaregiverForm.get('tL_CG_YEARS').reset();
                this.primaryCaregiverForm.get('tL_CG_MONTHS').reset();
                this.primaryCaregiverForm.get('tL_CG_FREQUENCY').reset();
                this.primaryCaregiverForm.get('tL_CG_MINOR_CHILDREN').reset();
                this.primaryCaregiverForm.get('tL_CG_OTHERS').reset();
                this.primaryCaregiverForm.get('tL_CG_DISTANCE').reset();
            }
        });

        this.primaryCaregiverForm.get('tL_OTHER_CG').valueChanges.subscribe(cg => {
            if (cg == 'Y') {
                this.primaryCaregiverForm.get('tL_OTHER_CG_COMMENTS').enable();
                this.primaryCaregiverForm.get('tL_OTHER_CG_COMMENTS').setValidators(Validators.required);
                this.primaryCaregiverForm.controls['tL_OTHER_CG_COMMENTS'].markAsTouched();
            } else {
                this.primaryCaregiverForm.get('tL_OTHER_CG_COMMENTS').setValue('');
                this.primaryCaregiverForm.get('tL_OTHER_CG_COMMENTS').disable();
            }
        });
    }

    ClearForm(e) {
      this.onClear();
    }
    updatPrimaryCaregiver(values: Object) {
        Object.assign(this.primaryCaregiver, values);
        this.primaryCaregiver.tL_ACAT_ID = this.dataService.getSelectedDocument();
    }
    submitForm() {
        this.isSubmitting = true;
        this.updatPrimaryCaregiver(this.primaryCaregiverForm.value);
        console.log(this.primaryCaregiver);
        this.sectionLService.postPrimaryCaregiverAssessment(this.primaryCaregiver).subscribe(response => {
          this.alertService.success('Primary Caregiver Assessment Saved');
        }, err => {
            console.error('Error Saving Primary Caregiver Assessment', err);
            this.alertService.error("Error Saving Primary Caregiver Assessment");
        });
        this.isSubmitting = false;
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.primaryCaregiverForm.reset();
      }
    });

  }

}
