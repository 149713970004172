import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionBRoutingModule } from './section-B-routing.module';
import { IndividualRelationComponent } from './individual-relation/individual-relation.component';
import { SectionBComponent } from './section-b.component';
import { TextMaskModule } from 'angular2-text-mask';
import { AddNewContactComponent } from './individual-relation/add-new-contact/add-new-contact.component';

@NgModule({
  declarations: [IndividualRelationComponent, SectionBComponent, AddNewContactComponent],
  imports: [
    CommonModule,
    SectionBRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,   
    TextMaskModule],
  entryComponents: [AddNewContactComponent]
})
export class SectionBModule { }
