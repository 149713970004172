import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionHRoutingModule } from './section-h-routing.module';
import { SectionHComponent } from './section-h.component';
import { SchoolWorkComponent } from './school-work/school-work.component';

@NgModule({
  declarations: [SectionHComponent, SchoolWorkComponent],
  imports: [
    CommonModule,
    SectionHRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionHModule { }
