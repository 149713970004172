import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionAService, AlertsService, DataService, UserService, getTextForDropdownValues, SectionA } from '../../shared/index';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-insurance-info',
  templateUrl: './insurance-info.component.html',
  styleUrls: ['./insurance-info.component.css']
})
export class InsuranceInfoComponent implements OnInit {
  insuranceInfoForm: FormGroup;
  newACATID: any;
  formStatus: boolean;
  constructor(private fb: FormBuilder,
    private sectionAService: SectionAService,
    private alertsService: AlertsService,
    public dataService: DataService,
    private router: Router,    
    private userService: UserService) {
    this.insuranceInfoForm = this.fb.group({
      tA_ACAT_ID: new FormControl(''),
      tA_MEDICAID_BILLING_NUMBER: new FormControl('', Validators.minLength(12)),
      tA_MEDICAID_MCP: new FormControl(''),
      tA_MEDICAID_D: new FormControl(''),
      tA_MEDICAID_LIABILITY: new FormControl(''),
      tA_MEDICAID_MYCARE_PLAN: new FormControl(''),
      tA_MEDICARE_ADVANTAGE: new FormControl(''),
      tA_MEDICAID_BUY_IN: new FormControl(''),   
      tA_MEDICAID_A: new FormControl(''),
      tA_MEDICAID_B: new FormControl(''),
      tA_COMM_CARRIER: new FormControl(''),
      tA_COMM_POLICY_ID: new FormControl(''),
      tA_COMM_PHONE: new FormControl('', [Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")])
    });
  }

  ngOnInit() {
    this.newACATID = this.dataService.getSelectedDocument();
    if (this.newACATID != null) {
      this.sectionAService.getSectionA(this.newACATID).subscribe(response => {
        this.receiveInsuranceInformation(response);
        this.alertsService.success("Insurance Information Loaded");

      }, err => {
        this.alertsService.error(err);
      });}

  }
  receiveInsuranceInformation(data) {
    this.insuranceInfoForm.patchValue(data);
    this.setData(data);
  }
  setData(data) {
    if (data['tA_MEDICAID_A'] == 'Y') {
      this.insuranceInfoForm.get('tA_MEDICAID_A').setValue(true);
    }
    else {
      this.insuranceInfoForm.get('tA_MEDICAID_A').setValue(false);
    }
    if (data['tA_MEDICAID_B'] == 'Y') {
      this.insuranceInfoForm.get('tA_MEDICAID_B').setValue(true);
    }
    else {
      this.insuranceInfoForm.get('tA_MEDICAID_B').setValue(false);
    }
  }
  insuranceInfoSave() {

    this.insuranceInfoForm.get('tA_ACAT_ID').setValue('');

    for (let cnt in this.insuranceInfoForm.controls) {
      if (this.insuranceInfoForm.controls[cnt].value == null || this.insuranceInfoForm.controls[cnt].value == '') {
        this.formStatus = false;
      }
      else {
        this.formStatus = true;
        break;
      }
    }

    if (this.formStatus == false) {
     // this.alertsService.error("Please add atleast one insurance information");
      this.warningAlert();
      return;
    }


    this.insuranceInfoForm.get('tA_ACAT_ID').setValue(this.dataService.getSelectedDocument());

    if (!this.insuranceInfoForm.valid) {
      for (let cnt in this.insuranceInfoForm.controls) {
        if (this.insuranceInfoForm.controls[cnt].invalid) {
          console.log(cnt, this.insuranceInfoForm.controls[cnt]);
        }
      }
      return;
    }

    let insuranceData = this.insuranceInfoForm.value;

    insuranceData['tA_MEDICAID_A'] = insuranceData['tA_MEDICAID_A'] ? 'Y' : 'N';
    insuranceData['tA_MEDICAID_B'] = insuranceData['tA_MEDICAID_B'] ? 'Y' : 'N';

    this.sectionAService.PostInsuranceInformation(insuranceData).subscribe(resp => {
      this.alertsService.success("Insurance Information Saved");
    }, error => {
        console.error('Error Saving Insurance Information', error);
        this.alertsService.error("Error Saving Insurance Information");
    });


  }
  clearInsuranceInfoForm() {
    this.onClear();    
  }
  warningAlert() {
    Swal.fire(
      '',
      'Please add atleast one insurance information',
      'warning'
    )
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.insuranceInfoForm.reset();
      }
    });

  }
}
