import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService, User, AlertsService } from '../../shared';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  authForm: FormGroup;
  isSubmitting = false;
  constructor(
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private alertsService: AlertsService
  ) {
    this.authForm = this.fb.group({
      UserName: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60), Validators.required]),
      Password: new FormControl('', [Validators.maxLength(60), Validators.required])
    });
  }

  ngOnInit() {
    this.alertsService.clearLoader();
  }

  submitForm() {
    //this.alertsService.();
    this.isSubmitting = true;
    const credentials = this.authForm.value;
    this.userService
      .attemptAuth('login', credentials)
      .subscribe(response => {
        this.router.navigateByUrl('/search');
      },
      err => {
        //this.alertsService.error(err);
        this.alertsService.error('Your login is invalid.  Please try again.');
      });
    this.isSubmitting = false;
  }

}
