import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionFRoutingModule } from './section-F-routing.module';
import { SectionFComponent } from './section-f.component';
import { AdlsIadlsComponent } from './adls-iadls/adls-iadls.component';
import { AdlRowComponent } from './adls-iadls/adl-row/adl-row.component';

@NgModule({
  declarations: [
     SectionFComponent,
     AdlsIadlsComponent,
     AdlRowComponent
    ],
  imports: [
    CommonModule,
    SectionFRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionFModule { }
