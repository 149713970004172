import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { SearchRoutingModule } from './search-routing.module';
import { MaterialModule } from '../material.module';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedModule } from '../shared';
@NgModule({
    declarations: [SearchComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SearchRoutingModule,
        MaterialModule,
        TextMaskModule,
        SharedModule
    ]
})
export class SearchModule { }
