import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionAService, AlertsService, DataService, SectionDropdownService, UserService, getTextForDropdownValues } from '../../shared/index';
import * as moment from 'moment';
import * as ALOCValidators from '../../shared/validators/AlocDateValidators';
import { timer } from 'rxjs';
import { isNullOrUndefined } from 'util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-language-spoken',
  templateUrl: './language-spoken.component.html',
  styleUrls: ['./language-spoken.component.css']
})
export class LanguageSpokenComponent implements OnInit {
  languageSpokenForm: FormGroup;
  allLanguageSpoken: any[];
  show_TA_LANG_OTHER_DESC = false;
  show_TA_LANG_PREFERRED_DESC = false;
  newACATID: any;
  dropDownSelectText = getTextForDropdownValues;
  constructor(private fb: FormBuilder,
    private sectionAService: SectionAService,
    private alertsService: AlertsService,
    public dataService: DataService,
    private router: Router,
    private dropdownservice: SectionDropdownService,
    private userService: UserService) {
    this.languageSpokenForm = this.fb.group({
      tA_ACAT_ID: new FormControl(''),
      tA_LANG_SPOKEN: new FormControl(''),
      tA_LANG_ENGLISH: new FormControl(''),
      tA_LANG_RUSSIAN: new FormControl(''),
      tA_LANG_SPANISH: new FormControl(''),
      tA_LANG_SOMALI: new FormControl(''),
      tA_LANG_NEPALESE: new FormControl(''),
      tA_LANG_ARABIC: new FormControl(''),
      tA_LANG_SERBIAN: new FormControl(''),
      tA_LANG_LAOTIAN: new FormControl(''),
      tA_LANG_CONTONESE: new FormControl(''),
      tA_LANG_ESTONIAN: new FormControl(''),
      tA_LANG_MANDARIN: new FormControl(''),
      tA_LANG_OTHER: new FormControl(''),
      tA_LANG_OTHER_DESC: new FormControl(''),
      tA_LANG_PREFERRED: new FormControl('', [Validators.required]),
      tA_LANG_PREFERRED_DESC: new FormControl(''),
      tA_INTERPRETER_REQ: new FormControl('', Validators.required)
    });}

  ngOnInit() {

    this.newACATID = this.dataService.getSelectedDocument();
    if (this.newACATID != null) {
      this.sectionAService.getSectionA(this.newACATID).subscribe(response => {
        this.receiveLanguageSpoken(response);
        this.alertsService.success("Household Language(s) Spoken Loaded");

      }, err => {
        this.alertsService.error(err);
      });
    }

    this.loadDropdownService();
    this.configDynValidations();
  }
  receiveLanguageSpoken(data) {
    this.languageSpokenForm.patchValue(data);
    this.setFormData(data);
  }
  loadDropdownService() {

    this.dropdownservice.GetDropDownValues('LANGUAGE_SPOKEN').subscribe((data: any[]) => {
      this.allLanguageSpoken = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });
  }
  languageSpokenSave() {

    this.languageSpokenForm.get('tA_ACAT_ID').setValue(this.dataService.getSelectedDocument());

    if (!this.languageSpokenForm.valid) {
      for (let cnt in this.languageSpokenForm.controls) {
        if (this.languageSpokenForm.controls[cnt].invalid) {
          console.log(cnt, this.languageSpokenForm.controls[cnt]);
        }
      }
      return;
    }

    this.languageSpokenForm.get('tA_LANG_ENGLISH').setValue(null);
    this.languageSpokenForm.get('tA_LANG_RUSSIAN').setValue(null);
    this.languageSpokenForm.get('tA_LANG_SPANISH').setValue(null);
    this.languageSpokenForm.get('tA_LANG_SOMALI').setValue(null);
    this.languageSpokenForm.get('tA_LANG_NEPALESE').setValue(null);
    this.languageSpokenForm.get('tA_LANG_ARABIC').setValue(null);
    this.languageSpokenForm.get('tA_LANG_SERBIAN').setValue(null);
    this.languageSpokenForm.get('tA_LANG_LAOTIAN').setValue(null);
    this.languageSpokenForm.get('tA_LANG_CONTONESE').setValue(null);
    this.languageSpokenForm.get('tA_LANG_ESTONIAN').setValue(null);
    this.languageSpokenForm.get('tA_LANG_MANDARIN').setValue(null);
    this.languageSpokenForm.get('tA_LANG_OTHER').setValue(null);
    let LanguageList: [] = this.languageSpokenForm.get('tA_LANG_SPOKEN').value;

    if (Array.isArray(LanguageList)) {
      LanguageList.forEach(value => {
        switch (value) {
          case 'ENGLISH':
            this.languageSpokenForm.get('tA_LANG_ENGLISH').setValue('Y');
            break;
          case 'RUSS':
            this.languageSpokenForm.get('tA_LANG_RUSSIAN').setValue('Y');
            break;
          case 'SPANISH':
            this.languageSpokenForm.get('tA_LANG_SPANISH').setValue('Y');
            break;
          case 'SOMALI':
            this.languageSpokenForm.get('tA_LANG_SOMALI').setValue('Y');
            break;
          case 'NEPALESE':
            this.languageSpokenForm.get('tA_LANG_NEPALESE').setValue('Y');
            break;
          case 'ARABIC':
            this.languageSpokenForm.get('tA_LANG_ARABIC').setValue('Y');
            break;
          case 'SERBIAN':
            this.languageSpokenForm.get('tA_LANG_SERBIAN').setValue('Y');
            break;
          case 'LAOT':
            this.languageSpokenForm.get('tA_LANG_LAOTIAN').setValue('Y');
            break;
          case 'CANTO':
            this.languageSpokenForm.get('tA_LANG_CONTONESE').setValue('Y');
            break;
          case 'ESTO':
            this.languageSpokenForm.get('tA_LANG_ESTONIAN').setValue('Y');
            break;
          case 'MAND':
            this.languageSpokenForm.get('tA_LANG_MANDARIN').setValue('Y');
            break;          
          case 'OTHER':
            this.languageSpokenForm.get('tA_LANG_OTHER').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    if (this.languageSpokenForm.get('tA_LANG_OTHER').value != 'Y') {
      this.languageSpokenForm.get('tA_LANG_OTHER_DESC').setValue(null);      
    }

    if (this.languageSpokenForm.get('tA_LANG_PREFERRED').value != 'OTHER') {
      this.languageSpokenForm.get('tA_LANG_PREFERRED_DESC').setValue(null);
    }

    let spokenLanguageData = this.languageSpokenForm.value;

    this.sectionAService.PostHouseholdLanguageSpoken(spokenLanguageData).subscribe(resp => {
      this.alertsService.success("Household Language(s) Spoken Saved");
    }, error => {
        console.error('Error Saving Household Language(s) Spoken', error);
        this.alertsService.error("Error Household Language(s) Spoken");
    });

  }
  clearLanguageSpokenForm() {
    this.onClear();  

  }
  configDynValidations() {

    this.languageSpokenForm.get('tA_LANG_SPOKEN').valueChanges.subscribe(value => {
      if (value.indexOf('OTHER') > -1) {
      //if (value == 'OTHER') {
        this.show_TA_LANG_OTHER_DESC = true;
        this.languageSpokenForm.get('tA_LANG_OTHER_DESC').setValidators([Validators.required]);
        this.languageSpokenForm.get('tA_LANG_OTHER_DESC').markAsTouched();
      } else {
        this.show_TA_LANG_OTHER_DESC = false;
        this.languageSpokenForm.get('tA_LANG_OTHER_DESC').clearValidators();
        this.languageSpokenForm.get('tA_LANG_OTHER_DESC').setValue(null);     
      }
      this.languageSpokenForm.get('tA_LANG_OTHER_DESC').updateValueAndValidity();
    });


    this.languageSpokenForm.get('tA_LANG_PREFERRED').valueChanges.subscribe(valueDR => {
      if (valueDR == 'OTHER') {
        this.show_TA_LANG_PREFERRED_DESC = true;
        this.languageSpokenForm.get('tA_LANG_PREFERRED_DESC').setValidators([Validators.required]);
        this.languageSpokenForm.get('tA_LANG_PREFERRED_DESC').markAsTouched();
      } else {
        this.show_TA_LANG_PREFERRED_DESC = false;
        this.languageSpokenForm.get('tA_LANG_PREFERRED_DESC').clearValidators();
        this.languageSpokenForm.get('tA_LANG_PREFERRED_DESC').setValue(null);     
      }
      this.languageSpokenForm.get('tA_LANG_PREFERRED_DESC').updateValueAndValidity();
    });
    
  }
  setFormData(data) {

    let languageSpoken: string[] = [];
    if (data['tA_LANG_ENGLISH'] == 'Y') {
      languageSpoken.push('ENGLISH');
    }
    if (data['tA_LANG_RUSSIAN'] == 'Y') {
      languageSpoken.push('RUSS');
    }
    if (data['tA_LANG_SPANISH'] == 'Y') {
      languageSpoken.push('SPANISH');
    }
    if (data['tA_LANG_SOMALI'] == 'Y') {
      languageSpoken.push('SOMALI');
    }
    if (data['tA_LANG_NEPALESE'] == 'Y') {
      languageSpoken.push('NEPALESE');
    }
    if (data['tA_LANG_ARABIC'] == 'Y') {
      languageSpoken.push('ARABIC');
    }
    if (data['tA_LANG_SERBIAN'] == 'Y') {
      languageSpoken.push('SERBIAN');
    }
    if (data['tA_LANG_LAOTIAN'] == 'Y') {
      languageSpoken.push('LAOT');
    }
    if (data['tA_LANG_CONTONESE'] == 'Y') {
      languageSpoken.push('CANTO');
    }
    if (data['tA_LANG_ESTONIAN'] == 'Y') {
      languageSpoken.push('ESTO');
    }
    if (data['tA_LANG_MANDARIN'] == 'Y') {
      languageSpoken.push('MAND');
    }
    if (data['tA_LANG_OTHER'] == 'Y') {
      languageSpoken.push('OTHER');
      this.show_TA_LANG_OTHER_DESC = true;
    }
    else {
      this.show_TA_LANG_OTHER_DESC = false;
    }
    this.languageSpokenForm.get('tA_LANG_SPOKEN').setValue(languageSpoken);

  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.languageSpokenForm.get('tA_LANG_OTHER_DESC').clearValidators();
        this.languageSpokenForm.get('tA_LANG_PREFERRED_DESC').clearValidators();
        this.show_TA_LANG_OTHER_DESC = false;
        this.show_TA_LANG_PREFERRED_DESC = false;
        this.languageSpokenForm.reset({ tA_LANG_SPOKEN: this.allLanguageSpoken });
      }
    });

  }
}
