import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Medication } from '../medication-admin';
import { DropdownOptionsType } from '../../../shared/common/DropDownOptionsType';
import { DataService, AlertsService, SectionDService, SectionDropdownService } from '../../../shared';
import { diaMode, dialogModeConfig } from '../medication-new/dialogOptions';
import { MedicationNewComponent } from '../medication-new/medication-new.component';
import { forkJoin } from 'rxjs';
import { ConfirmMedDeleteComponent } from '../confirm-med-delete/confirm-med-delete.component';
import { confirmDialogParam } from '../../../shared/components/confirm-dialog/confirm-dialog-common';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-med-tbl',
    templateUrl: './med-tbl.component.html',
    styleUrls: ['./med-tbl.component.css']
})
export class MedTblComponent implements OnInit {

    constructor(
        private dataService: DataService,
        private alerts: AlertsService,
        private sectiondSerice: SectionDService,
        private dropdownService: SectionDropdownService,
        public addNewDialog: MatDialog) { }

    medicationColumns = [
        "tdM_NAME",
        "tdM_OBTAINED",
        "tdM_MAIL_ORDER",
        "tdM_DOSE",
        "tdM_FREQUENCY",
        "tdM_ROUTE",
        "tdM_PACKAGING",
        "tdM_ASSISTANCE",
        "tdM_ASSISTANCE_NAME",
        "tdM_PRESCRIBED_BY",
        "actions"];
    medicationDataSource: MatTableDataSource<Medication>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    //drop down variables
    whereObtainedSrc: DropdownOptionsType[];
    assistNeededSrc: DropdownOptionsType[];
    medicaRouteSrc: DropdownOptionsType[];
    medicaPackgSrc: DropdownOptionsType[];

    documentReferance: { alocid: string, sectionid: string, name: string };

    ngOnInit() {

        this.documentReferance = { alocid: '', sectionid: '', name: '' };
        this.documentReferance.alocid = this.dataService.getSelectedDocument();
        this.documentReferance.name = this.dataService.getSelectedDocumentName();

        this.loadDropdowns().subscribe(val => {
            this.loadMeds();
        })
    }

    loadMeds() {
        //load all medication records
        this.sectiondSerice.getMedications(parseInt(this.documentReferance.alocid)).subscribe((meds: Medication[]) => {
            //console.log('medication', meds);
            this.medicationDataSource = new MatTableDataSource<Medication>(meds);
            this.medicationDataSource.paginator = this.paginator;
            this.medicationDataSource.sort = this.sort;

            this.alerts.clearLoader();
        }, error => {
            console.error(error);
            this.alerts.error('Error loading data');
        });
       
    }

    loadDropdowns(): EventEmitter<string> {
        let dropDownDone = new EventEmitter<string>();
        //DD load medication where
        let med_where = this.dropdownService.GetDropDownValues('MEDICATION_WHERE');
        //DD MEDICATION_ASSIST
        let med_Asst = this.dropdownService.GetDropDownValues('MEDICATION_ASSIST');
        //DD MEDICATION_ROUTE
        let med_rout = this.dropdownService.GetDropDownValues('MEDICATION_ROUTE');
        //DD MEDICATION_PACKG
        let med_pkg = this.dropdownService.GetDropDownValues('MEDICATION_PACKG');

        forkJoin(med_where, med_Asst, med_rout, med_pkg).subscribe(([medWhere, medAsst, medRout, medPkg]) => {
            this.whereObtainedSrc = medWhere;
            this.assistNeededSrc = medAsst;
            this.medicaRouteSrc = medRout;
            this.medicaPackgSrc = medPkg;
            dropDownDone.emit('done');
            dropDownDone.complete();
        }, error => {
            console.error('error loading drop downs', error);
        });
        return dropDownDone;
    }

    applyFilter = (filterTxt: string) => this.medicationDataSource.filter = filterTxt;

    getLabel = (value: string, field: string) => {
        if (value == null) return '';
        let label = '';
        switch (field) {
            case 'whereObtained':
                if (this.whereObtainedSrc == null) break;
                this.whereObtainedSrc.forEach((src) => {
                    if (src.dV_VALUE == value) {
                        label = src.dV_EXPANDED_VALUE;
                        return;
                    }
                });
                break;
            case 'assistNeeded':
                if (this.assistNeededSrc == null) break;
                this.assistNeededSrc.forEach((src) => {
                    if (src.dV_VALUE == value) {
                        label = src.dV_EXPANDED_VALUE;
                        return;
                    }
                });
                break;
            case 'route':
                if (this.medicaRouteSrc == null) break;
                this.medicaRouteSrc.forEach((src) => {
                    if (src.dV_VALUE == value) {
                        label = src.dV_EXPANDED_VALUE;
                        return;
                    }
                });
                break;
            case 'packg':
                if (this.medicaPackgSrc == null) break;
                this.medicaPackgSrc.forEach((src) => {
                    if (src.dV_VALUE == value) {
                        label = src.dV_EXPANDED_VALUE;
                        return;
                    }
                });
                break;

            default:
                break;
        }
        return label;


    }

    onView(row: Medication) {
        this.openDialog({ mode: diaMode.View, row: row });
    }

    onEdit(row: Medication) {
        this.openDialog({ mode: diaMode.Edit, row: row });
    }

    onDelete(row: Medication) {

        let delConfirm: confirmDialogParam = {
            title: 'Delete Medication',
            subtitle: `Please confirm whether you want to delete the following medication ?`,
            message: `
<label>Name :</label> &nbsp${row.tdM_NAME}<br>
`
        };
        const confirmDelete = this.addNewDialog.open(ConfirmDialogComponent, {
            width: '500px',
            disableClose: true,
            data: delConfirm
        });
        confirmDelete.afterClosed().subscribe(result => {
            this.alerts.clearLoader();
            if (result == 'yes') {
                this.sectiondSerice.deleteMedications(parseInt(row.tdM_SECTION_ID)).subscribe((resp) => {
                    console.log(resp);
                    this.loadMeds();
                }, error => {
                    console.error('error deleting medication ', row.tdM_NAME);
                    console.error(error);
                    this.alerts.error(`Error deleting '${row.tdM_NAME}'`);
                })
                this.loadMeds();
            }
        });

    }

    onAddnew() {
        this.openDialog({ mode: diaMode.New, row: null });
    }

    private openDialog(options: dialogModeConfig) {
        let doc = {
            alocid: this.documentReferance.alocid,
            sectionid: options.row != null && options.row.tdM_SECTION_ID != null ? options.row.tdM_SECTION_ID : null
        };
        const addnewdia = this.addNewDialog.open(MedicationNewComponent, {
            height: '500px',
            width: '1000px',
            disableClose: true,
            data: {
                'obtainedSrc': this.whereObtainedSrc,
                'assistSrc': this.assistNeededSrc,
                'mediRoute': this.medicaRouteSrc,
                'mediPackg': this.medicaPackgSrc,
                'document': doc ,
                'modeConfig': options
            }
        });
        addnewdia.afterClosed().subscribe(result => {
            this.alerts.clearLoader();
            if (result == 'success') {
                this.loadMeds();
            }
        });
    }



}
