import { Component, OnInit } from '@angular/core';
import { DataService, SectionDropdownService, AlertsService, SectionDService } from '../../shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BackgroundInfoFields, BackgroundInfo } from './background-info';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-background-info',
    templateUrl: './background-info.component.html',
    styleUrls: ['./background-info.component.css']
})
export class BackgroundInfoComponent implements OnInit {

    constructor(private cntxService: DataService,
        private dropdownService: SectionDropdownService,
        private sectionDService: SectionDService,
        private alerts: AlertsService) { }

    documentReferance: { alocid: string, sectionid: string, name: string };
    backGroundInfoForm: FormGroup;
    screenName = "Background Information";

    ngOnInit() {
        this.documentReferance = { alocid: '', sectionid: '', name: '' };;
        this.documentReferance.alocid = this.cntxService.getSelectedDocument();
        this.documentReferance.name = this.cntxService.getSelectedDocumentName();
        this.initForm();
        this.loadData();
    }

    initForm() {
        let cntrls = {};
        for (let crl in BackgroundInfoFields) {
            cntrls[crl] = new FormControl();
        }
        this.backGroundInfoForm = new FormGroup(cntrls);
        this.backGroundInfoForm.get(BackgroundInfoFields.tD_ACAT_ID).setValidators(Validators.required);
        this.backGroundInfoForm.get(BackgroundInfoFields.tD_COMMENTS).setValidators(Validators.required);
    }

    loadData() {
        this.sectionDService.getBackgroundInfo(parseInt(this.documentReferance.alocid)).subscribe((resp: BackgroundInfo) => {
            //console.log(resp);
            if (resp.tD_ACAT_ID == null || resp.tD_ACAT_ID == 0) {
                this.alerts.error('No data found for Background Information');
            } else {
                this.alerts.success(`${this.screenName} Loaded`);
            }
            this.backGroundInfoForm.patchValue(resp);
            this.documentReferance.sectionid = resp.tD_SECTION_ID == null ? null : resp.tD_SECTION_ID.toString();
        }, error => {
            console.error(error);
            this.alerts.error('Error loading data');
        });
    }

    

    onSave() {
        this.backGroundInfoForm.get(BackgroundInfoFields.tD_ACAT_ID).setValue(this.documentReferance.alocid);
        this.backGroundInfoForm.get(BackgroundInfoFields.tD_SECTION_ID).setValue(this.documentReferance.sectionid);
        if (this.backGroundInfoForm.valid) {
            let formData = this.backGroundInfoForm.value as BackgroundInfo;
            this.sectionDService.saveBackgroundInfo(formData).subscribe(resp => {
                //console.log(resp);
                this.alerts.success(`${this.screenName} Saved`);
            }, error => {
                console.error(error);
                this.alerts.error('Error saving data');
            });
        } else {
            console.error("invalid form !");
        }
    }
    onClear1() {
        this.backGroundInfoForm.reset();
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.backGroundInfoForm.reset();
      }
    });

  }
}
