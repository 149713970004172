import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionEComponent } from './section-e.component';
import { CommunicationComponent } from './communication/communication.component';
import { BcatScreenComponent } from './bcat-screen/bcat-screen.component';
const routes: Routes = [
  {
    path: '',
    component: SectionEComponent,
    children: [
      {
        path: 'bcat-screen',
        component: BcatScreenComponent
      },
      {
        path: 'communication',
        component: CommunicationComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionERoutingModule { }
