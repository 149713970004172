import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionLRoutingModule } from './section-l-routing.module';
import { SectionLComponent } from './section-l.component';
import { PrimaryCaregiverComponent } from './primary-caregiver/primary-caregiver.component';
import { EmploymentSchoolComponent } from './employment-school/employment-school.component';
import { SkillsTrainingComponent } from './skills-training/skills-training.component';
import { AssessorObservationComponent } from './assessor-observation/assessor-observation.component';

@NgModule({
  declarations: [
    SectionLComponent,
    PrimaryCaregiverComponent,
    EmploymentSchoolComponent,
    SkillsTrainingComponent,
    AssessorObservationComponent],
  imports: [
    CommonModule,
    SectionLRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionLModule { }
