import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionGRoutingModule } from './section-g-routing.module';
import { SectionGComponent } from './section-g.component';
import { DevelopmentalDisabilityComponent } from './developmental-disability/developmental-disability.component';
import { AcOkComponent } from './ac-ok/ac-ok.component';
import { NutritionalScreenComponent } from './nutritional-screen/nutritional-screen.component';
import { DentalScreenComponent } from './dental-screen/dental-screen.component';

@NgModule({
  declarations: [
    SectionGComponent,
    DevelopmentalDisabilityComponent,
    AcOkComponent,
    NutritionalScreenComponent,
    DentalScreenComponent],
  imports: [
    CommonModule,
    SectionGRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionGModule { }
