import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';
import { SKNDBMergeParam } from './SKNDBMergeParam';
import { SKNSectionFormType } from './SKNSectionFormType';
import { isDate } from 'util';
import { DatePipe } from '@angular/common';

export function validateFreqOptions(cntrl: AbstractControl): { [key: string]: any } | null {
    let anySelected = cntrl.get('SUN').value || cntrl.get('MON').value ||
        cntrl.get('TUE').value || cntrl.get('WED').value ||
        cntrl.get('FRI').value || cntrl.get('SAT').value;
    //console.log('selected ', anySelected);
    if (anySelected) {
        //console.log(this.sectionForm.get('WeeklyOptions'));
        return null;
    } else {
        //console.log(this.sectionForm.get('WeeklyOptions'));
        return {
            WeeklyOptionsMissing: { valid: false, msg: 'Please select atleast one weekly day. ' }
        }
    }
}


export function transformToSKNMergeParams(formValues: SKNSectionFormType): SKNDBMergeParam {
  if (formValues.SKILL_ID == 'OXYGEN') {
    if (formValues.Option_2 == 'OTHER' && formValues.Option_2_other == '') {
      formValues.Option_2_other = 'OTHER';
    }
  }

  if (formValues.SKILL_ID == 'BOWEL') {
    if (formValues.Option_1 == 'OTHER' && formValues.Option_1_other == '') {
      formValues.Option_1_other = 'OTHER';
    }
  }

    Object.keys(formValues).forEach((fld) => {
        if (formValues.NotApplicable) {
            if (['NotApplicable','ACAT_ID', 'CATEGORY', 'SKILL_ID', 'SkillLevel'].indexOf(fld) < 0) {
                formValues[fld] = null;
            }
        }
        if (formValues[fld]!= null && isDate(formValues[fld])) {
            formValues[fld] = (new DatePipe("en-us")).transform(formValues[fld], 'MM/dd/yyyy');
        }        
    });
    
    return {
        TDS_ACAT_ID: formValues.ACAT_ID,
        TDS_CATEGORY: formValues.CATEGORY,
        TDS_SKILL_ID: formValues.SKILL_ID,
        TDS_SKILL_LEVEL: formValues.SkillLevel,
        TDS_SKILL_COMMENT: formValues.Comments,
        TDS_FREQ: formValues.FrequencyOptions,
        TDS_FREQ_DAYS: weeklyOptionsToString(formValues),
        TDS_FREQ_COMMENT: formValues.FrequencyOthers,
        TDS_OPTION1: formValues.Option_1 == 'OTHER' ? formValues.Option_1_other : formValues.Option_1,
        TDS_OPTION2: formValues.Option_2 == 'OTHER' ? formValues.Option_2_other : formValues.Option_2,
        TDS_OPTION3: formValues.Option_3 == 'OTHER' ? formValues.Option_3_other : formValues.Option_3,
        TDS_OPTION4: formValues.Option_4 == 'OTHER' ? formValues.Option_4_other : formValues.Option_4
    }
}

function weeklyOptionsToString(formValues: SKNSectionFormType): string {
    let weekdays = [];
    for (let day in formValues.WeeklyOptions) {
        if (formValues.WeeklyOptions[day])
            weekdays.push(day);
    }
    return weekdays.join(',');
}

export enum SKNFormMode {
    SkilledRehab,
    SkilledNursing
}
