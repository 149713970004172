import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SectionFModel, SectionFResponse, ADLOptions, SectionFFormModel } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SectionFService {
    constructor(private apiService: ApiService) { }

    getSectionFData(acatId): Observable<SectionFResponse[]> {
        return this.apiService.get(`sectionF/${acatId}`);
    }

    getADLText(): Observable<ADLOptions[]>  {
        return this.apiService.get(`sectionF/ADLTEXT`);
    }

    saveADLIADL(formData: SectionFFormModel) {
        return this.apiService.putNoLoader(`sectionF`, formData);
    }
}

