import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule, HeaderComponent, FooterComponent, SectionComponent, ConsumerinfoComponent } from './shared';
import { SearchModule } from './search';
import { MaterialModule } from './material.module';
import { SectionAModule } from './section-a/section-a.module';
import { SectionBModule } from './section-b/section-b.module';
import { SectionCModule } from './section-c/section-c.module';
import { SectionDModule } from './section-d/section-d.module';
import { SectionEModule } from './section-e/section-e.module';
import { SectionFModule } from './section-f/section-f.module';
import { SectionGModule } from './section-g/section-g.module';
import { SectionHModule } from './section-h/section-h.module';
import { SectionIModule } from './section-i/section-i.module';
import { SectionJModule } from './section-j/section-j.module';
import { SectionKModule } from './section-k/section-k.module';
import { SectionLModule } from './section-l/section-l.module';
import { SummaryModule } from './summary/summary.module';
import { LoginModule } from './login/login.module';
import { UserInfoModule } from './user-info/user-info.module';
import { NaviSetupModule } from './navi-setup/navi-setup.module';
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SectionComponent,
        ConsumerinfoComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        SearchModule,
        MaterialModule,
        SectionAModule,
        SectionBModule,
        SectionCModule,
        SectionDModule,
        SectionEModule,
        SectionFModule,
        SectionGModule,
        SectionHModule,
        SectionIModule,
        SectionJModule,
        SectionKModule,
        SectionLModule,
        SummaryModule,
        LoginModule,
        UserInfoModule,
        NaviSetupModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
