import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionHComponent } from './section-h.component';
import { SchoolWorkComponent } from './school-work/school-work.component';

const routes: Routes = [
  {
    path: '',
    component: SectionHComponent,
    children: [
      {
        path: 'school-work',
        component: SchoolWorkComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionHRoutingModule { }
