
export class EmergencyVisit {
    tD_SECTION_ID: number;
    tD_ACAT_ID: number;
    tD_ER_IND: string;
    tD_ER_NUMBER: number;
    tD_ER_DATE: string;
    tD_ER_NAME: string;
    tD_ER_REASON: string;
    tD_URGENT_IND: string;
    tD_URGENT_NUMBER: number;
    tD_HOSP_IND: string;
    tD_HOSP_NUMBER: number;
    tD_HOSP_ADMIT_DATE: string;
    tD_HOSP_DISCHARGE_DATE: string;
    tD_HOSP_NAME: string;
    tD_HOSP_REASON: string;
    tD_BH_IND: string;
    tD_BH_ADMIT_DATE: string;
    tD_BH_DISCHARGE_DATE: string;
    tD_BH_NAME: string;
    tD_BH_REASON: string;
    tD_NF_IND: string;
    tD_NF_NUMBER: number;
    tD_NF_ADMIT_DATE: string;
    tD_NF_DISCHARGE_DATE: string;
    tD_NF_NAME: string;
    tD_NF_REASON: string;
}

export class EmergencyVisitFields {
    public static tD_SECTION_ID = 'tD_SECTION_ID';
    public static tD_ACAT_ID = 'tD_ACAT_ID';
    public static tD_ER_IND = 'tD_ER_IND';
    public static tD_ER_NUMBER = 'tD_ER_NUMBER';
    public static tD_ER_DATE = 'tD_ER_DATE';
    public static tD_ER_NAME = 'tD_ER_NAME';
    public static tD_ER_REASON = 'tD_ER_REASON';
    public static tD_URGENT_IND = 'tD_URGENT_IND';
    public static tD_URGENT_NUMBER = 'tD_URGENT_NUMBER';
    public static tD_HOSP_IND = 'tD_HOSP_IND';
    public static tD_HOSP_NUMBER = 'tD_HOSP_NUMBER';
    public static tD_HOSP_ADMIT_DATE = 'tD_HOSP_ADMIT_DATE';
    public static tD_HOSP_DISCHARGE_DATE = 'tD_HOSP_DISCHARGE_DATE';
    public static tD_HOSP_NAME = 'tD_HOSP_NAME';
    public static tD_HOSP_REASON = 'tD_HOSP_REASON';
    public static tD_BH_IND = 'tD_BH_IND';
    public static tD_BH_ADMIT_DATE = 'tD_BH_ADMIT_DATE';
    public static tD_BH_DISCHARGE_DATE = 'tD_BH_DISCHARGE_DATE';
    public static tD_BH_NAME = 'tD_BH_NAME';
    public static tD_BH_REASON = 'tD_BH_REASON';
    public static tD_NF_IND = 'tD_NF_IND';
    public static tD_NF_NUMBER = 'tD_NF_NUMBER';
    public static tD_NF_ADMIT_DATE = 'tD_NF_ADMIT_DATE';
    public static tD_NF_DISCHARGE_DATE = 'tD_NF_DISCHARGE_DATE';
    public static tD_NF_NAME = 'tD_NF_NAME';
    public static tD_NF_REASON = 'tD_NF_REASON'; 

}

