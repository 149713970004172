import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionFComponent } from './section-f.component';
import { AdlsIadlsComponent } from './adls-iadls/adls-iadls.component';
import { AdlsUsaveGurardGuard } from './adls-usave-gurard.guard';
const routes: Routes = [
    {
        path: '',
        component: SectionFComponent,
        children: [
            {
                path: 'adls-iadls',
                component: AdlsIadlsComponent,
                canDeactivate: [AdlsUsaveGurardGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SectionFRoutingModule { }
