import { Component, OnInit, ViewChild } from '@angular/core';
import { SectionDService, DataService, AlertsService } from '../../shared';
import { PhysicianContact, NewPhysicianContext, NewPhysicianScreenMode } from '../common/PhysicianContact';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AddNewPhysicianContactComponent } from './add-new-physician-contact/add-new-physician-contact.component';
import { confirmDialogParam } from '../../shared/components/confirm-dialog/confirm-dialog-common';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-physician-info',
    templateUrl: './physician-info.component.html',
    styleUrls: ['./physician-info.component.css']
})
export class PhysicianInfoComponent implements OnInit {

    constructor(private secDService: SectionDService,
        private dataService: DataService,
        private alerts: AlertsService,
        public addNewDialog: MatDialog) { }

    physicianContactsDS: MatTableDataSource<PhysicianContact>;
    physicianContactsDSColumns: string[] = ['tdP_PRIMARY_IND', 'tdP_FIRST_NAME', 'tdP_LAST_NAME', 'tdP_TYPE', 'tdP_ADDRESS', 'tdP_CITY', 'tdP_STATE', 'tdP_ZIP_CODE', 'tdP_COUNTY', 'tdP_PHONE_PRIMARY', 'Actions'];
    documentID: string;
    documntName: string;
    sectionID: string;

    @ViewChild(MatSort, { static: true }) phyTblSort: MatSort;

    ngOnInit() {
        this.loadPhysiciansList();
        this.documentID = this.dataService.getSelectedDocument();
        this.sectionID = this.dataService.getSectionId();
        this.documntName = this.dataService.getSelectedDocumentName();

    }

    loadPhysiciansList() {
        //get physicians
        this.secDService.getPhysicians(parseInt(this.dataService.getSelectedDocument())).subscribe((physicians: PhysicianContact[]) => {
            //console.dir(physicians);
            this.alerts.clearLoader();
            this.physicianContactsDS = new MatTableDataSource(physicians);
          this.physicianContactsDS.sort = this.phyTblSort;
          this.alerts.success("Physician Information Loaded");
        }, (error) => {
            console.error('Error getting physicians ', error);
            this.alerts.clearLoader();
        });
    }

    onViewPhy = (row) => {

        let cntx_data: NewPhysicianContext = {
            ACATID: this.documentID,
            SectionID: this.sectionID,
            Mode: NewPhysicianScreenMode.View,
            SelectedRecord: row
        };
        const addnewdia = this.addNewDialog.open(AddNewPhysicianContactComponent, {
            height: '680px',
            width: '1000px',
            disableClose: true,
            data: cntx_data
        });
        addnewdia.afterClosed().subscribe(result => {
            if (result == 'success') {
                //this.loadPhysiciansList();
            }
        });

    };

    onEditPhy = (row: PhysicianContact) => {
        //console.log('selected row ', row);
        //console.log('contx sec id ', this.sectionID);
        let cntx_data: NewPhysicianContext = {
            ACATID: this.documentID,
            SectionID: row.tdP_SECTION_ID.toString(),
            Mode: NewPhysicianScreenMode.Edit,
            SelectedRecord: row
        };
        const addnewdia = this.addNewDialog.open(AddNewPhysicianContactComponent, {
            height: '680px',
            width: '1000px',
            disableClose: true,
            data: cntx_data
        });
        addnewdia.afterClosed().subscribe(result => {
            if (result == 'success') {
                this.loadPhysiciansList();
            }
        });

    };

    onDeletePhy = (row: PhysicianContact) => {
        //console.log(row);
        let delConfirm: confirmDialogParam = {
            message: `
<label>Type: </label> &nbsp${row.tdP_TYPE}<br>
<label>Name: </label> &nbsp${row.tdP_FIRST_NAME + ' ' + row.tdP_LAST_NAME}<br>
<label>Address: </label> &nbsp${row.tdP_ADDRESS == null ? '' : row.tdP_ADDRESS}<br>
`,
            title: 'Delete Physician',
            subtitle: 'Please confirm whether you want to delete the following Physician ?'
        };
        const deleteDia = this.addNewDialog.open(ConfirmDialogComponent, {
            width: '500px',
            disableClose: true,
            data: delConfirm
        });
        deleteDia.afterClosed().subscribe(result => {
            if (result == 'yes') {
                this.secDService.deletePhysician(row.tdP_SECTION_ID).subscribe(resp => {
                    this.alerts.success('Physician deleted successfully.');
                    this.loadPhysiciansList();
                }, error => {
                        this.alerts.error('Error deleting physician');
                       
                })
            } else if (result == 'no') {

            }
        });
    };

    onRefreshPhy = (row) => {
        this.loadPhysiciansList();
    }

    addNewPhyCon() {

        let cntx_data: NewPhysicianContext = {
            ACATID: this.documentID,
            SectionID: null,
            Mode: NewPhysicianScreenMode.New,
            SelectedRecord: null
        };
        const addnewdia = this.addNewDialog.open(AddNewPhysicianContactComponent, {
            height: '680px',
            width: '1000px',
            disableClose: true,
            data: cntx_data
        });
        addnewdia.afterClosed().subscribe(result => {
            if (result == 'success') {
                this.loadPhysiciansList();
            }
        });

    }

    applyFilter = (filterTxt: string) => this.physicianContactsDS.filter = filterTxt;

}
