import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'new-document',
    loadChildren: () => import('./section-a/section-a.module').then(m => m.SectionAModule)
  },
  {
    path: 'section-a',
    loadChildren: () => import('./section-a/section-a.module').then(m => m.SectionAModule)
  },
  {
    path: 'section-b',
    loadChildren: () => import('./section-b/section-b.module').then(m => m.SectionBModule)
  },
  {
    path: 'section-c',
    loadChildren: () => import('./section-c/section-c.module').then(m => m.SectionCModule)
  },
  {
    path: 'section-d',
    loadChildren: () => import('./section-d/section-d.module').then(m => m.SectionDModule)
  },
  {
    path: 'section-e',
    loadChildren: () => import('./section-e/section-e.module').then(m => m.SectionEModule)
  },
  {
    path: 'section-f',
    loadChildren: () => import('./section-f/section-f.module').then(m => m.SectionFModule)
  },
  {
    path: 'section-g',
    loadChildren: () => import('./section-g/section-g.module').then(m => m.SectionGModule)
  },
  {
    path: 'section-h',
    loadChildren: () => import('./section-h/section-h.module').then(m => m.SectionHModule)
  },
  {
    path: 'section-i',
    loadChildren: () => import('./section-i/section-i.module').then(m => m.SectionIModule)
  },
  {
    path: 'section-j',
    loadChildren: () => import('./section-j/section-j.module').then(m => m.SectionJModule)
  },
  {
    path: 'section-k',
    loadChildren: () => import('./section-k/section-k.module').then(m => m.SectionKModule)
  },
  {
    path: 'section-l',
    loadChildren: () => import('./section-l/section-l.module').then(m => m.SectionLModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule)
  },
  {
    path: 'search',
    loadChildren : () => import('./search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./user-info/user-info.module').then(m => m.UserInfoModule)
  },
  {
    path: 'navi',
    loadChildren: () => import('./navi-setup/navi-setup.module').then(m => m.NaviSetupModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
