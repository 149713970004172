import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionAService, AlertsService, DataService, UserService, getTextForDropdownValues, SectionA, SectionDropdownService } from '../../shared/index';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { validatePastDate } from '../../shared/validators/AlocDateValidators';
@Component({
  selector: 'app-living-situation',
  templateUrl: './living-situation.component.html',
  styleUrls: ['./living-situation.component.css'],
  providers: [DatePipe]
})
export class LivingSituationComponent implements OnInit {
  livingSituationForm: FormGroup;
  currentResidence: any[];
  individualInstitutionalStatus: string;
  showIndividualInstitutional: boolean;
  newACATID: any;
  constructor(private fb: FormBuilder,
    private sectionAService: SectionAService,
    private alertsService: AlertsService,
    public dataService: DataService,
    private router: Router,
    private dropdownservice: SectionDropdownService,
    private userService: UserService,
    private datePipe: DatePipe) {
    this.livingSituationForm = this.fb.group({
      tA_ACAT_ID: new FormControl(''),
      tA_RESIDENCE: new FormControl('', [Validators.required]),
      tA_INSTITUTIONALIZED: new FormControl('', [Validators.required]),
      tA_DISCHARGE_DATE: new FormControl('', [validatePastDate]),
      tA_DISCHARGE_PLACE_KNOWN: new FormControl(''),
      tA_DISCHARGE_PLACE: new FormControl(''),
      tA_DISCHARGE_ADDRESS: new FormControl('', [Validators.pattern("[A-Za-z0-9/:)(-@&*#,;.\. \'-]+"), Validators.maxLength(60)]),
      tA_COMMENTS: new FormControl('')
    });}

  ngOnInit() {

    this.newACATID = this.dataService.getSelectedDocument();
    if (this.newACATID != null) {
      this.sectionAService.getSectionA(this.newACATID).subscribe(response => {
        this.receiveLivingSituation(response);
        this.alertsService.success("Living Situation Loaded");

      }, err => {
        this.alertsService.error(err);
      });
    }
   
    this.loadDropdownService();
  }
  receiveLivingSituation(data) {
    this.livingSituationForm.patchValue(data);
    this.setFormData(data)
  }
  setFormData(data) {
   
    if (data['tA_INSTITUTIONALIZED'] == 'Y') {
      this.showIndividualInstitutional = true;
    }
    else {
      this.showIndividualInstitutional = false;
    }
    if (data['tA_DISCHARGE_DATE'] != null && data['tA_DISCHARGE_DATE'] != '0001-01-01T00:00:00') {
      this.livingSituationForm.get('tA_DISCHARGE_DATE').setValue(new Date(data['tA_DISCHARGE_DATE']));
    } else {
      this.livingSituationForm.get('tA_DISCHARGE_DATE').setValue(null);
    }
      
  }
  loadDropdownService() {

    this.dropdownservice.GetDropDownValues('LIVING_SITUATION').subscribe((data: any[]) => {
      this.currentResidence = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });
  }
  livingSituationSave() {
    this.livingSituationForm.get('tA_ACAT_ID').setValue(this.dataService.getSelectedDocument());

    if (!this.livingSituationForm.valid) {
      for (let cnt in this.livingSituationForm.controls) {
        if (this.livingSituationForm.controls[cnt].invalid) {
          console.log(cnt, this.livingSituationForm.controls[cnt]);
        }
      }
      return;
    }

    let livingSituationData = this.livingSituationForm.value;

    if (livingSituationData['tA_DISCHARGE_DATE'] != null)
      livingSituationData['tA_DISCHARGE_DATE'] = this.datePipe.transform(livingSituationData['tA_DISCHARGE_DATE'], 'MM/dd/yyyy');

    this.sectionAService.PostLivingSituation(livingSituationData).subscribe(resp => {
      this.alertsService.success("Living Situation Saved");
    }, error => {
        console.error('Error Saving Living Situation', error);
        this.alertsService.error("Error Saving Living Situation");
    });
  }
  changeIndividualInstitutional() {
    this.individualInstitutionalStatus = this.livingSituationForm.get('tA_INSTITUTIONALIZED').value;
    if (this.individualInstitutionalStatus === "Y") {
      this.showIndividualInstitutional = true;
      this.livingSituationForm.get('tA_DISCHARGE_DATE').setValidators([Validators.required, validatePastDate]);
      this.livingSituationForm.get('tA_DISCHARGE_DATE').updateValueAndValidity();

    } else {
      this.showIndividualInstitutional = false;
      this.livingSituationForm.get('tA_DISCHARGE_DATE').clearValidators();
      this.livingSituationForm.get('tA_DISCHARGE_DATE').setValue(null);
      this.livingSituationForm.get('tA_DISCHARGE_DATE').updateValueAndValidity();
      this.livingSituationForm.get('tA_DISCHARGE_PLACE_KNOWN').setValue(null);
      this.livingSituationForm.get('tA_DISCHARGE_PLACE').setValue(null);
      this.livingSituationForm.get('tA_DISCHARGE_ADDRESS').setValue(null);
    }
  }
  clearLivingSituationForm() {
    this.onClear();    
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.showIndividualInstitutional = false;
        this.livingSituationForm.get('tA_DISCHARGE_DATE').clearValidators();
        this.livingSituationForm.reset();
      }
    });

  }
}
