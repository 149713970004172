import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SearchResultModel, SearchParams } from 'src/app/shared';
import { SearchService, SearchModel, AlertsService, DataService } from '../../shared';
import { MatDialog, MatSort } from '@angular/material';
import { confirmDialogParam } from '../../shared/components/confirm-dialog/confirm-dialog-common';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
    providers : [DatePipe]
})

export class SearchComponent implements OnInit {
    searchForm: FormGroup;
    isSubmitting = false;
    documentTypes: any = [{ value: 'ACAT', text: 'ACAT' }];
    documentStatus: any = [{ value: 'STARTED', text: 'STARTED' }, { value: 'REFERRED', text: 'NOT STARTED' }, { value: 'COMPLETE', text: 'COMPLETE' }];
  nfDocumentStatus: any = [{ value: 'CREATED', text: 'CREATED' }, { value: 'RECEIVED', text: 'RECEIVED' }];
  document_status: { value: string, text: string }[] = [{ value: 'INPROCESS', text: 'IN PROCESS' }, { value: 'SUBMITTED', text: 'SUBMITTED' }, { value: 'COMPLETE', text: 'COMPLETE' },
  { value: 'PIMSLOADED', text: 'PIMS LOADED' }, { value: 'DETERM', text: 'DETERM' }];
    isAdmin: any;
    isActive: any;
    dataSource: MatTableDataSource<SearchResultModel>;


    searchModel: SearchModel = {} as SearchModel;
    searchResultModel: SearchResultModel[] = {} as SearchResultModel[];
    ssnmask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    constructor(private fb: FormBuilder,
        private searchService: SearchService,
        private router: Router,
        private alertsService: AlertsService,
        private dataService: DataService,
        public addNewDialog: MatDialog,
        private date_pipe: DatePipe) {

        this.searchForm = this.fb.group({
            AS_FIRST_NAME: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9 %]+$/), Validators.maxLength(60)]),
            AS_LAST_NAME: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9 %]+$/), Validators.maxLength(60)]),
          AS_SSN: new FormControl('', [Validators.pattern(/\d{3}-\d{2}-\d{4}/)]),
            AS_DATE_OF_BIRTH: new FormControl(''),
            AS_ID: new FormControl('', [Validators.pattern(/^[0-9]+$/)]),
            AS_BEGIN_DATE: new FormControl(''),
            AS_END_DATE: new FormControl(''),
            AS_MEDICAID_BILLING_NUMBER: new FormControl('', [Validators.pattern(/^[0-9%]+$/)]),
            AS_CLIENT_NUMBER: new FormControl('', [Validators.pattern(/^[0-9%]+$/)]),
            AS_PIMS_EVENT_ID: new FormControl('', [Validators.pattern(/^[0-9%]+$/)]),
            AS_TYPE: new FormControl('', [Validators.pattern(/^[a-zA-Z]+$/)]),
            AS_DODD_STATUS: new FormControl(''),
            AS_NF_STATUS: new FormControl(''),
            AS_LEVEL_II: new FormControl(''),
          AS_CATEGORICAL_IND: new FormControl(''),
          AS_DOCUMENT_STATUS: new FormControl('')
        });

    }

    displayedColumns: string[] = ['Delete', 'Edit', 'Print', 'Review', 'DB_ID', 'CATEGORICAL', 'STATUS', 'NAME', 'SUBMIT_DATE', 'REVIEWER', 'ASSESSOR', 'SUBMITTER_FACILITY', 'CREATE_DATE', 'MEDICAID_NUMBER', 'SSN', 'DATE_OF_BIRTH', 'SEX', 'PSYCH_DISCHARGE', 'ODMH_STATUS', 'DODD_STATUS', 'LOCAL_STATUS', 'PIMS_CLIENT_NUMBER', 'COUNTY_CODE', 'TYPE'];


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;


    ngOnInit() {
     
     this.loadData();
      this.onChanges();
      this.searchForm.reset();
    }

    loadData() {
        this.searchService.getSearchLoad(false).subscribe((response: SearchResultModel[]) => {
            console.log('default data ', response);
            this.searchResultModel = response;
            this.dataSource = new MatTableDataSource<SearchResultModel>(this.searchResultModel);
            this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'CREATE_DATE':
                const newDate = new Date(item.CREATE_DATE);
                return newDate;
              default:
                return item[property];
            }
          }; 
            this.alertsService.success('Data Loaded Successfully');
        }, error => {
                console.error(error);
                this.alertsService.error('Error loading data');
        });
        if (this.dataService.getSearchFilter() != null) {
            this.searchForm.patchValue(this.dataService.getSearchFilter());
        }
        
    }

    onChanges() {
        //this.searchForm.get('P_AS_SSN').valueChanges.subscribe(ssn => {
        //  console.log(ssn.length);
        //  //if (ssn.length != 10) {
        //  //  this.searchForm.get('P_AS_SSN').setErrors(errors)
        //  //}
        //});
    }

    onDelete(documentId) {

        let delConfirm: confirmDialogParam = {
            title: 'Delete ACAT document !',
            subtitle: `Following ACAT document will be deleted ! <br/> Do you want to proceed ?`,
            message: `Document Id : <b>${documentId}</b>`
        };
        const confirmDelete = this.addNewDialog.open(ConfirmDialogComponent, {
            width: '500px',
            disableClose: true,
            data: delConfirm
        });
        confirmDelete.afterClosed().subscribe(result => {
            this.alertsService.clearLoader();
            if (result == 'yes') {
                this.searchService.deleteACATDoc(documentId).subscribe(resp => {
                    console.log(resp);
                    this.alertsService.success('Document deleted successfully');
                    this.loadData();
                }, error => {
                    console.log(error);
                    this.alertsService.error('Error deleting document ');
                });
            }
        });
    }

    onEdit(document) {
        //  console.log(documentId);
        this.dataService.saveSelectedDocument({ dB_ID: document.DB_ID, name: document.NAME });
        this.router.navigate(['section-a']);
        //console.log(this.dataService.getSelectedDocument());
    }

    onPrint(documentId) {
        //   console.log(documentId);

    }

    levelIIChange($event) {
        // console.log($event);
        if ($event.checked) {
            this.searchForm.get('P_AS_LEVEL_II').setValue(true);
        }
    }

    categoricalChange($event) {
        //  console.log($event);
        if ($event.checked) {
            this.searchForm.get('P_AS_CATEGORICAL_IND').setValue(true);
        }
    }

    onReset() {     
      this.searchForm.reset();
      this.dataService.clearSearchFilter();
      this.loadData();
    }

    SearchACAT() {

        if (this.searchForm.invalid)
            return;

        let searchParams: SearchParams = this.searchForm.value;

        for (let fld in searchParams) {
            if (searchParams[fld] == '') {
                searchParams[fld] = null;
            }
        }

        searchParams.AS_BEGIN_DATE = searchParams.AS_BEGIN_DATE == null ? null : this.date_pipe.transform(searchParams.AS_BEGIN_DATE, 'MM/dd/yyyy');
        searchParams.AS_END_DATE = searchParams.AS_END_DATE == null ? null : this.date_pipe.transform(searchParams.AS_END_DATE, 'MM/dd/yyyy');
      searchParams.AS_DATE_OF_BIRTH = searchParams.AS_DATE_OF_BIRTH == null ? null : this.date_pipe.transform(searchParams.AS_DATE_OF_BIRTH, 'MM/dd/yyyy');
      searchParams.AS_SSN = searchParams.AS_SSN != null ? searchParams.AS_SSN.replace(/-/g, '') : null;

        //console.log(JSON.stringify(searchParams));

        //console.log('search params ', searchParams);
        this.dataService.saveSearchFilter(searchParams);
        this.searchService.search(searchParams).subscribe((response: SearchResultModel[]) => {
            //console.log(response);
            this.searchResultModel = response;
            //console.log(this.searchResultModel)
            this.dataSource = new MatTableDataSource<SearchResultModel>(this.searchResultModel);
            this.dataSource.paginator = this.paginator;
            this.alertsService.success('Data Loaded Successfully');
        }, error => {
                console.error(error);
                this.alertsService.error('Error searching ACAT');;
        });
    }

    onReview(document) {
        this.dataService.saveSelectedDocument({ dB_ID: document.DB_ID, name: document.NAME });
        this.router.navigate(['summary']);
  }
  getStatusLabel(status) {
    for (let st of this.document_status) {
      if (st.value == status) {
        return st.text;
      }
    }
  }
}
