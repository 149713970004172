import { DatePipe } from '@angular/common';



  export function toTitleCase(str: string) {
    if (str == null || str.length == 0) return str;
    return str.split(' ')
      .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(' ');
}

export function getTextForDropdownValues(selectedValues: string[], dataSource: any[], valueField: string, textField: string): string {
  if (dataSource == null || selectedValues == null || valueField == null || textField == null) return null;
  let textArray = [];
  dataSource.forEach(data => {
    if (selectedValues.indexOf(data[valueField]) > -1) {
      textArray.push(data[textField]);
    }
  });
  return textArray.join(', ');
}

export let DateHelper = {
    ConvertDateToString: (dateValue) => {
        return (new DatePipe('en-us')).transform(dateValue, 'MM/dd/yyyy');
    },
    ConvertStrinToDate: (dateValue) => {
        return dateValue == null || dateValue=='' ? '': new Date(dateValue);
    }
};
