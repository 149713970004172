
export class SubstanceUseFields {
    public static tD_SECTION_ID = 'tD_SECTION_ID';
    public static tD_ACAT_ID = 'tD_ACAT_ID';
    public static ALCOHOL_NA = 'ALCOHOL_NA';
    public static tD_ALCOHOL_USE = 'tD_ALCOHOL_USE';
    public static tD_ALCOHOL_AMT = 'tD_ALCOHOL_AMT';
    public static tD_ALCOHOL_FREQ = 'tD_ALCOHOL_FREQ';
    public static tD_ALCOHOL_LAST_DATE = 'tD_ALCOHOL_LAST_DATE';
    public static CAFFEINE_NA = 'CAFFEINE_NA';
    public static tD_CAFFEINE_USE = 'tD_CAFFEINE_USE';
    public static tD_CAFFEINE_AMT = 'tD_CAFFEINE_AMT';
    public static tD_CAFFEINE_FREQ = 'tD_CAFFEINE_FREQ';
    public static tD_CAFFEINE_LAST_DATE = 'tD_CAFFEINE_LAST_DATE';
    public static NICOTINE_NA = 'NICOTINE_NA';
    public static tD_NICOTINE_USE = 'tD_NICOTINE_USE';
    public static tD_NICOTINE_AMT = 'tD_NICOTINE_AMT';
    public static tD_NICOTINE_FREQ = 'tD_NICOTINE_FREQ';
    public static tD_NICOTINE_LAST_DATE = 'tD_NICOTINE_LAST_DATE';
    public static OTHER_NA = 'OTHER_NA';
    public static tD_OTHER_USE = 'tD_OTHER_USE';
    public static tD_OTHER_AMT = 'tD_OTHER_AMT';
    public static tD_OTHER_FREQ = 'tD_OTHER_FREQ';
    public static tD_OTHER_LAST_DATE = 'tD_OTHER_LAST_DATE';
    public static tD_RECOVERY_IND = 'tD_RECOVERY_IND';
    public static tD_SUBSTANCE_COMMENT = 'tD_SUBSTANCE_COMMENT'; 
}
