import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SectionB1Service {

  constructor(private apiService: ApiService) { }

  getSectionB1(ACATId): Observable<any> {
    return this.apiService.get(`Sectionb1/${ACATId}`);
  }

  updateSectionB1(formData): Observable<any> {
    return this.apiService.putNoLoader('Sectionb1/', formData);
  }

}
