import { TransplantInfo } from './TransplantInfo';
import { HadTransplant } from './had-transplant-cntrl/HadTransplant';
import { TransplantInfoLabels } from './TransplantInfoLabel';
import { DatePipe } from '@angular/common';

export class TransplantInfoFormHelper {
    
    public static mapDataChildToParent(parentData: TransplantInfo, allChildData: HadTransplant[]): TransplantInfo {
        let dp = new DatePipe('en-us');
        for (let child of allChildData) {
            switch (child.TX_LABEL) {
                case TransplantInfoLabels.BoneMarrow:
                    parentData.tD_BONE_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_BONE_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_BONE_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_BONE_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_BONE_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;
                case TransplantInfoLabels.CornealImplants:
                    parentData.tD_CORNEA_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_CORNEA_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_CORNEA_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_CORNEA_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_CORNEA_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;
                case TransplantInfoLabels.Heart:
                    parentData.tD_HEART_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_HEART_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_OTHER_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_HEART_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_HEART_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;
                case TransplantInfoLabels.Intestine:
                    parentData.tD_INTESTINE_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_INTESTINE_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_INTESTINE_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_INTESTINE_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_INTESTINE_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;
                case TransplantInfoLabels.Kidney:
                    parentData.tD_KIDNEY_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_KIDNEY_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_KIDNEY_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_KIDNEY_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_KIDNEY_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;
                case TransplantInfoLabels.Liver:
                    parentData.tD_LIVER_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_LIVER_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_LIVER_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_LIVER_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_LIVER_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;
                case TransplantInfoLabels.Lung:
                    parentData.tD_LUNG_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_LUNG_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_LUNG_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_LUNG_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_LUNG_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;
                case TransplantInfoLabels.Pancreas:
                    parentData.tD_PANCREAS_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_PANCREAS_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_PANCREAS_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_PANCREAS_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_PANCREAS_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;
                case TransplantInfoLabels.SkinGrafts:
                    parentData.tD_SKIN_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_SKIN_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_SKIN_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_SKIN_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_SKIN_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;
                case TransplantInfoLabels.Other:
                    parentData.tD_OTHER_TX_DESC = child.TX_OTHER;
                    parentData.tD_OTHER_NO_IND = child.TX_NO ? 'Y' : 'N';
                    parentData.tD_OTHER_YES_IND = child.TX_YES ? 'Y' : 'N';
                    parentData.tD_OTHER_PENDING_IND = child.TX_PENDING ? 'Y' : 'N';
                    parentData.tD_OTHER_TX_DATE = child.TX_DATE != null ? dp.transform(child.TX_DATE, 'MM/dd/yyyy') : null;
                    parentData.tD_OTHER_PENDING_DATE = child.TX_PENDING_DATE != null ? dp.transform(child.TX_PENDING_DATE, 'MM/dd/yyyy') : null;
                    break;

                default:
                    console.error('unidentfied child row', child);
                    break;
            }
        }
        return parentData;
    }

    public static mapParentToChild(parentData: TransplantInfo): Array<HadTransplant> {
        let txOrganData = new Array<HadTransplant>();
        //BONE MARROW/STEM CELL
        txOrganData.push({
            TX_LABEL: 'Bone Marrow/Stem Cell',
            TX_NO: parentData.tD_BONE_NO_IND == 'Y',
            TX_YES: parentData.tD_BONE_YES_IND == 'Y',
            TX_DATE: new Date(parentData.tD_HEART_PENDING_DATE) ,
            TX_PENDING_DATE: new Date(parentData.tD_BONE_PENDING_DATE) ,
            TX_PENDING: parentData.tD_BONE_PENDING_IND == 'Y',
            TX_OTHER: '',
            DISABLED: true,
            SUBMITTED: false
        });

        //HEART
        txOrganData.push({
            TX_LABEL: 'Heart',
            TX_NO: parentData.tD_HEART_NO_IND == 'Y',
            TX_YES: parentData.tD_HEART_YES_IND == 'Y',
            TX_PENDING: parentData.tD_HEART_PENDING_IND == 'Y',
            TX_PENDING_DATE: new Date(parentData.tD_HEART_PENDING_DATE),
            TX_DATE: new Date(parentData.tD_HEART_TX_DATE),
            TX_OTHER: '',
            DISABLED: true,
            SUBMITTED: false
        });

        //INTESTINE
        txOrganData.push({
            TX_LABEL: 'Intestine',
            TX_NO: parentData.tD_INTESTINE_NO_IND == 'Y',
            TX_YES: parentData.tD_INTESTINE_YES_IND == 'Y',
            TX_PENDING: parentData.tD_INTESTINE_PENDING_IND == 'Y',
            TX_PENDING_DATE: new Date(parentData.tD_INTESTINE_PENDING_DATE),
            TX_DATE: new Date(parentData.tD_INTESTINE_TX_DATE),
            TX_OTHER: '',
            DISABLED: true,
            SUBMITTED: false
        });

        //KIDNEY
        txOrganData.push({
            TX_LABEL: 'Kidney',
            TX_NO: parentData.tD_KIDNEY_NO_IND == 'Y',
            TX_YES: parentData.tD_KIDNEY_YES_IND == 'Y',
            TX_PENDING: parentData.tD_KIDNEY_PENDING_IND == 'Y',
            TX_PENDING_DATE: new Date(parentData.tD_KIDNEY_PENDING_DATE),
            TX_DATE: new Date(parentData.tD_KIDNEY_TX_DATE),
            TX_OTHER: '',
            DISABLED: true,
            SUBMITTED: false
        });

        //SKIN GRAFTS
        txOrganData.push({
            TX_LABEL: 'Skin Grafts',
            TX_NO: parentData.tD_SKIN_NO_IND == 'Y',
            TX_YES: parentData.tD_SKIN_YES_IND == 'Y',
            TX_PENDING: parentData.tD_SKIN_PENDING_IND == 'Y',
            TX_PENDING_DATE: new Date(parentData.tD_SKIN_PENDING_DATE),
            TX_DATE: new Date(parentData.tD_SKIN_TX_DATE),
            TX_OTHER: '',
            DISABLED: true,
            SUBMITTED: false
        });

        //LIVER
        txOrganData.push({
            TX_LABEL: 'Liver',
            TX_NO: parentData.tD_LIVER_NO_IND == 'Y',
            TX_YES: parentData.tD_LIVER_YES_IND == 'Y',
            TX_PENDING: parentData.tD_LIVER_PENDING_IND == 'Y',
            TX_PENDING_DATE: new Date(parentData.tD_LIVER_PENDING_DATE),
            TX_DATE: new Date(parentData.tD_LIVER_TX_DATE),
            TX_OTHER: '',
            DISABLED: true,
            SUBMITTED: false
        });

        //Lung
        txOrganData.push({
            TX_LABEL: 'Lung',
            TX_NO: parentData.tD_LUNG_NO_IND == 'Y',
            TX_YES: parentData.tD_LUNG_YES_IND == 'Y',
            TX_PENDING: parentData.tD_LUNG_PENDING_IND == 'Y',
            TX_PENDING_DATE: new Date(parentData.tD_LUNG_PENDING_DATE),
            TX_DATE: new Date(parentData.tD_LUNG_TX_DATE),
            TX_OTHER: '',
            DISABLED: true,
            SUBMITTED: false
        });

        //Pancreas
        txOrganData.push({
            TX_LABEL: 'Pancreas',
            TX_NO: parentData.tD_PANCREAS_NO_IND == 'Y',
            TX_YES: parentData.tD_PANCREAS_YES_IND == 'Y',
            TX_PENDING: parentData.tD_PANCREAS_PENDING_IND == 'Y',
            TX_PENDING_DATE: new Date(parentData.tD_PANCREAS_PENDING_DATE),
            TX_DATE: new Date(parentData.tD_PANCREAS_TX_DATE),
            TX_OTHER: '',
            DISABLED: true,
            SUBMITTED: false
        });

        //Corneal Implants
        txOrganData.push({
            TX_LABEL: 'Corneal Implants',
            TX_NO: parentData.tD_CORNEA_NO_IND == 'Y',
            TX_YES: parentData.tD_CORNEA_YES_IND == 'Y',
            TX_PENDING: parentData.tD_CORNEA_PENDING_IND == 'Y',
            TX_PENDING_DATE: new Date(parentData.tD_CORNEA_PENDING_DATE),
            TX_DATE: new Date(parentData.tD_CORNEA_TX_DATE),
            TX_OTHER: '',
            DISABLED: true,
            SUBMITTED: false
        });

        //Other
        txOrganData.push({
            TX_LABEL: 'Other:',
            TX_NO: parentData.tD_OTHER_NO_IND == 'Y',
            TX_YES: parentData.tD_OTHER_YES_IND == 'Y',
            TX_PENDING: parentData.tD_OTHER_PENDING_IND == 'Y',
            TX_PENDING_DATE: new Date(parentData.tD_OTHER_PENDING_DATE),
            TX_DATE: new Date(parentData.tD_OTHER_TX_DATE),
            TX_OTHER: parentData.tD_OTHER_TX_DESC,
            DISABLED: true,
            SUBMITTED: false
        });

        return txOrganData;
    }


}
