import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { PrimaryCaregiver, EmploymentSchool, SkillsTraining, AssessorObservations } from '../models';
@Injectable({
  providedIn: 'root'
})
export class SectionLService {

  constructor(private apiService: ApiService) { }
  GetSectionLInfo(alocID): Observable<any> {
    return this.apiService.get(`sectionL/GetSectionLInfo/${alocID}`);
  }
  postPrimaryCaregiverAssessment(formData): Observable<any> {
    return this.apiService.post('sectionL/postPrimaryCaregiverAssessment', formData);
  }
  postAssessorObservation(formData): Observable<any> {
    return this.apiService.post('sectionL/postAssessorObservation', formData);
  }
  postCaregiverEmploymentandSchool(formData): Observable<any> {
    return this.apiService.post('sectionL/postCaregiverEmploymentandSchool', formData);
  }

  postCaregiverSkillsandTrainingAssessment(formData): Observable<any> {
    return this.apiService.post('sectionL/postCaregiverSkillsandTrainingAssessment', formData);
  }
  
}
