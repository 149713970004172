import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SectionA, CityState, SectionAGet } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SectionAService {

  constructor(private apiService: ApiService) { }

  getSectionA(ACATId): Observable<SectionAGet> {
    return this.apiService.get('sectiona/' + ACATId);   
  }

  PostAssessmentParticipants(formData): Observable<any> {
    return this.apiService.post('sectiona/PostAssessmentParticipants', formData);
  }
  PostHouseholdLanguageSpoken(formData): Observable<any> {
    return this.apiService.post('sectiona/PostHouseholdLanguageSpoken', formData);
  }
  PostInsuranceInformation(formData): Observable<any> {
    return this.apiService.post('sectiona/PostInsuranceInformation', formData);
  }
  PostLivingSituation(formData): Observable<any> {
    return this.apiService.post('sectiona/PostLivingSituation', formData);
  }
  PostVeteranStatus(formData): Observable<any> {
    return this.apiService.post('sectiona/PostVeteranStatus', formData);
  }
  PostAssessmentInformation(formData): Observable<any> {
    return this.apiService.post('sectiona/PostAssessmentInformation', formData);
  }
  getCounties(): Observable<any> {
    return this.apiService.get('sectiona/getCounties');
  }

  saveSection(formData): Observable<any> {
    return this.apiService.put('sectiona/', formData);
  }

  createSection(formData): Observable<any>{
    return this.apiService.post('sectiona/',formData);
  }

  getCityState(zipCode): Observable<CityState> {

    return this.apiService.get('sectiona/GetCityState', zipCode)
      .pipe(map(data => { return data; }));
  }


}
