import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material';
import { Section } from '../shared';


@Component({
  selector: 'app-navi-setup',
  templateUrl: './navi-setup.component.html',
  styleUrls: ['./navi-setup.component.css']
})
export class NaviSetupComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    //console.log(this.stepper);
    //this.stepper.steps.forEach(s => {
    //  console.log(s);
    //  //s.stepControl.disable();
    //  s.editable = false;
    //});

    this.stepper.selectionChange.subscribe(selection => {

      console.log(selection);
      switch (selection.selectedIndex) {
        case 0:
          this.router.navigate(['section-a']);
          break;
        case 1:
          this.router.navigate(['section-b']);
          break;
        case 2:
          this.router.navigate(['section-b1']);
          break;
        case 3:
          this.router.navigate(['section-b2']);
          break;
        case 4:
          this.router.navigate(['section-c']);
          break;
        case 5:
          this.router.navigate(['section-d']);
          break;
        case 6:
          this.router.navigate(['section-e']);
          break;
        case 7:
          this.router.navigate(['summary']);
          break;
        default:
          this.router.navigate(['search']);
          break;

      }
    });

  }

  stepNodes: Section[] = [
    { title: 'Section A', description: 'Individual Information' },
    { title: 'Section B', description: 'Health Information' },
    { title: 'Section B1', description: 'Skilled Nursing & Rehab' },
    { title: 'Section B2', description: 'Assessment of Medical Stability' },
    { title: 'Section C', description: 'ADLs and IADLs' },
    { title: 'Section D', description: 'BCAT® Screen' },
    { title: 'Section E', description: 'Screenings' },
    { title: 'Summary', description: 'ACAT Summary' },
  ];

}
