import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionEService, BcatScreen } from '../../shared';
import Swal from 'sweetalert2';
import { isNumber } from 'util';
@Component({
  selector: 'app-bcat-screen',
  templateUrl: './bcat-screen.component.html',
  styleUrls: ['./bcat-screen.component.css']
})
export class BcatScreenComponent implements OnInit {
  TE_BCAT_SCORE = 0;
  documentMode: string;
  documentReference: { acat: string, sectionid: string } = { acat: '', sectionid: '' };

  newACATID: any;
  bcatscreenForm: FormGroup;
  bcatscreen: BcatScreen = {} as BcatScreen;
  isSubmitting = false;
  formStatus: boolean;
  constructor(private fb: FormBuilder,
    private alertService: AlertsService,
    public dataService: DataService,
    public sectionEService: SectionEService,
    private formBuilder: FormBuilder) {   
  }

  ngOnInit() {

    this.bcatscreenForm = this.formBuilder.group({
      tE_ACAT_ID: [''],
      tE_SECTION_ID: [''],
      tE_ORIENTATION: [''
        , [
          Validators.required,
          Validators.max(6),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_IMMEDIATE_VERBAL_RECALL: [''
        , [
          Validators.required,
          Validators.max(4),
          Validators.min(0),
          Validators.maxLength(1)]
      ],
      tE_VISUAL_RECOGNITION_NAMING: [''
        , [
          Validators.required,
          Validators.max(3),
          Validators.min(0),
          Validators.maxLength(1)]
      ],
      tE_ATTN_LETTER_LIST: [''
        , [
          Validators.required,
          Validators.max(1),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_ATTN_MC_COUNT_BACKWARD: [''
        , [
          Validators.required,
          Validators.max(1),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_ATTN_DAYS_OF_WEEK_BACKWARD: [''
        , [
          Validators.required,
          Validators.max(1),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_ATTN_DIGITS_FORWARD: [''
        , [
          Validators.required,
          Validators.max(2),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_ATTN_DIGITS_BACKWARD: [''
        , [
          Validators.required,
          Validators.max(2),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_ABSTRACTION: [''
        , [
          Validators.required,
          Validators.max(3),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_LANG_REPEAT: [''
        , [
          Validators.required,
          Validators.max(1),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_LANG_FLUENCY: [''
        , [
          Validators.required,
          Validators.max(2),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_EXEC_COGNITIVE_SHIFTING: [''
        , [
          Validators.required,
          Validators.max(2),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_EXEC_ARITHMETIC_REASONING: [''
        , [
          Validators.required,
          Validators.max(1),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_EXEC_JUDGEMENT: [''
        , [
          Validators.required,
          Validators.max(1),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_VISUOSPATIAL_DESIGN: [''
        , [
          Validators.required,
          Validators.max(2),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_VISUOSPATIAL_CLOCK: [''
        , [
          Validators.required,
          Validators.max(2),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_DELAYED_VERBAL_RECALL: [''
        , [
          Validators.required,
          Validators.max(4),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_IMMEDIATE_STORY_RECALL: [''
        , [
          Validators.required,
          Validators.max(2),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_DELAYED_VISUAL_MEMORY: [''
        , [
          Validators.required,
          Validators.max(3),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_DELAYED_STORY_RECALL: [''
        , [
          Validators.required,
          Validators.max(2),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_STORY_RECOGNITION: [''
        , [
          Validators.required,
          Validators.max(5),
          Validators.min(0),
          Validators.maxLength(1)
        ]
      ],
      tE_BCAT_SCORE: ['', [Validators.max(50)]]
    });

    this.bcatscreenForm.valueChanges.subscribe(frmValues => {
      let total = 0;
      Object.keys(frmValues).forEach(fld => {
        if (['tE_BCAT_SCORE', 'tE_ACAT_ID', 'tE_SECTION_ID'].indexOf(fld) == -1) {
          let fld_val = parseInt(frmValues[fld]);
          if (isNumber(fld_val) && !isNaN(fld_val)) {
            total += parseInt(frmValues[fld]);
          }
        }
      });
      this.TE_BCAT_SCORE = total;     

    });
    this.pageLoad();
    
  }
  pageLoad() {

    if (this.dataService.getSelectedDocument() == null) {
      console.error('context document id is null');
      return;
    }
    this.newACATID = this.dataService.getSelectedDocument();
    if (this.newACATID != null) {
      this.sectionEService.GetSectionEInfo(this.newACATID).subscribe(data => {
        if (data != null) {
          if (data.tE_ACAT_ID == null) {
            this.documentMode = 'add';           
          } else {
            this.documentMode = 'update';
          }
        }

        this.bcatscreenForm.patchValue(data);
        this.alertService.success("BCAT Screen Loaded");

      }, err => {
        this.alertService.error(err);
      });
    }
  }

  onChanges() {
    this.bcatscreenForm.get('tE_BCAT_SCORE').valueChanges.subscribe(score => {
      if (score > 49) {
        this.bcatscreenForm.get('tE_BCAT_SCORE').setErrors({ 'incorrect': true });
      }
    });
  }
 
  ClearForm(e) {
    this.onClear();
  }

  submitForm() {

    this.isSubmitting = true;
    this.bcatscreenForm.updateValueAndValidity();  
    this.bcatscreenForm.get('tE_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    this.bcatscreenForm.get('tE_BCAT_SCORE').setValue(this.TE_BCAT_SCORE);
    if (!this.bcatscreenForm.valid) {
      for (let cnt in this.bcatscreenForm.controls) {
        if (this.bcatscreenForm.controls[cnt].invalid) {
          console.log(cnt, this.bcatscreenForm.controls[cnt]);
        }
      }
      this.isSubmitting = false;
      return;
    }

    let bCatFormData = this.bcatscreenForm.value;
    this.sectionEService.PostBCat(bCatFormData).subscribe(response => {
      this.alertService.success('BCAT Screen Saved');
    }, err => {
        console.error('Error Saving BCAT', err);
        this.alertService.error("Error Saving BCAT");
      });

    this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.bcatscreenForm.reset();
      }
    });

  } 
}
