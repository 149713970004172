import { SectionDropdownService } from '../../shared/services/section-dropdown.service';
import { SKNRowType } from './SKNRowType';
import { SKNDynFormFields } from './SNDynForm';
import { SKNControlType } from './SKNControlType';

export class SKNDynFormBuilder {
  strOxgenOther: string;
  stringBowelOther: string;

  constructor(private dropdownservice: SectionDropdownService) { }

  GetDynSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    switch (SKNRow.tdS_SKILL_ID) {
      case 'BOWEL':
        return this.generateBowelSection(SKNRow);
      case 'DIALYSIS':
        return this.generateDialysisSection(SKNRow);
      case 'IVS':
        return this.generateIVSSection(SKNRow);
      case 'TPN':
        return this.generateTPNSection(SKNRow);
      case 'TUBEFEED':
        return this.generateTubeFeedingSection(SKNRow);
      case 'OXYGEN':
        return this.generateOxygenUseSection(SKNRow);
      case 'DEEPSUC':
        return this.generateDeepSuctioningSection(SKNRow);
      case 'VENTILATOR':
        return this.generateVentilatorSection(SKNRow);
      case 'CPAP':
        return this.generateRespiratoryTreatmentCPAPSection(SKNRow);
      case 'BIPAP':
        return this.generateRespiratoryTreatmentBiPAPSection(SKNRow);
      case 'IPPB':
        return this.generateRespiratoryTreatmentIPPBSection(SKNRow);
      case 'CHESTPERC':
        return this.generateRespiratoryTreatmentChestPercussionSection(SKNRow);
      case 'COUGHASST':
        return this.generateRespiratoryTreatmentVestCoughAssistDeviceSection(SKNRow);
      case 'CATHETER':
        return this.generateUrinaryCatheterUrostomyRelatedSkilledTasksSection(SKNRow);
      case 'SEIZURE':
        return this.generateSeizureManagementSection(SKNRow);
      //case 'WOUND':
      //  return this.generateWoundCareSection(SKNRow);
      case 'OTHERSN':
        return this.generateOtherSKNSection(SKNRow);
      case 'OTHERSR':
        return this.generateOtherSRSSection(SKNRow);

      default:
        break;
    }
  }

  private generateBowelSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();

    let skilledTask: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    skilledTask.label = 'Skilled Task';
    skilledTask.type = SKNControlType.Dropdown;
    skilledTask.name = 'Option_1';
    skilledTask.optionsName = 'SKILLED_TASK';
    skilledTask.required = true;
    skilledTask.show = true;
    skilledTask.showDepentancy = null;
    this.stringBowelOther = SKNRow.tdS_OPTION1;
    if (SKNRow.tdS_OPTION1 != null) {
      if (SKNRow.tdS_OPTION1 == 'DIGITALSTI' || SKNRow.tdS_OPTION1 == 'IRRIGATION' || SKNRow.tdS_OPTION1 == 'WAFER') {
        SKNRow.tdS_OPTION1 = SKNRow.tdS_OPTION1;
        this.stringBowelOther = '';
      } else {
        SKNRow.tdS_OPTION1 = 'OTHER';
      }
    }
    skilledTask.value = SKNRow.tdS_OPTION1;
    allFields.push(skilledTask);

    let otherSkilledTask: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    otherSkilledTask.label = 'Other Skilled Task';
    otherSkilledTask.type = SKNControlType.TextAreaField;
    otherSkilledTask.name = 'Option_1_other';
    otherSkilledTask.required = false;
    otherSkilledTask.show = false;
    //otherSkilledTask.value = SKNRow.tdS_OPTION2;
    otherSkilledTask.value = this.stringBowelOther;
    otherSkilledTask.showDepentancy = {
      cntrlName: 'Option_1', cntrlValue: 'OTHER'
    };

    allFields.push(otherSkilledTask);

    return allFields;
  }

  private generateDialysisSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //type of dialysis
    let typeOfDia: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    typeOfDia.label = 'Type of Dialysis';
    typeOfDia.type = SKNControlType.Dropdown;
    typeOfDia.name = 'Option_1';
    typeOfDia.optionsName = 'DIALYSIS_TYPE';
    typeOfDia.required = true;
    typeOfDia.show = true;
    typeOfDia.showDepentancy = null;
    typeOfDia.value = SKNRow.tdS_OPTION1;
    allFields.push(typeOfDia);

    //location
    let location: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    location.label = 'Location';
    location.type = SKNControlType.Dropdown;
    location.name = 'Option_2';
    location.optionsName = 'DIALYSIS_LOCATION';
    location.required = true;
    location.show = true;
    location.showDepentancy = null;
    location.value = SKNRow.tdS_OPTION2;
    allFields.push(location);

    return allFields;
  }

  private generateIVSSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();

    //Type Of IV
    let typeOfIV: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    typeOfIV.label = 'Type of IV';
    typeOfIV.type = SKNControlType.Dropdown;
    typeOfIV.name = 'Option_1';
    typeOfIV.optionsName = 'IV_TYPE';
    typeOfIV.required = true;
    typeOfIV.show = true;
    typeOfIV.showDepentancy = null;
    typeOfIV.value = SKNRow.tdS_OPTION1;
    allFields.push(typeOfIV);

    //location
    let location: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    location.label = 'Location';
    location.type = SKNControlType.Dropdown;
    location.name = 'Option_2';
    location.optionsName = 'IV_LOCATION';
    location.required = true;
    location.show = true;
    location.showDepentancy = null;
    location.value = SKNRow.tdS_OPTION2;
    allFields.push(location);

    return allFields;
  }

  private generateTPNSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();

    //Kcals/day:
    let kcalsday: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    kcalsday.label = 'Kcals/day:';
    kcalsday.type = SKNControlType.TextField;
    kcalsday.name = 'Option_1';
    kcalsday.required = true;
    kcalsday.show = true;
    kcalsday.showDepentancy = null;
    kcalsday.value = SKNRow.tdS_OPTION1;
    allFields.push(kcalsday);

    //mL/kg / day
    let mlkgday: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    mlkgday.label = 'mL/kg/day:';
    mlkgday.type = SKNControlType.TextField;
    mlkgday.name = 'Option_2';
    mlkgday.required = true;
    mlkgday.show = true;
    mlkgday.showDepentancy = null;
    mlkgday.value = SKNRow.tdS_OPTION2;
    allFields.push(mlkgday);

    return allFields;
  }

  private generateTubeFeedingSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();

    //Type of Tube Feeding
    let typeOfTubeFeeding: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    typeOfTubeFeeding.label = 'Type of Tube Feeding';
    typeOfTubeFeeding.type = SKNControlType.Dropdown;
    typeOfTubeFeeding.optionsName = "TUBEFEED_TYPE";
    typeOfTubeFeeding.name = 'Option_1';
    typeOfTubeFeeding.required = true;
    typeOfTubeFeeding.show = true;
    typeOfTubeFeeding.showDepentancy = null;
    typeOfTubeFeeding.value = SKNRow.tdS_OPTION1;
    allFields.push(typeOfTubeFeeding);

    //Type of Tube Feeding Other
    let otherTypeOfTubeFeeding: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    otherTypeOfTubeFeeding.label = 'Other Type of Tube Feeding';
    otherTypeOfTubeFeeding.type = SKNControlType.TextAreaField;
    //otherTypeOfTubeFeeding.optionsName = "TUBEFEED_TYPE";
    otherTypeOfTubeFeeding.name = 'Option_1_other';
    otherTypeOfTubeFeeding.required = true;
    otherTypeOfTubeFeeding.show = false;
    otherTypeOfTubeFeeding.showDepentancy = { cntrlName: 'Option_1', cntrlValue: 'OTHER' };
    otherTypeOfTubeFeeding.value = SKNRow.tdS_OPTION1;
    allFields.push(otherTypeOfTubeFeeding);

    //Type of Tube Feeding
    let duration: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    duration.label = 'Duration';
    duration.type = SKNControlType.Dropdown;
    duration.optionsName = "TUBEFEED_DURATION";
    duration.name = 'Option_2';
    duration.required = true;
    duration.show = true;
    duration.showDepentancy = null;
    duration.value = SKNRow.tdS_OPTION2;
    allFields.push(duration);

    //Method of deliver
    let delivery: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    delivery.label = 'Method of Delivery';
    delivery.type = SKNControlType.Dropdown;
    delivery.optionsName = "TUBEFEED_METHOD";
    delivery.name = 'Option_3';
    delivery.required = true;
    delivery.show = true;
    delivery.showDepentancy = null;
    delivery.value = SKNRow.tdS_OPTION3;
    allFields.push(delivery);



    return allFields;
  }

  private generateOxygenUseSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();

    //Oxygen Use
    let oxygenUse: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    oxygenUse.label = 'Oxygen Use';
    oxygenUse.type = SKNControlType.Dropdown;
    oxygenUse.optionsName = "OXYGEN_USE";
    oxygenUse.name = 'Option_4';
    oxygenUse.required = true;
    oxygenUse.show = true;
   // oxygenUse.showDepentancy = { cntrlName: 'Option_3', cntrlValue: 'SAT_CONT' };
    oxygenUse.showDepentancy = null;    
    oxygenUse.value = SKNRow.tdS_OPTION4;
    allFields.push(oxygenUse);

    //Liters Per Minute (LPN):
    let litersPerMin: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    litersPerMin.label = 'Liters Per Minute (LPN):';
    litersPerMin.type = SKNControlType.TextField;
    //litersPerMin.optionsName = "OXYGEN_SPECIAL";
    litersPerMin.name = 'Option_1';
    litersPerMin.required = true;
    litersPerMin.show = true;
    litersPerMin.showDepentancy = null;
    litersPerMin.value = SKNRow.tdS_OPTION1;
    allFields.push(litersPerMin);

    //Method of Delivery
    let methodOfDelivery: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    methodOfDelivery.label = 'Method of Delivery';
    methodOfDelivery.type = SKNControlType.Dropdown;
    methodOfDelivery.optionsName = "OXYGEN_METHOD";
    methodOfDelivery.name = 'Option_2';
    methodOfDelivery.required = true;
    methodOfDelivery.show = true;
    methodOfDelivery.showDepentancy = null;
    this.strOxgenOther = SKNRow.tdS_OPTION2;
    if (SKNRow.tdS_OPTION2 != null) {
      if (SKNRow.tdS_OPTION2 == 'MASK' || SKNRow.tdS_OPTION2 == 'NASALC') {
        SKNRow.tdS_OPTION2 = SKNRow.tdS_OPTION2;
        this.strOxgenOther = '';
      } else {
        SKNRow.tdS_OPTION2 ='OTHER'
      }
    }
    methodOfDelivery.value = SKNRow.tdS_OPTION2;
    allFields.push(methodOfDelivery);

    //Method of Delivery Other
    let methodOfDeliveryOther: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    methodOfDeliveryOther.label = 'Other Method of Delivery';
    methodOfDeliveryOther.type = SKNControlType.TextAreaField;
    //methodOfDeliveryOther.optionsName = "OXYGEN_METHOD";
    methodOfDeliveryOther.name = 'Option_2_other';
    methodOfDeliveryOther.required = true;
    methodOfDeliveryOther.show = false;
    methodOfDeliveryOther.showDepentancy = {
      cntrlName: 'Option_2', cntrlValue: 'OTHER'
    };
    //methodOfDeliveryOther.value = SKNRow.tdS_OPTION2;
    methodOfDeliveryOther.value = this.strOxgenOther;
    allFields.push(methodOfDeliveryOther);

    //Special Orders
    let specialOrders: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    specialOrders.label = 'Special Orders';
    specialOrders.type = SKNControlType.Dropdown;
    specialOrders.optionsName = "OXYGEN_SPECIAL";
    specialOrders.name = 'Option_3';
    specialOrders.required = true;
    specialOrders.show = true;
    specialOrders.showDepentancy = null;
    specialOrders.value = SKNRow.tdS_OPTION3;
    allFields.push(specialOrders);

    //Oxygen Use
    //let oxygenUse: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    //oxygenUse.label = 'Oxygen Use';
    //oxygenUse.type = SKNControlType.Dropdown;
    //oxygenUse.optionsName = "OXYGEN_USE";
    //oxygenUse.name = 'Option_4';
    //oxygenUse.required = true;
    //oxygenUse.show = false;
    //oxygenUse.showDepentancy = { cntrlName: 'Option_3', cntrlValue: 'SAT_CONT' };
    //oxygenUse.value = SKNRow.tdS_OPTION4;
    //allFields.push(oxygenUse);


    return allFields;
  }

  private generateDeepSuctioningSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();

    //Type of Deep Suctioning
    let typeOfDepSuc: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    typeOfDepSuc.label = 'Type of Deep Suctioning';
    typeOfDepSuc.type = SKNControlType.Dropdown;
    typeOfDepSuc.optionsName = "DEEPSUC_TYPE";
    typeOfDepSuc.name = 'Option_1';
    typeOfDepSuc.required = true;
    typeOfDepSuc.show = true;
    typeOfDepSuc.showDepentancy = null;
    typeOfDepSuc.value = SKNRow.tdS_OPTION1;
    allFields.push(typeOfDepSuc);

    return allFields;
  }

  private generateVentilatorSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();

    //Type of Deep Suctioning
    let duration: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    duration.label = 'Duration';
    duration.type = SKNControlType.Dropdown;
    duration.optionsName = "VENT_DURATION";
    duration.name = 'Option_1';
    duration.required = true;
    duration.show = true;
    duration.showDepentancy = null;
    duration.value = SKNRow.tdS_OPTION1;
    allFields.push(duration);

    return allFields;
  }

  private generateRespiratoryTreatmentCPAPSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //C-PAP Duration:
    let duration: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    duration.label = 'C-PAP Duration:';
    duration.type = SKNControlType.TextAreaField;
    duration.name = 'Option_1';
    duration.required = true;
    duration.show = true;
    duration.showDepentancy = null;
    duration.value = SKNRow.tdS_OPTION1;
    allFields.push(duration);


    return allFields;
  }

  private generateRespiratoryTreatmentBiPAPSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //C-PAP Duration:
    let duration: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    duration.label = 'Bi-PAP Duration:';
    duration.type = SKNControlType.TextAreaField;
    duration.name = 'Option_1';
    duration.required = true;
    duration.show = true;
    duration.showDepentancy = null;
    duration.value = SKNRow.tdS_OPTION1;
    allFields.push(duration);

    return allFields;
  }

  private generateRespiratoryTreatmentIPPBSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //C-PAP Duration:
    let duration: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    duration.label = 'IPPB Duration:';
    duration.type = SKNControlType.TextAreaField;
    duration.name = 'Option_1';
    duration.required = true;
    duration.show = true;
    duration.showDepentancy = null;
    duration.value = SKNRow.tdS_OPTION1;
    allFields.push(duration);

    return allFields;
  }

  private generateRespiratoryTreatmentChestPercussionSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //C-PAP Duration:
    let duration: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    duration.label = 'Chest Percussion Duration:';
    duration.type = SKNControlType.TextAreaField;
    duration.name = 'Option_1';
    duration.required = true;
    duration.show = true;
    duration.showDepentancy = null;
    duration.value = SKNRow.tdS_OPTION1;
    allFields.push(duration);

    return allFields;
  }

  private generateRespiratoryTreatmentVestCoughAssistDeviceSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //C-PAP Duration:
    let duration: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    duration.label = 'Vest/Cough Device Duration:';
    duration.type = SKNControlType.TextAreaField;
    duration.name = 'Option_1';
    duration.required = true;
    duration.show = true;
    duration.showDepentancy = null;
    duration.value = SKNRow.tdS_OPTION1;
    allFields.push(duration);

    return allFields;
  }

  private generateUrinaryCatheterUrostomyRelatedSkilledTasksSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //Type of Catheter
    let typeOfCathe: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    typeOfCathe.label = 'Type of Catheter';
    typeOfCathe.type = SKNControlType.Dropdown;
    typeOfCathe.optionsName = 'CATHETER_TYPE';
    typeOfCathe.name = 'Option_1';
    typeOfCathe.required = true;
    typeOfCathe.show = true;
    typeOfCathe.showDepentancy = null;
    typeOfCathe.value = SKNRow.tdS_OPTION1;
    allFields.push(typeOfCathe);

    //Date of last change (mm/dd/yyyy):
    let dateOfLastChg: SKNDynFormFields = new SKNDynFormFields();
    dateOfLastChg.label = 'Date of last change (mm/dd/yyyy):';
    dateOfLastChg.type = SKNControlType.DateField;
    dateOfLastChg.name = 'Option_2';
    dateOfLastChg.required = true;
    dateOfLastChg.customValidators = ['futureDate'];
    dateOfLastChg.show = false;   
    dateOfLastChg.showDepentancy = {
      cntrlName: 'Option_1', cntrlValue: 'INDWELLING'
    };
    dateOfLastChg.value = SKNRow.tdS_OPTION2;   
    allFields.push(dateOfLastChg);    

    return allFields;
  }

  private generateSeizureManagementSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //Seizure Severity
    let seizSev: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    seizSev.label = 'Seizure Severity';
    seizSev.type = SKNControlType.Dropdown;
    seizSev.optionsName = 'SEIZURE_TYPE';
    seizSev.name = 'Option_1';
    seizSev.required = true;
    seizSev.show = true;
    seizSev.showDepentancy = null;
    seizSev.value = SKNRow.tdS_OPTION1;
    allFields.push(seizSev);

    //Date of last seizure (mm/dd/yyyy):
    let dateOfLastSeiz: SKNDynFormFields = new SKNDynFormFields();
    dateOfLastSeiz.label = 'Date of last seizure (mm/dd/yyyy):';
    dateOfLastSeiz.type = SKNControlType.DateField;
    dateOfLastSeiz.name = 'Option_2';
    dateOfLastSeiz.required = true;
    dateOfLastSeiz.customValidators = ['futureDate'];
    dateOfLastSeiz.show = true;
    dateOfLastSeiz.showDepentancy = null;
    dateOfLastSeiz.value = SKNRow.tdS_OPTION2;
    allFields.push(dateOfLastSeiz);

    return allFields;
  }

  private generateWoundCareSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //Comments
    let comm: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    comm.label = 'Comments:';
    comm.type = SKNControlType.TextAreaField;
    comm.name = 'Option_1';
    comm.required = true;
    comm.show = true;
    comm.showDepentancy = null;
    comm.value = SKNRow.tdS_OPTION1;
    allFields.push(comm);

    return allFields;
  }

  private generateOtherSKNSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //Indicate the service
    let serv: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    serv.label = 'Indicate the service:';
    serv.type = SKNControlType.TextField;
    serv.name = 'Option_1';
    serv.required = true;
    serv.show = true;
    serv.showDepentancy = null;
    serv.value = SKNRow.tdS_OPTION1;
    allFields.push(serv);

    
    return allFields;
  }

  private generateOtherSRSSection(SKNRow: SKNRowType): SKNDynFormFields[] {
    let allFields = new Array<SKNDynFormFields>();
    //Indicate the service
    let serv: SKNDynFormFields = new SKNDynFormFields(this.dropdownservice);
    serv.label = 'Indicate the service:';
    serv.type = SKNControlType.TextField;
    serv.name = 'Option_1';
    serv.required = true;
    serv.show = true;
    serv.showDepentancy = null;
    serv.value = SKNRow.tdS_OPTION1;
    allFields.push(serv);


    return allFields;
  }
}
