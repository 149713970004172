export interface AdaptiveAssistiveEquip {
    tJ_SECTION_ID: number;
    tJ_ACAT_ID: number;
    tJ_CATEGORY: string;
    tJ_DESCRIPTION: string;
    tJ_HAVE_NEED: string;
    tJ_TYPE: string;
    TYPES_SRC: any[];
    TYPES_FLD: string;
    tJ_TYPE_OTHER: string;
    tJ_LEASE_OWN: string;
    tJ_SPECIAL_CONS: string;
    tJ_COMPANY: string;
    tJ_AGE: string;
    tJ_CONDITION: string;
    tJ_FUNDING: string;
    tJ_FUNDING_OTHER: string;
    tJ_COMMENT: string;
}


export class AdpAssEquipFields {
    public static tJ_SECTION_ID = 'tJ_SECTION_ID';
    public static tJ_ACAT_ID = 'tJ_ACAT_ID';
    public static tJ_CATEGORY = 'tJ_CATEGORY';
    public static tJ_DESCRIPTION = 'tJ_DESCRIPTION';
    public static tJ_HAVE_NEED = 'tJ_HAVE_NEED';
    public static tJ_TYPE = 'tJ_TYPE';
    public static tJ_TYPE_OTHER = 'tJ_TYPE_OTHER';
    public static tJ_LEASE_OWN = 'tJ_LEASE_OWN';
    public static tJ_SPECIAL_CONS = 'tJ_SPECIAL_CONS';
    public static tJ_COMPANY = 'tJ_COMPANY';
    public static tJ_AGE = 'tJ_AGE';
    public static tJ_CONDITION = 'tJ_CONDITION';
    public static tJ_FUNDING = 'tJ_FUNDING';
    public static tJ_FUNDING_OTHER = 'tJ_FUNDING_OTHER';
    public static tJ_COMMENT = 'tJ_COMMENT';

}



