import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionLComponent } from './section-l.component';
import { PrimaryCaregiverComponent } from './primary-caregiver/primary-caregiver.component';
import { EmploymentSchoolComponent } from './employment-school/employment-school.component';
import { SkillsTrainingComponent } from './skills-training/skills-training.component';
import { AssessorObservationComponent } from './assessor-observation/assessor-observation.component';
const routes: Routes = [
  {
    path: '',
    component: SectionLComponent,
    children: [
      {
        path: 'assessor-observation',
        component: AssessorObservationComponent
      },
      {
        path: 'employment-school',
        component: EmploymentSchoolComponent
      },
      {
        path: 'primary-caregiver',
        component: PrimaryCaregiverComponent
      },
      {
        path: 'skills-training',
        component: SkillsTrainingComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionLRoutingModule { }
