import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionDComponent } from './section-d.component';
import { PhysicianInfoComponent } from './physician-info/physician-info.component';
import { PharmacyInfoComponent } from './pharmacy-info/pharmacy-info.component';
import { DiagnosesInfoComponent } from './diagnoses-info/diagnoses-info.component';
import { MedicationAdminComponent } from './medication-admin/medication-admin.component';
import { AllergyInfoComponent } from './allergy-info/allergy-info.component';
import { SubstanceUseComponent } from './substance-use/substance-use.component';
import { TransplantInfoComponent } from './transplant-info/transplant-info.component';
import { IncontinenceInfoComponent } from './incontinence-info/incontinence-info.component';
import { SkilledNursingComponent } from './skilled-nursing/skilled-nursing.component';
import { AssessMedicalStabilityComponent } from './assess-medical-stability/assess-medical-stability.component';
import { EmergencyVisitComponent } from './emergency-visit/emergency-visit.component';
import { BackgroundInfoComponent } from './background-info/background-info.component';
import { SecB1UnsaveGuradGuard } from './skilled-nursing/sec-b1-unsave-gurad.guard';

const routes: Routes = [
    {
        path: '',
        component: SectionDComponent,
        children: [
            {
                path: 'allergy-info',
                component: AllergyInfoComponent
            },
            {
                path: 'assess-medical-stability',
                component: AssessMedicalStabilityComponent
            },
            {
                path: 'background-info',
                component: BackgroundInfoComponent
            },
            {
                path: 'diagnoses-info',
                component: DiagnosesInfoComponent
            },
            {
                path: 'emergency-visit',
                component: EmergencyVisitComponent
            },
            {
                path: 'incontinence-info',
                component: IncontinenceInfoComponent
            },
            {
                path: 'medication-admin',
                component: MedicationAdminComponent
            },
            {
                path: 'pharmacy-info',
                component: PharmacyInfoComponent
            },
            {
                path: 'skilled-nursing',
                component: SkilledNursingComponent,
                data: { title: 'skilled-nursing' },
                canDeactivate: [SecB1UnsaveGuradGuard]
            },
            {
                path: 'skilled-rehab',
                component: SkilledNursingComponent,
                data: { title: 'skilled-rehab' },
                canDeactivate: [SecB1UnsaveGuradGuard]
            },
            {
                path: 'substance-use',
                component: SubstanceUseComponent
            },
            {
                path: 'transplant-info',
                component: TransplantInfoComponent
            },
            {
                path: 'physician-info',
                component: PhysicianInfoComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SectionDRoutingModule { }
