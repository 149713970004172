import { FormGroup, ValidationErrors } from '@angular/forms';

export interface HadTransplant {
    TX_YES: boolean;
    TX_NO: boolean;
    TX_PENDING: boolean;
    TX_LABEL: string;
    TX_DATE: Date;
    TX_PENDING_DATE: Date;
    TX_OTHER: string;
    DISABLED: boolean;
    SUBMITTED: boolean;
}

export let HadTransplantFields = {
    TX_YES: 'TX_YES',
    TX_NO: 'TX_NO',
    TX_PENDING: 'TX_PENDING',
    TX_LABEL: 'TX_LABEL',
    TX_DATE: 'TX_DATE',
    TX_PENDING_DATE: 'TX_PENDING_DATE',
    TX_OTHER:'TX_OTHER'
}

export function ValidateHadTranspOptns(frm: FormGroup): ValidationErrors | null {
    let frmdata: HadTransplant = frm.value as HadTransplant;
    if (frmdata.TX_LABEL == 'Other:') {
        if (frmdata.TX_OTHER == null || frmdata.TX_OTHER == '') {
            return null;
        }
    }
    if (!frm.get(HadTransplantFields.TX_YES).value && !frm.get(HadTransplantFields.TX_NO).value && !frm.get(HadTransplantFields.TX_PENDING).value) {
        return { 'noyespending_error': 'please choose an options' };
    } else {
        return null;
    }
    

}
