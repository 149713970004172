import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import Swal from 'sweetalert2';
import { SummaryResponse, TopSummaryFields } from '../../../shared';
import { UserType } from '../../../shared/common/UserTypes';
import { confirmDialogParam } from '../../../shared/components/confirm-dialog/confirm-dialog-common';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { FileUploaderComponent } from '../../../shared/components/document-uploader-module/file-uploader/file-uploader.component';
import { AlertsService, DataService, SectionAService, SummaryService, UserService } from '../../../shared/services';

@Component({
    selector: 'app-topsummary',
    templateUrl: './topsummary.component.html',
    styleUrls: ['./topsummary.component.css']
})
export class TopsummaryComponent implements OnInit {
    topSummaryForm: FormGroup;
  private _summaryData: SummaryResponse;
  showClone: boolean;
  newACATID: any;

    set summaryData(value: SummaryResponse) {
        this._summaryData = value;
        this.loadData();
    }

    @ViewChild(FileUploaderComponent, { static: true })
    fileUpload: FileUploaderComponent;

    constructor(private fb: FormBuilder,
        public dataService: DataService,
        private router: Router,
        private userService: UserService,
        private alertService: AlertsService,
      private summService: SummaryService,
      private sectionAService: SectionAService,
        public withDrawConfirm: MatDialog) { }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        let cntrls = {};
        for (let fld in TopSummaryFields) {
            cntrls[fld] = new FormControl('');
        }
        this.topSummaryForm = new FormGroup(cntrls);
    }


  loadData() {

    if (this._summaryData.status == "INPROCESS") {
      this._summaryData.status = 'IN PROCESS';
    } else if (this._summaryData.status == "PIMSLOADED") {
      this._summaryData.status = 'PIMS LOADED';
    }
    if (this._summaryData.status == "COMPLETE") {
      this.showClone = true;
    } else {
      this.showClone = false;
    }

    if (this._summaryData.clienT_NUMBER == "NOTLOADED") {
      this._summaryData.clienT_NUMBER = 'NOT LOADED';
    }

    if (this._summaryData.consumeR_NAME != null) {
      this._summaryData.consumeR_NAME = this._summaryData.consumeR_NAME.replace(",", ", ");
    }

    if (this._summaryData.submitteR_NAME != null) {
      this._summaryData.submitteR_NAME = this._summaryData.submitteR_NAME.replace(",", ", ");
    }
    if (this._summaryData.assessoR_NAME != null) {
      this._summaryData.assessoR_NAME = this._summaryData.assessoR_NAME.replace(",", ", ");
    }
        this.topSummaryForm.patchValue(this._summaryData);
        //this.topSummaryForm.get(SummaryFields.datE_OF_BIRTH).setValue(new Date(this._summaryData.datE_OF_BIRTH));
        //this.topSummaryForm.get(SummaryFields.submiT_DATE).setValue(new Date(this._summaryData.submiT_DATE));
    }

    onEdit() {
        this.router.navigate(["section-a"]);
    }

    onFileUpload() {
        this.fileUpload.openDialog();
    }

    changeStatus() {
        let user = this.userService.getCurrentUser();
        console.log(user);
        if (user.userAgencyType == UserType.PAA) {

        } else {
            this.alertService.error('No allowed !');
        }
    }

    onWithdraw() {

        let delConfirm: confirmDialogParam = {
            message: ``,
            title: 'Withdraw Document',
            subtitle: `Please confirm whether you want to withdraw the document '${this.dataService.getSelectedDocument()}'?`
        };
        let confirm = this.withDrawConfirm.open(ConfirmDialogComponent, {
            width: '500px',
            disableClose: true,
            data: delConfirm
        });
        confirm.afterClosed().subscribe(value => {
            if (value == 'yes') {
                this.summService.withDraw(this.dataService.getSelectedDocument()).subscribe(resp => {
                    //console.log(resp);
                    this.alertService.success('Document withdrawn successfully');
                    timer(500).subscribe(_ => {
                        this.router.navigate(['search']);
                    })
                   
                }, error => {
                    console.error(error);
                    this.alertService.error('Error withdrawing document');
                });
            }
        }, error => { console.error(error);});

  }
  onClone() {

    let delConfirm: confirmDialogParam = {
      message: ``,
      title: 'Clone Document',
      subtitle: `Please confirm whether you want to clone the document '${this.dataService.getSelectedDocument()}'?`
    };
    let confirm = this.withDrawConfirm.open(ConfirmDialogComponent, {
      width: '500px',
      disableClose: true,
      data: delConfirm
    });
    confirm.afterClosed().subscribe(value => {
      if (value == 'yes') {        
        
        this.summService.GetClone(this.dataService.getSelectedDocument()).subscribe(resp => {
       
          this.newACATID = resp.tO_ACAT_ID;
          if (this.newACATID != null) {
            this.dataService.saveSelectedDocument({
              dB_ID: this.newACATID, name: ''
            });
            this.alertService.clearLoader();
           // this.onConfirm(this.newACATID);
          }
          if (this.newACATID != null) {

            this.sectionAService.getSectionA(this.newACATID).subscribe(response => {
              this.dataService.saveSelectedDocument({
                dB_ID: `${response.tA_ACAT_ID}`,
                name: `${response.tA_LAST_NAME} ,${response.tA_FIRST_NAME}`
              });

              this.router.navigate(['section-a']);
              this.alertService.clearLoader();
            }, err => {
              this.alertService.error(err);
            });
          }
          

        }, error => {
          console.error(error);
            this.alertService.error('Error Cloning Document');
        });
      }
    }, error => { console.error(error); });

  }

  onConfirm(newid: string) {
    var test = newid;
    Swal.fire({     
      text: "The new document ID is "+newid,      
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {        

        if (newid != null) {

          this.sectionAService.getSectionA(newid).subscribe(response => {
            this.dataService.saveSelectedDocument({
              dB_ID: `${response.tA_ACAT_ID}`,
              name: `${response.tA_LAST_NAME} ,${response.tA_FIRST_NAME}`
            });

            this.router.navigate(['section-a']);
            this.alertService.clearLoader();
          }, err => {
            this.alertService.error(err);
          });
        }
      }
    });

  }

}
