import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionAService, AlertsService, DataService, UserService, getTextForDropdownValues, SectionDropdownService} from '../../shared/index';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import * as ALOCValidators from '../../shared/validators/AlocDateValidators';
import { timer } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { SectionA } from '../../shared/models/sectionA.model';
import { User } from '../../shared/models';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-assess-info',
  templateUrl: './assess-info.component.html',
  styleUrls: ['./assess-info.component.css'],
  providers: [DatePipe]
})
export class AssessInfoComponent implements OnInit {
  profileUser: User;
  sectionAFrm: FormGroup;
  sectionAModel: SectionA = {} as SectionA;
  emailValidator = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  ssnValidator = /^(?!111-11-1111|222-22-2222|333-33-3333|444-44-4444|555-55-5555|666-66-6666|777-77-7777|888-88-8888|999-99-9999)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
  isSubmitting = false;
  newACATID: any;
  assessmentReasons: any[];
  assessmentsTypes: any[];
  assessorNames: any[];
  assessmentSources: any[];
  assessorQualifications: any[];
  assessorPlaces: any[];
  counties: any[];
  maritalStatus: any[];
  races: any[];
  referralSource: any[];
  genders = [
    { Text: 'Male', Value: 'M' },
    { Text: 'Female', Value: 'F' }
  ];
  show_TO_REASON_OTHER_DESC = false;
  show_TO_PLACE_DESC = false;
  show_TO_SOURCE_OTHER_DESC = false;
  show_TO_REFERRAL_SOURCE_OTHER = false;
  show_TO_ReferralSource: boolean;
  dropDownSelectText = getTextForDropdownValues;  
  strCounty: string;
  strCity: string;
  strZipcode: string;

  constructor(private fb: FormBuilder,
    private sectionAService: SectionAService,
    private alertsService: AlertsService,
    public dataService: DataService,
    private router: Router,
    private dropdownservice: SectionDropdownService,
    private userService: UserService,
    private datePipe: DatePipe) {
    this.sectionAFrm = this.fb.group({
      tO_ACAT_ID: new FormControl(''),
     // tO_ASSESSOR: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60), Validators.required]),
      tO_ASSESSOR: new FormControl({ value: '', disabled: true }),
      tO_BEGIN_DATE: new FormControl('', [Validators.required, Validators.maxLength(10), ALOCValidators.validateFutureDate]),
      tO_ASSESSOR_FIRST_NAME: new FormControl('', [Validators.pattern("[A-Za-z- ]+"), Validators.maxLength(60), Validators.required]),
      tO_ASSESSOR_LAST_NAME: new FormControl('', [Validators.pattern("[A-Za-z- ]+"), Validators.maxLength(60), Validators.required]),
      tO_REFERRAL_DATE: new FormControl('', [Validators.required, Validators.maxLength(10), ALOCValidators.validateFutureDate]), 
      tO_QUAL: new FormControl('', [Validators.required]),
      tO_QUAL_RN: new FormControl(''),
      tO_QUAL_LSW: new FormControl(''),
      tO_QUAL_LISW: new FormControl(''),
      tO_TYPE: new FormControl('', [Validators.required]),
      tO_REASON_FOR_ASS: new FormControl('', [Validators.required]),
      tO_REASON_AL: new FormControl(''),
      tO_REASON_PP: new FormControl(''),
      tO_REASON_LTCC: new FormControl(''),
      tO_REASON_OHCW: new FormControl(''),
      tO_REASON_OTHER: new FormControl(''),
      tO_REASON_OTHER_DESC: new FormControl('', [Validators.maxLength(60)]),
      tO_PLACE: new FormControl('', [Validators.required]),
      tO_PLACE_COMMENTS: new FormControl('', [Validators.maxLength(60)]),
      tO_SOURCE_OF_ASS: new FormControl('', [Validators.required]),
      tO_SOURCE_INDIVIDUAL: new FormControl(''),
      tO_SOURCE_CAREGIVER: new FormControl(''),
      tO_SOURCE_FAMILY: new FormControl(''),
      tO_SOURCE_GUARDIAN: new FormControl(''),
      tO_SOURCE_PHYSICIAN: new FormControl(''),
      tO_SOURCE_PROVIDER: new FormControl(''),
      tO_SOURCE_MED_RECORD: new FormControl(''),
      tO_SOURCE_OTHER: new FormControl(''),
      tO_SOURCE_OTHER_DESC: new FormControl('', [Validators.maxLength(60)]),   
      tO_REFERRAL_SOURCE: new FormControl(''),
      tO_REFERRAL_SOURCE_OTHER: new FormControl('', [Validators.maxLength(60)]),
      tA_ACAT_ID: new FormControl(''),
      tA_FIRST_NAME: new FormControl('', [Validators.pattern("[A-Za-z- ]+"), Validators.maxLength(60), Validators.required]),
      tA_INIT: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(1)]),
      tA_LAST_NAME: new FormControl('', [Validators.pattern("[A-Za-z- ]+"), Validators.maxLength(60), Validators.required]),
      tA_NICKNAME: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60)]),
      tA_ADDRESS: new FormControl('', [Validators.pattern("[A-Za-z0-9/:)(-@&*#,;.\. \'-]+"), Validators.maxLength(60), Validators.required]),
      tA_CITY: new FormControl('', [Validators.pattern(/^[A-Za-z ]+$/), Validators.maxLength(60), Validators.required]),
      tA_STATE: new FormControl('', [Validators.pattern(/^[A-Za-z]+$/), Validators.maxLength(3), Validators.required]),
      tA_ZIP_CODE: new FormControl('', [Validators.pattern("[0-9]{5}"), Validators.maxLength(5), Validators.required]),
      tA_COUNTY: new FormControl('', Validators.required),
      tA_PHONE_PRIMARY: new FormControl('', [Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
      tA_PHONE_SECONDARY: new FormControl('', [Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
      tA_EMAIL: new FormControl('', [Validators.maxLength(128), Validators.pattern(this.emailValidator)]),
      tA_SEX: new FormControl('', Validators.required),
      tA_DATE_OF_BIRTH: new FormControl('', [Validators.required, Validators.maxLength(10), ALOCValidators.validateFutureDate, ALOCValidators.validateAge]),
      tA_AGE: new FormControl('', [Validators.required]),
      tA_SSN: new FormControl('', [Validators.required, Validators.pattern(this.ssnValidator)]),
     // tA_SSN: new FormControl('',[Validators.required, Validators.pattern("[0-9]{3}-[0-9]{2}-[0-9]{4}")]),
      tA_MARITAL_STATUS: new FormControl('', Validators.required),
      tA_RACE: new FormControl(''),
      tA_RACE_AI_AN: new FormControl(''),
      tA_RACE_ASIAN: new FormControl(''),
      tA_RACE_AA: new FormControl(''),
      tA_RACE_WHITE: new FormControl(''),
      tA_RACE_NH_PI: new FormControl('')     

    });
  }

  ngOnInit() {

    this.newACATID = this.dataService.getSelectedDocument();    

    if (this.newACATID != null) {

      this.sectionAService.getSectionA(this.newACATID).subscribe(response => {
        // this.sectionAModel = response;
        this.strCounty = response.tA_COUNTY;
        this.strCity = response.tA_CITY;
        this.strZipcode = response.tA_ZIP_CODE;
        this.receiveAssessmentInformation(response);
        this.alertsService.success("Assessment Information Loaded");
      }, err => {
        this.alertsService.error(err);
      });
    } else {
      this.loadUserInfo();
      this.strCounty = null;
      this.strCity = null;
      this.strZipcode = null;
    }
   
    this.loadDropdownService();    
    this.configDynValidations();
    this.onChanges()
  }
  loadUserInfo() {
    this.userService.currentUser.subscribe((userData: User) => { this.profileUser = userData; });
    this.sectionAFrm.get('tO_ASSESSOR').setValue(this.profileUser.userAgencyName);
    this.alertsService.clearLoader();
  }
  receiveAssessmentInformation(data) {
    this.sectionAFrm.patchValue(data);
    this.setFormData(data)
  }
  loadDropdownService() {

    this.dropdownservice.GetDropDownValues('ASSESSMENT_TYPE').subscribe((data: any[]) => {
      this.assessmentsTypes = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('ASSESSOR_QUALIFICATIONS').subscribe(data => {
      this.assessorQualifications = data;
      this.alertsService.clearLoader();
    }, error => {

      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('ASSESSMENT_REASON').subscribe(data => {
      this.assessmentReasons = data;
      this.alertsService.clearLoader();
    }, error => {

      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('ASSESSMENT_PLACE').subscribe(data => {
      this.assessorPlaces = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('ASST_SOURCE').subscribe(data => {
      this.assessmentSources = data;
      this.alertsService.clearLoader();
    }, error => {

      this.alertsService.error(error);
      });

    this.sectionAService.getCounties().subscribe(data => {
      this.counties = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    this.dropdownservice.GetDropDownValues('MARITAL_STATUS').subscribe(data => {
      this.maritalStatus = data;
      this.alertsService.clearLoader();
    }, error => {

      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('RACE').subscribe(data => {
      this.races = data;
      this.alertsService.clearLoader();
    }, error => {

      this.alertsService.error(error);
      });

    this.dropdownservice.GetDropDownValues('REF_SOURCE').subscribe(data => {
      this.referralSource = data;
      this.alertsService.clearLoader();
    }, error => {

      this.alertsService.error(error);
    });
  }
  sectionASave() {

    this.sectionAFrm.get('tO_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    this.sectionAFrm.get('tA_ACAT_ID').setValue(this.dataService.getSelectedDocument());
    if (!this.sectionAFrm.valid) {
      for (let cnt in this.sectionAFrm.controls) {
        if (this.sectionAFrm.controls[cnt].invalid) {
          console.log(cnt, this.sectionAFrm.controls[cnt]);
        }
      }
      return;
    }

    this.sectionAFrm.get('tO_QUAL_RN').setValue(null);
    this.sectionAFrm.get('tO_QUAL_LSW').setValue(null);
    this.sectionAFrm.get('tO_QUAL_LISW').setValue(null);
    let assQualificationList: [] = this.sectionAFrm.get('tO_QUAL').value;

    if (Array.isArray(assQualificationList)) {
      assQualificationList.forEach(value => {
        switch (value) {
          case 'RN':
            this.sectionAFrm.get('tO_QUAL_RN').setValue('Y');
            break;
          case 'LSW':
            this.sectionAFrm.get('tO_QUAL_LSW').setValue('Y');
            break;
          case 'LISW':
            this.sectionAFrm.get('tO_QUAL_LISW').setValue('Y');
            break;         
          default:
            break;
        }
      });
    }

    this.sectionAFrm.get('tO_REASON_AL').setValue(null);
    this.sectionAFrm.get('tO_REASON_PP').setValue(null);
    this.sectionAFrm.get('tO_REASON_LTCC').setValue(null);
    this.sectionAFrm.get('tO_REASON_OHCW').setValue(null);
    this.sectionAFrm.get('tO_REASON_OTHER').setValue(null);
    let reasonForAss: [] = this.sectionAFrm.get('tO_REASON_FOR_ASS').value;

    if (Array.isArray(reasonForAss)) {
      reasonForAss.forEach(value => {
        switch (value) {
          case 'ALW':
            this.sectionAFrm.get('tO_REASON_AL').setValue('Y');
            break;
          case 'PPW':
            this.sectionAFrm.get('tO_REASON_PP').setValue('Y');
            break;
          case 'LTCC':
            this.sectionAFrm.get('tO_REASON_LTCC').setValue('Y');
            break;
          case 'OHCW':
            this.sectionAFrm.get('tO_REASON_OHCW').setValue('Y');
            break;
          case 'OTHER':
            this.sectionAFrm.get('tO_REASON_OTHER').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    this.sectionAFrm.get('tO_SOURCE_INDIVIDUAL').setValue(null);
    this.sectionAFrm.get('tO_SOURCE_CAREGIVER').setValue(null);
    this.sectionAFrm.get('tO_SOURCE_FAMILY').setValue(null);
    this.sectionAFrm.get('tO_SOURCE_GUARDIAN').setValue(null);
    this.sectionAFrm.get('tO_SOURCE_PHYSICIAN').setValue(null);
    this.sectionAFrm.get('tO_SOURCE_PROVIDER').setValue(null);
    this.sectionAFrm.get('tO_SOURCE_MED_RECORD').setValue(null);
    this.sectionAFrm.get('tO_SOURCE_OTHER').setValue(null);
    let sourceOfAss: [] = this.sectionAFrm.get('tO_SOURCE_OF_ASS').value;

    if (Array.isArray(sourceOfAss)) {
      sourceOfAss.forEach(value => {
        switch (value) {
          case 'INDIVIDUAL':
            this.sectionAFrm.get('tO_SOURCE_INDIVIDUAL').setValue('Y');
            break;
          case 'CAREGIVER':
            this.sectionAFrm.get('tO_SOURCE_CAREGIVER').setValue('Y');
            break;
          case 'FAMILY':
            this.sectionAFrm.get('tO_SOURCE_FAMILY').setValue('Y');
            break;
          case 'LEGALGUARD':
            this.sectionAFrm.get('tO_SOURCE_GUARDIAN').setValue('Y');
            break;
          case 'PHYSICIAN':
            this.sectionAFrm.get('tO_SOURCE_PHYSICIAN').setValue('Y');
            break;
          case 'PROVIDER':
            this.sectionAFrm.get('tO_SOURCE_PROVIDER').setValue('Y');
            break;
          case 'MEDRECORD':
            this.sectionAFrm.get('tO_SOURCE_MED_RECORD').setValue('Y');
            break;
          case 'OTHER':
            this.sectionAFrm.get('tO_SOURCE_OTHER').setValue('Y');
            break;
          default:
            break;
        }
      });
    }

    this.sectionAFrm.get('tA_RACE_AI_AN').setValue(null);
    this.sectionAFrm.get('tA_RACE_ASIAN').setValue(null);
    this.sectionAFrm.get('tA_RACE_AA').setValue(null);
    this.sectionAFrm.get('tA_RACE_WHITE').setValue(null);
    this.sectionAFrm.get('tA_RACE_NH_PI').setValue(null);
    
    let raceList: [] = this.sectionAFrm.get('tA_RACE').value;

    if (Array.isArray(raceList)) {
      raceList.forEach(value => {
        switch (value) {
          case 'AMINDIAN':
            this.sectionAFrm.get('tA_RACE_AI_AN').setValue('Y');
            break;
          case 'ASIAN':
            this.sectionAFrm.get('tA_RACE_ASIAN').setValue('Y');
            break;
          case 'BLACK':
            this.sectionAFrm.get('tA_RACE_AA').setValue('Y');
            break;
          case 'WHITE':
            this.sectionAFrm.get('tA_RACE_WHITE').setValue('Y');
            break;
          case 'HAWAIIAN':
            this.sectionAFrm.get('tA_RACE_NH_PI').setValue('Y');
            break;          
          default:
            break;
        }
      });
    }

   // let assessmentInfoData = this.sectionAFrm.value;
    let assessmentInfoData = this.sectionAFrm.getRawValue();

    if (assessmentInfoData['tO_BEGIN_DATE'] != null)
      assessmentInfoData['tO_BEGIN_DATE'] = this.datePipe.transform(assessmentInfoData['tO_BEGIN_DATE'], 'MM/dd/yyyy');

    if (assessmentInfoData['tO_REFERRAL_DATE'] != null)
      assessmentInfoData['tO_REFERRAL_DATE'] = this.datePipe.transform(assessmentInfoData['tO_REFERRAL_DATE'], 'MM/dd/yyyy');

    if (assessmentInfoData['tA_DATE_OF_BIRTH'] != null)
      assessmentInfoData['tA_DATE_OF_BIRTH'] = this.datePipe.transform(assessmentInfoData['tA_DATE_OF_BIRTH'], 'MM/dd/yyyy');

    assessmentInfoData['tA_SSN'] = assessmentInfoData['tA_SSN'] != null ? assessmentInfoData['tA_SSN'].replace(/-/g, '') : null;

    if (this.dataService.getSelectedDocument() == null) {
      this.sectionAService.PostAssessmentInformation(assessmentInfoData).subscribe(resp => {
        this.alertsService.success("Assessment Information Saved");
        this.newACATID = resp.details.outputParams.TO_ACAT_ID_OUT;
        if (this.newACATID != null) {
          this.dataService.saveSelectedDocument({
            dB_ID: this.newACATID,name:''
          });
         // this.router.navigate(['assess-info']);
          this.alertsService.clearLoader();
        }
        if (this.newACATID != null) {
         
          this.sectionAService.getSectionA(this.newACATID).subscribe(response => {            
            this.receiveAssessmentInformation(response);
            this.dataService.saveSelectedDocument({
              dB_ID: `${response.tA_ACAT_ID}`,
              name: `${response.tA_LAST_NAME} ,${response.tA_FIRST_NAME}`
            });

            this.router.navigate(['section-a']);
            this.alertsService.clearLoader();
          }, err => {
            this.alertsService.error(err);
          });
        }
      }, error => {
        console.error('Error Saving Assessment Information', error);
        this.alertsService.error("Error Saving Assessment Information");
      });  
    }
    else {
      this.sectionAService.PostAssessmentInformation(assessmentInfoData).subscribe(resp => {
        this.sectionAService.getSectionA(this.dataService.getSelectedDocument()).subscribe(response => {
          this.dataService.saveSelectedDocument({
            dB_ID: `${response.tA_ACAT_ID}`,
            name: `${response.tA_LAST_NAME} ,${response.tA_FIRST_NAME}`
          });
        }, err => {
            this.alertsService.error(err);
          });

        this.alertsService.success("Assessment Information Saved");
      }, error => {
          console.error('Error Saving Assessment Information', error);
          this.alertsService.error("Error Saving Assessment Information");
      });  

    }

  }
  sectionAReset() {
    this.onClear();  

  }
  configDynValidations() {
       
    this.sectionAFrm.get('tO_REASON_FOR_ASS').valueChanges.subscribe(value => {
      if (value.indexOf('OTHER') > -1) {
      //if (value == 'OTHER') {
        this.show_TO_REASON_OTHER_DESC = true;
        this.sectionAFrm.get('tO_REASON_OTHER_DESC').setValidators([Validators.required]);
        this.sectionAFrm.get('tO_REASON_OTHER_DESC').markAsTouched();
      } else {
        this.show_TO_REASON_OTHER_DESC = false;
        this.sectionAFrm.get('tO_REASON_OTHER_DESC').clearValidators();
        this.sectionAFrm.get('tO_REASON_OTHER_DESC').setValue(null);      }
      this.sectionAFrm.get('tO_REASON_OTHER_DESC').updateValueAndValidity();
    });
   

    this.sectionAFrm.get('tO_PLACE').valueChanges.subscribe(valueDR => {
      if (valueDR == 'ASSMNTOTH') {
        this.show_TO_PLACE_DESC = true;
        this.sectionAFrm.get('tO_PLACE_COMMENTS').setValidators([Validators.required]);
        this.sectionAFrm.get('tO_PLACE_COMMENTS').markAsTouched();
      } else {
        this.show_TO_PLACE_DESC = false;
        this.sectionAFrm.get('tO_PLACE_COMMENTS').clearValidators();
        this.sectionAFrm.get('tO_PLACE_COMMENTS').setValue(null);
      }
      this.sectionAFrm.get('tO_PLACE_COMMENTS').updateValueAndValidity();
    });

    this.sectionAFrm.get('tO_SOURCE_OF_ASS').valueChanges.subscribe(value => {
      if (value.indexOf('OTHER') > -1) {
      //if (value == 'OTHER') {
        this.show_TO_SOURCE_OTHER_DESC = true;
        this.sectionAFrm.get('tO_SOURCE_OTHER_DESC').setValidators([Validators.required]);
        this.sectionAFrm.get('tO_SOURCE_OTHER_DESC').markAsTouched();
      } else {
        this.show_TO_SOURCE_OTHER_DESC = false;
        this.sectionAFrm.get('tO_SOURCE_OTHER_DESC').clearValidators();
        this.sectionAFrm.get('tO_SOURCE_OTHER_DESC').setValue(null);
      }
      this.sectionAFrm.get('tO_SOURCE_OTHER_DESC').updateValueAndValidity();
    });

    this.sectionAFrm.get('tO_REFERRAL_SOURCE').valueChanges.subscribe(value => {
      if (value == 'OTHER') {
        this.show_TO_REFERRAL_SOURCE_OTHER = true;
        this.sectionAFrm.get('tO_REFERRAL_SOURCE_OTHER').setValidators([Validators.required]);
        this.sectionAFrm.get('tO_REFERRAL_SOURCE_OTHER').markAsTouched();
      } else {
        this.show_TO_REFERRAL_SOURCE_OTHER = false;
        this.sectionAFrm.get('tO_REFERRAL_SOURCE_OTHER').clearValidators();
        this.sectionAFrm.get('tO_REFERRAL_SOURCE_OTHER').setValue(null);
      }
      this.sectionAFrm.get('tO_REFERRAL_SOURCE_OTHER').updateValueAndValidity();
    });

    this.sectionAFrm.get('tO_TYPE').valueChanges.subscribe(value => {
      if (value == 'INITASSESS') {
        this.show_TO_ReferralSource = true;
        this.sectionAFrm.get('tO_REFERRAL_SOURCE').setValidators([Validators.required]);
        this.sectionAFrm.get('tO_REFERRAL_SOURCE').markAsTouched();
      } else {
        this.show_TO_ReferralSource = false;
        this.sectionAFrm.get('tO_REFERRAL_SOURCE').clearValidators();
        this.sectionAFrm.get('tO_REFERRAL_SOURCE').setValue(null);
      }
      this.sectionAFrm.get('tO_REFERRAL_SOURCE').updateValueAndValidity();
    });
  }
  setFormData(data) {

    if (data['tO_BEGIN_DATE'] != null && data['tO_BEGIN_DATE'] != '0001-01-01T00:00:00') {
      this.sectionAFrm.get('tO_BEGIN_DATE').setValue(new Date(data['tO_BEGIN_DATE']));
    } else {
      this.sectionAFrm.get('tO_BEGIN_DATE').setValue(null);
    }
      

    if (data['tA_DATE_OF_BIRTH'] != null && data['tA_DATE_OF_BIRTH'] != '0001-01-01T00:00:00') {
      this.sectionAFrm.get('tA_DATE_OF_BIRTH').setValue(new Date(data['tA_DATE_OF_BIRTH']));
    } else {
      this.sectionAFrm.get('tA_DATE_OF_BIRTH').setValue(null);
    }
      

    if (data['tO_REFERRAL_DATE'] != null && data['tO_REFERRAL_DATE'] != '0001-01-01T00:00:00') {
      this.sectionAFrm.get('tO_REFERRAL_DATE').setValue(new Date(data['tO_REFERRAL_DATE']));
    } else {
      this.sectionAFrm.get('tO_REFERRAL_DATE').setValue(null);
    }
      

    let ass_qual: string[] = [];
    if (data['tO_QUAL_RN'] == 'Y') {
      ass_qual.push('RN');
    }
    if (data['tO_QUAL_LSW'] == 'Y') {
      ass_qual.push('LSW');
    }
    if (data['tO_QUAL_LISW'] == 'Y') {
      ass_qual.push('LISW');
    }
    this.sectionAFrm.get('tO_QUAL').setValue(ass_qual);


    let reason_for_ass: string[] = [];
    if (data['tO_REASON_AL'] == 'Y') {
      reason_for_ass.push('ALW');
    }
    if (data['tO_REASON_PP'] == 'Y') {
      reason_for_ass.push('PPW');
    }
    if (data['tO_REASON_LTCC'] == 'Y') {
      reason_for_ass.push('LTCC');
    }
    if (data['tO_REASON_OHCW'] == 'Y') {
      reason_for_ass.push('OHCW');
    }
    if (data['tO_REASON_OTHER'] == 'Y') {
      reason_for_ass.push('OTHER');
      this.show_TO_REASON_OTHER_DESC = true;
    }
    else {
      this.show_TO_REASON_OTHER_DESC = false;
      this.sectionAFrm.get('tO_REASON_OTHER_DESC').setValue(null);
    }
    this.sectionAFrm.get('tO_REASON_FOR_ASS').setValue(reason_for_ass);

    let source_of_ass: string[] = [];
    if (data['tO_SOURCE_INDIVIDUAL'] == 'Y') {
      source_of_ass.push('INDIVIDUAL');
    }
    if (data['tO_SOURCE_CAREGIVER'] == 'Y') {
      source_of_ass.push('CAREGIVER');
    }
    if (data['tO_SOURCE_FAMILY'] == 'Y') {
      source_of_ass.push('FAMILY');
    }
    if (data['tO_SOURCE_GUARDIAN'] == 'Y') {
      source_of_ass.push('LEGALGUARD');
    }
    if (data['tO_SOURCE_PHYSICIAN'] == 'Y') {
      source_of_ass.push('PHYSICIAN');
    }
    if (data['tO_SOURCE_PROVIDER'] == 'Y') {
      source_of_ass.push('PROVIDER');
    }
    if (data['tO_SOURCE_MED_RECORD'] == 'Y') {
      source_of_ass.push('MEDRECORD');
    }
    if (data['tO_SOURCE_OTHER'] == 'Y') {
      source_of_ass.push('OTHER');
      this.show_TO_SOURCE_OTHER_DESC = true;
    }
    else {
      this.show_TO_SOURCE_OTHER_DESC = false;
      this.sectionAFrm.get('tO_SOURCE_OTHER_DESC').setValue(null);
    }
    this.sectionAFrm.get('tO_SOURCE_OF_ASS').setValue(source_of_ass);

    let race: string[] = [];
    if (data['tA_RACE_AI_AN'] == 'Y') {
      race.push('AMINDIAN');
    }
    if (data['tA_RACE_ASIAN'] == 'Y') {
      race.push('ASIAN');
    }
    if (data['tA_RACE_AA'] == 'Y') {
      race.push('BLACK');
    }
    if (data['tA_RACE_WHITE'] == 'Y') {
      race.push('WHITE');
    }
    if (data['tA_RACE_NH_PI'] == 'Y') {
      race.push('HAWAIIAN');
    }
   
    this.sectionAFrm.get('tA_RACE').setValue(race);

    //Dropdown - Place of assessment

    if (data['tO_PLACE'] == 'ASSMNTOTH') {
      this.show_TO_PLACE_DESC = true;
    }
    else {
      this.show_TO_PLACE_DESC = false;
    }

    //Dropdown - Referral Source

    if (data['tO_REFERRAL_SOURCE'] == 'OTHER') {
      this.show_TO_REFERRAL_SOURCE_OTHER = true;
    }
    else {
      this.show_TO_REFERRAL_SOURCE_OTHER = false;
    }

  }

  private calculateAge(birthdate: any): number {
    return moment().diff(birthdate, 'years');
  }
  onChanges(): void {

    this.sectionAFrm.get('tA_DATE_OF_BIRTH').valueChanges.subscribe(dob => {
      this.sectionAFrm.patchValue({ tA_AGE: this.calculateAge(dob) });
    });

  
    this.sectionAFrm.get('tA_ZIP_CODE').valueChanges.subscribe(zipcode => {
      if (zipcode != undefined) {
        let city = this.strCity;      
        let county = this.strCounty
        let zipcode1 = this.strZipcode;
        let zip = { ZipCode: this.sectionAFrm.get('tA_ZIP_CODE').value };

        if (zip.ZipCode.length == 5) {
          this.sectionAService.getCityState(zip).subscribe(
            cityState => {
              if (zip.ZipCode == zipcode1) {
                if (cityState.city != city && city != null) {
                  this.sectionAFrm.patchValue({ tA_CITY: city });
                } else {
                  this.sectionAFrm.patchValue({ tA_CITY: cityState.city });
                }

                if (cityState.countY_CODE != county && county != null) {
                  this.sectionAFrm.patchValue({ tA_COUNTY: county });
                } else {
                  this.sectionAFrm.patchValue({ tA_COUNTY: cityState.countY_CODE });
                }
                this.sectionAFrm.patchValue({ tA_STATE: (zipcode.substring(0, 2) == '43' || zipcode.substring(0, 2) == '44' || zipcode.substring(0, 2) == '45') ? 'OH' : '' });

              } else {
                this.sectionAFrm.patchValue({ tA_CITY: cityState.city });
                this.sectionAFrm.patchValue({ tA_COUNTY: cityState.countY_CODE });
                this.sectionAFrm.patchValue({ tA_STATE: (zipcode.substring(0, 2) == '43' || zipcode.substring(0, 2) == '44' || zipcode.substring(0, 2) == '45') ? 'OH' : '' });
               
              }
              this.alertsService.clearLoader();
            }, err => {
              this.alertsService.error(err);
            });
        }
        else {
          this.sectionAFrm.patchValue({ tA_CITY: '' });
          this.sectionAFrm.patchValue({ tA_STATE: '' });
          this.sectionAFrm.patchValue({ tA_COUNTY: '' });
        }
      }
      else {
        this.sectionAFrm.patchValue({ tA_CITY: '' });
        this.sectionAFrm.patchValue({ tA_STATE: '' });
        this.sectionAFrm.patchValue({ tA_COUNTY: '' });
      }
    }, err => {
      this.alertsService.error(err);
    });

  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.show_TO_REASON_OTHER_DESC = false;
        this.show_TO_PLACE_DESC = false;
        this.show_TO_SOURCE_OTHER_DESC = false;
        this.show_TO_REFERRAL_SOURCE_OTHER = false;
        this.sectionAFrm.get('tO_REASON_OTHER_DESC').clearValidators();
        this.sectionAFrm.get('tO_PLACE_COMMENTS').clearValidators();
        this.sectionAFrm.get('tO_SOURCE_OTHER_DESC').clearValidators();
        this.sectionAFrm.get('tO_REFERRAL_SOURCE_OTHER').clearValidators();
        this.sectionAFrm.reset();        
       // this.sectionAFrm.reset({ tO_REASON_FOR_ASS: this.assessmentReasons, tO_SOURCE_OF_ASS: this.assessmentSources });
      
      }
    });

  }
}
