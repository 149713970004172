import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionKService, HomeAssessment, DropdownModel, getTextForDropdownValues } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-assess',
  templateUrl: './home-assess.component.html',
  styleUrls: ['./home-assess.component.css']
})
export class HomeAssessComponent implements OnInit {
    dropDownSelectText = getTextForDropdownValues;
    homeAssessmentForm: FormGroup;
    isSubmitting = false;
    homeAssessment: HomeAssessment = {} as HomeAssessment;
    homeTypes: DropdownModel[];
    homeLevel: DropdownModel[];
    homeOwnership: DropdownModel[];
    homeResStable: DropdownModel[];
    homeSafety: DropdownModel[];
    constructor(private fb: FormBuilder,
        private dropdownSerice: SectionDropdownService,
        private alertService: AlertsService,
        public dataService: DataService,
        public sectionKService: SectionKService) {
        this.homeAssessmentForm = this.fb.group({
            tK_SECTION_ID: new FormControl(''),
            tK_ACAT_ID: new FormControl(''),
            tK_HOME_TYPE: new FormControl(''),
            tK_HOME_TYPE_OTHER: new FormControl({value:'',disabled:true}),
            tK_HOME_LEVEL: new FormControl(''),
            tK_HOME_OWN_LEASE: new FormControl(''),
            tK_HOME_OWNER_CONTACT: new FormControl({ value: '', disabled: true }),
            tK_ENTER_EXIT_STATUS: new FormControl(''),
            tK_ENTER_EXIT_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_KITCHEN_STATUS: new FormControl(''),
            tK_KITCHEN_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_BATHROOM_STATUS: new FormControl(''),
            tK_BATHROOM_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_BEDROOM_STATUS: new FormControl(''),
            tK_BEDROOM_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_LR_STATUS: new FormControl(''),
            tK_LR_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_ELECTRICAL_STATUS: new FormControl(''),
            tK_ELECTRICAL_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_PLUMBING_STATUS: new FormControl(''),
            tK_PLUMBING_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_FLOORS_STATUS: new FormControl(''),
            tK_FLOORS_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_STAIRS_STATUS: new FormControl(''),
            tK_STAIRS_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_CLEANLINESS_STATUS: new FormControl(''),
            tK_CLEANLINESS_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_WINDOWS_STATUS: new FormControl(''),
            tK_WINDOWS_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_HEAT_COOL_STATUS: new FormControl(''),
            tK_HEAT_COOL_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_ANIMALS_IND: new FormControl('', Validators.required),
            tK_ANIMALS_COMMENT: new FormControl({ value: '', disabled: true }),
            tK_STABILITY: new FormControl('', Validators.required),
            tK_STABILITY_COMMENTS: new FormControl(''),
            tK_SAFETY: new FormControl('', Validators.required),
            tK_SAFETY_COMMENTS: new FormControl('')
        });
    }

    ngOnInit() {
        this.sectionKService.getHomeAssessment(this.dataService.getSelectedDocument()).subscribe(response => {
            this.homeAssessmentForm.patchValue(response);
            console.log(response);
          this.alertService.success('Home Assessment Loaded');
        }, err => {
            this.alertService.error(JSON.stringify(err));
        });

        this.dropdownSerice.GetDropDownValues('HOME_TYPE').subscribe(data => {
            this.homeTypes = data;
        }, error => {
            this.alertService.error(error);
        });

        this.dropdownSerice.GetDropDownValues('HOME_LEVEL').subscribe(data => {
            this.homeLevel = data;
        }, error => {
            this.alertService.error(error);
        });

        this.dropdownSerice.GetDropDownValues('HOME_OWNERSHIP').subscribe(data => {
            this.homeOwnership = data;
        }, error => {
            this.alertService.error(error);
        });

        this.dropdownSerice.GetDropDownValues('HOME_RESIDENTIAL_STABILITY').subscribe(data => {
            this.homeResStable = data;
        }, error => {
            this.alertService.error(error);
        });

        this.dropdownSerice.GetDropDownValues('HOME_SAFETY_RATING').subscribe(data => {
            this.homeSafety = data;
        }, error => {
            this.alertService.error(error);
        });

        this.onChange();
  }
    onChange() {
        this.homeAssessmentForm.get('tK_HOME_TYPE').valueChanges.subscribe(ht => {
            if (ht == 'OTHER') {
                this.homeAssessmentForm.get('tK_HOME_TYPE_OTHER').enable();
                this.homeAssessmentForm.get('tK_HOME_TYPE_OTHER').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_HOME_TYPE_OTHER'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_HOME_TYPE_OTHER').setValue('');
                this.homeAssessmentForm.get('tK_HOME_TYPE_OTHER').disable();
            }
        });

        this.homeAssessmentForm.get('tK_HOME_OWN_LEASE').valueChanges.subscribe(ho => {
            if (ho == 'LEASE') {
                this.homeAssessmentForm.get('tK_HOME_OWNER_CONTACT').enable();
                this.homeAssessmentForm.get('tK_HOME_OWNER_CONTACT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_HOME_OWNER_CONTACT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_HOME_OWNER_CONTACT').setValue('');
                this.homeAssessmentForm.get('tK_HOME_OWNER_CONTACT').disable();
            }
        });


        this.homeAssessmentForm.get('tK_ENTER_EXIT_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_ENTER_EXIT_COMMENT').enable();
                this.homeAssessmentForm.get('tK_ENTER_EXIT_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_ENTER_EXIT_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_ENTER_EXIT_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_ENTER_EXIT_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_KITCHEN_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_KITCHEN_COMMENT').enable();
                this.homeAssessmentForm.get('tK_KITCHEN_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_KITCHEN_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_KITCHEN_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_KITCHEN_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_BATHROOM_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_BATHROOM_COMMENT').enable();
                this.homeAssessmentForm.get('tK_BATHROOM_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_BATHROOM_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_BATHROOM_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_BATHROOM_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_BEDROOM_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_BEDROOM_COMMENT').enable();
                this.homeAssessmentForm.get('tK_BEDROOM_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_BEDROOM_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_BEDROOM_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_BEDROOM_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_LR_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_LR_COMMENT').enable();
                this.homeAssessmentForm.get('tK_LR_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_LR_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_LR_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_LR_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_ELECTRICAL_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_ELECTRICAL_COMMENT').enable();
                this.homeAssessmentForm.get('tK_ELECTRICAL_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_ELECTRICAL_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_ELECTRICAL_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_ELECTRICAL_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_PLUMBING_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_PLUMBING_COMMENT').enable();
                this.homeAssessmentForm.get('tK_PLUMBING_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_PLUMBING_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_PLUMBING_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_PLUMBING_COMMENT').disable();
            }
        });


        this.homeAssessmentForm.get('tK_FLOORS_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_FLOORS_COMMENT').enable();
                this.homeAssessmentForm.get('tK_FLOORS_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_FLOORS_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_FLOORS_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_FLOORS_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_STAIRS_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_STAIRS_COMMENT').enable();
                this.homeAssessmentForm.get('tK_STAIRS_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_STAIRS_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_STAIRS_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_STAIRS_COMMENT').disable();
            }
        });


        this.homeAssessmentForm.get('tK_CLEANLINESS_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_CLEANLINESS_COMMENT').enable();
                this.homeAssessmentForm.get('tK_CLEANLINESS_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_CLEANLINESS_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_CLEANLINESS_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_CLEANLINESS_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_WINDOWS_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_WINDOWS_COMMENT').enable();
                this.homeAssessmentForm.get('tK_WINDOWS_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_WINDOWS_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_WINDOWS_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_WINDOWS_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_HEAT_COOL_STATUS').valueChanges.subscribe(ee => {
            if (ee == 'ACCESSISSU' || ee == 'REPSAFISSU') {
                this.homeAssessmentForm.get('tK_HEAT_COOL_COMMENT').enable();
                this.homeAssessmentForm.get('tK_HEAT_COOL_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_HEAT_COOL_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_HEAT_COOL_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_HEAT_COOL_COMMENT').disable();
            }
        });

        this.homeAssessmentForm.get('tK_ANIMALS_IND').valueChanges.subscribe(ani => {
            if (ani == 'Y') {
                this.homeAssessmentForm.get('tK_ANIMALS_COMMENT').enable();
                this.homeAssessmentForm.get('tK_ANIMALS_COMMENT').setValidators(Validators.required);
                this.homeAssessmentForm.controls['tK_ANIMALS_COMMENT'].markAsTouched();
            } else {
                this.homeAssessmentForm.get('tK_ANIMALS_COMMENT').setValue('');
                this.homeAssessmentForm.get('tK_ANIMALS_COMMENT').disable();
            }
        });
    }
    ClearForm() {
     // this.homeAssessmentForm.reset();
      this.onClear();
    }
    updatEmergencyNumber(values: Object) {
        Object.assign(this.homeAssessment, values);
        this.homeAssessment.tK_ACAT_ID = this.dataService.getSelectedDocument();
    }

    submitForm() {

        this.isSubmitting = true;
        this.updatEmergencyNumber(this.homeAssessmentForm.value);
        console.log(this.homeAssessment);
        this.sectionKService.postHomeAssessment(this.homeAssessment).subscribe(response => {
          this.alertService.success('Home Assessment Saved');
        }, err => {
            this.alertService.error(JSON.stringify(err));
        });
        this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {     
        this.homeAssessmentForm.reset();


      }
    });

  }
}
