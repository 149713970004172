import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, DataService, SectionDropdownService, SectionLService, SkillsTraining, DropdownModel } from '../../shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-skills-training',
  templateUrl: './skills-training.component.html',
  styleUrls: ['./skills-training.component.css']
})
export class SkillsTrainingComponent implements OnInit {
    formStatus: boolean;
    skillsTrainingForm: FormGroup;
    isSubmitting = false;
    skillsTraining: SkillsTraining = {} as SkillsTraining;

    constructor(private fb: FormBuilder,
        private dropdownSerice: SectionDropdownService,
        private alertService: AlertsService,
        public dataService: DataService,
        public sectionLService: SectionLService) {
        this.skillsTrainingForm = this.fb.group({            
            tL_ACAT_ID: new FormControl(''),
            tL_INFO_YOURSELF_IND: new FormControl(''),
            tL_COUNSELING_IND: new FormControl(''),
            tL_LEGAL_IND: new FormControl(''),
            tL_IN_HOME_IND: new FormControl(''),
            tL_FACILITY_IND: new FormControl(''),
            tL_COORDINATING_IND: new FormControl(''),
            tL_ONLINE_IND: new FormControl(''),
            tL_LTC_OPTIONS_IND: new FormControl(''),
            tL_RECIPIENT_COND_IND: new FormControl(''),
            tL_RESPITE_IND: new FormControl(''),
            tL_HOW_TO_IND: new FormControl(''),
            tL_SUPPORT_GROUPS_IND: new FormControl(''),
            tL_SKILLS_IND: new FormControl(''),
            tL_SIMILAR_IND: new FormControl(''),
            tL_HELP_IND: new FormControl(''),
            tL_DOCTORS_IND: new FormControl(''),
            tL_APPTS_IND: new FormControl(''),
            tL_MODS_EQUIP_IND: new FormControl(''),
            tL_NEEDED_INFO: new FormControl(''),
            tL_RECEIVED_SUPPORT_IND: new FormControl(''),
            tL_TRAINING_COMMENTS: new FormControl('')
        });
    }

  ngOnInit() {

    if (this.dataService.getSelectedDocument() != null) {
      this.sectionLService.GetSectionLInfo(this.dataService.getSelectedDocument()).subscribe(response => {
        this.receiveCaregiverSkillsandTraining(response);        
        this.alertService.success("Caregiver Skills and Training Assessment Loaded");
      }, err => {
        this.alertService.error(err);
      });
    }

  }
    ClearForm(e) {
      this.onClear();
    }   
  receiveCaregiverSkillsandTraining(data) {
    this.skillsTrainingForm.patchValue(data);
    this.setData(data);
  }
  setData(data) {
    if (data['tL_INFO_YOURSELF_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_INFO_YOURSELF_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_INFO_YOURSELF_IND').setValue(false);
    }

    if (data['tL_COUNSELING_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_COUNSELING_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_COUNSELING_IND').setValue(false);
    }

    if (data['tL_LEGAL_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_LEGAL_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_LEGAL_IND').setValue(false);
    }

    if (data['tL_IN_HOME_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_IN_HOME_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_IN_HOME_IND').setValue(false);
    }

    if (data['tL_FACILITY_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_FACILITY_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_FACILITY_IND').setValue(false);
    }

    if (data['tL_COORDINATING_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_COORDINATING_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_COORDINATING_IND').setValue(false);
    }

    if (data['tL_ONLINE_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_ONLINE_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_ONLINE_IND').setValue(false);
    }

    if (data['tL_LTC_OPTIONS_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_LTC_OPTIONS_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_LTC_OPTIONS_IND').setValue(false);
    }

    if (data['tL_RECIPIENT_COND_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_RECIPIENT_COND_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_RECIPIENT_COND_IND').setValue(false);
    }

    if (data['tL_RESPITE_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_RESPITE_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_RESPITE_IND').setValue(false);
    }

    if (data['tL_HOW_TO_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_HOW_TO_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_HOW_TO_IND').setValue(false);
    }

    if (data['tL_SUPPORT_GROUPS_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_SUPPORT_GROUPS_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_SUPPORT_GROUPS_IND').setValue(false);
    }
    if (data['tL_SKILLS_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_SKILLS_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_SKILLS_IND').setValue(false);
    }
    if (data['tL_SIMILAR_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_SIMILAR_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_SIMILAR_IND').setValue(false);
    }
    if (data['tL_HELP_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_HELP_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_HELP_IND').setValue(false);
    }
    if (data['tL_DOCTORS_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_DOCTORS_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_DOCTORS_IND').setValue(false);
    }
    if (data['tL_APPTS_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_APPTS_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_APPTS_IND').setValue(false);
    }
    if (data['tL_MODS_EQUIP_IND'] == 'T') {
      this.skillsTrainingForm.get('tL_MODS_EQUIP_IND').setValue(true);
    }
    else {
      this.skillsTrainingForm.get('tL_MODS_EQUIP_IND').setValue(false);
    }
  }
    submitForm() {
      this.isSubmitting = true;
      this.skillsTrainingForm.get('tL_ACAT_ID').setValue('');
      for (const field in this.skillsTrainingForm.controls) {

        if (this.skillsTrainingForm.controls[field].value == null || this.skillsTrainingForm.controls[field].value == '') {
          this.formStatus = false;
        } else {
          this.formStatus = true;
          break;
        }
      }

      if (this.formStatus == false) {
        this.warningAlert();
        this.isSubmitting = false;
        return;
      }
      this.skillsTrainingForm.get('tL_ACAT_ID').setValue(this.dataService.getSelectedDocument());
      console.log(this.skillsTrainingForm);
      this.sectionLService.postCaregiverSkillsandTrainingAssessment(this.skillsTrainingForm.value).subscribe(response => {
        this.alertService.success('Caregiver Skills and Training Assessment Saved');
      }, err => {
          console.error('Error Saving Caregiver Skills and Training Assessment', err);
          this.alertService.error("Error Saving Caregiver Skills and Training Assessment");
        });
        
        this.isSubmitting = false;
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.skillsTrainingForm.reset();
      }
    });

  }
  warningAlert() {
    Swal.fire(
      '',
      'Please add atleast one caregiver skills and training assessment information',
      'warning'
    )
  }
}
