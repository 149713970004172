import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { NaviSetupComponent } from './navi-setup.component';
import { NaviSetupRoutingModule } from './navi-setup-routing.module';
import { PageOneComponent } from './page-one/page-one.component';
import { PageTwoComponent } from './page-two/page-two.component';
import { PageThreeComponent } from './page-three/page-three.component';

@NgModule({
  declarations: [NaviSetupComponent, PageOneComponent, PageTwoComponent, PageThreeComponent],
  imports: [
    CommonModule,
    NaviSetupRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class NaviSetupModule { }
