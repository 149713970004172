import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import * as $ from 'jquery';
import { UserService, LoaderService, DataService, AuthService } from '../app/shared/services';
import { timer } from 'rxjs/observable/timer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private loaderService: LoaderService,
    private dataService: DataService,
    private authService: AuthService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationCancel && event.url == '/') {
        this.userService.purgeAuth(); this.dataService.clearToken();
        this.loaderService.hideLoader();
      }
    });
    const source = timer(1000, 60000);
    const subscribe = source.subscribe(val => {
      //console.log(val);
      //console.log(sessionStorage.getItem('ACAT'));
      if (sessionStorage.getItem('ACAT')) {
       // console.log(sessionStorage.getItem('ACAT'));
        this.authService.showAuthRefresh();
      } 
    });
  
  }

  title = 'ACAT';
  showMatStepper: boolean = false;
  showConsumerInfo: boolean = true;
  ngOnInit() {
    this.userService.populate();   
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/section-a' ||
          event.url == '/section-b' ||
          event.url == '/section-b1' ||
          event.url == '/section-b2' ||
          event.url == '/section-c' ||
          event.url == '/section-d' ||
          event.url == '/section-e' ||
          event.url == '/summary' ||
          event.url == '/section-a/assess-info' ||
          event.url == '/section-a/assess-participants' ||
          event.url == '/section-a/language-spoken' ||
          event.url == '/section-a/insurance-info' ||
          event.url == '/section-a/veteran-status' ||
          event.url == '/section-a/living-situation' ||
          event.url == '/section-b/individual-relation' ||
          event.url == '/section-c/legal-concern' ||
          event.url == '/section-d/allergy-info' ||
          event.url == '/section-d/assess-medical-stability' ||
          event.url == '/section-d/background-info' ||
          event.url == '/section-d/diagnoses-info' ||
          event.url == '/section-d/emergency-visit' ||
          event.url == '/section-d/incontinence-info' ||
          event.url == '/section-d/medication-admin' ||
          event.url == '/section-d/pharmacy-info' ||
          event.url == '/section-d/skilled-nursing' ||
          event.url == '/section-d/skilled-rehab' ||
          event.url == '/section-d/substance-use' ||
          event.url == '/section-d/transplant-info' ||
          event.url == '/section-d/physician-info' ||
          event.url == '/section-e/bcat-screen' ||
          event.url == '/section-e/communication' ||
          event.url == '/section-f/adls-iadls' ||
          event.url == '/section-g/ac-ok' ||
          event.url == '/section-g/dental-screen' ||
          event.url == '/section-g/developmental-disability' ||
          event.url == '/section-g/nutritional-screen' ||
          event.url == '/section-h/school-work' ||
          event.url == '/section-i/supports' ||
          event.url == '/section-j/adaptive-assistive-equip' ||
          event.url == '/section-k/emergency-number' ||
          event.url == '/section-k/home-assess' ||
          event.url == '/section-k/home-safety' ||
          event.url == '/section-k/med-safety' ||
          event.url == '/section-l/assessor-observation' ||
          event.url == '/section-l/employment-school' ||
          event.url == '/section-l/primary-caregiver' ||
          event.url == '/section-l/skills-training') {
          if (this.dataService.getSelectedDocument()) {
            this.showMatStepper = true;
            this.showConsumerInfo = true;
          } else {
            this.showMatStepper = false;
            this.showConsumerInfo = false;
          }
        } else {
          this.showMatStepper = false;
          this.showConsumerInfo = false;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {

        if (event instanceof NavigationStart) {
          this.loaderService.showLoader();
        } else {
          this.loaderService.hideLoader();
        }

      });
  }
}
