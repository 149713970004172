import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-section-k',
  templateUrl: './section-k.component.html',
  styleUrls: ['./section-k.component.css']
})
export class SectionKComponent implements OnInit {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/section-k') {
          router.navigate(['/section-k/home-assess']);
        }
      }
    });
  }
  ngOnInit() {
  }

}
