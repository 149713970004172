import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-control',
  templateUrl: './equipment-control.component.html',
  styleUrls: ['./equipment-control.component.css']
})
export class EquipmentControlComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
