import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { SectionDService, DataService, AlertsService } from '../../shared';
import { SKNRowType } from './../common/SKNRowType';
import { SKNDBMergeParam } from '../common/SKNDBMergeParam';
import { interval, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SKNFormMode } from '../common/skilled-nursing-common';
import { KeyedCollection } from '../../shared/common/Dictionary';
import { error } from '@angular/compiler/src/util';

@Component({
    selector: 'app-skilled-nursing',
    templateUrl: './skilled-nursing.component.html',
    styleUrls: ['./skilled-nursing.component.css']
})
export class SkilledNursingComponent implements OnInit, OnDestroy {


    constructor(private sectionDService: SectionDService,
        private dataService: DataService,
        private alerts: AlertsService,
        private route: ActivatedRoute) { }
    documentID: string;
    documntName: string;

    skilledNursingSections: SKNRowType[];

    sectionDataUpdates = new KeyedCollection<SKNDBMergeParam>();
    sectionUpdateTimer: Subscription;
    sectionUpdateInProgress: boolean = false;
    waitForUserUpdate = false;
    formMode: SKNFormMode;

    ngOnInit() {

        if (this.route.snapshot.data.title == 'skilled-nursing') {
            this.formMode = SKNFormMode.SkilledNursing;
        } else {
            this.formMode = SKNFormMode.SkilledRehab;
        }

        this.documentID = this.dataService.getSelectedDocument();
        this.documntName = this.dataService.getSelectedDocumentName();

        this.loadData();

        this.autoSave();

    }

    ngOnDestroy(): void {
        this.sectionUpdateTimer.unsubscribe();
        this.sectionUpdateTimer = null;
    }

    loadData() {
        //console.log("this.dataService.getSectionId()  ", this.dataService.getSectionId());
        //if (this.dataService.getSectionId() == "0" || this.dataService.getSectionId() == "" || this.dataService.getSectionId() == null) {
        //    this.alerts.error("Skilled Nursing Section not yet created !");
        //    return;
        //}
        this.sectionDService.getSkilledNursing(this.dataService.getSelectedDocument(), 0 , this.formMode).subscribe((resp: SKNRowType[]) => {
            //console.log(' skilled nursing ', resp);
            this.skilledNursingSections = resp;
            this.alerts.clearLoader();

            if (resp.length == 0) {
                this.alerts.error("No data found.");
            }

        }, error => {
            this.alerts.clearLoader();
            console.error(error);
        });
    }

    autoSave() {
        //auto save <<
        if (this.sectionUpdateTimer == null) {

            this.sectionUpdateTimer = interval(2000).subscribe(() => {

                if (this.sectionUpdateInProgress) return;
                if (this.waitForUserUpdate) {
                    this.waitForUserUpdate = false;
                    return;
                }

                for (let sec of this.sectionDataUpdates.Keys()) {
                    this.sectionUpdateInProgress = true;
                    this.sectionDService.saveSklledNursing(this.sectionDataUpdates.Item(sec)).subscribe((resp) => {
                        this.sectionUpdateInProgress = false;
                        this.alerts.success(`${this.sectionDataUpdates.Item(sec).TDS_SKILL_ID} Saved`);
                        this.sectionDataUpdates.Remove(sec);
                    }, (error) => {
                        console.error('error saving skilled nursing update ', error);
                        //this.sectionUpdateInProgress = false;
                    });
                }

            });

        }
        //>>
    }

    onSectionUpdate(sectionData: SKNDBMergeParam) {
        //console.log(sectionData);
        if (sectionData == null || sectionData.TDS_ACAT_ID == null || sectionData.TDS_SKILL_ID == null) {
            //throw new Error('invalid section ! \n no acat id found or section data null');
            return;
        }
        this.waitForUserUpdate = true;
        this.sectionDataUpdates.Add(sectionData.TDS_SKILL_ID, sectionData);
        //console.log(' this.sectionDataUpdates cnt ', this.sectionDataUpdates.Count())
        //this.sectionchanges[sectionData.TDS_SKILL_ID] = sectionData;
        this.sectionUpdateInProgress = false;
    }

    getSkillLabel = (skillID: string): string => {
        let row = this.skilledNursingSections.filter(row => row.tdS_SKILL_ID == skillID);
        if (row != null && row.length > 0) {
            return row[0].skilL_ID;
        }
        return "";
    }

    @HostListener('window:beforeunload')
    canNavigateAway(): boolean {
        return this.sectionDataUpdates.Count() <= 0;
    }

}
