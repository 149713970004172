import { SKNControlType } from './SKNControlType';

export class DynFormField {    
    label: string;
    type: SKNControlType;
    name: string;
    value: any;
    required: boolean;
    show: boolean;

    config(label: string, type: SKNControlType, name: string, value: any, required?: boolean, show?: boolean): DynFormField {
        this.label = label;
        this.type = type;
        this.name = name;
        this.value = value;
        if (required)
            this.required = required;
        else
            this.required = false;
        if (show)
            this.show = show;
        else
            this.show = true;
        return this;
    }
}
