export class TransplantInfoFields {
    public static tD_SECTION_ID = 'tD_SECTION_ID';
    public static tD_ACAT_ID = 'tD_ACAT_ID';
    public static tD_TX_IND = 'tD_TX_IND';
    public static tD_BONE_NO_IND = 'tD_BONE_NO_IND';
    public static tD_BONE_YES_IND = 'tD_BONE_YES_IND';
    public static tD_BONE_PENDING_IND = 'tD_BONE_PENDING_IND';
    public static tD_BONE_TX_DATE = 'tD_BONE_TX_DATE';
    public static tD_BONE_PENDING_DATE = 'tD_BONE_PENDING_DATE';
    public static tD_HEART_NO_IND = 'tD_HEART_NO_IND';
    public static tD_HEART_YES_IND = 'tD_HEART_YES_IND';
    public static tD_HEART_PENDING_IND = 'tD_HEART_PENDING_IND';
    public static tD_HEART_TX_DATE = 'tD_HEART_TX_DATE';
    public static tD_HEART_PENDING_DATE = 'tD_HEART_PENDING_DATE';
    public static tD_INTESTINE_NO_IND = 'tD_INTESTINE_NO_IND';
    public static tD_INTESTINE_YES_IND = 'tD_INTESTINE_YES_IND';
    public static tD_INTESTINE_PENDING_IND = 'tD_INTESTINE_PENDING_IND';
    public static tD_INTESTINE_TX_DATE = 'tD_INTESTINE_TX_DATE';
    public static tD_INTESTINE_PENDING_DATE = 'tD_INTESTINE_PENDING_DATE';
    public static tD_KIDNEY_NO_IND = 'tD_KIDNEY_NO_IND';
    public static tD_KIDNEY_YES_IND = 'tD_KIDNEY_YES_IND';
    public static tD_KIDNEY_PENDING_IND = 'tD_KIDNEY_PENDING_IND';
    public static tD_KIDNEY_TX_DATE = 'tD_KIDNEY_TX_DATE';
    public static tD_KIDNEY_PENDING_DATE = 'tD_KIDNEY_PENDING_DATE';
    public static tD_SKIN_NO_IND = 'tD_SKIN_NO_IND';
    public static tD_SKIN_YES_IND = 'tD_SKIN_YES_IND';
    public static tD_SKIN_PENDING_IND = 'tD_SKIN_PENDING_IND';
    public static tD_SKIN_TX_DATE = 'tD_SKIN_TX_DATE';
    public static tD_SKIN_PENDING_DATE = 'tD_SKIN_PENDING_DATE';
    public static tD_LIVER_NO_IND = 'tD_LIVER_NO_IND';
    public static tD_LIVER_YES_IND = 'tD_LIVER_YES_IND';
    public static tD_LIVER_PENDING_IND = 'tD_LIVER_PENDING_IND';
    public static tD_LIVER_TX_DATE = 'tD_LIVER_TX_DATE';
    public static tD_LIVER_PENDING_DATE = 'tD_LIVER_PENDING_DATE';
    public static tD_LUNG_NO_IND = 'tD_LUNG_NO_IND';
    public static tD_LUNG_YES_IND = 'tD_LUNG_YES_IND';
    public static tD_LUNG_PENDING_IND = 'tD_LUNG_PENDING_IND';
    public static tD_LUNG_TX_DATE = 'tD_LUNG_TX_DATE';
    public static tD_LUNG_PENDING_DATE = 'tD_LUNG_PENDING_DATE';
    public static tD_PANCREAS_NO_IND = 'tD_PANCREAS_NO_IND';
    public static tD_PANCREAS_YES_IND = 'tD_PANCREAS_YES_IND';
    public static tD_PANCREAS_PENDING_IND = 'tD_PANCREAS_PENDING_IND';
    public static tD_PANCREAS_TX_DATE = 'tD_PANCREAS_TX_DATE';
    public static tD_PANCREAS_PENDING_DATE = 'tD_PANCREAS_PENDING_DATE';
    public static tD_CORNEA_NO_IND = 'tD_CORNEA_NO_IND';
    public static tD_CORNEA_YES_IND = 'tD_CORNEA_YES_IND';
    public static tD_CORNEA_PENDING_IND = 'tD_CORNEA_PENDING_IND';
    public static tD_CORNEA_TX_DATE = 'tD_CORNEA_TX_DATE';
    public static tD_CORNEA_PENDING_DATE = 'tD_CORNEA_PENDING_DATE';
    public static tD_OTHER_NO_IND = 'tD_OTHER_NO_IND';
    public static tD_OTHER_YES_IND = 'tD_OTHER_YES_IND';
    public static tD_OTHER_PENDING_IND = 'tD_OTHER_PENDING_IND';
    public static tD_OTHER_TX_DATE = 'tD_OTHER_TX_DATE';
    public static tD_OTHER_PENDING_DATE = 'tD_OTHER_PENDING_DATE';
    public static tD_OTHER_TX_DESC = 'tD_OTHER_TX_DESC'; 

}
