
export class AllergyInfoFields {
    public static tD_SECTION_ID = 'tD_SECTION_ID';
    public static tD_ACAT_ID = 'tD_ACAT_ID';
    public static tD_ALLERGY_IND = 'tD_ALLERGY_IND';
    public static tD_ALLERGY_MED_1 = 'tD_ALLERGY_MED_1';
    public static tD_ALLERGY_MED_REACTION_1 = 'tD_ALLERGY_MED_REACTION_1';
    public static tD_ALLERGY_MED_2 = 'tD_ALLERGY_MED_2';
    public static tD_ALLERGY_MED_REACTION_2 = 'tD_ALLERGY_MED_REACTION_2';
    public static tD_ALLERGY_MED_3 = 'tD_ALLERGY_MED_3';
    public static tD_ALLERGY_MED_REACTION_3 = 'tD_ALLERGY_MED_REACTION_3';
    public static tD_ALLERGY_MED_4 = 'tD_ALLERGY_MED_4';
    public static tD_ALLERGY_MED_REACTION_4 = 'tD_ALLERGY_MED_REACTION_4';
    public static tD_ALLERGY_MED_5 = 'tD_ALLERGY_MED_5';
    public static tD_ALLERGY_MED_REACTION_5 = 'tD_ALLERGY_MED_REACTION_5';
    public static tD_ALLERGY_FOOD_1 = 'tD_ALLERGY_FOOD_1';
    public static tD_ALLERGY_FOOD_REACTION_1 = 'tD_ALLERGY_FOOD_REACTION_1';
    public static tD_ALLERGY_FOOD_2 = 'tD_ALLERGY_FOOD_2';
    public static tD_ALLERGY_FOOD_REACTION_2 = 'tD_ALLERGY_FOOD_REACTION_2';
    public static tD_ALLERGY_FOOD_3 = 'tD_ALLERGY_FOOD_3';
    public static tD_ALLERGY_FOOD_REACTION_3 = 'tD_ALLERGY_FOOD_REACTION_3';
    public static tD_ALLERGY_FOOD_4 = 'tD_ALLERGY_FOOD_4';
    public static tD_ALLERGY_FOOD_REACTION_4 = 'tD_ALLERGY_FOOD_REACTION_4';
    public static tD_ALLERGY_FOOD_5 = 'tD_ALLERGY_FOOD_5';
    public static tD_ALLERGY_FOOD_REACTION_5 = 'tD_ALLERGY_FOOD_REACTION_5';
    public static tD_ALLERGY_ENV_1 = 'tD_ALLERGY_ENV_1';
    public static tD_ALLERGY_ENV_REACTION_1 = 'tD_ALLERGY_ENV_REACTION_1';
    public static tD_ALLERGY_ENV_2 = 'tD_ALLERGY_ENV_2';
    public static tD_ALLERGY_ENV_REACTION_2 = 'tD_ALLERGY_ENV_REACTION_2';
    public static tD_ALLERGY_ENV_3 = 'tD_ALLERGY_ENV_3';
    public static tD_ALLERGY_ENV_REACTION_3 = 'tD_ALLERGY_ENV_REACTION_3';
    public static tD_ALLERGY_ENV_4 = 'tD_ALLERGY_ENV_4';
    public static tD_ALLERGY_ENV_REACTION_4 = 'tD_ALLERGY_ENV_REACTION_4';
    public static tD_ALLERGY_ENV_5 = 'tD_ALLERGY_ENV_5';
    public static tD_ALLERGY_ENV_REACTION_5 = 'tD_ALLERGY_ENV_REACTION_5'; 

}
