import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionIRoutingModule } from './section-i-routing.module';
import { SectionIComponent } from './section-i.component';
import { SupportsComponent } from './supports/supports.component';
import { AddNewSupportComponent } from './supports/add-new-support/add-new-support.component';

@NgModule({
  declarations: [SectionIComponent, SupportsComponent, AddNewSupportComponent],
  imports: [
    CommonModule,
    SectionIRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule],
  entryComponents: [AddNewSupportComponent]
})
export class SectionIModule { }
