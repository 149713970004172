
export interface PhysicianContact {
  tdP_ACAT_ID: number,
  tdP_SECTION_ID: number,
  tdP_PRIMARY_IND: null,
  tdP_FIRST_NAME: string,
  tdP_INIT: string,
  tdP_LAST_NAME: string,
  tdP_TYPE: string,
  tdP_ADDRESS: string,
  tdP_ADDRESS2: string,
  tdP_CITY: string,
  tdP_STATE: string,
  tdP_ZIP_CODE: string,
  tdP_COUNTY: string,
  tdP_PHONE_PRIMARY: string,
  tdP_PHONE_SECONDARY: string,
  tdP_PHONE_FAX: string,
  tdP_EMAIL: string,
  tdP_OTHER_INFO: string,
  tdP_DATE_LAST_SEEN: string,
  tdP_PRESCRIBING_IND: string,
  tA_FIRST_NAME: string,
  tA_LAST_NAME: string
}

export interface NewPhysicianContext {
    Mode: NewPhysicianScreenMode,
    ACATID: string,
    SectionID: string,
    SelectedRecord: PhysicianContact
}

export enum NewPhysicianScreenMode {
    View,
    Edit,
    New
}
